import React from 'react';

import { Grid, Typography, Paper, PaperProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { formatter } from '../InputCN/customInputs';

interface Props {
  valorAtual: string;
  type: 'valor' | 'taxa';
  meta: string;
  paperProps?: PaperProps;
}

const useStyles = makeStyles(() => ({
  lineOut: { height: 8, backgroundColor: '#f4f4f4', margin: 8 },
  lineIn: { height: 8, backgroundColor: '#24c361' },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '17px 37px',
    borderRadius: 16,
    marginBottom: 12,
    width: '98%',
  },
  valorAtual: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  valorMeta: {
    fontWeight: 'bold',
  },
}));

function format(valor: number) {
  return formatter(valor.toString(10), false, true);
}

export function MetaCard({ valorAtual, type, meta, paperProps }: Props) {
  const classes = useStyles();
  const numberMeta = Number(meta) * 100;
  const numberValorAtual = Number(valorAtual) * 100;
  const percentCalc = (Number(valorAtual) / Number(meta)) * 100;
  const percent = percentCalc === Infinity ? 0 : percentCalc;

  return (
    <Paper elevation={2} className={classes.paper} {...paperProps}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography className={classes.valorAtual}>{`${
            type === 'valor' ? format(numberValorAtual) : `${Number(valorAtual).toFixed(3)}%`
          } (${percent ? percent.toFixed(2) : '0'})%`}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.lineOut} style={{ padding: 0 }}>
          <Grid item xs={12} className={classes.lineIn} style={{ maxWidth: `${percent}%` }}></Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>Meta ({type})</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.valorMeta}>
            {type === 'valor' ? format(numberMeta) : `${Number(meta).toFixed(3)}%`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
