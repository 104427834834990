import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { RelatorioNode, MutationRemoverRelatorioArgs } from 'types/graphql';

const removeBackgroudReportsMutation = gql`
  mutation RemoveBackgroudReportsMutation($ids: [ID]!) {
    removerRelatorio(ids: $ids) {
      relatorios {
        atualizadoEm
        criadoEm
        id
        status
        url
      }
    }
  }
`;

export interface RemoveBackgroudReportsMutation {
  removerRelatorio: {
    relatorios: RelatorioNode[];
  };
}

export function useRemoveBackgroudReportsMutation() {
  return useMutation<RemoveBackgroudReportsMutation, MutationRemoverRelatorioArgs>(removeBackgroudReportsMutation, {
    refetchQueries: ['getEmployeeData'],
  });
}
