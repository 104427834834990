import React, { useCallback, useEffect, useMemo } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { MenuItem as MuiMenuItem, Menu as MuiMenu, IconButton } from '@material-ui/core';
import { compareDesc, parseISO, format, formatISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { FaClock, FaTimes, FaCloudDownloadAlt } from 'react-icons/fa';

import { useRemoveBackgroudReportsMutation } from './removeReports';

import { IconContext } from 'components';
import { RelatorioStatus, RelatorioNode, FuncionarioType, FuncionarioTypeRelay } from 'types/graphql';
import { usePrevious } from 'utils/empty';
import { getEmployeeData } from 'utils/useGetEmployee';

type MuiMenuProps = React.ComponentProps<typeof MuiMenu>;

type Props = {
  reports: RelatorioNode[];
  anchorEl?: MuiMenuProps['anchorEl'];
  onClose?: MuiMenuProps['onClose'];
};

const reportStatusNames = {
  [RelatorioStatus.Completo]: { name: 'Finalizado', icon: FaCloudDownloadAlt },
  [RelatorioStatus.Erro]: { name: 'com Erro', icon: FaTimes },
  [RelatorioStatus.Iniciado]: { name: 'em Andamento', icon: FaClock },
};

export function MenuItemReport({ reports = [], anchorEl, onClose }: Props) {
  const orderedReports = useMemo(
    () => reports.sort((a: RelatorioNode, b: RelatorioNode) => compareDesc(parseISO(a.criadoEm), parseISO(b.criadoEm))),
    [reports],
  );

  const [removeReport] = useRemoveBackgroudReportsMutation();
  function onClickButton(report: RelatorioNode) {
    if (report.status === RelatorioStatus.Iniciado) return;

    if (report.url) window.open(report.url, '_blank');

    setTimeout(() => removeReport({ variables: { ids: [report.id] } }), 2000);
  }

  const previousReports = usePrevious(orderedReports);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    orderedReports.forEach((e, i) => {
      if (
        e.status === RelatorioStatus.Completo &&
        previousReports &&
        previousReports?.[i]?.status === RelatorioStatus.Completo
      ) {
        enqueueSnackbar('Relatório Finalizado', {
          variant: 'success',
          preventDuplicate: true,
          key: '@report:finished',
        });
      }

      if (
        e.status === RelatorioStatus.Erro &&
        previousReports &&
        previousReports?.[i]?.status === RelatorioStatus.Erro
      ) {
        enqueueSnackbar('Relatório teve erro', { variant: 'error', preventDuplicate: true, key: `@report:error` });
      }
    });
  }, [enqueueSnackbar, JSON.stringify(orderedReports)]);

  return (
    <MuiMenu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 10001 }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {orderedReports.map(report => {
        const Icon = reportStatusNames[report.status].icon;
        return (
          <MuiMenuItem key={report.id}>
            Relatório {report.nome} [{format(parseISO(report.criadoEm), 'dd/MM HH:mm')}]{' '}
            {reportStatusNames[report.status].name}{' '}
            <IconButton onClick={() => onClickButton(report)}>
              <IconContext icon={<Icon />} size={'25px'} />
            </IconButton>
          </MuiMenuItem>
        );
      })}
    </MuiMenu>
  );
}

export function useCacheAddReport() {
  const apolloClient = useApolloClient();

  return useCallback(() => {
    const query = getEmployeeData;
    type QueryType = { me: FuncionarioType };
    const queryValue = apolloClient.readQuery<QueryType>({ query, variables: { withReports: true } });
    if (!queryValue) return;
    apolloClient.writeQuery<QueryType>({
      query,
      data: {
        me: {
          ...queryValue.me,
          relatorios: [
            {
              id: '@cache:id',
              status: RelatorioStatus.Iniciado,
              funcionario: (null as unknown) as FuncionarioTypeRelay,
              criadoEm: formatISO(new Date()),
              atualizadoEm: formatISO(new Date()),
              url: 'url',
              nome: 'nome',
              __typename: 'RelatorioNode',
            },
            ...queryValue?.me.relatorios,
          ],
        },
      },
    });
  }, [apolloClient]);
}
