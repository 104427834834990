import React, { useMemo } from 'react';

import { useGetPermissionsQueries } from './useGetEmployee';

import { PermissaoDescricao, PermissaoDeGrupoDescricao, EntidadeTypeRelay } from 'types/graphql';

export function useGetPermissions() {
  const { groupPermissions, personalPermissions } = useGetPermissionsQueries();
  const permissions = useMemo(
    () =>
      Array.from(
        new Set(
          [
            personalPermissions.data?.permissoes.map(e => e.descricao),
            groupPermissions.data?.gruposPermissoesUserLogado.edges.flatMap(e =>
              e?.node?.permissoes.map(p => p.descricao),
            ),
          ].flat(),
        ),
      ),
    [groupPermissions, personalPermissions],
  );

  return [
    permissions as Array<PermissaoDescricao | PermissaoDeGrupoDescricao>,
    {
      loading: [groupPermissions.loading, personalPermissions.loading].some(Boolean),
      error: groupPermissions.error || personalPermissions.error,
    },
  ] as const;
}

export function useHasPermission(perms: PermissaoDescricao[]) {
  const [permissions] = useGetPermissions();
  const allowed = perms.filter(perm => permissions.includes(perm));
  return allowed.length > 0 && allowed;
}

export function useHasPermissionCallBack() {
  const [permissions] = useGetPermissions();
  return (perms: PermissaoDescricao[]) => perms.filter(perm => permissions.includes(perm)).length > 0;
}

export const checkPermission = (
  permission: PermissaoDescricao | PermissaoDeGrupoDescricao,
  entityId?: number | string | null,
  groupPermissions?: any,
  personalPermissions?: any,
) => {
  const permissaoSet = personalPermissions.data?.permissoes ?? [];
  const grupos =
    groupPermissions.data?.gruposPermissoesUserLogado.edges.map(({ node }: any) => ({
      entidadesIds: node.entidades.map((entidade: any) => entidade.entidade.pk),
      permissaoSet: node.permissoes.map((permissao: any) => permissao.descricao),
    })) ?? [];

  const allowed = [
    ...permissaoSet.filter(
      (perm: any) => perm.descricao === permission && Number(perm.entidade.pk) === Number(entityId),
    ),
    ...grupos.filter(
      (grupo: any) =>
        grupo.entidadesIds.includes(Number(entityId)) &&
        grupo.permissaoSet.includes(permission as PermissaoDeGrupoDescricao),
    ),
  ];
  return allowed.length > 0 && allowed;
};

export function useHasPermissionInEntity(
  permission: PermissaoDescricao | PermissaoDeGrupoDescricao,
  entityId?: number | string | null,
) {
  const { groupPermissions, personalPermissions } = useGetPermissionsQueries();

  return checkPermission(permission, entityId, groupPermissions, personalPermissions);
}

export function useHasPermissionInEntityCallBack(id?: number | string | null) {
  const { groupPermissions, personalPermissions } = useGetPermissionsQueries();
  const permissaoSet = personalPermissions.data?.permissoes ?? [];
  const grupos =
    groupPermissions.data?.gruposPermissoesUserLogado.edges.map(({ node }) => ({
      entidadesIds: node.entidades.map(entidade => entidade.entidade.pk),
      permissaoSet: node.permissoes.map(permissao => permissao.descricao),
    })) ?? [];
  return (
    permission: PermissaoDescricao | PermissaoDeGrupoDescricao,
    entityId: number | string | null | undefined = id,
  ) =>
    [
      permissaoSet.filter(perm => perm.descricao === permission && Number(perm.entidade.pk) === Number(entityId)),
      grupos.filter(
        grupo =>
          grupo.entidadesIds.includes(Number(entityId)) &&
          grupo.permissaoSet.includes(permission as PermissaoDeGrupoDescricao),
      ),
    ].flat().length > 0;
}

export function useEmployeeEntitys() {
  const { groupPermissions, personalPermissions } = useGetPermissionsQueries();
  const stores = React.useMemo(() => {
    const entitiesInPermission = (personalPermissions.data?.permissoes.map(e => e.entidade).filter(Boolean) ??
      []) as EntidadeTypeRelay[];

    const entitiesInGroups =
      groupPermissions.data?.gruposPermissoesUserLogado.edges.flatMap(({ node }) =>
        node.entidades.map(e => e.entidade),
      ) ?? [];

    const entityIds = new Set([
      ...entitiesInPermission.map(entity => entity.pk),
      ...entitiesInGroups.map(entity => entity.pk),
    ]);

    return Array.from(entityIds)
      .map(pk => entitiesInPermission.find(e => e.pk === pk) || entitiesInGroups.find(e => e.pk === pk))
      .filter(Boolean) as EntidadeTypeRelay[];
  }, [groupPermissions, personalPermissions]);

  return {
    stores,
    storesLoading: [groupPermissions.loading, personalPermissions.loading].some(Boolean),
    storeError: groupPermissions.error || personalPermissions.error,
  };
}

export function useEmployeeEntitysByPermission(
  permission: PermissaoDescricao | PermissaoDeGrupoDescricao,
  withGoals = false,
) {
  const { groupPermissions, personalPermissions } = useGetPermissionsQueries(withGoals);
  const stores = React.useMemo(() => {
    const entitiesInPermission =
      (personalPermissions &&
        personalPermissions.data?.permissoes
          .filter(e => e.descricao === permission)
          .map(e => e.entidade)
          .filter(Boolean)) ??
      [];

    const entitiesInGroups =
      (groupPermissions &&
        groupPermissions.data?.gruposPermissoesUserLogado.edges
          .filter(({ node }) => node.permissoes.find(e => e.descricao === permission))
          .flatMap(({ node }) => node.entidades.map(e => e.entidade))) ??
      [];

    const entityIds = new Set([
      ...entitiesInPermission.map(entity => entity.pk),
      ...entitiesInGroups.map(entity => entity.pk),
    ]);

    return Array.from(entityIds)
      .map(pk => entitiesInPermission.find(e => e.pk === pk) || entitiesInGroups.find(e => e.pk === pk))
      .filter(Boolean) as EntidadeTypeRelay[];
  }, [groupPermissions, personalPermissions, permission]);

  return {
    stores,
    storesLoading: [groupPermissions.loading, personalPermissions.loading].some(Boolean),
    storeError: groupPermissions.error || personalPermissions.error,
  };
}

export function useEmployeeEntitysByPermissions(permissions: (PermissaoDescricao | PermissaoDeGrupoDescricao)[]) {
  const { groupPermissions, personalPermissions } = useGetPermissionsQueries();
  const hasPermissionInArray = (permission: PermissaoDescricao | PermissaoDeGrupoDescricao) =>
    permissions.some(e => e === permission);

  const stores = React.useMemo(() => {
    const entitiesInPermission =
      personalPermissions.data?.permissoes
        .filter(e => hasPermissionInArray(e.descricao))
        .map(e => e.entidade)
        .filter(Boolean) ?? [];

    const entitiesInGroups =
      groupPermissions.data?.gruposPermissoesUserLogado.edges
        .filter(({ node }) => node.permissoes.find(e => hasPermissionInArray(e.descricao)))
        .flatMap(({ node }) => node.entidades.map(e => e.entidade)) ?? [];

    const entityIds = new Set([
      ...entitiesInPermission.map(entity => entity.pk),
      ...entitiesInGroups.map(entity => entity.pk),
    ]);

    return Array.from(entityIds)
      .map(pk => entitiesInPermission.find(e => e.pk === pk) || entitiesInGroups.find(e => e.pk === pk))
      .filter(Boolean) as EntidadeTypeRelay[];
  }, [groupPermissions, personalPermissions]);

  return {
    stores,
    storesLoading: [groupPermissions.loading, personalPermissions.loading].some(Boolean),
    storeError: groupPermissions.error || personalPermissions.error,
  };
}
