import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { FormContextValues } from 'react-hook-form';
import { FaPlusSquare, FaRegTrashAlt } from 'react-icons/fa';

import { IconContext, InputAdornmentIcon, InputCTL, MaskedInputCTL } from 'components';
import { useMobile } from 'utils/useMobile';
import 'styled-components/macro';

type GridValues = React.ComponentProps<typeof Grid>['xs'];

export type phonesSchema = {
  id: string;
  phone: string;
  description?: string | null | undefined;
};

type Props = {
  formHandlers: FormContextValues<any>;
  name: string;
  right: {
    title?: string;
    grid: GridValues;
    plusLine: string;
  };
  left: {
    title: string;
    grid: GridValues;
    format: string;
  };
  disabled?: boolean;
};

export function PhonesListInput({ left, right, name, disabled, formHandlers }: Props) {
  const [phones, setPhones] = useState([0]);
  const [phonesId, setPhonesId] = useState(1);
  const { register, unregister, control, watch } = formHandlers;
  const isMobile = useMobile();

  function addPhones() {
    setPhones(prevPhones => [...prevPhones, phonesId]);
    setPhonesId(prevCounter => prevCounter + 1);
  }

  function removeRow(id: number) {
    unregister(`${name}[${phones.findIndex(phone => phone === id)}].id`);
    setPhones(phones.filter(phone => phone !== id));
  }

  useEffect(() => {
    register(`${name}Size`);
  }, []);

  const formArraySize = watch(`${name}Size`);
  useEffect(() => {
    if (formArraySize !== undefined && formArraySize > 0) {
      setPhones(Array.from({ length: formArraySize }, (_, i) => i));
      setPhonesId(formArraySize + 1);
    }
  }, [formArraySize]);

  return (
    <>
      {phones.map((phone, i) => {
        const fieldName = `${name}[${phone}]`;
        register({ name: `${fieldName}.id` });

        return (
          <React.Fragment key={fieldName}>
            <Grid item xs={6} md={left.grid}>
              <MaskedInputCTL
                label={left.title}
                disabled={disabled}
                format={left.format}
                mask="_"
                control={control}
                name={`${fieldName}.phone`}
                endAdornment={
                  !disabled &&
                  isMobile &&
                  (phones.length === i + 1 ? (
                    <InputAdornmentIcon
                      label=""
                      onClick={addPhones}
                      icon={<IconContext icon={<FaPlusSquare />} color="#24C361" size="1.5rem" />}
                      css="width: auto"
                    />
                  ) : (
                    <InputAdornmentIcon
                      label=""
                      onClick={() => removeRow(phone)}
                      icon={<IconContext icon={<FaRegTrashAlt />} color="#F44336" size="1.5rem" />}
                      css="width: auto"
                    />
                  ))
                }
              />
            </Grid>
            <Grid item xs={6} md={right.grid}>
              <InputCTL
                label={right?.title ?? ''}
                fullWidth
                disabled={disabled}
                endAdornment={
                  !disabled &&
                  !isMobile &&
                  (phones.length === i + 1 ? (
                    <InputAdornmentIcon
                      label={right.plusLine}
                      onClick={addPhones}
                      icon={<IconContext icon={<FaPlusSquare />} color="#24C361" size="1.8rem" />}
                    />
                  ) : (
                    <InputAdornmentIcon
                      label="Remover Linha"
                      onClick={() => removeRow(phone)}
                      icon={<IconContext icon={<FaRegTrashAlt />} color="#F44336" size="1.8rem" />}
                    />
                  ))
                }
                control={control}
                name={`${fieldName}.description`}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
}
