import { Dictionary } from 'ts-essentials';

import { S3Client } from 'api';

export type ArgumentTypes<F> = F extends (...args: infer A) => any ? A : never;
export interface FileWithPreview extends File {
  id?: string;
  preview: FileReader['result'];
}

export async function createFile(url: string, name?: string | null, preview?: string) {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Origin: window.location.origin,
    },
  });

  const data = await response.blob();

  const file = new File([data], name || 'ANEXO', { type: data.type }) as FileWithPreview;

  if (preview) file.preview = preview;
  // file.preview = URL.createObjectURL(file);
  return file;
}

export function getKeyFromS3URL(url: string) {
  return new URL(url).pathname.split('.')[0].slice(1);
}

export async function uploadedAttachments<T extends Dictionary<File | undefined, string>>(anexos: T) {
  const uploadedEntries = await Promise.all(
    Object.entries(anexos).map(async ([key, anexo]) => {
      if (!anexo) return [key, undefined] as const;
      const attachmentUploaded = await S3Client.uploadFile(anexo);
      return [key, attachmentUploaded.location] as const;
    }),
  );
  return Object.fromEntries(uploadedEntries) as Record<keyof T, string>;
}

export async function getFiles<T extends Dictionary<string | undefined>>(anexos: T) {
  const downloadEntries = await Promise.all(
    Object.entries(anexos).map(async ([key, value]) => {
      if (value) {
        const file = await createFile(value, key, value);
        return [key, file] as const;
      }
      return [key, null] as const;
    }),
  );
  return Object.fromEntries(downloadEntries) as Record<keyof T, FileWithPreview>;
}
