import React from 'react';

import { Controller } from 'react-hook-form';

import { AttachmentInput } from './AttachmentInput';

type ControlledProps = React.ComponentProps<typeof AttachmentInput> &
  Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function AttachmentCTL({ control, name, multiple, ...rest }: ControlledProps) {
  return (
    <Controller
      as={AttachmentInput}
      name={name ?? ''}
      control={control}
      onChange={([value]) => (multiple ? value : value[0])}
      multiple={multiple}
      {...rest}
    />
  );
}
