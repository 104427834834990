import React from 'react';

import { Tooltip } from '@material-ui/core';

interface TooltipedTextProps {
  children: string | JSX.Element;
  fullText: string;
  placement?: 'top' | 'bottom' | 'bottom-start' | 'left' | 'right' | 'top-start' | 'top-end';
  arrow?: boolean;
}

export function TooltipedText({ children, fullText, placement, arrow }: TooltipedTextProps) {
  return (
    <Tooltip title={fullText} placement={placement} arrow={arrow}>
      <span>{children}</span>
    </Tooltip>
  );
}
