import React from 'react';

type ProvidersIconProps = {
  display?: string;
  color?: string;
  margin?: string;
};

export function ProvidersIcon({ color, display, margin }: ProvidersIconProps) {
  return (
    <span
      style={{
        width: '30px',
        height: '30px',
        display: display,
        justifyContent: 'center',
        alignItems: 'center',
        color: color,
        margin: margin,
      }}
      className="material-symbols-outlined"
    >
      diversity_3
    </span>
  );
}
