import 'styled-components/macro';
import React from 'react';

import { Box, Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { AttachmentCTL } from 'components/AttachmentInput';
import { useStyledTheme } from 'styles';
import { ContaDeClienteTipoConta } from 'types/graphql';
import { Masks } from 'utils/inputMasks';

import { TypographyCN } from '..';

type Props = {
  title?: string;
  account?: {
    pix: string;
    tipoPix: string;
    bank: string;
    agency: string;
    account: string;
    type: string;
    variation?: string | null;
  };
  bankSlip?: {
    barCode: string;
    dueDate: string;
    controlNumber: string;
  };
  client: {
    name: string;
    cpf: string;
  };
  totalValue: number;
  direction?: 'row' | 'column';
  attachmentInputProps?: React.ComponentProps<typeof AttachmentCTL>;
  boxProps?: React.ComponentProps<typeof Box>;
  renderBottom?: React.ReactNode;
};

export function PaymentView({
  title = 'Informações para pagamento',
  account,
  bankSlip,
  client,
  totalValue,
  direction = 'column',
  renderBottom: RenderBottom,
  boxProps,
  attachmentInputProps,
}: Props) {
  const themeContext = useStyledTheme();

  const bankSlipFont = `
    margin: 6px 0;
    @media screen and (max-width: 470px) {
      font-size: 0.708rem;
    }
  `;
  return (
    <Box
      bgcolor="#FFFCBCB1"
      borderRadius={4}
      width="100%"
      maxWidth="450px"
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      minHeight="445px"
      flexWrap="wrap"
      paddingBottom="16px"
      {...boxProps}
    >
      <Grid item xs={12} container justify="center">
        <TypographyCN
          css={`
            text-align: center;
          `}
          variant="h6"
        >
          {title}
        </TypographyCN>
      </Grid>
      <Grid item xs={12} container justify="space-around" direction={direction} alignItems="center" css="height: 65%;">
        <Box display="flex" flexDirection="column" alignItems="center">
          {account ? (
            <>
              <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
                {!account.pix ? 'Sem chave pix vinculada' : `CHAVE PIX: ${account.pix}`}
              </TypographyCN>
              <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
                {account.bank}
              </TypographyCN>
              <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
                AG {account.agency}
              </TypographyCN>
              <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
                C {account.account}
              </TypographyCN>
              {account.type !== ContaDeClienteTipoConta.Corrente && (
                <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
                  Variação {account.variation}
                </TypographyCN>
              )}
              <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
                Conta {account.type}
              </TypographyCN>
            </>
          ) : bankSlip ? (
            <>
              <TypographyCN variant="body2" weight="lighthen" css={bankSlipFont}>
                Código de Barras:
              </TypographyCN>
              <TypographyCN variant="body2" weight="lighthen" css={bankSlipFont}>
                {bankSlip.barCode}
              </TypographyCN>
              <TypographyCN variant="body2" weight="lighthen" css={bankSlipFont}>
                Número de Controle: {bankSlip.controlNumber}
              </TypographyCN>
              <TypographyCN variant="body2" weight="lighthen" css={bankSlipFont}>
                Data de Vencimento {bankSlip.dueDate}
              </TypographyCN>
            </>
          ) : (
            <TypographyCN variant="body2" weight="lighthen" css="margin: 6px 0">
              Sem conta cadastrada
            </TypographyCN>
          )}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TypographyCN variant="body2" weight="lighthen" css="margin: 3px 0">
            {client.name}
          </TypographyCN>
          <TypographyCN variant="body2" weight="lighthen" css="margin: 3px 0">
            {Masks.CPF_CNPJ(client?.cpf ?? '')}
          </TypographyCN>
          <TypographyCN variant="body2" css="margin: 8px 0">
            Valor a pagar
          </TypographyCN>
          <NumberFormat
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            thousandSeparator="."
            prefix="R$"
            displayType="text"
            value={totalValue}
            renderText={value => (
              <TypographyCN variant="body2" css="margin: 3px 0" setColor={themeContext.palette.secondary.main}>
                {value}
              </TypographyCN>
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={8} container justify="center">
        {RenderBottom ? RenderBottom : <AttachmentCTL {...attachmentInputProps} />}
      </Grid>
    </Box>
  );
}
