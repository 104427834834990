import 'styled-components/macro';

import React, { useEffect, useState } from 'react';

import { Box, IconButton, Link } from '@material-ui/core';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FieldErrors, ErrorMessage } from 'react-hook-form';
import { FaRegFileAlt, FaRegTrashAlt } from 'react-icons/fa';

import { ContentContainer, DropRoot, FileList, FileListItem } from './styles';
import { ArgumentTypes, FileWithPreview } from './utils';

import { IconContext, InputAdornmentIcon, TypographyCN } from 'components';
import { useMobile } from 'utils/useMobile';

type InnerProps = {
  errorMessage?: string | FieldErrors<any>;
  onDrop?: DropzoneOptions['onDrop'];
  onChange?: (files: FileWithPreview[]) => void;
  multiple?: boolean;
  value?: FileWithPreview[] | FileWithPreview;
  disabled?: boolean;
  name?: string;
};

function InnerAttachmentInput({ errorMessage, value, onDrop, onChange, multiple, disabled, name }: InnerProps) {
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  async function droping(...args: ArgumentTypes<DropzoneOptions['onDrop']>) {
    const accepteds = args[0];

    const acceptedsWithPreview = accepteds.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );

    setFiles(oldFiles => (multiple ? [...oldFiles, ...acceptedsWithPreview] : [acceptedsWithPreview[0]]));

    onDrop && onDrop(...args);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: droping,
    multiple,
    disabled,
  });

  function removeFile(index: number) {
    setFiles(oldFiles => oldFiles.filter((_, i) => i !== index));
  }

  useEffect(() => {
    onChange && onChange(files);
  }, [files]);

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) setFiles(value);
    else if (value instanceof File) setFiles([value]);
  }, [value]);

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview as string));
  }, []);

  return (
    <>
      <ContentContainer disabled={disabled}>
        {multiple && files.length > 0 && (
          <FileList>
            {files.map((file, index) => (
              <FileListItem key={`${file.name}-${file.lastModified}-${file.size}-${index}`}>
                <TypographyCN
                  as={Link}
                  css={`
                    margin-left: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-decoration: underline;
                  `}
                  href={file.preview}
                  target="_blank"
                  rel="noopener"
                >
                  {file.name}
                </TypographyCN>
                {!disabled ? (
                  <IconButton aria-label="delete" disabled={disabled} onClick={() => removeFile(index)}>
                    <IconContext icon={<FaRegTrashAlt />} color="#EF6D1C" />
                  </IconButton>
                ) : (
                  <Box marginRight="8px">
                    <IconContext icon={<FaRegFileAlt />} color="#898989" size="1.8rem" />
                  </Box>
                )}
              </FileListItem>
            ))}
          </FileList>
        )}

        <DropRoot>
          {!multiple && files[0] ? (
            <Box display="flex" alignItems="center" justifyContent={disabled ? 'center' : 'space-between'} flexGrow={1}>
              <TypographyCN
                as={Link}
                css={`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  max-width: 130px;
                  text-decoration: underline;
                `}
                href={files[0].preview}
                target="_blank"
                rel="noopener"
              >
                {files[0].name}
              </TypographyCN>
              {disabled || (
                <IconButton aria-label="delete" disabled={disabled} onClick={() => removeFile(0)}>
                  <IconContext icon={<FaRegTrashAlt />} color="#EF6D1C" />
                </IconButton>
              )}
            </Box>
          ) : (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <InputAdornmentIcon
                width={multiple && files.length > 0 ? '180px' : '135px'}
                label={multiple && files.length > 0 ? 'Anexar mais Arquivos' : 'Anexar Arquivo'}
                icon={<IconContext icon={<FaRegFileAlt />} color="#898989" size="1.8rem" />}
                disabled={disabled}
              />
            </div>
          )}
        </DropRoot>
      </ContentContainer>
      <TypographyCN component="span" variant="body2" align="center" weight="lighter" setColor="red">
        {errorMessage &&
          (typeof errorMessage === 'string' ? (
            errorMessage
          ) : (
            <ErrorMessage errors={errorMessage} name={name as string}>
              {({ message }) => message}
            </ErrorMessage>
          ))}
      </TypographyCN>
      {/* {errorMessage && (
        <TypographyCN component="span" variant="body2" align="center" weight="lighter" setColor="red">
          {errorMessage}
        </TypographyCN>
      )} */}
    </>
  );
}

type Props = {
  label?: string;
  labelProps?: React.ComponentProps<typeof TypographyCN>;
} & InnerProps;

export function AttachmentInput({ label, labelProps, ...rest }: Props) {
  const downBreakPoint = useMobile();

  return (
    <>
      {label ? (
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between" width="100%">
          <TypographyCN
            css="min-height: 32px;"
            component="label"
            variant={downBreakPoint ? 'body1' : 'h6'}
            align="center"
            weight="bold"
            {...labelProps}
          >
            {label}
          </TypographyCN>
          <InnerAttachmentInput {...rest} />
        </Box>
      ) : (
        <InnerAttachmentInput {...rest} />
      )}
    </>
  );
}
