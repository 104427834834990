import React from 'react';

import { Box, TypographyProps } from '@material-ui/core';

import { TypographyCN, IconContext } from '..';

import { spacing, SpacingProps } from '@material-ui/system';
import { FaRegFileAlt } from 'react-icons/fa';

import styled from 'styled-components/macro';

type Props = {
  label?: string;
  src?: string;
  name: string;
  secondary?: boolean;
  disabled?: boolean;
  fontSize?: string;
  labelProps?: TypographyProps;
};

const ViewContainer = styled.div<Pick<Props, 'secondary' | 'disabled'> & SpacingProps>`
  background: ${p => (p.secondary ? '#FAF9D9' : p.disabled ? 'rgba(0, 0, 0, 0.12)' : '#FFF')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  width: 100%;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: center;
  ${spacing}
`;

const Link = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  color: gray;
`;

const InnerLink = ({ src, name, secondary, disabled, ...rest }: Props & React.ComponentProps<typeof ViewContainer>) => (
  <ViewContainer {...{ secondary, disabled, ...rest }}>
    <IconContext icon={<FaRegFileAlt />} color="#898989" size="1.8rem" />
    <Link target="__blank" href={src}>
      {name}
    </Link>
  </ViewContainer>
);

export function ViewAttachment({
  label,
  fontSize,
  labelProps,
  ...rest
}: Props & React.ComponentProps<typeof ViewContainer>) {
  return label ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-end"
      width="100%"
      minHeight="90px"
    >
      <TypographyCN
        component="label"
        {...labelProps}
        variant="h6"
        align="left"
        weight="bold"
        color="gray"
        fontSize={fontSize}
      >
        {label}
      </TypographyCN>
      <InnerLink {...rest} />
    </Box>
  ) : (
    <InnerLink {...rest} />
  );
}
