import React from 'react';

import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { useMobile } from 'utils/useMobile';

interface MessageProps {
  message: {
    id: string;
    nome: string;
    texto: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 12,
    position: 'relative',
  },
  header: {
    position: 'absolute',
    display: 'inline-block',
    backgroundColor: '#fff',
    padding: '4px 12px 0 12px',
    zIndex: 0,
    fontWeight: 'bold',
    borderRadius: 8,
  },
  content: {
    marginTop: 12,
    padding: '26px 12px 12px 12px',
    backgroundColor: '#fff',
    zIndex: 10,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',
  },
}));

export function MessageCard({ message }: MessageProps) {
  const classes = useStyles();
  const history = useHistory();

  function navigateToMessageDetail(id: string) {
    console.log(id);
    // history.push(`/mensagem/visualizar/${id}`);
  }

  return (
    <Box className={classes.container} onClick={() => navigateToMessageDetail(message.id)}>
      <Typography className={classes.header} color="textPrimary">
        {message.nome}
      </Typography>
      <Box className={classes.content}>
        <Typography color="textSecondary">{message.texto}</Typography>
      </Box>
    </Box>
  );
}
