import React from 'react';

import { Select, TextField } from '@material-ui/core';
import { typography, TypographyProps } from '@material-ui/system';

import filterProps from 'utils/filterProps';

import styled from 'styled-components';

export type StyleProps = {
  //   width?: string;
  typography?: string;
  weight?: string;
  height?: string;
  setColor?: string;
  fontSize?: string;
};

interface StyledLabelSelectProps {
  color?: string;
  fontSize?: string;
  text?: string;
}

export const StyledLabelSelect: any = styled(filterProps<StyledLabelSelectProps>(Select, []))`
  .MuiSelect-icon {
    color: ${props => props.color};
  }

  .MuiInputBase-input {
    color: ${props => props.color};
    font-weight: bold;
    text-transform: ${props => props.text};
  }
`;

export const StyledSelect = styled(
  filterProps<StyleProps>(Select, ['setColor', 'typography', 'weight', 'height', 'fontSize']),
)<StyleProps>`
  background: #fff;
  box-shadow: 0px 3px 2px #00000005;
  height: ${props => props.height};

  & .MuiSelect-root {
    padding: 17px;
    color: ${props => props.setColor || '#45A9FF'};
    font-weight: ${props => props.weight || 'bold'};
    font-size: ${props => props.fontSize || '1.2rem'};

    @media (max-width: 470px) {
      padding: 18.5px 17px !important;
    }
  }

  & .MuiSelect-icon {
    font-size: 2rem;
    color: #898989;
    top: auto;
  }

  & .MuiSelect-iconFilled {
    right: 12px;
  }
`;

export const StyledTextInput = styled(TextField).withConfig({
  shouldForwardProp: prop => !['maxWidth', 'setColor', 'weight', 'fontSize'].includes(prop),
})<{ maxWidth?: 'string' | undefined; disabled?: boolean } & StyleProps>`
  max-width: ${p => p.maxWidth ?? '500px'};
  box-shadow: 0px 3px 2px #00000005;
  & .MuiFilledInput-root {
    padding: 0;
    ${p => (p.disabled ? '' : 'background: #FFF;')}
  }

  & .MuiFilledInput-input {
    ${p => (p.disabled ? '' : 'background: #FFF;')}
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    padding: 15px !important;
    color: ${props => props.setColor || '#45A9FF'};
    font-weight: ${props => props.weight || 'bold'};
    font-size: ${props => props.fontSize || '1.2rem'};

    &:hover {
      ${p => (p.disabled ? '' : 'background: rgba(0, 0, 0, 0.09);')}
    }

    &:focus {
      ${p => (p.disabled ? '' : 'background: rgba(0, 0, 0, 0.09);')}
    }
  }

  & .MuiIconButton-label {
    font-size: 2rem;
    color: #898989;
  }

  & .MuiAutocomplete-endAdornment {
    top: auto;
    right: 12px;
  }
`;

const InputTags = styled.span`
  margin-left: 4px;
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #45a9ff;
  font-weight: bold;
  font-size: 1rem;

  @media screen and (${p => p.theme.breakpoints.down('lg')}) {
    margin-left: 1px;
    width: 55%;
  }

  @media (max-width: 470px) {
    font-size: 0.875rem;
  }

  ${typography}
`;

export function makeRenderTags<T>(getLabel: (option: T) => void, typographyProps?: TypographyProps) {
  return (values: T[]) => (
    <InputTags {...typographyProps}>{values.map(option => getLabel(option)).join(', ')}</InputTags>
  );
}
