import styled from 'styled-components';

type StyleProps = {
  height?: string;
};

export default styled.header<StyleProps>(
  ({ height }) => `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  height: ${height};
  padding: 4px 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
  margin-bottom: 15px;

  @media(max-width: 470px){
    margin-bottom: 0;
  }

  box-sizing: border-box;
  flex-grow: 0;
  flex-basis: 100%;
  flex-direction: column;


  & > * {
    margin: 8px 0;
  } 
`,
);
