import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { FaWindowClose } from 'react-icons/fa';

import { gerarNovaSenhaMutation } from './query';
import { ForgotPasswordTitle, ForgotPasswordButton, InputContainer } from './styles';

import { TypographyCN } from 'components/TypographyCN';
import { GerarNovaSenhaFuncionarioMutation, MutationGerarNovaSenhaFuncionarioArgs } from 'types/graphql';

type Props = {
  open: boolean;
  onClose: () => void;
} & React.ComponentPropsWithoutRef<typeof MuiDialog>;

type ForgotPasswordFormType = {
  userEmail: string;
};

export function ForgotPassword({ open, onClose }: Props) {
  const { control, getValues } = useForm<ForgotPasswordFormType>();

  const [gerarSenha, { data, error }] = useMutation<
    { gerarNovaSenhaFuncionario: GerarNovaSenhaFuncionarioMutation },
    MutationGerarNovaSenhaFuncionarioArgs
  >(gerarNovaSenhaMutation);

  async function onSubmit() {
    await gerarSenha({
      variables: { emailFuncionario: getValues().userEmail },
    });
  }

  return (
    <MuiDialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          padding: 0,
        }}
        disableTypography
      >
        <IconButton onClick={onClose}>
          <FaWindowClose style={{ color: '#FF0000', fontSize: 35 }} />
        </IconButton>
      </DialogTitle>
      <ForgotPasswordTitle disableTypography>Esqueceu a senha ?</ForgotPasswordTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <DialogContentText style={{ color: '#454545', fontSize: 20, margin: 5 }}>
          Informe seu e-mail cadastrado que enviaremos instruções para recuperar a senha.
        </DialogContentText>
        <InputContainer>
          <Controller
            as={
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email"
                error={!!error}
                helperText={!!error && 'Email não encontrado'}
              />
            }
            {...{ control, name: 'userEmail' }}
          />
        </InputContainer>
        {data?.gerarNovaSenhaFuncionario.ok && (
          <TypographyCN
            fontSize="22px"
            fontWeight="lighter"
            setColor="#24C361"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            mt={-3}
          >
            Senha enviada por e-mail.
          </TypographyCN>
        )}
      </DialogContent>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <DialogActions style={{ marginBottom: '20px' }}>
          <ForgotPasswordButton type="submit" onClick={onSubmit} typography="caption">
            ENVIAR
          </ForgotPasswordButton>
        </DialogActions>
      </div>
    </MuiDialog>
  );
}
