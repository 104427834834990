import React from 'react';

import { Container as MuiContainer, Grid } from '@material-ui/core';

import { useMobile } from 'utils/useMobile';

import styled from 'styled-components/macro';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const Container = styled.div`
  background: #f5f7fb;
  padding: ${props => props.theme.spacing(6)}px;
  box-sizing: border-box;
  overflow-x: hidden;

  min-height: calc(100% - 64px);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 750px) {
    padding: 18px;
    padding-bottom: 60px;
  }
`;

export function PageContainer({ children, className, style }: Props) {
  const downBreakPoint = useMobile();
  return (
    <MuiContainer>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        css={`
          ${downBreakPoint ? undefined : 'margin: 20px 8px;'}
        `}
        className={className}
        style={style}
      >
        {children}
      </Grid>
    </MuiContainer>
  );
}
