import 'react-hot-loader';
import 'react-app-polyfill/stable';
import 'core-js/features/promise/all-settled';
import React from 'react';

import { init as SentryInit } from '@sentry/react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE)
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIROMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
