import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export function useIsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  return data.isLoggedIn as boolean;
}
