import React from 'react';

import { ProvidersIcon } from 'icons/pendingActions';
import PointOfSaleIcon from 'icons/PointOfSaleIcon';
import {
  FaCalculator,
  FaChartPie,
  FaFileInvoiceDollar,
  FaHandHoldingUsd,
  FaIdBadge,
  FaRegFileExcel,
  FaSitemap,
  FaStoreAlt,
  FaTachometerAlt,
  FaUsers,
  FaPlusSquare,
  FaCog,
  FaFilter,
  FaHandshake,
  FaTelegramPlane,
  FaWhatsapp,
  FaExclamationCircle,
} from 'react-icons/fa';
import { ImTarget } from 'react-icons/im';
import { MdReplay, MdPerson } from 'react-icons/md';

import { IconContext } from 'components/IconContext';
import { PermissaoDescricao } from 'types/graphql';
import microFrontUrlWithToken from 'utils/redirectToMicroFront';

interface BottomMenuItem {
  to: string;
  primary: string;
  exact?: boolean;
  icon: React.ReactElement;
  permission?: PermissaoDescricao[];
}

const items: BottomMenuItem[] = [
  {
    to: '/dashboard',
    primary: 'Visão Geral',
    exact: true,
    icon: <IconContext icon={<FaTachometerAlt size={24} />} color="#EF6915" />,
  },
  {
    to: '/dashboard/lojas',
    primary: 'Lojas',
    icon: <IconContext icon={<FaStoreAlt size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerEntidade],
  },
  {
    to: '/dashboard/grupos',
    primary: 'Grupos',
    icon: <IconContext icon={<FaSitemap size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerGrupoPermissoes],
  },
  {
    to: '/dashboard/funcionarios',
    primary: 'Funcionários',
    icon: <IconContext icon={<FaIdBadge size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerFuncionario],
  },
  {
    to: '/dashboard/clientes',
    primary: 'Clientes',
    icon: <IconContext icon={<FaUsers size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerCliente],
  },
  {
    to: microFrontUrlWithToken('contas'),
    primary: 'Contas',
    icon: (
      <IconContext
        icon={
          <svg width="24px" height="24px" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5516 0.351562H3.2C2.54375 0.351562 1.99688 0.588542 1.55938 1.0625C1.12188 1.5 0.903126 2.02865 0.903126 2.64844L0.848438 21.3516C0.848438 21.9714 1.06719 22.5182 1.50469 22.9922C1.97865 23.4297 2.54375 23.6484 3.2 23.6484H17.2C17.8563 23.6484 18.4031 23.4297 18.8406 22.9922C19.3146 22.5182 19.5516 21.9714 19.5516 21.3516V7.35156L12.5516 0.351562ZM8.94219 19L4.84063 14.8438L6.48125 13.2031L8.94219 15.7188L13.9188 10.7422L15.5594 12.3828L8.94219 19ZM11.3484 8.5V2.10156L17.8016 8.5H11.3484Z"
              fill="#EF6915"
            />
          </svg>
        }
      />
    ),
    permission: [PermissaoDescricao.VerContaPagar],
  },
  {
    to: microFrontUrlWithToken('cobrancas'),
    primary: 'Cobranças',
    icon: (
      <IconContext
        icon={
          <>
            <FaExclamationCircle
              color="#24c361"
              style={{
                transform: 'translateX(-20%)',
                width: '0.75rem',
                marginRight: '1rem',
                position: 'absolute',
                marginTop: '-1rem',
              }}
            />
            <PointOfSaleIcon color={'#EF6915'} className="icon" />
          </>
        }
      />
    ),
    permission: [PermissaoDescricao.VerCobranca],
  },
  // {
  //   to: '/dashboard/reembolsos',
  //   primary: 'Reembolsos',
  //   icon: <IconContext icon={<FaHandHoldingUsd size={24} />} color="#EF6915" />,
  //   permission: [PermissaoDescricao.VerPedidoReembolso],
  // },
  {
    to: '/dashboard/emprestimos',
    primary: 'Empréstimos',
    icon: <IconContext icon={<FaFileInvoiceDollar size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerEmprestimo],
  },
  {
    to: '/dashboard/maquinas',
    primary: 'Máquinas',
    icon: <IconContext icon={<FaCalculator size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerMaquina],
  },
  {
    to: '/dashboard/relatorios',
    primary: 'Relatórios',
    icon: <IconContext icon={<FaRegFileExcel size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.FechamentoCaixaRelatorio],
  },
  {
    to: '/dashboard/taxas',
    primary: 'Taxas',
    icon: <IconContext icon={<FaChartPie size={24} />} color="#EF6915" />,
    permission: [
      PermissaoDescricao.DefinirTaxaLucroEntidade,
      PermissaoDescricao.DefinirTaxaMinimaEntidade,
      PermissaoDescricao.DefinirTaxaSegurancaEntidade,
    ],
  },
  {
    to: '/dashboard/metas',
    primary: 'Metas',
    icon: <IconContext icon={<ImTarget size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerMeta],
  },
  {
    to: '/dashboard/crm',
    primary: 'CRM',
    icon: (
      <div style={{ position: 'relative', height: 28 }}>
        <IconContext
          icon={<MdPerson size={10} style={{ top: 11, left: 9, position: 'absolute' }} />}
          className="icon"
          color="#EF6915"
        />
        <IconContext icon={<MdReplay size={28} />} className="icon" color="#EF6915" />
      </div>
    ),
    permission: [PermissaoDescricao.VerLeads],
  },
  {
    to: '/dashboard/prospeccao',
    primary: 'Prospecção',
    icon: <IconContext icon={<FaFilter size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerLeads],
  },
  {
    to: '/dashboard/repasses',
    primary: 'Repasses',
    icon: <IconContext icon={<FaHandshake size={24} />} color="#EF6915" />,
    permission: [
      PermissaoDescricao.DashboardParceiro,
      PermissaoDescricao.DashboardFuncionarioParceiro,
      PermissaoDescricao.VerTabelaParceiro,
      PermissaoDescricao.VerParceiro,
    ],
  },
  {
    to: '/dashboard/acoes',
    primary: 'Adicionar',
    icon: <IconContext icon={<FaPlusSquare size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.FechamentoCaixaRelatorio],
  },
  {
    to: '/dashboard/envios',
    primary: 'Envios',
    icon: <IconContext icon={<FaTelegramPlane size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.DashboardEnviosSms],
  },
  {
    to: '/dashboard/zeki-bot',
    primary: 'Zeki Bot',
    icon: <IconContext icon={<FaWhatsapp />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerBotZeki],
  },
  {
    to: microFrontUrlWithToken('fornecedores'),
    primary: 'Fornecedores',
    icon: <IconContext icon={<ProvidersIcon color="#EF6915" />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerFornecedor],
  },
  {
    to: '/dashboard/configuracao',
    primary: 'Configuração',
    icon: <IconContext icon={<FaCog size={24} />} color="#EF6915" />,
    permission: [PermissaoDescricao.VerCliente],
  },
];

export default items;
