import { KeyboardDatePicker } from '@material-ui/pickers';

import styled from 'styled-components/macro';

type DateProps = React.ComponentProps<typeof KeyboardDatePicker>;

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiFilledInput-root {
    background: #fff;
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }
  margin: 0;
  box-shadow: 0px 3px 2px #00000005;
  input {
    padding: 21px 12px 16px;
  }

  @media screen and (max-width: 470px) {
    .MuiInputAdornment-positionEnd {
      margin-left: -22px;
    }
  }
` as React.ComponentType<DateProps>;
