import { Drawer as MuiDrawer, Paper } from '@material-ui/core';

import styled, { css } from 'styled-components/macro';

export const GridMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 2fr);
  grid-gap: 2px;
  height: 100%;
  font-size: 10px;

  a {
    :nth-child(6),
    :nth-child(7),
    :nth-child(8),
    :nth-child(9),
    :nth-child(10) {
      margin-top: 0.25rem;
    }

    :nth-child(6) {
      span {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
`;

export const BorderMenu = styled.div`
  background: #00000045;
  height: 5px;
  width: 70px;
  border-radius: 5px;
`;

export const BarMenu = styled.div`
  position: fixed;
  margin-top: 1.5rem;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
`;

export const CircleIcon = styled(Paper)`
  width: 50px;
  height: 50px;
  border-radius: 200px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 5px;
`;

export const ButtonMenu = styled.div`
  position: absolute;
  right: 0;
  bottom: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

export const GoBackButton = styled.div`
  position: absolute;
  left: 0;
  bottom: 10px;
  margin-left: 10px;
  width: 40px;
  height: 40px;
`;

export const PageName = styled.p`
  text-align: center;
  position: relative;
  font-size: 14px;
  color: #f59019;
  font-weight: 600;
`;

const drawerOpen = css`
  height: 250px !important;
  overflow-x: hidden;
  transition: ${p =>
    p.theme.transitions.create('height', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.enteringScreen,
    })};
`;

const drawerClose = css`
  transition: ${p =>
    p.theme.transitions.create('height', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen,
    })};
  height: 70px !important;
`;

export const Drawer = styled(MuiDrawer)`
  height: ${p => p.theme.spacing(7) + 1}px;
  flex-shrink: 0;
  white-space: nowrap;
  overflow-x: hidden;

  & > div {
    position: fixed;
    bottom: 0;
    transition: height;
    transition: background;
    overflow: unset;
    ${p => (p.open ? drawerOpen : drawerClose)}
  }
`;
