import styled, { css } from 'styled-components';

interface ShipPillProps {
  isActive?: boolean;
}

interface HeaderShipContainerProps {
  marginTop: boolean;
}

export const HeaderShipContainer = styled.div<HeaderShipContainerProps>`
  width: 100%;
  display: flex;

  @media (max-width: 860px) {
    flex-direction: column;

    section {
      margin-top: 1rem;
    }
  }

  ${props =>
    props.marginTop &&
    css`
      margin-top: 1.5rem;
    `}

  div {
    margin: auto 1rem;
    flex: 1;
    height: 1px;
    background-color: #121213;
  }

  section {
    display: flex;
  }
`;

export const ShipPill = styled.button<ShipPillProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  background-color: ${props => (props.isActive ? '#24C361' : props.disabled ? 'inherit' : '#d9d9d9')};
  color: ${props => (props.isActive ? 'white' : props.disabled ? '#d9d9d9' : '#000')};

  border: ${props => (props.disabled ? '1px solid #d9d9d9' : 'none')};
  border-radius: 1rem;

  padding: 0.6rem 1.2rem;
  margin: 0 0.5rem;

  @media (max-width: 560px) {
    padding: 0.4rem 0.8rem;
    margin: 0 0.35rem;
    font-size: 0.8rem;
  }
`;
