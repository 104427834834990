import chroma from 'chroma-js';
import { formatISO, isAfter, isBefore } from 'date-fns';

import { DashboardTableData } from '../tableInfo';

export function unShiftTableData(tableData: DashboardTableData[] | null | undefined) {
  tableData &&
    tableData.unshift({
      id: '',
      data: '',
      valorMeta: '',
      taxaMeta: '',
      valorAcumulado: '',
      taxaAcumulada: '',
    });
  return tableData;
}

type ProgressProps = {
  totalAlcancado: number;
  totalMeta: number;
  diariaAlcancada: number;
  diariaMeta: number;
  dataMeta: string;
  dataDia: string;
};

export function setValueAndColorProgress({
  totalAlcancado,
  totalMeta,
  diariaAlcancada,
  diariaMeta,
  dataMeta,
  dataDia,
}: ProgressProps) {
  const totalProgress = Number((totalAlcancado / totalMeta) * 100).toFixed(0);
  const dailyProgress = diariaMeta ? Number((diariaAlcancada / diariaMeta) * 100).toFixed(0) : 0;

  const todayDate = formatISO(new Date(), { representation: 'date' });

  const goalsDate = {
    day: dataMeta.slice(0, 2),
    month: dataMeta.slice(3, 5),
    year: dataMeta.slice(6, 10),
  };

  const pastDay = isBefore(
    new Date(Number(goalsDate.year), Number(goalsDate.month), Number(goalsDate.day)),
    new Date(Number(todayDate.slice(0, 4)), Number(todayDate.slice(5, 7)), Number(todayDate.slice(8, 10))),
  );
  const futureDay = isAfter(
    new Date(Number(goalsDate.year), Number(goalsDate.month), Number(goalsDate.day)),
    new Date(Number(todayDate.slice(0, 4)), Number(todayDate.slice(5, 7)), Number(todayDate.slice(8, 10))),
  );
  const isLastDay = dataDia === todayDate;

  function getColor(percent: number) {
    if (percent < 100 && dataDia < todayDate) {
      return '#EF1D15';
    }
    if (percent < 100 && !isLastDay) {
      return '#F59019';
    }
    if (percent >= 100) {
      return '#24C361';
    }
  }

  function getDailyColor(percent: number) {
    if (pastDay && !futureDay) {
      if (percent >= 100) {
        return '#24C361';
      } else return '#EF1D15';
    }

    if (isLastDay) {
      if (percent >= 100) {
        return '#24C361';
      } else return '#F59019';
    }
  }

  return {
    totalProgress,
    dailyProgress,
    totalColor: getColor(Number(totalProgress)),
    dailyColor: getDailyColor(Number(dailyProgress)),
    pastDay,
    futureDay,
  };
}

export function getAlmostRandomColor(n: number) {
  return chroma
    .scale(['#EF6915', '#24C361', '#45A9FF'])
    .mode('lab')
    .colors(n);
}
