import React from 'react';

import { ResponsiveBar, BarProps } from '@nivo/bar';

interface Props {
  data: Record<string, string | number>;
  keys: string[];
  config?: BarProps;
  index: string;
}

export const LinearGraph = ({ data, keys, config }: Props) => (
  <ResponsiveBar
    data={[data]}
    keys={keys}
    colors={({ id, data }: any) => data[`${id}Color`]}
    indexBy="value"
    // tooltip={(d: any) => (
    //   <TypographyCN>
    //     {d.id}:{formatToBRL(d.value).slice(2)}
    //   </TypographyCN>
    // )}
    padding={0.5}
    layout="horizontal"
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={null}
    enableGridY={false}
    enableLabel={false}
    // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    legends={[]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    {...config}
  />
);
