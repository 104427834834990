import styled from 'styled-components/macro';

export default styled.div`
  height: 100vh;
  box-sizing: border-box;
  padding-left: 24px;
  overflow-x: auto;
  outline: currentcolor none 0px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ContentContainer = styled.div`
  display: flex;
  height: auto;
  align-items: flex-start;
  justify-content: space-evenly;
  min-width: 100%;
  width: auto;
  flex-shrink: 0;

  height: 0;
  margin-top: -310px;

  & > * {
    margin: 0 18px;
  }
  @media screen and (max-width: 1580px) {
    & > :last-child {
      margin-right: 38px;
    }
  }
`;
