import React from 'react';

import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

type GridItemLinkProps = {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  exact?: boolean;
};

export function GridItemLink({ icon, primary, exact, to }: GridItemLinkProps) {
  return (
    <NavLink
      to={to}
      activeClassName="active"
      exact={exact}
      style={{
        textAlign: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
      }}
    >
      <span>{icon}</span>
      <p style={{ fontSize: 10, color: '#000', textDecoration: 'none', fontWeight: 500 }}>{primary}</p>
    </NavLink>
  );
}

export function GridItemExternalLink({ icon, primary, to }: GridItemLinkProps) {
  return (
    <a
      href={to}
      style={{
        textAlign: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
      }}
    >
      <span>{icon}</span>
      <p style={{ fontSize: 10, color: '#000', textDecoration: 'none', fontWeight: 500 }}>{primary}</p>
    </a>
  );
}

type GridItemClickProps = {
  icon?: React.ReactElement;
  primary: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export function GridItemClick({ icon, primary, onClick }: GridItemClickProps) {
  return (
    <Box
      onClick={onClick}
      style={{
        textAlign: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
      }}
    >
      <span>{icon}</span>
      <p style={{ fontSize: 10, color: '#000', textDecoration: 'none', fontWeight: 500 }}>{primary}</p>
    </Box>
  );
}
