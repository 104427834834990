import { AppBar, Drawer, Typography } from '@material-ui/core';

import { theme } from 'styles';
import { StyledFilter } from 'utils/filterProps';

import styled, { css, CSSObject } from 'styled-components/macro';

const drawerWidth = 258;

export const StyledDrawerAppBar = styled(AppBar)`
  /* -index: ${p => p.theme.zIndex.drawer + 1}; /*/
  background: transparent linear-gradient(180deg, #f59019 0%, #f26900 100%);
  z-index: 10000;
`;

const drawerOpen = css`
  width: ${drawerWidth}px;
  overflow-x: hidden;
  transition: ${p =>
    p.theme.transitions.create('width', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.enteringScreen,
    })};
`;

const drawerClose = css`
  transition: ${p =>
    p.theme.transitions.create('width', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen,
    })};
  overflow-x: hidden;
  width: ${p => p.theme.spacing(7) + 4}px;
`;

type StyledDrawerProps = {
  open?: boolean;
};

export const StyledDrawer = styled(Drawer)<StyledDrawerProps>`
  width: ${p => p.theme.spacing(7) + 1}px;
  flex-shrink: 0;
  white-space: nowrap;
  overflow-x: hidden;

  & > div {
    position: fixed;
    left: 0;
    transition: width;
    ${p => (p.open ? 'width: 100vw !important;' : drawerClose)}
    transition: background;
    background: ${p => (p.open ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0,0,0,0)')};
    &:focus {
      background: red;
    }
  }
` as StyledFilter<typeof Drawer, StyledDrawerProps>;

export const DrawerItemsContainer = styled.div<{ openDrawer?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  background: white;
  overflow: hidden;
  overflow-y: auto;

  ${p => (p.openDrawer ? drawerOpen : drawerClose)}

  .active .icon {
    color: ${theme.palette.primary.main} !important;
  }
`;

export const StyledMainContent = styled.main`
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AppBarSpacer = styled.div(p => p.theme.mixins.toolbar as CSSObject);

export const StyledAppBarTitle = styled(Typography)`
  flex-grow: 1;
  display: none;
  ${p => p.theme.breakpoints.up('sm')} {
    display: block;
  }
`;
