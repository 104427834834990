import { Paper } from '@material-ui/core';

import { TypographyCN } from 'components/TypographyCN';
import { ITheme } from 'styles';

import styled from 'styled-components/macro';

export const PaperContainer = styled(Paper)`
  width: 185px;
  height: 225px;
  background: #fff;

  @media screen and (max-width: 470px) {
    width: 362px;
    height: 85px;
    margin-top: 12px;
  }

  padding: ${props => (props.theme as ITheme).spacing(1)}px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PaperContainerMobile = styled(Paper)`
  display: flex;
  flex-direction: row;
  /* min-width: 362px;
  max-width: 362px; */
  height: 54px;
  margin-bottom: 6px;
  /* @media screen and (max-width: 372px) {
    min-width: 348px;
  }
  @media screen and (max-width: 321px) {
    min-width: 306px;
  } */
  width: 100%;
`;

export const HeaderTitle = styled(TypographyCN)`
  font-size: 1rem;
  color: #f59019;
  margin-left: 8px;

  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const HeaderValue = styled(TypographyCN)`
  font-size: 1.2rem;
  color: #454545;
  margin-right: 0px;
`;

export const HeaderValueBalance = styled(TypographyCN)`
  font-size: 1.2rem;
  color: #454545;

  @media screen and (max-width: 367px) {
    margin-right: 6px;
  }
`;

export const PlusIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-right: 5px;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;
