const microFrontUrlWithToken = (module: 'contas' | 'cobrancas' | 'fornecedores', path = '') => {
  const userToken = localStorage.getItem('token') || sessionStorage.getItem('token');
  return `${process.env.REACT_APP_MICRO_FRONTEND_URL}/${module}${path}?token=${userToken}`;
};

export const recreateUrlWithToken = (url = '') => {
  let externalUrlWithToken = url;
  if (url?.includes('token=null')) {
    const userToken = localStorage.getItem('token') || sessionStorage.getItem('token');
    externalUrlWithToken = `${url.split('?token=null')[0]}?token=${userToken}`;
  }
  return externalUrlWithToken;
};

export default microFrontUrlWithToken;
