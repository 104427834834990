import React from 'react';

import { ResponsiveBar, BarProps } from '@nivo/bar';
import { formatToBRL } from 'brazilian-values';

interface Props {
  data: Record<any, any>[];
  keys: string[];
  config?: BarProps;
  index: string;
}

export const BarGraph = ({ data, keys, config, index }: Props) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={index}
    colors={({ data }: any) => data.color}
    label={(d: any) =>
      ((
        <>
          <tspan>{formatToBRL(d.value).slice(2)}</tspan>
        </>
      ) as unknown) as string
    }
    // tooltip={(d: any) => (
    //   <TypographyCN>
    //     {d.indexValue}:{formatToBRL(d.value).slice(2)}
    //   </TypographyCN>
    // )}
    layout="horizontal"
    {...config}
    margin={{ top: 50, right: 20, bottom: 50, left: 120 }}
    padding={0.3}
    //@ts-ignore
    colorBy="indexValue"
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);
