import React from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { FaSignOutAlt } from 'react-icons/fa';

import { IconContext } from 'components/IconContext';
import { ListItemClick } from 'components/ListItemLink';

export function LogoutButton({ disableTooltip }: { disableTooltip?: boolean }) {
  const client = useApolloClient();

  return (
    <ListItemClick
      primary="Sair"
      icon={<IconContext icon={<FaSignOutAlt />} color="#F59019" />}
      onClick={async () => {
        await client.clearStore();
        client.writeData({ data: { isLoggedIn: false } });
        localStorage.clear();
      }}
      disableTooltip={disableTooltip}
    />
  );
}
