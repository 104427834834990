import { useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-boost';
import { gql } from 'apollo-boost';
import { DeepRequired } from 'ts-essentials';

import { GraphqlRelayType } from './graphql';
import { useHasPermissionCallBack } from './useHasPermission';
import { useLocalStorage } from './useStorage';

import { FuncionarioType, PermissaoType, GrupoDePermissoesTypeRelay, PermissaoDescricao } from 'types/graphql';

const getLoggedEmployee = gql`
  query getLoggedEmployee {
    employee @client {
      id
      nome
      email
      categoria
      tipoContrato
    }
  }
`;

export const getEmployeeData = gql`
  query getEmployeeData($withReports: Boolean = false) {
    me {
      id
      nome
      email
      categoria
      tipoContrato
      relatorios @include(if: $withReports) {
        id
        status
        url
        criadoEm
        atualizadoEm
        nome
      }
      permissaoglobal {
        origens
        funcionarios
        contasAPagar
        lojas
        emprestimos
      }
    }
  }
`;

export function useGetLoggedEmployee() {
  const { data, loading, error } = useQuery<{ employee: FuncionarioType }>(getLoggedEmployee);
  return { data: data?.employee as FuncionarioType, loading, error };
}

export function useGetLoggedEmployeeNoCache(withReports = false) {
  const hasPermission = useHasPermissionCallBack();

  const { data, loading, error } = useQuery<{ me: FuncionarioType }>(getEmployeeData, {
    pollInterval: hasPermission([
      PermissaoDescricao.ContaPagarRelatorio,
      PermissaoDescricao.PedidoReembolsoRelatorio,
      PermissaoDescricao.FechamentoCaixaRelatorio,
    ])
      ? 180000
      : 0,
    variables: { withReports },
  });
  return { data: data?.me, loading, error };
}

const getPersonalPermissionQuery = gql`
  query GetPersonalPermissionQuery($withGoals: Boolean!) {
    permissoes {
      id
      descricao
      entidade {
        pk
        id
        taxaMinima
        taxaSeguranca
        meta @include(if: $withGoals) {
          edges {
            node {
              id
            }
          }
        }
        nomeIdentificacao
        mensagemInvoice
      }
    }
  }
`;

export const getGroupPermissionQuery = gql`
  query GetGroupPermissionQuery($withGoals: Boolean!, $permissao_Descricao: String, $permissao_Descricao_In: String) {
    gruposPermissoesUserLogado(
      permissao_Descricao: $permissao_Descricao
      permissao_Descricao_In: $permissao_Descricao_In
    ) {
      edges {
        node {
          pk
          id
          permissoes {
            id
            descricao
          }
          nome
          entidades {
            id
            entidade {
              pk
              id
              taxaMinima
              taxaSeguranca
              meta @include(if: $withGoals) {
                edges {
                  node {
                    id
                  }
                }
              }
              nomeIdentificacao
              mensagemInvoice
            }
          }
        }
      }
    }
  }
`;

export function useGetPermissionsQueries(withGoals = false) {
  const personalPermissions = useQuery<{ permissoes: DeepRequired<PermissaoType>[] }>(getPersonalPermissionQuery, {
    fetchPolicy: 'cache-first',
    variables: { withGoals },
  });
  const groupPermissions = useQuery<{ gruposPermissoesUserLogado: GraphqlRelayType<GrupoDePermissoesTypeRelay> }>(
    getGroupPermissionQuery,
    {
      fetchPolicy: 'cache-first',
      variables: { withGoals },
    },
  );

  const [storedPersonal, setPersonal] = useLocalStorage<typeof personalPermissions>('permissions:personal');
  useEffect(() => {
    if (personalPermissions.data) setPersonal(personalPermissions);
  }, [personalPermissions, setPersonal]);

  const [storedGroup, setGroup] = useLocalStorage<typeof groupPermissions>('permissions:group');
  useEffect(() => {
    if (groupPermissions.data) setGroup(groupPermissions);
  }, [groupPermissions, setGroup]);

  const personalPermissionsReturn =
    personalPermissions.data === undefined && storedPersonal !== undefined ? storedPersonal : personalPermissions;
  const groupPermissionsReturn =
    groupPermissions.data === undefined && storedGroup !== undefined ? storedGroup : groupPermissions;

  return {
    personalPermissions: personalPermissionsReturn,
    groupPermissions: groupPermissionsReturn,
  };
}

export async function getPermissionsPromiseQueries(client: ApolloClient<object>, withGoals = false) {
  const personalPermissions = await client.query<{
    permissoes: DeepRequired<PermissaoType>[];
  }>({
    query: getPersonalPermissionQuery,
    fetchPolicy: 'cache-first',
    variables: { withGoals },
  });
  const groupPermissions = await client.query<{
    gruposPermissoesUserLogado: GraphqlRelayType<GrupoDePermissoesTypeRelay>;
  }>({
    query: getGroupPermissionQuery,
    fetchPolicy: 'cache-first',
    variables: { withGoals },
  });

  return {
    personalPermissions,
    groupPermissions,
  };
}
