import { gql } from 'apollo-boost';

import { AlterarSenhaFuncionarioMutation } from '../../types/graphql';

export const alterarSenhaMutation = gql`
  mutation alterarSenhaFuncionario($idFuncionario: ID, $senhaAtual: String!, $senhaNova: String!) {
    alterarSenhaFuncionario(idFuncionario: $idFuncionario, senhaAtual: $senhaAtual, senhaNova: $senhaNova) {
      ok
      funcionario {
        id
        nome
      }
    }
  }
`;

export interface ChangePassType {
  alterarSenhaFuncionario: AlterarSenhaFuncionarioMutation;
}
