import { useCallback } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { atom, useSetRecoilState, useResetRecoilState } from 'recoil';

import { LoanFirstStepFormType, MinTaxModalType, LoanSecondStepFormType, NsuSchema } from '../formType';

import { FilterForm } from 'pages/Loan/List/filters';
import { calcMinTax } from 'pages/Loan/utils';
import { EntidadeData, getEntityTableTax } from 'pages/Store/query';
import { ClienteType } from 'types/graphql';

export type Steps = Partial<{
  firstStep: Partial<LoanFirstStepFormType>;
  modalMinTax: MinTaxModalType;
  mainScreen: LoanSecondStepFormType;
}>;

export const loanClient = atom<ClienteType | undefined>({
  key: 'loanClient',
  default: undefined,
});

export const loanLead = atom<string | number | undefined | null>({
  key: 'loanLead',
  default: undefined,
});

export const loanTaxes = atom<{ minTax: number; securityTax: number }>({
  key: 'loanTaxes',
  default: {
    minTax: 0,
    securityTax: 0,
  },
});

export const loanFormStates = atom<Steps>({
  key: 'loanformstate',
  default: {},
});

export const loanFilterState = atom<FilterForm>({
  key: 'loanFilterState',
  default: {},
});

export type NsuDivergent = Array<{
  values: NsuSchema;
  nsuIndex: number;
}>;

export const loanDivergentOwner = atom<NsuDivergent>({
  key: 'loanDivergentOwner',
  default: [],
});

export function useUpdateTaxes() {
  const setTaxes = useSetRecoilState(loanTaxes);
  const { query } = useApolloClient();

  return useCallback(
    (id: number, parcel: number, value?: number, defaultTaxes?: any) => {
      query<EntidadeData, { id: number }>({
        query: getEntityTableTax,
        variables: { id },
        fetchPolicy: 'cache-first',
      })
        .then(result => {
          const { entidade } = result.data;
          setTaxes({
            minTax: calcMinTax({ entidade: entidade, parcelas: parcel, valorDesejado: value, defaultTaxes }),
            // ||
            // Number(entidade?.taxaMinima),
            securityTax: calcMinTax({
              entidade: entidade,
              parcelas: parcel,
              taxKey: 'taxaSeguranca',
              valorDesejado: value,
              defaultTaxes,
            }),
          });
        })
        .catch(console.error);
    },
    [query, setTaxes],
  );
}

export function useResetLoanStates() {
  const resetClient = useResetRecoilState(loanClient);
  const resetLead = useResetRecoilState(loanLead);
  const resetTaxes = useResetRecoilState(loanTaxes);
  const resetFormsStates = useResetRecoilState(loanFormStates);
  const resetDivergent = useResetRecoilState(loanDivergentOwner);

  return useCallback(() => {
    resetClient();
    resetLead();
    resetTaxes();
    resetFormsStates();
    resetDivergent();
  }, [resetClient, resetFormsStates, resetTaxes, resetDivergent]);
}
