import React, { createElement, JSXElementConstructor } from 'react';

// TODO: use fowardRef
export function filterProps<StyledComponentProps>(
  Component: JSXElementConstructor<object>,
  propsToFilter: Array<keyof StyledComponentProps>,
) {
  return (props: StyledComponentProps) => {
    const filteredProps = { ...props };

    propsToFilter.forEach(prop => delete filteredProps[prop]);
    if (typeof Component === 'string') {
      return createElement(Component, filteredProps);
    }
    return <Component {...filteredProps} />;
  };
}

export type StyledFilter<Component extends JSXElementConstructor<object>, Filter> = React.ComponentType<
  Filter & Omit<React.ComponentProps<Component>, keyof Filter>
>;

export default filterProps;
