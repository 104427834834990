import { useMemo } from 'react';

import { DeepPartial } from 'react-hook-form';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { MarkRequired } from 'ts-essentials';

export interface RouterState<T> {
  defaultValues?: DeepPartial<T>;
  redirect?: string;
  forceAwait?: boolean;
  cancelFetch?: boolean;
  shouldFetch?: boolean;
}

export function useRouter<T = any, R = {}>() {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory<RouterState<T> & R>();
  const match = useRouteMatch();

  const routerState: MarkRequired<RouterState<T>, 'defaultValues'> = Object.assign(
    {},
    { defaultValues: {} },
    location.state,
  );

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      go: history.go,
      id: params.id,
      routerState,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        // ...queryString.parse(location.search), // Convert string to object
        ...params,
      },

      match,
      location,
      history,
    };
  }, [history, location, params, routerState, match]);
}
