import React from 'react';

import { Control, Controller } from 'react-hook-form';

import { TimePicker } from './TimePicker';

interface TimerPickCNProps {
  name: string;
  control: Control;
  label: string;
}

export function TimePickerCN({ name, control, label, ...props }: TimerPickCNProps) {
  return <Controller as={TimePicker} control={control} name={name} label={label} {...props} />;
}
