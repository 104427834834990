import React, { useEffect, useState } from 'react';

import { Box, Button, Dialog, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { format, isValid, parse } from 'date-fns';
import { pt } from 'date-fns/locale';
import { NumberFormatValues } from 'react-number-format';

import 'styled-components/macro';
import { DateRangePicker } from './dateRangePicker';
import { DateRangeType } from './types';

import { MaskedInput } from 'components';
import { LabelSelectCN } from 'components/SelectCN/LabelSelectCN';

type Props = {
  onChange?: (arg: DateRangeType | undefined) => void;
  error?: string;
  grid?: {
    left?: React.ComponentProps<typeof Grid>;
    right?: React.ComponentProps<typeof Grid>;
  };
  value: any;
  setValue: (value: string) => void;
  defaultInitialDate?: string;
  defaultLabelSelect?: string;
  defaultEndDate?: string;
};
export const selectLabelOptions = {
  dateRangePayment: { label: 'Filtrar por Data de Pagamento', idx: 0, key: 'dateRangePayment' },
  dateRangeDue: { label: 'Filtrar por Data de Vencimento', idx: 1, key: 'dateRangeDue' },
  dateRangeRegistry: { label: 'Filtrar por Data de Cadastro', idx: 2, key: 'dateRangeRegistry' },
};

export function DateRangeSelect({
  onChange,
  error,
  grid,
  value,
  setValue,
  defaultEndDate,
  defaultInitialDate,
  defaultLabelSelect = 'Filtrar por Data de Pagamento',
}: Props) {
  const [isDialogOpen, dispatch] = useState(false);
  const [dateRange, setDateRange] = useState<DateRangeType>();
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [defaultValueInitialDate, setDefaultValueInitialDate] = useState('');
  const [defaultValueEndDate, setDefaultValueEndDate] = useState('');

  useEffect(() => {
    if (defaultInitialDate !== undefined && defaultEndDate !== undefined) {
      setDefaultValueInitialDate(defaultInitialDate);
      setDefaultValueEndDate(defaultEndDate);
    }
  }, [defaultInitialDate, defaultEndDate]);

  useEffect(() => {
    onChange && onChange(dateRange);
  }, [dateRange]);

  const dateMask = '##/##/####';
  const dateFormat = 'dd/MM/yyyy';

  function handleValue(value: any) {
    const index = value.target.value;
    if (index === 0) {
      setValue('dateRangePayment');
    } else if (index === 1) {
      setValue('dateRangeDue');
    } else if (index === 2) {
      setValue('dateRangeRegistry');
    }
  }

  useEffect(() => {
    if (value?.startDate === null && value?.endDate === null) {
      setDateRange(undefined);
      setInitialDate('');
      setEndDate('');
    }
  }, [value]);

  useEffect(() => {
    if (isValid(value?.startDate) && isValid(value?.endDate) && value?.startDate !== null && value?.endDate !== null) {
      setInitialDate(String(format(value?.startDate as Date, dateFormat)));
      setEndDate(String(format(value?.endDate as Date, dateFormat)));
    }
  }, [value]);
  return (
    <>
      <Grid item xs={12} md={4} {...grid?.left}>
        <LabelSelectCN
          color={'#ef6915'}
          value={value}
          defaultValue={[
            selectLabelOptions.dateRangePayment,
            selectLabelOptions.dateRangeDue,
            selectLabelOptions.dateRangeRegistry,
          ]?.findIndex?.(value => value.key === defaultLabelSelect)}
          options={[
            selectLabelOptions.dateRangePayment.label,
            selectLabelOptions.dateRangeDue.label,
            selectLabelOptions.dateRangeRegistry.label,
          ]}
          getValue={handleValue}
        />
        <MaskedInput
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="open date dialog"
                onClick={() => dispatch(true)}
                onMouseDown={e => e.preventDefault()}
              >
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          }
          errorMessage={
            (initialDate.replace('_', '').length === dateMask.length &&
              isValid(dateRange?.startDate) === false &&
              'Data Invalida') ||
            error
          }
          format={dateMask}
          mask="_"
          value={defaultValueInitialDate ? defaultValueInitialDate : initialDate}
          onValueChange={(values: NumberFormatValues) => {
            if (defaultValueInitialDate) {
              setDefaultValueInitialDate(values.formattedValue);
              setDateRange(oldRange => ({
                ...oldRange,
                startDate: parse(values.formattedValue ?? '', dateFormat, new Date()),
              }));
            }
            setInitialDate(values.formattedValue);
            setDateRange(oldRange => ({
              ...oldRange,
              startDate: parse(values.formattedValue ?? '', dateFormat, new Date()),
            }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} {...grid?.right}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-end"
          minHeight="85px"
          mt="3px"
        >
          <MaskedInput
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="open date dialog"
                  onClick={() => dispatch(true)}
                  onMouseDown={e => e.preventDefault()}
                >
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            }
            errorMessage={
              endDate.replace('_', '').length === dateMask.length &&
              isValid(dateRange?.endDate) === false &&
              'Data Invalida'
            }
            format={dateMask}
            mask="_"
            // value={endDate}
            value={defaultValueEndDate ? defaultValueEndDate : endDate}
            onValueChange={(values: NumberFormatValues) => {
              if (defaultValueEndDate) {
                setDefaultValueEndDate(values.formattedValue);
                setDateRange(oldRange => ({
                  ...oldRange,
                  endDate: parse(values?.formattedValue ?? '', dateFormat, new Date()),
                }));
              }
              setEndDate(values.formattedValue);
              setDateRange(oldRange => ({
                ...oldRange,
                endDate: parse(values?.formattedValue ?? '', dateFormat, new Date()),
              }));
            }}
          />
        </Box>
      </Grid>
      <Dialog
        open={isDialogOpen}
        onClose={() => dispatch(false)}
        aria-labelledby="date-dialog-title"
        css={`
          .MuiDialog-paperWidthSm {
            max-width: none;
          }
        `}
      >
        <DateRangePicker
          initialDateRange={{
            startDate: isValid(dateRange?.startDate) ? dateRange?.startDate : undefined,
            endDate: isValid(dateRange?.endDate) ? dateRange?.endDate : undefined,
          }}
          onChange={range => {
            setDateRange(range);
            if (defaultValueInitialDate && defaultValueEndDate) {
              range.startDate && setDefaultValueInitialDate(format(range.startDate, dateFormat));
              range.endDate && setDefaultValueEndDate(format(range.endDate, dateFormat));
            }
            range.startDate && setInitialDate(format(range.startDate, dateFormat));
            range.endDate && setEndDate(format(range.endDate, dateFormat));
          }}
          translation={{
            endDate: 'Data Final',
            startDate: 'Data Inicial',
            months: ['JAN', 'FER', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
            weekDays: ['DOM', 'SEG', 'TER', 'QUAR', 'QUIN', 'SEX', 'SAB'],
            locale: pt,
          }}
          slot={
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={() => dispatch(false)} color="primary">
                OK
              </Button>
            </Box>
          }
        />
      </Dialog>
    </>
  );
}
