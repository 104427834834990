import { darken } from '@material-ui/core/styles';

import { ButtonCN as DefaultButtonCN } from 'components';

import styled from 'styled-components';

export const CRMButton = styled(DefaultButtonCN)`
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-right: 15px;
  min-width: ${p => (p.fullWidth ? '100%' : p.width)};
  background: ${props => (props.outlined ? 'transparent' : props.backgroundColor)};
  ${props => props.outlined && `border: 1px solid ${props.backgroundColor};`};
  color: ${props => (props.outlined ? ' #454545' : '#ffffff')};

  &:hover {
    color: #ffffff;
    background: ${props => darken(props.backgroundColor, 0.2)};
  }

  @media (max-width: 470px) {
    margin-right: 0px;
  }
`;
