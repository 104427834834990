export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
  DateTime: any,
  /** 
 * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
  Date: any,
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any,
  /** 
 * Allows use of a JSON String for input / output from the GraphQL schema.
   * 
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
 */
  JSONString: any,
  /** 
 * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
  Time: any,
  /** 
 * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
 */
  GenericScalar: any,
};

export type AdicionarAnexosTaxaAbaixoDeEmprestimo = {
   __typename?: 'AdicionarAnexosTaxaAbaixoDeEmprestimo',
  emprestimo?: Maybe<EmprestimoType>,
};

export type AdicionarPagamentoEmprestimo = {
   __typename?: 'AdicionarPagamentoEmprestimo',
  ok?: Maybe<Scalars['Boolean']>,
  emprestimo?: Maybe<EmprestimoNode>,
};

export type AgrupadoPorDescricaoType = {
   __typename?: 'AgrupadoPorDescricaoType',
  loja?: Maybe<Array<Maybe<Scalars['Int']>>>,
  permssaoDescricao?: Maybe<Scalars['String']>,
};

export type AgrupadoPorLojaType = {
   __typename?: 'AgrupadoPorLojaType',
  loja?: Maybe<Scalars['Int']>,
  permssaoDescricao?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type AlterarEmailFuncionario = {
   __typename?: 'AlterarEmailFuncionario',
  funcionario?: Maybe<FuncionarioType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type AlterarGrupoDePermissoesMutation = {
   __typename?: 'AlterarGrupoDePermissoesMutation',
  ok?: Maybe<Scalars['Boolean']>,
  grupoPermissoes?: Maybe<GrupoDePermissoesType>,
};

export type AlterarSenhaCLienteMutation = {
   __typename?: 'AlterarSenhaCLienteMutation',
  cliente?: Maybe<ClienteType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type AlterarSenhaFuncionarioMutation = {
   __typename?: 'AlterarSenhaFuncionarioMutation',
  funcionario?: Maybe<FuncionarioType>,
  ok?: Maybe<Scalars['Boolean']>,
  token?: Maybe<Scalars['String']>,
};

export type AnexoContaType = {
   __typename?: 'AnexoContaType',
  id: Scalars['ID'],
  conta: ContaNode,
  anexo?: Maybe<Scalars['String']>,
};

export type AnexoDeContaAPagarType = {
   __typename?: 'AnexoDeContaAPagarType',
  id: Scalars['ID'],
  conta: ContaAPagarNode,
  descricao?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type AnexoDeEmprestimoType = {
   __typename?: 'AnexoDeEmprestimoType',
  id: Scalars['ID'],
  emprestimo: EmprestimoRelatorioType,
  descricao?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type AnexoDePedidoDeReembolsoType = {
   __typename?: 'AnexoDePedidoDeReembolsoType',
  id: Scalars['ID'],
  pedido: PedidoDeReembolsoNode,
  descricao?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type AnotacaoType = {
   __typename?: 'AnotacaoType',
  id: Scalars['ID'],
  nota: Scalars['String'],
  lead: LeadsNodeTypeRelayProp,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type AppConfigType = {
   __typename?: 'AppConfigType',
  id: Scalars['ID'],
  nomeApp: Scalars['String'],
  entidade: EntidadeTypeRelay,
  /** Selecione a unidade */
  unidade: EntidadeTypeRelayConnection,
  email?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  onesignalKey?: Maybe<Scalars['String']>,
  onesignalAppId?: Maybe<Scalars['String']>,
  appslideSet: Array<AppSlideType>,
  apppoliticaprivacideSet: Array<AppPoliticaPrivacideType>,
  apptermosusoSet: Array<AppTermosUsoType>,
  appfaqSet: Array<AppFaqType>,
};


export type AppConfigTypeUnidadeArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type AppFaqType = {
   __typename?: 'AppFaqType',
  id: Scalars['ID'],
  titulo: Scalars['String'],
  campo: Scalars['String'],
  appconfig: AppConfigType,
};

export type AppPoliticaPrivacideType = {
   __typename?: 'AppPoliticaPrivacideType',
  id: Scalars['ID'],
  titulo: Scalars['String'],
  campo: Scalars['String'],
  appconfig: AppConfigType,
};

export type AppSlideType = {
   __typename?: 'AppSlideType',
  id: Scalars['ID'],
  slide: Scalars['String'],
  appconfig: AppConfigType,
};

export type AppTermosUsoType = {
   __typename?: 'AppTermosUsoType',
  id: Scalars['ID'],
  titulo: Scalars['String'],
  campo: Scalars['String'],
  appconfig: AppConfigType,
};

export type AssociarClienteEntidadeMutation = {
   __typename?: 'AssociarClienteEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
};

export type AssociarEntidadesGrupoMutation = {
   __typename?: 'AssociarEntidadesGrupoMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type AssociarFuncionariosGrupoMutation = {
   __typename?: 'AssociarFuncionariosGrupoMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type AtributosDePermissaoInput = {
  idEntidade: Scalars['ID'],
  descricao: Scalars['String'],
};

export type AtualizarAnexoDeContaAPagarMutation = {
   __typename?: 'AtualizarAnexoDeContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  anexo?: Maybe<AnexoDeContaAPagarType>,
};

export type AtualizarAnexoDePedidoDeReembolsoMutation = {
   __typename?: 'AtualizarAnexoDePedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  anexo?: Maybe<AnexoDePedidoDeReembolsoType>,
};

export type AtualizarClienteMutation = {
   __typename?: 'AtualizarClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
};

export type AtualizarClienteRapidoInput = {
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  nomeRepresentante?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  celular?: Maybe<Scalars['String']>,
  asaasId: Scalars['String'],
  idEntidade: Scalars['ID'],
};

export type AtualizarClienteRapidoMutation = {
   __typename?: 'AtualizarClienteRapidoMutation',
  cliente?: Maybe<ClienteType>,
};

export type AtualizarContaAPagarMutation = {
   __typename?: 'AtualizarContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  contaPagar?: Maybe<ContaAPagarNode>,
};

export type AtualizarContaDeClienteMutation = {
   __typename?: 'AtualizarContaDeClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  conta?: Maybe<ContaDeClienteType>,
};

export type AtualizarEmprestimoMutation = {
   __typename?: 'AtualizarEmprestimoMutation',
  emprestimo?: Maybe<EmprestimoNode>,
};

export type AtualizarEmprestimoNsu = {
   __typename?: 'AtualizarEmprestimoNsu',
  emprestimo?: Maybe<EmprestimoNode>,
  nsu?: Maybe<NsuType>,
};

export type AtualizarEntidadeMutation = {
   __typename?: 'AtualizarEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
  entidade?: Maybe<EntidadeType>,
};

export type AtualizarFuncionarioMutation = {
   __typename?: 'AtualizarFuncionarioMutation',
  funcionario?: Maybe<FuncionarioType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type AtualizarInstanciaZApi = {
   __typename?: 'AtualizarInstanciaZApi',
  instancia?: Maybe<InstanciaZApiType>,
};

export type AtualizarMaquinaMutation = {
   __typename?: 'AtualizarMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type AtualizarPedidoDeReembolsoMutation = {
   __typename?: 'AtualizarPedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  pedidoReembolso?: Maybe<PedidoDeReembolsoNode>,
};

export type AtualizarTelefoneDeClienteMutation = {
   __typename?: 'AtualizarTelefoneDeClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<TelefoneDeClienteType>,
};

export type AtualizarTelefoneDeEntidadeMutation = {
   __typename?: 'AtualizarTelefoneDeEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<TelefoneDeEntidadeType>,
};

export type AtualizarTitularDivergenteNsu = {
   __typename?: 'AtualizarTitularDivergenteNsu',
  emprestimo?: Maybe<EmprestimoNode>,
  titularDivergente?: Maybe<NsuTitularDivergenteType>,
};

export type BandeirasDash = {
   __typename?: 'BandeirasDash',
  id?: Maybe<Scalars['String']>,
  label?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['Decimal']>,
};

export type BandeiraTabelaParceirosType = {
   __typename?: 'BandeiraTabelaParceirosType',
  id: Scalars['ID'],
  nomeBandeira: Scalars['String'],
};

export type BloquearFuncionario = {
   __typename?: 'BloquearFuncionario',
  funcionario?: Maybe<FuncionarioType>,
};

export type BloquearMaquinaMutation = {
   __typename?: 'BloquearMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type BoletoClienteMobile = {
  vencimento: Scalars['Date'],
  codigoBarra: Scalars['String'],
  numeroControle: Scalars['String'],
  valor: Scalars['Float'],
  anexoBoleto?: Maybe<Scalars['String']>,
};

export type BuscarECriarClienteAsaasInput = {
  nome: Scalars['String'],
  email: Scalars['String'],
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  celular?: Maybe<Scalars['String']>,
};

export type BuscarECriarClienteAsaasMutation = {
   __typename?: 'BuscarECriarClienteAsaasMutation',
  asaasId?: Maybe<Scalars['String']>,
};

export type CancelaEmprestimoMobile = {
   __typename?: 'CancelaEmprestimoMobile',
  ok?: Maybe<Scalars['Boolean']>,
};

export type CartaoDeCreditoNodeTypeRelay = Node & {
   __typename?: 'CartaoDeCreditoNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nomeNoCartao: Scalars['String'],
  validade: Scalars['String'],
  numeroCartao: Scalars['String'],
  cvc?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  cpf: Scalars['String'],
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  cartaoFrente?: Maybe<Scalars['String']>,
  cartaoVerso?: Maybe<Scalars['String']>,
  cartaoSelf?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  pagcertoPayload: Scalars['JSONString'],
  cliente: EmprestimoClienteNode,
  entidadeId?: Maybe<Scalars['Int']>,
  deleted: Scalars['Boolean'],
};

export type CartaoDeCreditoNodeTypeRelayConnection = {
   __typename?: 'CartaoDeCreditoNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CartaoDeCreditoNodeTypeRelayEdge>>,
};

/** A Relay edge containing a `CartaoDeCreditoNodeTypeRelay` and its cursor. */
export type CartaoDeCreditoNodeTypeRelayEdge = {
   __typename?: 'CartaoDeCreditoNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<CartaoDeCreditoNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type CartaoDeCreditoType = {
   __typename?: 'CartaoDeCreditoType',
  id: Scalars['ID'],
  nomeNoCartao: Scalars['String'],
  validade: Scalars['String'],
  numeroCartao: Scalars['String'],
  cvc?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  cpf: Scalars['String'],
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  cartaoFrente?: Maybe<Scalars['String']>,
  cartaoVerso?: Maybe<Scalars['String']>,
  cartaoSelf?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  pagcertoPayload?: Maybe<PagcertoCreditCardPayloadType>,
  cliente: EmprestimoClienteNode,
  entidadeId?: Maybe<Scalars['Int']>,
  deleted: Scalars['Boolean'],
  numeroMascara?: Maybe<Scalars['String']>,
};

export type CategoriaTypeRelay = Node & {
   __typename?: 'CategoriaTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  excluido: Scalars['Boolean'],
  subcategoriasSet: SubCategoriasTypeRelayConnection,
  fornecedorSet: FornecedorViewNodeTypeConnection,
  contaSet: ContaNodeConnection,
  totalContas?: Maybe<Scalars['Int']>,
  totalSubcategoriaPorCategoria?: Maybe<Scalars['Int']>,
};


export type CategoriaTypeRelaySubcategoriasSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['Float']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  excluido?: Maybe<Scalars['Boolean']>,
  categoria_Id_In?: Maybe<Scalars['String']>
};


export type CategoriaTypeRelayFornecedorSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type CategoriaTypeRelayContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type CategoriaTypeRelayConnection = {
   __typename?: 'CategoriaTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `CategoriaTypeRelay` and its cursor. */
export type CategoriaTypeRelayEdge = {
   __typename?: 'CategoriaTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<CategoriaTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type CheckDuplicateCobranca = {
   __typename?: 'CheckDuplicateCobranca',
  duplicateInSgi?: Maybe<Scalars['Boolean']>,
  duplicateInAsaas?: Maybe<Scalars['Boolean']>,
  duplicate?: Maybe<Scalars['Boolean']>,
  cobranca?: Maybe<CobrancaType>,
};

export type CheckDuplicateCobrancaInput = {
  value: Scalars['Decimal'],
  vencimento: Scalars['Date'],
  lojaId: Scalars['ID'],
  clienteId: Scalars['ID'],
  asaasCustomerId: Scalars['ID'],
  cobrancaId: Scalars['ID'],
};

/** An enumeration. */
export enum ClienteEstadoCivil {
  /** Solteiro */
  Solteiro = 'SOLTEIRO',
  /** Casado */
  Casado = 'CASADO',
  /** Divorciado */
  Divorciado = 'DIVORCIADO',
  /** Viúvo */
  Viuvo = 'VIUVO'
}

export type ClienteInputMobile = {
  nome?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  sexo?: Maybe<Scalars['String']>,
  estadoCivil?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
};

export type ClienteRelatorioInput = {
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  entidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  lead?: Maybe<Scalars['Boolean']>,
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  nascimento_Lte?: Maybe<Scalars['Date']>,
  nascimento_Gte?: Maybe<Scalars['Date']>,
  nascimento_Month_In?: Maybe<Scalars['String']>,
  nascimento_Day_In?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  sexo_In?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  telefone_Numero?: Maybe<Scalars['String']>,
  telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  telefone_Ddd?: Maybe<Scalars['String']>,
  telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  uf_Icontains?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  bairro_Icontains?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  profissao_Icontains?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
};

/** An enumeration. */
export enum ClienteSexo {
  /** Masculino */
  Masculino = 'MASCULINO',
  /** Feminino */
  Feminino = 'FEMININO',
  /** Indefinido */
  Indefinido = 'INDEFINIDO'
}

export type ClienteType = {
   __typename?: 'ClienteType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  cpf?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  sexo?: Maybe<ClienteSexo>,
  estadoCivil?: Maybe<ClienteEstadoCivil>,
  rg?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  entidades: EntidadeTypeRelayConnection,
  lead: Scalars['Boolean'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  origem?: Maybe<Scalars['String']>,
  user?: Maybe<UserType>,
  asaasCustomerPayload: Scalars['JSONString'],
  asaasCustomerId?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  nomeRepresentante?: Maybe<Scalars['String']>,
  telefones: Array<TelefoneDeClienteType>,
  contas: Array<ContaDeClienteType>,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  cobrancaSet: Array<CobrancaType>,
  cartaodecreditoSet: CartaoDeCreditoNodeTypeRelayConnection,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
  pk: Scalars['Int'],
};


export type ClienteTypeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ClienteTypeEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type ClienteTypeCartaodecreditoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>
};


export type ClienteTypeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type ClienteTypeListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type ClienteTypeRelay = Node & {
   __typename?: 'ClienteTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  cpf?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  sexo?: Maybe<ClienteSexo>,
  estadoCivil?: Maybe<ClienteEstadoCivil>,
  rg?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  entidades: EntidadeTypeRelayConnection,
  lead: Scalars['Boolean'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  origem?: Maybe<Scalars['String']>,
  user?: Maybe<UserType>,
  asaasCustomerPayload: Scalars['JSONString'],
  asaasCustomerId?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  nomeRepresentante?: Maybe<Scalars['String']>,
  telefones: Array<TelefoneDeClienteType>,
  contas: Array<ContaDeClienteType>,
  emprestimoSet?: Maybe<Array<Maybe<EmprestimoClienteType>>>,
  cobrancaSet: Array<CobrancaType>,
  cartaodecreditoSet: CartaoDeCreditoNodeTypeRelayConnection,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
  pk: Scalars['Int'],
};


export type ClienteTypeRelayEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ClienteTypeRelayCartaodecreditoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>
};


export type ClienteTypeRelayLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type ClienteTypeRelayListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type ClienteTypeRelayConnection = {
   __typename?: 'ClienteTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClienteTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ClienteTypeRelay` and its cursor. */
export type ClienteTypeRelayEdge = {
   __typename?: 'ClienteTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<ClienteTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type CobrancaAgregacoes = {
   __typename?: 'CobrancaAgregacoes',
  somaValorTotal?: Maybe<Scalars['Decimal']>,
  somaValorPago?: Maybe<Scalars['Decimal']>,
  somaValorPendente?: Maybe<Scalars['Decimal']>,
};

/** An enumeration. */
export enum CobrancaBillingType {
  /** PIX */
  Pix = 'PIX',
  /** CREDIT_CARD */
  CreditCard = 'CREDIT_CARD',
  /** BOLETO */
  Boleto = 'BOLETO',
  /** UNDEFINED */
  Undefined = 'UNDEFINED'
}

/** An enumeration. */
export enum CobrancaImportadoVia {
  /** SGI */
  Sgi = 'SGI',
  /** Integração */
  Integracao = 'INTEGRACAO',
  /** Automação */
  Automacao = 'AUTOMACAO'
}

export type CobrancaInput = {
  id?: Maybe<Scalars['ID']>,
  value: Scalars['Decimal'],
  vencimento: Scalars['Date'],
  jurosAoMes?: Maybe<Scalars['Decimal']>,
  tipoDeMulta?: Maybe<Scalars['String']>,
  valorDaMulta?: Maybe<Scalars['Decimal']>,
  vigenciaInicio?: Maybe<Scalars['Date']>,
  vigenciaFim?: Maybe<Scalars['Date']>,
  descricao?: Maybe<Scalars['String']>,
  lojaId: Scalars['ID'],
  clienteId: Scalars['ID'],
};

export type CobrancaListInput = {
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  invoiceNumber?: Maybe<Scalars['String']>,
  invoiceNumber_In?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  descricao_Istartswith?: Maybe<Scalars['String']>,
  vigenciaInicio?: Maybe<Scalars['Date']>,
  vigenciaInicio_Gte?: Maybe<Scalars['Date']>,
  vigenciaFim?: Maybe<Scalars['Date']>,
  vigenciaFim_Lte?: Maybe<Scalars['Date']>,
  cliente_Nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Email_Icontains?: Maybe<Scalars['String']>,
  status_Icontains?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  billingType_Icontains?: Maybe<Scalars['String']>,
  billingType_In?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['Float']>,
  value_Gte?: Maybe<Scalars['Float']>,
  value_Lte?: Maybe<Scalars['Float']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm_Date_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Date_Lte?: Maybe<Scalars['DateTime']>,
  clientPaymentDate_Date_Gte?: Maybe<Scalars['DateTime']>,
  clientPaymentDate_Date_Lte?: Maybe<Scalars['DateTime']>,
  vencimento?: Maybe<Scalars['Date']>,
  vencimento_Gte?: Maybe<Scalars['Date']>,
  vencimento_Lte?: Maybe<Scalars['Date']>,
  cliente_Cnpj_Icontains?: Maybe<Scalars['String']>,
  cliente_Cpf_Icontains?: Maybe<Scalars['String']>,
  lojaId?: Maybe<Scalars['ID']>,
  lojaId_In?: Maybe<Scalars['ID']>,
  criadoPorId?: Maybe<Scalars['ID']>,
  criadoPorId_In?: Maybe<Scalars['ID']>,
  buscaGeral?: Maybe<Scalars['String']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
};

export type CobrancaNode = Node & {
   __typename?: 'CobrancaNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  status?: Maybe<CobrancaStatus>,
  invoiceUrl?: Maybe<Scalars['String']>,
  invoiceNumber?: Maybe<Scalars['String']>,
  transactionReceiptUrl?: Maybe<Scalars['String']>,
  clientPaymentDate?: Maybe<Scalars['DateTime']>,
  lastInvoiceViewedDate?: Maybe<Scalars['DateTime']>,
  asaasId?: Maybe<Scalars['String']>,
  billingType: CobrancaBillingType,
  value: Scalars['Float'],
  vencimento: Scalars['Date'],
  jurosAoMes?: Maybe<Scalars['Float']>,
  tipoDeMulta?: Maybe<CobrancaTipoDeMulta>,
  valorDaMulta?: Maybe<Scalars['Float']>,
  vigenciaInicio?: Maybe<Scalars['Date']>,
  vigenciaFim?: Maybe<Scalars['Date']>,
  descricao?: Maybe<Scalars['String']>,
  importadoVia: CobrancaImportadoVia,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  responsePayload: Scalars['JSONString'],
  excluidoPor?: Maybe<FuncionarioTypeRelay>,
  criadoPor?: Maybe<FuncionarioTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  loja?: Maybe<EntidadeTypeRelay>,
};

export type CobrancaNodeConnection = {
   __typename?: 'CobrancaNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CobrancaNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `CobrancaNode` and its cursor. */
export type CobrancaNodeEdge = {
   __typename?: 'CobrancaNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<CobrancaNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum CobrancaStatus {
  /** Excluido no asaas */
  Deleted = 'DELETED',
  /** Aguardando pagamento */
  Pending = 'PENDING',
  /** Recebida (saldo já creditado na conta) */
  Received = 'RECEIVED',
  /** Pagamento confirmado (saldo ainda não creditado) */
  Confirmed = 'CONFIRMED',
  /** Vencida */
  Overdue = 'OVERDUE',
  /** Estornada */
  Refunded = 'REFUNDED',
  /** Recebida em dinheiro (não gera saldo na conta) */
  ReceivedInCash = 'RECEIVED_IN_CASH',
  /** Estorno Solicitado */
  RefundRequested = 'REFUND_REQUESTED',
  /** Estorno em processamento (liquidação já está agendada, cobrança será estornada após executar a liquidação) */
  RefundInProgress = 'REFUND_IN_PROGRESS',
  /** Recebido chargeback */
  ChargebackRequested = 'CHARGEBACK_REQUESTED',
  /** Em disputa de chargeback (caso sejam apresentados documentos para contestação) */
  ChargebackDispute = 'CHARGEBACK_DISPUTE',
  /** Disputa vencida, aguardando repasse da adquirente */
  AwaitingChargebackReversal = 'AWAITING_CHARGEBACK_REVERSAL',
  /** Em processo de negativação */
  DunningRequested = 'DUNNING_REQUESTED',
  /** Recuperada */
  DunningReceived = 'DUNNING_RECEIVED',
  /** Pagamento em análise */
  AwaitingRiskAnalysis = 'AWAITING_RISK_ANALYSIS'
}

/** An enumeration. */
export enum CobrancaTipoDeMulta {
  /** FIXED */
  Fixed = 'FIXED',
  /** PERCENTAGE */
  Percentage = 'PERCENTAGE'
}

export type CobrancaType = {
   __typename?: 'CobrancaType',
  id: Scalars['ID'],
  status?: Maybe<CobrancaStatus>,
  invoiceUrl?: Maybe<Scalars['String']>,
  invoiceNumber?: Maybe<Scalars['String']>,
  transactionReceiptUrl?: Maybe<Scalars['String']>,
  clientPaymentDate?: Maybe<Scalars['DateTime']>,
  lastInvoiceViewedDate?: Maybe<Scalars['DateTime']>,
  asaasId?: Maybe<Scalars['String']>,
  billingType: CobrancaBillingType,
  value: Scalars['Float'],
  vencimento: Scalars['Date'],
  jurosAoMes?: Maybe<Scalars['Float']>,
  tipoDeMulta?: Maybe<CobrancaTipoDeMulta>,
  valorDaMulta?: Maybe<Scalars['Float']>,
  vigenciaInicio?: Maybe<Scalars['Date']>,
  vigenciaFim?: Maybe<Scalars['Date']>,
  descricao?: Maybe<Scalars['String']>,
  importadoVia: CobrancaImportadoVia,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  responsePayload: Scalars['JSONString'],
  excluidoPor?: Maybe<FuncionarioTypeRelay>,
  criadoPor?: Maybe<FuncionarioTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  loja?: Maybe<EntidadeTypeRelay>,
};

export type CobrancaUpdateInput = {
  value?: Maybe<Scalars['Decimal']>,
  vencimento?: Maybe<Scalars['Date']>,
  jurosAoMes?: Maybe<Scalars['Decimal']>,
  tipoDeMulta?: Maybe<Scalars['String']>,
  valorDaMulta?: Maybe<Scalars['Decimal']>,
  vigenciaInicio?: Maybe<Scalars['Date']>,
  vigenciaFim?: Maybe<Scalars['Date']>,
  descricao?: Maybe<Scalars['String']>,
  lojaId?: Maybe<Scalars['ID']>,
  asaasCustomerId?: Maybe<Scalars['ID']>,
  cobrancaId: Scalars['ID'],
};

export type ColunaTabelaType = {
   __typename?: 'ColunaTabelaType',
  id: Scalars['ID'],
  valorGeral: Scalars['Float'],
  taxaTabela: Scalars['Float'],
  line1?: Maybe<Scalars['Float']>,
  line1Valor: Scalars['Float'],
  line2?: Maybe<Scalars['Float']>,
  line2Valor: Scalars['Float'],
  line3?: Maybe<Scalars['Float']>,
  line3Valor: Scalars['Float'],
  line4?: Maybe<Scalars['Float']>,
  line4Valor: Scalars['Float'],
  line5?: Maybe<Scalars['Float']>,
  line5Valor: Scalars['Float'],
  line6?: Maybe<Scalars['Float']>,
  line6Valor: Scalars['Float'],
  line7?: Maybe<Scalars['Float']>,
  line7Valor: Scalars['Float'],
  line8?: Maybe<Scalars['Float']>,
  line8Valor: Scalars['Float'],
  line9?: Maybe<Scalars['Float']>,
  line9Valor: Scalars['Float'],
  line10?: Maybe<Scalars['Float']>,
  line10Valor: Scalars['Float'],
  line11?: Maybe<Scalars['Float']>,
  line11Valor: Scalars['Float'],
  line12?: Maybe<Scalars['Float']>,
  line12Valor: Scalars['Float'],
  line13?: Maybe<Scalars['Float']>,
  line13Valor: Scalars['Float'],
  line14?: Maybe<Scalars['Float']>,
  line14Valor: Scalars['Float'],
  line15?: Maybe<Scalars['Float']>,
  line15Valor: Scalars['Float'],
  line16?: Maybe<Scalars['Float']>,
  line16Valor: Scalars['Float'],
  line17?: Maybe<Scalars['Float']>,
  line17Valor: Scalars['Float'],
  line18?: Maybe<Scalars['Float']>,
  line18Valor: Scalars['Float'],
  line19?: Maybe<Scalars['Float']>,
  line19Valor: Scalars['Float'],
  line20?: Maybe<Scalars['Float']>,
  line20Valor: Scalars['Float'],
  line21?: Maybe<Scalars['Float']>,
  line21Valor: Scalars['Float'],
  tabela: TabelasNodeTypeRelay,
};

export type ColunaTabelaTypeInput = {
  valorGeral?: Maybe<Scalars['Float']>,
  taxaTabela?: Maybe<Scalars['Float']>,
  line1?: Maybe<Scalars['Float']>,
  line1Valor?: Maybe<Scalars['Float']>,
  line2?: Maybe<Scalars['Float']>,
  line2Valor?: Maybe<Scalars['Float']>,
  line3?: Maybe<Scalars['Float']>,
  line3Valor?: Maybe<Scalars['Float']>,
  line4?: Maybe<Scalars['Float']>,
  line4Valor?: Maybe<Scalars['Float']>,
  line5?: Maybe<Scalars['Float']>,
  line5Valor?: Maybe<Scalars['Float']>,
  line6?: Maybe<Scalars['Float']>,
  line6Valor?: Maybe<Scalars['Float']>,
  line7?: Maybe<Scalars['Float']>,
  line7Valor?: Maybe<Scalars['Float']>,
  line8?: Maybe<Scalars['Float']>,
  line8Valor?: Maybe<Scalars['Float']>,
  line9?: Maybe<Scalars['Float']>,
  line9Valor?: Maybe<Scalars['Float']>,
  line10?: Maybe<Scalars['Float']>,
  line10Valor?: Maybe<Scalars['Float']>,
  line11?: Maybe<Scalars['Float']>,
  line11Valor?: Maybe<Scalars['Float']>,
  line12?: Maybe<Scalars['Float']>,
  line12Valor?: Maybe<Scalars['Float']>,
  line13?: Maybe<Scalars['Float']>,
  line13Valor?: Maybe<Scalars['Float']>,
  line14?: Maybe<Scalars['Float']>,
  line14Valor?: Maybe<Scalars['Float']>,
  line15?: Maybe<Scalars['Float']>,
  line15Valor?: Maybe<Scalars['Float']>,
  line16?: Maybe<Scalars['Float']>,
  line16Valor?: Maybe<Scalars['Float']>,
  line17?: Maybe<Scalars['Float']>,
  line17Valor?: Maybe<Scalars['Float']>,
  line18?: Maybe<Scalars['Float']>,
  line18Valor?: Maybe<Scalars['Float']>,
  line19?: Maybe<Scalars['Float']>,
  line19Valor?: Maybe<Scalars['Float']>,
  line20?: Maybe<Scalars['Float']>,
  line20Valor?: Maybe<Scalars['Float']>,
  line21?: Maybe<Scalars['Float']>,
  line21Valor?: Maybe<Scalars['Float']>,
};

/** An enumeration. */
export enum ColunaTaxasParcelasParcelas {
  /** 1x */
  A_1 = 'A_1',
  /** 2x */
  A_2 = 'A_2',
  /** 3x */
  A_3 = 'A_3',
  /** 4x */
  A_4 = 'A_4',
  /** 5x */
  A_5 = 'A_5',
  /** 6x */
  A_6 = 'A_6',
  /** 7x */
  A_7 = 'A_7',
  /** 8x */
  A_8 = 'A_8',
  /** 9x */
  A_9 = 'A_9',
  /** 10x */
  A_10 = 'A_10',
  /** 11x */
  A_11 = 'A_11',
  /** 12x */
  A_12 = 'A_12',
  /** 13x */
  A_13 = 'A_13',
  /** 14x */
  A_14 = 'A_14',
  /** 15x */
  A_15 = 'A_15',
  /** 16x */
  A_16 = 'A_16',
  /** 17x */
  A_17 = 'A_17',
  /** 18x */
  A_18 = 'A_18',
  /** 19x */
  A_19 = 'A_19',
  /** 20x */
  A_20 = 'A_20',
  /** 21x */
  A_21 = 'A_21',
  /** 22x */
  A_22 = 'A_22',
  /** 23x */
  A_23 = 'A_23',
  /** 24x */
  A_24 = 'A_24'
}

export type ColunaTaxasParcelasType = {
   __typename?: 'ColunaTaxasParcelasType',
  id: Scalars['ID'],
  tabelataxaparcelas: TabelaTaxaParcelasType,
  parcelas: ColunaTaxasParcelasParcelas,
  taxaLucro?: Maybe<Scalars['Float']>,
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
  taxas?: Maybe<TaxaColunaType>,
};

export type ComissaoFuncionarioNodeTypeRelay = Node & {
   __typename?: 'ComissaoFuncionarioNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  tipoComissao?: Maybe<ComissaoFuncionariosTipoComissao>,
  /** Esse parametro vai estar prenchido somento na COMISSAO_COLETIVA */
  funcionario?: Maybe<FuncionarioTypeRelay>,
  valorComissao: Scalars['Float'],
  emprestimo: EmprestimoRelatorioType,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateFuncionarioNodeTypeRelay>,
  detalhes: Scalars['String'],
  detalhesComissao?: Maybe<Scalars['JSONString']>,
};

export type ComissaoFuncionarioNodeTypeRelayConnection = {
   __typename?: 'ComissaoFuncionarioNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ComissaoFuncionarioNodeTypeRelayEdge>>,
  totalComissao?: Maybe<Scalars['Float']>,
  saldoComissao?: Maybe<Scalars['Float']>,
  valorReceber?: Maybe<Scalars['Float']>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ComissaoFuncionarioNodeTypeRelay` and its cursor. */
export type ComissaoFuncionarioNodeTypeRelayEdge = {
   __typename?: 'ComissaoFuncionarioNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<ComissaoFuncionarioNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type ComissaoFuncionariosAgregations = {
   __typename?: 'ComissaoFuncionariosAgregations',
  somaRepasseFuncionario?: Maybe<Scalars['Decimal']>,
};

export type ComissaoFuncionariosRelatorioInput = {
  tipoComissao?: Maybe<Scalars['String']>,
  funcionario?: Maybe<Scalars['ID']>,
  funcionario_In?: Maybe<Scalars['ID']>,
  valorComissao?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgate_InicioPeriodo?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Gte?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Gt?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Lt?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Lte?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Gte?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Gt?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Lt?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Lte?: Maybe<Scalars['Date']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

/** An enumeration. */
export enum ComissaoFuncionariosTipoComissao {
  /** COMISSAO_FUNCIONARIO */
  ComissaoFuncionario = 'COMISSAO_FUNCIONARIO',
  /** COMISSAO_COLETIVA */
  ComissaoColetiva = 'COMISSAO_COLETIVA'
}

export type ComissaoFuncionariosType = {
   __typename?: 'ComissaoFuncionariosType',
  id: Scalars['ID'],
  tipoComissao?: Maybe<ComissaoFuncionariosTipoComissao>,
  /** Esse parametro vai estar prenchido somento na COMISSAO_COLETIVA */
  funcionario?: Maybe<FuncionarioTypeRelay>,
  valorComissao: Scalars['Float'],
  emprestimo: EmprestimoRelatorioType,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateFuncionarioNodeTypeRelay>,
  detalhes: Scalars['String'],
  detalhesComissao?: Maybe<Scalars['JSONString']>,
};

export type ComissaoFuncionariosTypeRelay = Node & {
   __typename?: 'ComissaoFuncionariosTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  tipoComissao?: Maybe<ComissaoFuncionariosTipoComissao>,
  /** Esse parametro vai estar prenchido somento na COMISSAO_COLETIVA */
  funcionario?: Maybe<FuncionarioTypeRelay>,
  valorComissao: Scalars['Float'],
  emprestimo: EmprestimoRelatorioType,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateFuncionarioNodeTypeRelay>,
  detalhes: Scalars['String'],
  detalhesComissao?: Maybe<Scalars['JSONString']>,
};

export type ComissaoFuncionariosTypeRelayConnection = {
   __typename?: 'ComissaoFuncionariosTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ComissaoFuncionariosTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ComissaoFuncionariosTypeRelay` and its cursor. */
export type ComissaoFuncionariosTypeRelayEdge = {
   __typename?: 'ComissaoFuncionariosTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<ComissaoFuncionariosTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type ComissaoNodeTypeRelay = Node & {
   __typename?: 'ComissaoNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  valorComissao: Scalars['Float'],
  emprestimo: EmprestimoRelatorioType,
  valorPago?: Maybe<Scalars['Float']>,
  parceiro?: Maybe<ParceirosNodeTypeRelay>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  dataRepasse?: Maybe<Scalars['Date']>,
  detalhes?: Maybe<Scalars['String']>,
  detalhesComissao?: Maybe<Scalars['JSONString']>,
  parcelasNsu?: Maybe<Scalars['String']>,
  nomeLoja?: Maybe<Scalars['String']>,
};

export type ComissaoNodeTypeRelayConnection = {
   __typename?: 'ComissaoNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ComissaoNodeTypeRelayEdge>>,
  totalComissao?: Maybe<Scalars['Float']>,
  saldoComissao?: Maybe<Scalars['Float']>,
  valorReceber?: Maybe<Scalars['Float']>,
  edgeCount?: Maybe<Scalars['Int']>,
  totalCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ComissaoNodeTypeRelay` and its cursor. */
export type ComissaoNodeTypeRelayEdge = {
   __typename?: 'ComissaoNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<ComissaoNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type ComissaoParceirosType = {
   __typename?: 'ComissaoParceirosType',
  id: Scalars['ID'],
  valorComissao: Scalars['Float'],
  emprestimo: EmprestimoRelatorioType,
  valorPago?: Maybe<Scalars['Float']>,
  parceiro?: Maybe<ParceirosNodeTypeRelay>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  dataRepasse?: Maybe<Scalars['Date']>,
  detalhes?: Maybe<Scalars['String']>,
  detalhesComissao?: Maybe<Scalars['JSONString']>,
  emprestimoSet?: Maybe<Array<Maybe<EmprestimoType>>>,
};

export type ComissaoParceiroType = {
   __typename?: 'ComissaoParceiroType',
  id: Scalars['ID'],
  valorComissao: Scalars['Float'],
  emprestimo: EmprestimoRelatorioType,
  valorPago?: Maybe<Scalars['Float']>,
  parceiro?: Maybe<ParceirosNodeTypeRelay>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  dataRepasse?: Maybe<Scalars['Date']>,
  detalhes?: Maybe<Scalars['String']>,
  detalhesComissao?: Maybe<Scalars['JSONString']>,
};

export type CompletarEmprestimoDeliveryMutation = {
   __typename?: 'CompletarEmprestimoDeliveryMutation',
  emprestimo?: Maybe<EmprestimoNode>,
};

export type ContaAgregacao = {
   __typename?: 'ContaAgregacao',
  somaValorPendente?: Maybe<Scalars['Decimal']>,
  somaValorPago?: Maybe<Scalars['Decimal']>,
  somaValorTotal?: Maybe<Scalars['Decimal']>,
  totalPagas?: Maybe<Scalars['Int']>,
  totalPendentes?: Maybe<Scalars['Int']>,
  totalVencidas?: Maybe<Scalars['Int']>,
  totalAprovados?: Maybe<Scalars['Int']>,
};

/** An enumeration. */
export enum ContaAPagarFormaPagamento {
  /** Boleto */
  Boleto = 'BOLETO',
  /** Transferência */
  Transferencia = 'TRANSFERENCIA'
}

export type ContaAPagarNode = Node & {
   __typename?: 'ContaAPagarNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade: EntidadeTypeRelay,
  funcionario: FuncionarioTypeRelay,
  titulo: Scalars['String'],
  destinatario: Scalars['String'],
  valor: Scalars['Float'],
  valorPago?: Maybe<Scalars['Float']>,
  dataVencimento: Scalars['DateTime'],
  dataPagamento?: Maybe<Scalars['DateTime']>,
  recorrencia?: Maybe<ContaAPagarRecorrencia>,
  formaPagamento: ContaAPagarFormaPagamento,
  tipoDespesa?: Maybe<ContaAPagarTipoDespesa>,
  status: ContaAPagarStatus,
  categoria: Scalars['String'],
  observacao?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  boletoDataVencimento?: Maybe<Scalars['DateTime']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  transferenciaNome?: Maybe<Scalars['String']>,
  transferenciaBanco?: Maybe<Scalars['String']>,
  transferenciaAgencia?: Maybe<Scalars['String']>,
  transferenciaTipoConta?: Maybe<ContaAPagarTransferenciaTipoConta>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaTipoPix: Scalars['String'],
  transferenciaPix: Scalars['String'],
  transferenciaVariacao?: Maybe<Scalars['String']>,
  transferenciaCpfCnpj?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  justificativaValorPago?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  anexos: Array<AnexoDeContaAPagarType>,
};

export type ContaAPagarNodeConnection = {
   __typename?: 'ContaAPagarNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContaAPagarNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ContaAPagarNode` and its cursor. */
export type ContaAPagarNodeEdge = {
   __typename?: 'ContaAPagarNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<ContaAPagarNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum ContaAPagarRecorrencia {
  /** Dia */
  Dia = 'DIA',
  /** Semana */
  Semana = 'SEMANA',
  /** Mês */
  Mes = 'MES',
  /** Ano */
  Ano = 'ANO',
  /** Avulso */
  Avulso = 'AVULSO'
}

/** An enumeration. */
export enum ContaAPagarStatus {
  /** Pendente */
  Pendente = 'PENDENTE',
  /** Aprovado */
  Aprovado = 'APROVADO',
  /** Reprovado */
  Reprovado = 'REPROVADO',
  /** Cancelado */
  Cancelado = 'CANCELADO',
  /** Pago */
  Pago = 'PAGO',
  /** Vencido */
  Vencido = 'VENCIDO'
}

/** An enumeration. */
export enum ContaAPagarTipoDespesa {
  /** Fixa */
  Fixa = 'FIXA',
  /** Variável */
  Variavel = 'VARIAVEL'
}

/** An enumeration. */
export enum ContaAPagarTransferenciaTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

export type ContaClienteInputMobile = {
  id?: Maybe<Scalars['ID']>,
  banco?: Maybe<Scalars['String']>,
  agencia?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  variacao?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
};

export type ContaClienteMobileType2 = {
  id: Scalars['ID'],
  valor: Scalars['Float'],
};

/** An enumeration. */
export enum ContaDeClienteTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

export type ContaDeClienteType = {
   __typename?: 'ContaDeClienteType',
  id: Scalars['ID'],
  cliente: EmprestimoClienteNode,
  nome: Scalars['String'],
  banco: Scalars['String'],
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  agencia: Scalars['String'],
  tipoConta: ContaDeClienteTipoConta,
  conta: Scalars['String'],
  variacao?: Maybe<Scalars['String']>,
  cpfCnpj: Scalars['String'],
  entidadeId?: Maybe<Scalars['Int']>,
};

export type ContagemDeCategoriaType = {
   __typename?: 'ContagemDeCategoriaType',
  totalTipoLojaAssociados?: Maybe<Scalars['Int']>,
  totalTipoLojaLojas?: Maybe<Scalars['Int']>,
  totalTipoLojaParceiros?: Maybe<Scalars['Int']>,
  totalTipoLojaOutros?: Maybe<Scalars['Int']>,
};

export type ContaNode = Node & {
   __typename?: 'ContaNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade: EntidadeTypeRelay,
  funcionario: FuncionarioTypeRelay,
  fornecedor?: Maybe<FornecedorViewNodeType>,
  categoria?: Maybe<CategoriaTypeRelay>,
  subcategoria?: Maybe<SubCategoriasTypeRelay>,
  referencia?: Maybe<ReferenciaContaType>,
  titulo: Scalars['String'],
  valor: Scalars['Float'],
  valorPago?: Maybe<Scalars['Float']>,
  dataVencimento: Scalars['Date'],
  dataPagamento?: Maybe<Scalars['Date']>,
  parcelas?: Maybe<Scalars['Int']>,
  parcelaAtual?: Maybe<Scalars['Int']>,
  tipoDespesa: ContaTipoDespesa,
  status: ContaStatus,
  observacao?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  anexocontaSet: Array<AnexoContaType>,
  formapagamentocontaSet: Array<FormaPagamentoContaType>,
  historicocontaSet: Array<HistoricoContaType>,
  hasBillPaidWithSameReference?: Maybe<Scalars['Boolean']>,
};

export type ContaNodeConnection = {
   __typename?: 'ContaNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContaNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ContaNode` and its cursor. */
export type ContaNodeEdge = {
   __typename?: 'ContaNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<ContaNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum ContaParceirosTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

export type ContaParceirosType = {
   __typename?: 'ContaParceirosType',
  id: Scalars['ID'],
  titular: Scalars['String'],
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  banco: Scalars['String'],
  agencia: Scalars['String'],
  tipoConta: ContaParceirosTipoConta,
  conta: Scalars['String'],
  variacao?: Maybe<Scalars['String']>,
  cpfCnpj: Scalars['String'],
  parceiro: ParceirosNodeTypeRelay,
  resgateautomaticoparceirosSet: Array<ResgateAutomaticoParceirosType>,
};

export type ContaParceirosTypeInput = {
  titular?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  banco?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  agencia?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
  variacao?: Maybe<Scalars['String']>,
  tipoPix?: Maybe<Scalars['String']>,
  pix?: Maybe<Scalars['String']>,
};

export type ContaRelatorioInput = {
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
};

/** An enumeration. */
export enum ContaStatus {
  /** Pendente */
  Pendente = 'PENDENTE',
  /** Aprovado */
  Aprovado = 'APROVADO',
  /** Reprovado */
  Reprovado = 'REPROVADO',
  /** Cancelado */
  Cancelado = 'CANCELADO',
  /** Pago */
  Pago = 'PAGO',
  /** Vencido */
  Vencido = 'VENCIDO'
}

/** An enumeration. */
export enum ContaTipoDespesa {
  /** Fixa */
  Fixa = 'FIXA',
  /** parcela */
  Parcela = 'PARCELA'
}

export type CreateAnotacao = {
   __typename?: 'CreateAnotacao',
  ok?: Maybe<Scalars['Boolean']>,
  anotacao?: Maybe<AnotacaoType>,
};

export type CreateCartaoCreditoMobile = {
   __typename?: 'CreateCartaoCreditoMobile',
  ok?: Maybe<Scalars['Boolean']>,
  cartao?: Maybe<CartaoDeCreditoType>,
};

export type CreateCartaoCreditoTerceirosMobile = {
   __typename?: 'CreateCartaoCreditoTerceirosMobile',
  ok?: Maybe<Scalars['Boolean']>,
  cartao?: Maybe<CartaoDeCreditoType>,
};

export type CreateClienteFromLead = {
   __typename?: 'CreateClienteFromLead',
  cliente?: Maybe<ClienteType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CreateClienteMobile = {
   __typename?: 'CreateClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
  usuario?: Maybe<UserType>,
};

export type CreateCobranca = {
   __typename?: 'CreateCobranca',
  ok?: Maybe<Scalars['Boolean']>,
  cobranca?: Maybe<CobrancaType>,
};

export type CreateContaClienteMobilne = {
   __typename?: 'CreateContaClienteMobilne',
  ok?: Maybe<Scalars['Boolean']>,
  conta?: Maybe<ContaDeClienteType>,
};

export type CreateCustomerMobileSimplify = {
   __typename?: 'CreateCustomerMobileSimplify',
  ok?: Maybe<Scalars['Boolean']>,
  lead?: Maybe<LeadsType>,
  user?: Maybe<UserType>,
};

export type CreateEmprestimoMobile = {
   __typename?: 'CreateEmprestimoMobile',
  ok?: Maybe<Scalars['Boolean']>,
  emprestimo?: Maybe<EmprestimoType>,
};

export type CreateLeadCrm = {
   __typename?: 'CreateLeadCRM',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CreateLeadProp = {
   __typename?: 'CreateLeadProp',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CreateLoanSimulationMobile = {
   __typename?: 'CreateLoanSimulationMobile',
  loanSimulation?: Maybe<PropostasType>,
};

export type CreatePreClienteMobile = {
   __typename?: 'CreatePreClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
  usuario?: Maybe<UserType>,
};

export type CreateSourceMutation = {
   __typename?: 'CreateSourceMutation',
  ok?: Maybe<Scalars['Boolean']>,
  origem?: Maybe<OrigensType>,
};

export type CreateTarefa = {
   __typename?: 'CreateTarefa',
  ok?: Maybe<Scalars['Boolean']>,
  tarefa?: Maybe<TarefasType>,
};

export type CriarAutomacaoZApi = {
   __typename?: 'CriarAutomacaoZApi',
  instancia?: Maybe<InstanciaZApiType>,
};

export type CriarCategoriaMutation = {
   __typename?: 'CriarCategoriaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  categoria?: Maybe<CategoriaTypeRelay>,
};

export type CriarClienteMutation = {
   __typename?: 'CriarClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
};

export type CriarClienteRapidoInput = {
  idEntidade: Scalars['ID'],
  nome: Scalars['String'],
  email: Scalars['String'],
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  nomeRepresentante?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  celular?: Maybe<Scalars['String']>,
  asaasId?: Maybe<Scalars['String']>,
};

export type CriarClienteRapidoMutation = {
   __typename?: 'CriarClienteRapidoMutation',
  cliente?: Maybe<ClienteType>,
};

export type CriarContaAPagarMutation = {
   __typename?: 'CriarContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  contaPagar?: Maybe<ContaAPagarNode>,
};

export type CriarContaMutation = {
   __typename?: 'CriarContaMutation',
  contas?: Maybe<Array<Maybe<ContaNode>>>,
};

export type CriarEmprestimoMutation = {
   __typename?: 'CriarEmprestimoMutation',
  emprestimo?: Maybe<EmprestimoNode>,
};

export type CriarEntidadeMutation = {
   __typename?: 'CriarEntidadeMutation',
  permissao?: Maybe<PermissaoType>,
  entidade?: Maybe<EntidadeType>,
};

export type CriarEnvio = {
   __typename?: 'CriarEnvio',
  ok?: Maybe<Scalars['Boolean']>,
  envioSms?: Maybe<EnvioSmsType>,
  envioEmail?: Maybe<EnvioEmailType>,
};

export type CriarFornecedor = {
   __typename?: 'CriarFornecedor',
  fornecedor?: Maybe<FornecedorNodeType>,
};

export type CriarFuncionarioMutation = {
   __typename?: 'CriarFuncionarioMutation',
  funcionario?: Maybe<FuncionarioType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CriarFuncionarioRepasse = {
   __typename?: 'CriarFuncionarioRepasse',
  ok?: Maybe<Scalars['Boolean']>,
  message?: Maybe<Scalars['String']>,
};

export type CriarGrupoDePermissoesMutation = {
   __typename?: 'CriarGrupoDePermissoesMutation',
  ok?: Maybe<Scalars['Boolean']>,
  grupoPermissoes?: Maybe<GrupoDePermissoesType>,
};

export type CriarInstanciaZApi = {
   __typename?: 'CriarInstanciaZApi',
  instancia?: Maybe<InstanciaZApiType>,
};

export type CriarLista = {
   __typename?: 'CriarLista',
  lista?: Maybe<ListaSmsType>,
};

export type CriarMaquinaMutation = {
   __typename?: 'CriarMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CriarMensagens = {
   __typename?: 'CriarMensagens',
  ok?: Maybe<Scalars['Boolean']>,
  mensagem?: Maybe<MensagemSmsType>,
};

export type CriarMetaMutation = {
   __typename?: 'CriarMetaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  metasCriadasComSucesso?: Maybe<Array<Maybe<Scalars['String']>>>,
  metasComErro?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type CriarParceiros = {
   __typename?: 'CriarParceiros',
  parceiro?: Maybe<ParceirosType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CriarPedidoDeReembolsoMutation = {
   __typename?: 'CriarPedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  pedidoReembolso?: Maybe<PedidoDeReembolsoNode>,
};

export type CriarRegramentoEntidade = {
   __typename?: 'CriarRegramentoEntidade',
  entidade?: Maybe<EntidadeType>,
};

export type CriarRegramentoTipoEntidade = {
   __typename?: 'CriarRegramentoTipoEntidade',
  tipoEntidade?: Maybe<TiposEntidadesNodeType>,
};

export type CriarSubCategoriaMutation = {
   __typename?: 'CriarSubCategoriaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  subcategoria?: Maybe<SubCategoriasTypeRelay>,
};

export type CriarTabelas = {
   __typename?: 'CriarTabelas',
  tabela?: Maybe<TabelaParceirosType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type CriarTipoEntidade = {
   __typename?: 'CriarTipoEntidade',
  tipoEntidade?: Maybe<TiposEntidadesNodeType>,
};

export type CriarTipoFuncionarioMutation = {
   __typename?: 'CriarTipoFuncionarioMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type CustomJsonWebToken = {
   __typename?: 'CustomJSONWebToken',
  payload: Scalars['GenericScalar'],
  refreshExpiresIn: Scalars['Int'],
  funcionario?: Maybe<FuncionarioType>,
  permissoes?: Maybe<Array<Maybe<PermissaoType>>>,
  gruposPermissao?: Maybe<Array<Maybe<GrupoDePermissoesType>>>,
  token: Scalars['String'],
};

export type DarPermissoesGrupoMutation = {
   __typename?: 'DarPermissoesGrupoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  permissoesGrupo?: Maybe<Array<Maybe<PermissaoDeGrupoType>>>,
};

export type DarVariasPermissoesMutation = {
   __typename?: 'DarVariasPermissoesMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type Dashboard_Envios = {
   __typename?: 'Dashboard_envios',
  envios?: Maybe<Scalars['Int']>,
  listas?: Maybe<Scalars['Int']>,
  mensagens?: Maybe<Scalars['Int']>,
};

export type DashboardEmail = {
   __typename?: 'DashboardEmail',
  data?: Maybe<Scalars['Date']>,
  entregues?: Maybe<Scalars['Int']>,
  abertos?: Maybe<Scalars['Int']>,
  cliques?: Maybe<Scalars['Int']>,
  falhas?: Maybe<Scalars['Int']>,
  dascartados?: Maybe<Scalars['Int']>,
  spam?: Maybe<Scalars['Int']>,
  enviados?: Maybe<Scalars['Int']>,
};

export type DashboardSms = {
   __typename?: 'DashboardSms',
  data?: Maybe<Scalars['Date']>,
  enviados?: Maybe<Scalars['Int']>,
  recebidos?: Maybe<Scalars['Int']>,
  falhas?: Maybe<Scalars['Int']>,
};




export type DefinirTablelaTaxasParcelas = {
   __typename?: 'DefinirTablelaTaxasParcelas',
  entidades?: Maybe<Array<Maybe<EntidadeType>>>,
};

export type DefinirTaxasParcelas = {
   __typename?: 'DefinirTaxasParcelas',
  entidades?: Maybe<Array<Maybe<EntidadeType>>>,
};

export type DefinirTaxasTabela = {
   __typename?: 'DefinirTaxasTabela',
  ok?: Maybe<Scalars['Boolean']>,
  entidades?: Maybe<Array<Maybe<EntidadeType>>>,
};

export type DeletarInstanciaZApi = {
   __typename?: 'DeletarInstanciaZApi',
  ok?: Maybe<Scalars['Boolean']>,
};

export type DeletarTaxasMaquina = {
   __typename?: 'DeletarTaxasMaquina',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type DeleteCobranca = {
   __typename?: 'DeleteCobranca',
  ok?: Maybe<Scalars['Boolean']>,
};

export type DeleteMetaMutation = {
   __typename?: 'DeleteMetaMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type DeleteSourceMutation = {
   __typename?: 'DeleteSourceMutation',
  ok?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['ID']>,
};

export type DesabilitarMaquinaMutation = {
   __typename?: 'DesabilitarMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type DesassociarClienteEntidadeMutation = {
   __typename?: 'DesassociarClienteEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
};

export type DesassociarEntidadesGrupoMutation = {
   __typename?: 'DesassociarEntidadesGrupoMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type DesassociarFuncionariosGrupoMutation = {
   __typename?: 'DesassociarFuncionariosGrupoMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type DesbloquearFuncionario = {
   __typename?: 'DesbloquearFuncionario',
  funcionario?: Maybe<FuncionarioType>,
};

export type DesbloquearMaquinaMutation = {
   __typename?: 'DesbloquearMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type DescricaoPorGruposType = {
   __typename?: 'DescricaoPorGruposType',
  grupo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  permssaoDescricao?: Maybe<Scalars['String']>,
};

export type DisvinculaTokenOnesignalMutation = {
   __typename?: 'DisvinculaTokenOnesignalMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type EdiarLista = {
   __typename?: 'EdiarLista',
  ok?: Maybe<Scalars['Boolean']>,
  lista?: Maybe<ListaSmsType>,
};

export type EditarAnotacao = {
   __typename?: 'EditarAnotacao',
  ok?: Maybe<Scalars['Boolean']>,
  anotacao?: Maybe<AnotacaoType>,
};

export type EditarCategoriaMutation = {
   __typename?: 'EditarCategoriaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  categoria?: Maybe<CategoriaTypeRelay>,
};

export type EditarContaMutation = {
   __typename?: 'EditarContaMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditarFornecedor = {
   __typename?: 'EditarFornecedor',
  fornecedor?: Maybe<FornecedorNodeType>,
};

export type EditarFuncionarioRepasse = {
   __typename?: 'EditarFuncionarioRepasse',
  ok?: Maybe<Scalars['Boolean']>,
  message?: Maybe<Scalars['String']>,
  funcionarioRepasse?: Maybe<FuncionariosRepasseType>,
};

export type EditarLead = {
   __typename?: 'EditarLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditarLeadProp = {
   __typename?: 'EditarLeadProp',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditarParceiros = {
   __typename?: 'EditarParceiros',
  parceiro?: Maybe<ParceirosType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditarRegramentoEntidade = {
   __typename?: 'EditarRegramentoEntidade',
  entidade?: Maybe<EntidadeType>,
};

export type EditarRegramentoTipoEntidade = {
   __typename?: 'EditarRegramentoTipoEntidade',
  tipoEntidade?: Maybe<TiposEntidadesNodeType>,
};

export type EditarSubCategoriaMutation = {
   __typename?: 'EditarSubCategoriaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  subcategoria?: Maybe<SubCategoriasTypeRelay>,
};

export type EditarTabelas = {
   __typename?: 'EditarTabelas',
  tabela?: Maybe<TabelaParceirosType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditarTablelaTaxasParcelas = {
   __typename?: 'EditarTablelaTaxasParcelas',
  entidades?: Maybe<Array<Maybe<EntidadeType>>>,
};

export type EditarTarefa = {
   __typename?: 'EditarTarefa',
  ok?: Maybe<Scalars['Boolean']>,
  tarefa?: Maybe<TarefasType>,
};

export type EditarTarefasMult = {
   __typename?: 'EditarTarefasMult',
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditarTipoEntidade = {
   __typename?: 'EditarTipoEntidade',
  tipoEntidade?: Maybe<TiposEntidadesNodeType>,
};

export type EditarTipoFuncionarioMutation = {
   __typename?: 'EditarTipoFuncionarioMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type EditSourceMutation = {
   __typename?: 'EditSourceMutation',
  ok?: Maybe<Scalars['Boolean']>,
  origem?: Maybe<OrigensType>,
};

export type EditUserProfileMobile = {
   __typename?: 'EditUserProfileMobile',
  lead?: Maybe<LeadsType>,
  token?: Maybe<Scalars['String']>,
};

export type EmailRemetenteType = {
   __typename?: 'EmailRemetenteType',
  id: Scalars['ID'],
  email: Scalars['String'],
  entidade: EntidadeTypeRelay,
  envioemailSet: EnvioEmailTypeRelayConnection,
};


export type EmailRemetenteTypeEnvioemailSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type EmprestimoAgregations = {
   __typename?: 'EmprestimoAgregations',
  somaValoresPassados?: Maybe<Scalars['Decimal']>,
  somaValorRetirado?: Maybe<Scalars['Decimal']>,
  taxaMediaTotal?: Maybe<Scalars['Decimal']>,
  somaRepasseParceiro?: Maybe<Scalars['Decimal']>,
};

export type EmprestimoAnexoInput = {
  url: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
};

export type EmprestimoClienteAgregations = {
   __typename?: 'EmprestimoClienteAgregations',
  somaValorPassado?: Maybe<Scalars['Decimal']>,
  somaValorRetirado?: Maybe<Scalars['Decimal']>,
  taxaMediaTotal?: Maybe<Scalars['Decimal']>,
  mediaParcelas?: Maybe<Scalars['Float']>,
  ticketMedio?: Maybe<Scalars['Decimal']>,
};

export type EmprestimoClienteNode = Node & {
   __typename?: 'EmprestimoClienteNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  cpf?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  sexo?: Maybe<ClienteSexo>,
  estadoCivil?: Maybe<ClienteEstadoCivil>,
  rg?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  entidades: EntidadeTypeRelayConnection,
  lead: Scalars['Boolean'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  origem?: Maybe<Scalars['String']>,
  user?: Maybe<UserType>,
  asaasCustomerPayload: Scalars['JSONString'],
  asaasCustomerId?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  nomeRepresentante?: Maybe<Scalars['String']>,
  telefones: Array<TelefoneDeClienteType>,
  contas: Array<ContaDeClienteType>,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  cobrancaSet: Array<CobrancaType>,
  cartaodecreditoSet: CartaoDeCreditoNodeTypeRelayConnection,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
  pk: Scalars['Int'],
};


export type EmprestimoClienteNodeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoClienteNodeEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EmprestimoClienteNodeCartaodecreditoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>
};


export type EmprestimoClienteNodeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type EmprestimoClienteNodeListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type EmprestimoClienteNodeConnection = {
   __typename?: 'EmprestimoClienteNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmprestimoClienteNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EmprestimoClienteNode` and its cursor. */
export type EmprestimoClienteNodeEdge = {
   __typename?: 'EmprestimoClienteNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmprestimoClienteNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EmprestimoClienteRelatorioInput = {
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  cliente_Id?: Maybe<Scalars['Float']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_In?: Maybe<Scalars['String']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  cliente_Sexo_In?: Maybe<Scalars['String']>,
  cliente_Municipio?: Maybe<Scalars['String']>,
  cliente_Municipio_In?: Maybe<Scalars['String']>,
  cliente_Municipio_Icontains?: Maybe<Scalars['String']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['ID']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  quantidadeEmprestimo?: Maybe<Scalars['Float']>,
  taxaMedia?: Maybe<Scalars['Float']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type EmprestimoClienteRelatorioType = Node & {
   __typename?: 'EmprestimoClienteRelatorioType',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  cpf?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  sexo?: Maybe<ClienteSexo>,
  estadoCivil?: Maybe<ClienteEstadoCivil>,
  rg?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  entidades: EntidadeTypeRelayConnection,
  lead: Scalars['Boolean'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  origem?: Maybe<Scalars['String']>,
  user?: Maybe<UserType>,
  asaasCustomerPayload: Scalars['JSONString'],
  asaasCustomerId?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  nomeRepresentante?: Maybe<Scalars['String']>,
  telefones: Array<TelefoneDeClienteType>,
  contas: Array<ContaDeClienteType>,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  cobrancaSet: Array<CobrancaType>,
  cartaodecreditoSet: CartaoDeCreditoNodeTypeRelayConnection,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
  quantidadeEmprestimo?: Maybe<Scalars['Int']>,
  totalValorDesejado?: Maybe<Scalars['Decimal']>,
  totalValorEmprestimo?: Maybe<Scalars['Decimal']>,
  taxaMedia?: Maybe<Scalars['Decimal']>,
  lucroBruto?: Maybe<Scalars['Decimal']>,
  nomesEntidades?: Maybe<Scalars['String']>,
  numerosTelefones?: Maybe<Scalars['String']>,
};


export type EmprestimoClienteRelatorioTypeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoClienteRelatorioTypeEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EmprestimoClienteRelatorioTypeCartaodecreditoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>
};


export type EmprestimoClienteRelatorioTypeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type EmprestimoClienteRelatorioTypeListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type EmprestimoClienteRelatorioTypeConnection = {
   __typename?: 'EmprestimoClienteRelatorioTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmprestimoClienteRelatorioTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EmprestimoClienteRelatorioType` and its cursor. */
export type EmprestimoClienteRelatorioTypeEdge = {
   __typename?: 'EmprestimoClienteRelatorioTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmprestimoClienteRelatorioType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EmprestimoClienteType = {
   __typename?: 'EmprestimoClienteType',
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: NsuRelatorioTypeConnection,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
};


export type EmprestimoClienteTypeNsusArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EmprestimoClienteTypeComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoClienteTypeComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EmprestimoFormaPagamentoEditInput = {
  id: Scalars['ID'],
  valor?: Maybe<Scalars['Float']>,
  anexo?: Maybe<Scalars['String']>,
  contaNome?: Maybe<Scalars['String']>,
  contaBanco?: Maybe<Scalars['String']>,
  contaAgencia?: Maybe<Scalars['String']>,
  contaTipoConta?: Maybe<Scalars['String']>,
  contaConta?: Maybe<Scalars['String']>,
  contaVariacao?: Maybe<Scalars['String']>,
  contaCpfCnpj?: Maybe<Scalars['String']>,
  boletoDataVencimento?: Maybe<Scalars['Date']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoAnexo?: Maybe<Scalars['String']>,
  tipoPix?: Maybe<Scalars['String']>,
  pix?: Maybe<Scalars['String']>,
};

export type EmprestimoFormaPagamentoInput = {
  valor: Scalars['Float'],
  anexo?: Maybe<Scalars['String']>,
  contaNome?: Maybe<Scalars['String']>,
  tipoPix?: Maybe<Scalars['String']>,
  pix?: Maybe<Scalars['String']>,
  contaBanco?: Maybe<Scalars['String']>,
  contaAgencia?: Maybe<Scalars['String']>,
  contaTipoConta?: Maybe<ContaDeClienteTipoConta>,
  contaConta?: Maybe<Scalars['String']>,
  contaVariacao?: Maybe<Scalars['String']>,
  contaCpfCnpj?: Maybe<Scalars['String']>,
  boletoDataVencimento?: Maybe<Scalars['Date']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoAnexo?: Maybe<Scalars['String']>,
};

export type EmprestimoFormaPagamentoPagarInput = {
  id?: Maybe<Scalars['ID']>,
  anexo?: Maybe<Scalars['String']>,
};

export type EmprestimoFuncionarioNodeTypeRalay = Node & {
   __typename?: 'EmprestimoFuncionarioNodeTypeRalay',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: NsuRelatorioTypeConnection,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet?: Maybe<Array<Maybe<ComissaoFuncionariosType>>>,
};


export type EmprestimoFuncionarioNodeTypeRalayNsusArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EmprestimoFuncionarioNodeTypeRalayComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EmprestimoFuncionarioNodeTypeRalayConnection = {
   __typename?: 'EmprestimoFuncionarioNodeTypeRalayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmprestimoFuncionarioNodeTypeRalayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EmprestimoFuncionarioNodeTypeRalay` and its cursor. */
export type EmprestimoFuncionarioNodeTypeRalayEdge = {
   __typename?: 'EmprestimoFuncionarioNodeTypeRalayEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmprestimoFuncionarioNodeTypeRalay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EmprestimoNode = Node & {
   __typename?: 'EmprestimoNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: Array<NsuType>,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
  pk: Scalars['Int'],
};


export type EmprestimoNodeComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoNodeComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EmprestimoNodeConnection = {
   __typename?: 'EmprestimoNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmprestimoNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EmprestimoNode` and its cursor. */
export type EmprestimoNodeEdge = {
   __typename?: 'EmprestimoNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmprestimoNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EmprestimoNodeTypeRelay = Node & {
   __typename?: 'EmprestimoNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: Array<NsuType>,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
};


export type EmprestimoNodeTypeRelayComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoNodeTypeRelayComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EmprestimoNodeTypeRelayConnection = {
   __typename?: 'EmprestimoNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmprestimoNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EmprestimoNodeTypeRelay` and its cursor. */
export type EmprestimoNodeTypeRelayEdge = {
   __typename?: 'EmprestimoNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmprestimoNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum EmprestimoParcelas {
  /** 1x */
  A_1 = 'A_1',
  /** 2x */
  A_2 = 'A_2',
  /** 3x */
  A_3 = 'A_3',
  /** 4x */
  A_4 = 'A_4',
  /** 5x */
  A_5 = 'A_5',
  /** 6x */
  A_6 = 'A_6',
  /** 7x */
  A_7 = 'A_7',
  /** 8x */
  A_8 = 'A_8',
  /** 9x */
  A_9 = 'A_9',
  /** 10x */
  A_10 = 'A_10',
  /** 11x */
  A_11 = 'A_11',
  /** 12x */
  A_12 = 'A_12',
  /** 13x */
  A_13 = 'A_13',
  /** 14x */
  A_14 = 'A_14',
  /** 15x */
  A_15 = 'A_15',
  /** 16x */
  A_16 = 'A_16',
  /** 17x */
  A_17 = 'A_17',
  /** 18x */
  A_18 = 'A_18',
  /** 19x */
  A_19 = 'A_19',
  /** 20x */
  A_20 = 'A_20',
  /** 21x */
  A_21 = 'A_21',
  /** 22x */
  A_22 = 'A_22',
  /** 23x */
  A_23 = 'A_23',
  /** 24x */
  A_24 = 'A_24'
}

export type EmprestimoRelatorioInput = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Cpf?: Maybe<Scalars['String']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  nsu_NsuCv?: Maybe<Scalars['String']>,
  nsu_NsuCv_Icontains?: Maybe<Scalars['String']>,
  nsu_NsuCv_In?: Maybe<Scalars['String']>,
  valorTotal?: Maybe<Scalars['Float']>,
  valorTotal_In?: Maybe<Scalars['Float']>,
  valorTotal_Gte?: Maybe<Scalars['Float']>,
  valorTotal_Lte?: Maybe<Scalars['Float']>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorDesejado_In?: Maybe<Scalars['Float']>,
  valorDesejado_Gte?: Maybe<Scalars['Float']>,
  valorDesejado_Lte?: Maybe<Scalars['Float']>,
  nsu_TitularDivergente_Cpf?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_In?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  bandeiraCartao_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  parcelas_Lt?: Maybe<Scalars['String']>,
  parcelas_Gt?: Maybe<Scalars['String']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  entidade_Id?: Maybe<Scalars['ID']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  origem_In?: Maybe<Scalars['String']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
};

export type EmprestimoRelatorioType = Node & {
   __typename?: 'EmprestimoRelatorioType',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: Array<NsuType>,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
  lucroBruto?: Maybe<Scalars['Decimal']>,
  valorPassado?: Maybe<Scalars['Decimal']>,
};


export type EmprestimoRelatorioTypeComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoRelatorioTypeComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EmprestimoRelatorioTypeConnection = {
   __typename?: 'EmprestimoRelatorioTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmprestimoRelatorioTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EmprestimoRelatorioType` and its cursor. */
export type EmprestimoRelatorioTypeEdge = {
   __typename?: 'EmprestimoRelatorioTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<EmprestimoRelatorioType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum EmprestimoSolicitadoVia {
  /** Web */
  Web = 'WEB',
  /** App */
  App = 'APP'
}

/** An enumeration. */
export enum EmprestimoStatus {
  /** Delivery */
  Delivery = 'DELIVERY',
  /** Em análise */
  EmAnalise = 'EM_ANALISE',
  /** Aprovada */
  Aprovada = 'APROVADA',
  /** Reprovada */
  Reprovada = 'REPROVADA',
  /** Paga */
  Paga = 'PAGA',
  /** Cancelada */
  Cancelada = 'CANCELADA',
  /** Aguardando assinatura */
  AguardandoAssinatura = 'AGUARDANDO_ASSINATURA',
  /**   Em Rascunho */
  Rascunho = 'RASCUNHO'
}

export type EmprestimoType = {
   __typename?: 'EmprestimoType',
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: Array<NsuType>,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
};


export type EmprestimoTypeComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EmprestimoTypeComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

/** An enumeration. */
export enum EntidadeCategoria {
  /** Loja */
  Loja = 'LOJA',
  /** Parceiro Comercial */
  Parceiro = 'PARCEIRO',
  /** Associado Operacional */
  Associado = 'ASSOCIADO',
  /** Usuários do Sistema */
  Usuario = 'USUARIO'
}

export type EntidadeDeGrupoType = {
   __typename?: 'EntidadeDeGrupoType',
  id: Scalars['ID'],
  grupo: GrupoDePermissoesTypeRelay,
  entidade: EntidadeTypeRelay,
};

export type EntidadeFuncionarioRepasseType = {
   __typename?: 'EntidadeFuncionarioRepasseType',
  id: Scalars['ID'],
  funcionario: FuncionariosRepasseNodeTypeRelay,
  entidade: EntidadeTypeRelay,
};

export type EntidadesValor = {
  entidade?: Maybe<Scalars['ID']>,
  valor?: Maybe<Scalars['Decimal']>,
};

export type EntidadeType = {
   __typename?: 'EntidadeType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  razao?: Maybe<Scalars['String']>,
  nomeIdentificacao: Scalars['String'],
  cnpj?: Maybe<Scalars['String']>,
  anexoCnpj?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  comprovanteEndereco?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['String']>,
  categoria?: Maybe<EntidadeCategoria>,
  taxaLucro?: Maybe<Scalars['Float']>,
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
  tipoLoja?: Maybe<RegramentoTiposEntidadesNodeType>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  clientCustomerId?: Maybe<Scalars['String']>,
  mensagemInvoice: Scalars['String'],
  dddVinculacaoApp?: Maybe<Scalars['String']>,
  /** 
 * ex: Utilize o link
   * 'https://www.google.com/maps/dir/?api=1&origin=&destination=' + o endereço em
   * forma de url encoding, para formatar, pode utilizar o site <a
   * href='https://www.urlencoder.org/'>https://www.urlencoder.org/</a>
 */
  googleMapsUrl: Scalars['String'],
  /** https://api.whatsapp.com/send/?phone=[NUMERO_DA_LOJA]&text=[TEXTO_PARA_ENVIAR] */
  whatsappApi: Scalars['String'],
  imagem?: Maybe<Scalars['String']>,
  telefones: Array<TelefoneDeEntidadeType>,
  permissaoSet: Array<PermissaoType>,
  pedidodereembolsoSet: PedidoDeReembolsoNodeConnection,
  clienteSet: EmprestimoClienteNodeConnection,
  contaapagarSet: ContaAPagarNodeConnection,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  tabelataxaparcelasSet: Array<TabelaTaxaParcelasType>,
  taxasParcelas: Array<TaxasParcelasType>,
  entidadesGrupos: Array<EntidadeDeGrupoType>,
  meta: MetaNodeTypeRelayConnection,
  cobrancaSet: Array<CobrancaType>,
  maquinaSet: MaquinaNodeConnection,
  ocorrenciasMaquinas: OcorrenciaComMaquinaNodeConnection,
  ocorrenciasPosterioresMaquinas: OcorrenciaComMaquinaNodeConnection,
  appconfigSet: Array<AppConfigType>,
  /** Selecione a unidade */
  Entidades: Array<AppConfigType>,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  lojaparceiroSet: Array<LojaParceiroType>,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  entidadefuncionariorepasseSet: Array<EntidadeFuncionarioRepasseType>,
  listasmsSet: ListaSmsTypeRelayConnection,
  emailremetenteSet: Array<EmailRemetenteType>,
  instanciazapiSet: InstanciaZApiNodeConnection,
  fornecedorSet: FornecedorViewNodeTypeConnection,
  referenciaSet: Array<ReferenciaContaType>,
  contaSet: ContaNodeConnection,
  distancia?: Maybe<Scalars['String']>,
};


export type EntidadeTypePedidodereembolsoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeClienteSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  cliente_Id?: Maybe<Scalars['ID']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_In?: Maybe<Scalars['String']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  cliente_Sexo_In?: Maybe<Scalars['String']>,
  cliente_Municipio?: Maybe<Scalars['String']>,
  cliente_Municipio_In?: Maybe<Scalars['String']>,
  cliente_Municipio_Icontains?: Maybe<Scalars['String']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['ID']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  quantidadeEmprestimo?: Maybe<Scalars['Float']>,
  taxaMedia?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeContaapagarSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  destinatario?: Maybe<Scalars['String']>,
  destinatario_Icontains?: Maybe<Scalars['String']>,
  destinatario_Istartswith?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<Scalars['String']>,
  recorrencia_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gte?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lte?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gte?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lte?: Maybe<Scalars['DateTime']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaConta_Icontains?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EntidadeTypeMetaArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  valorMeta?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  inicioMeta?: Maybe<Scalars['String']>,
  inicioMeta_Gte?: Maybe<Scalars['Date']>,
  inicioMeta_Gt?: Maybe<Scalars['Date']>,
  inicioMeta_Lt?: Maybe<Scalars['Date']>,
  inicioMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta?: Maybe<Scalars['String']>,
  fimMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta_Lt?: Maybe<Scalars['Date']>,
  fimMeta_Gte?: Maybe<Scalars['Date']>,
  fimMeta_Gt?: Maybe<Scalars['Date']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeMaquinaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  cnpj?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  etiquetagem?: Maybe<Scalars['String']>,
  etiquetagem_Icontains?: Maybe<Scalars['String']>,
  numeroTerminal?: Maybe<Scalars['String']>,
  numeroTerminal_Icontains?: Maybe<Scalars['String']>,
  serialNumber?: Maybe<Scalars['String']>,
  serialNumber_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeOcorrenciasMaquinasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeOcorrenciasPosterioresMaquinasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type EntidadeTypeParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type EntidadeTypeListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type EntidadeTypeInstanciazapiSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['String']>,
  isActived?: Maybe<Scalars['Boolean']>,
  isConnected?: Maybe<Scalars['Boolean']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Float']>
};


export type EntidadeTypeFornecedorSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EntidadeTypeContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EntidadeTypeRelay = Node & {
   __typename?: 'EntidadeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  razao?: Maybe<Scalars['String']>,
  nomeIdentificacao: Scalars['String'],
  cnpj?: Maybe<Scalars['String']>,
  anexoCnpj?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  comprovanteEndereco?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['String']>,
  categoria?: Maybe<EntidadeCategoria>,
  taxaLucro?: Maybe<Scalars['Float']>,
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
  tipoLoja?: Maybe<RegramentoTiposEntidadesNodeType>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  clientCustomerId?: Maybe<Scalars['String']>,
  mensagemInvoice: Scalars['String'],
  dddVinculacaoApp?: Maybe<Scalars['String']>,
  /** 
 * ex: Utilize o link
   * 'https://www.google.com/maps/dir/?api=1&origin=&destination=' + o endereço em
   * forma de url encoding, para formatar, pode utilizar o site <a
   * href='https://www.urlencoder.org/'>https://www.urlencoder.org/</a>
 */
  googleMapsUrl: Scalars['String'],
  /** https://api.whatsapp.com/send/?phone=[NUMERO_DA_LOJA]&text=[TEXTO_PARA_ENVIAR] */
  whatsappApi: Scalars['String'],
  imagem?: Maybe<Scalars['String']>,
  telefones: Array<TelefoneDeEntidadeType>,
  permissaoSet: Array<PermissaoType>,
  pedidodereembolsoSet: PedidoDeReembolsoNodeConnection,
  clienteSet: EmprestimoClienteNodeConnection,
  contaapagarSet: ContaAPagarNodeConnection,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  tabelataxaparcelasSet: Array<TabelaTaxaParcelasType>,
  taxasParcelas: Array<TaxasParcelasType>,
  entidadesGrupos: Array<EntidadeDeGrupoType>,
  meta: MetaNodeTypeRelayConnection,
  cobrancaSet: Array<CobrancaType>,
  maquinaSet: MaquinaNodeConnection,
  ocorrenciasMaquinas: OcorrenciaComMaquinaNodeConnection,
  ocorrenciasPosterioresMaquinas: OcorrenciaComMaquinaNodeConnection,
  appconfigSet: Array<AppConfigType>,
  /** Selecione a unidade */
  Entidades: Array<AppConfigType>,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  lojaparceiroSet: Array<LojaParceiroType>,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  entidadefuncionariorepasseSet: Array<EntidadeFuncionarioRepasseType>,
  listasmsSet: ListaSmsTypeRelayConnection,
  emailremetenteSet: Array<EmailRemetenteType>,
  instanciazapiSet: InstanciaZApiNodeConnection,
  fornecedorSet: FornecedorViewNodeTypeConnection,
  referenciaSet: Array<ReferenciaContaType>,
  contaSet: ContaNodeConnection,
  pk: Scalars['Int'],
};


export type EntidadeTypeRelayPedidodereembolsoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayClienteSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  cliente_Id?: Maybe<Scalars['ID']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_In?: Maybe<Scalars['String']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  cliente_Sexo_In?: Maybe<Scalars['String']>,
  cliente_Municipio?: Maybe<Scalars['String']>,
  cliente_Municipio_In?: Maybe<Scalars['String']>,
  cliente_Municipio_Icontains?: Maybe<Scalars['String']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['ID']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  quantidadeEmprestimo?: Maybe<Scalars['Float']>,
  taxaMedia?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayContaapagarSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  destinatario?: Maybe<Scalars['String']>,
  destinatario_Icontains?: Maybe<Scalars['String']>,
  destinatario_Istartswith?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<Scalars['String']>,
  recorrencia_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gte?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lte?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gte?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lte?: Maybe<Scalars['DateTime']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaConta_Icontains?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EntidadeTypeRelayMetaArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  valorMeta?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  inicioMeta?: Maybe<Scalars['String']>,
  inicioMeta_Gte?: Maybe<Scalars['Date']>,
  inicioMeta_Gt?: Maybe<Scalars['Date']>,
  inicioMeta_Lt?: Maybe<Scalars['Date']>,
  inicioMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta?: Maybe<Scalars['String']>,
  fimMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta_Lt?: Maybe<Scalars['Date']>,
  fimMeta_Gte?: Maybe<Scalars['Date']>,
  fimMeta_Gt?: Maybe<Scalars['Date']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayMaquinaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  cnpj?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  etiquetagem?: Maybe<Scalars['String']>,
  etiquetagem_Icontains?: Maybe<Scalars['String']>,
  numeroTerminal?: Maybe<Scalars['String']>,
  numeroTerminal_Icontains?: Maybe<Scalars['String']>,
  serialNumber?: Maybe<Scalars['String']>,
  serialNumber_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayOcorrenciasMaquinasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayOcorrenciasPosterioresMaquinasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type EntidadeTypeRelayParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type EntidadeTypeRelayListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type EntidadeTypeRelayInstanciazapiSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['String']>,
  isActived?: Maybe<Scalars['Boolean']>,
  isConnected?: Maybe<Scalars['Boolean']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Float']>
};


export type EntidadeTypeRelayFornecedorSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EntidadeTypeRelayContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type EntidadeTypeRelayConnection = {
   __typename?: 'EntidadeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EntidadeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EntidadeTypeRelay` and its cursor. */
export type EntidadeTypeRelayEdge = {
   __typename?: 'EntidadeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<EntidadeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EnviarDocumentosClickSignDigitadoMobile = {
   __typename?: 'EnviarDocumentosClickSignDigitadoMobile',
  ok?: Maybe<Scalars['Boolean']>,
  documentKey?: Maybe<Scalars['String']>,
};

export type EnviarDocumentosClickSignMutation = {
   __typename?: 'EnviarDocumentosClickSignMutation',
  ok?: Maybe<Scalars['Boolean']>,
  documentKey?: Maybe<Scalars['String']>,
};

/** 
 * Esse método cria um pagamento com pré captura na Pagcerto e envia
 * Gera o contrato e envia para o cliente
 * Notifica o cliente quando da alguma falha no pagamento ou caso tenha sucesso
 */
export type EnviarDocumentosClickSignMutationMobile = {
   __typename?: 'EnviarDocumentosClickSignMutationMobile',
  ok?: Maybe<Scalars['Boolean']>,
  documentKey?: Maybe<Scalars['String']>,
};

export type EnviarEmailSuporteMobile = {
   __typename?: 'EnviarEmailSuporteMobile',
  ok?: Maybe<Scalars['Boolean']>,
};

export type EnviarSimulacaoEmail = {
   __typename?: 'EnviarSimulacaoEmail',
  ok?: Maybe<Scalars['Boolean']>,
};

export type EnvioEmailInput = {
  nome?: Maybe<Scalars['String']>,
  nomeTemplate?: Maybe<Scalars['String']>,
  descricaoTemplate?: Maybe<Scalars['String']>,
  emailRemetenteId?: Maybe<Scalars['ID']>,
};

/** An enumeration. */
export enum EnvioEmailStatus {
  /** AGENDADO */
  Agendado = 'AGENDADO',
  /** ENVIADO */
  Enviado = 'ENVIADO'
}

export type EnvioEmailType = {
   __typename?: 'EnvioEmailType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  nomeTemplate: Scalars['String'],
  descricaoTemplate: Scalars['String'],
  emailRemetente: EmailRemetenteType,
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  listas: ListaSmsTypeRelayConnection,
  messageId?: Maybe<Scalars['String']>,
  entregues: Scalars['Int'],
  totalContatos: Scalars['Int'],
  abertos: Scalars['Int'],
  cliques: Scalars['Int'],
  falhas: Scalars['Int'],
  dascartados: Scalars['Int'],
  spam: Scalars['Int'],
  enviados: Scalars['Int'],
  status?: Maybe<EnvioEmailStatus>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};


export type EnvioEmailTypeListasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type EnvioEmailTypeRelay = Node & {
   __typename?: 'EnvioEmailTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  nomeTemplate: Scalars['String'],
  descricaoTemplate: Scalars['String'],
  emailRemetente: EmailRemetenteType,
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  listas?: Maybe<Array<Maybe<ListaSmsType>>>,
  messageId?: Maybe<Scalars['String']>,
  entregues: Scalars['Int'],
  totalContatos: Scalars['Int'],
  abertos: Scalars['Int'],
  cliques: Scalars['Int'],
  falhas: Scalars['Int'],
  dascartados: Scalars['Int'],
  spam: Scalars['Int'],
  enviados: Scalars['Int'],
  status?: Maybe<EnvioEmailStatus>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  mensagens?: Maybe<Array<Maybe<MensagemSmsType>>>,
};

export type EnvioEmailTypeRelayConnection = {
   __typename?: 'EnvioEmailTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnvioEmailTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EnvioEmailTypeRelay` and its cursor. */
export type EnvioEmailTypeRelayEdge = {
   __typename?: 'EnvioEmailTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<EnvioEmailTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type EnvioSmsType = {
   __typename?: 'EnvioSmsType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  enviados?: Maybe<Scalars['Int']>,
  recebidos?: Maybe<Scalars['Int']>,
  falhas?: Maybe<Scalars['Int']>,
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  listas: ListaSmsTypeRelayConnection,
  mensagem: MensagemSmsTypeRelay,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tokenSms?: Maybe<Scalars['String']>,
  apiSmsFlag: Scalars['Boolean'],
  respostasmsSet: Array<RespostaSmsType>,
  tokensmsSet: Array<TokenSmsType>,
};


export type EnvioSmsTypeListasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type EnvioSmsTypeRelay = Node & {
   __typename?: 'EnvioSmsTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  enviados?: Maybe<Scalars['Int']>,
  recebidos?: Maybe<Scalars['Int']>,
  falhas?: Maybe<Scalars['Int']>,
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  listas?: Maybe<Array<Maybe<ListaSmsType>>>,
  mensagem: MensagemSmsTypeRelay,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tokenSms?: Maybe<Scalars['String']>,
  apiSmsFlag: Scalars['Boolean'],
  respostasmsSet: Array<RespostaSmsType>,
  tokensmsSet: Array<TokenSmsType>,
  mensagens?: Maybe<Array<Maybe<MensagemSmsType>>>,
};

export type EnvioSmsTypeRelayConnection = {
   __typename?: 'EnvioSmsTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnvioSmsTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `EnvioSmsTypeRelay` and its cursor. */
export type EnvioSmsTypeRelayEdge = {
   __typename?: 'EnvioSmsTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<EnvioSmsTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type ExcluirAnexoDeContaAPagarMutation = {
   __typename?: 'ExcluirAnexoDeContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  anexo?: Maybe<AnexoDeContaAPagarType>,
};

export type ExcluirAnexoDePedidoDeReembolsoMutation = {
   __typename?: 'ExcluirAnexoDePedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  anexo?: Maybe<AnexoDePedidoDeReembolsoType>,
};

export type ExcluirAnotacao = {
   __typename?: 'ExcluirAnotacao',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirCartaoCreditoMutation = {
   __typename?: 'ExcluirCartaoCreditoMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirCategoriaMutation = {
   __typename?: 'ExcluirCategoriaMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirClienteMobile = {
   __typename?: 'ExcluirClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirColunaTabelas = {
   __typename?: 'ExcluirColunaTabelas',
  coluna?: Maybe<ColunaTabelaType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirContaAPagarMutation = {
   __typename?: 'ExcluirContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  contaPagar?: Maybe<ContaAPagarNode>,
};

export type ExcluirContaClienteMobile = {
   __typename?: 'ExcluirContaClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirContaDeClienteMutation = {
   __typename?: 'ExcluirContaDeClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  conta?: Maybe<ContaDeClienteType>,
};

export type ExcluirContaMutation = {
   __typename?: 'ExcluirContaMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirEntidadeMutation = {
   __typename?: 'ExcluirEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirFuncionarioMutation = {
   __typename?: 'ExcluirFuncionarioMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirGrupoDePermissoesMutation = {
   __typename?: 'ExcluirGrupoDePermissoesMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirLista = {
   __typename?: 'ExcluirLista',
  ok?: Maybe<Scalars['Boolean']>,
  lista?: Maybe<ListaSmsType>,
};

export type ExcluirPedidoDeReembolsoMutation = {
   __typename?: 'ExcluirPedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  pedido?: Maybe<PedidoDeReembolsoNode>,
};

export type ExcluirRegramentoEntidade = {
   __typename?: 'ExcluirRegramentoEntidade',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirRegramentoTipoEntidade = {
   __typename?: 'ExcluirRegramentoTipoEntidade',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirSubCategoriaMutation = {
   __typename?: 'ExcluirSubCategoriaMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTabelaTaxas = {
   __typename?: 'ExcluirTabelaTaxas',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTarefa = {
   __typename?: 'ExcluirTarefa',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTarefasMult = {
   __typename?: 'ExcluirTarefasMult',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTaxasParcelas = {
   __typename?: 'ExcluirTaxasParcelas',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTelefoneDeClienteMutation = {
   __typename?: 'ExcluirTelefoneDeClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<TelefoneDeClienteType>,
};

export type ExcluirTelefoneDeEntidadeMutation = {
   __typename?: 'ExcluirTelefoneDeEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<TelefoneDeEntidadeType>,
};

export type ExcluirTipoEntidade = {
   __typename?: 'ExcluirTipoEntidade',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTipoFuncionarioMutation = {
   __typename?: 'ExcluirTipoFuncionarioMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirTodasTabelaTaxas = {
   __typename?: 'ExcluirTodasTabelaTaxas',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ExcluirVariasPermissoesMutation = {
   __typename?: 'ExcluirVariasPermissoesMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type FieldsHistoricoEmprestimoType = {
   __typename?: 'FieldsHistoricoEmprestimoType',
  atualizadoEm?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type FiltrosListaInput = {
  inputLeads?: Maybe<LeadRelatorioInput>,
  inputClient?: Maybe<ClienteRelatorioInput>,
  tipo?: Maybe<Scalars['String']>,
};

export type FiltrosListaObjectType = {
   __typename?: 'FiltrosListaObjectType',
  tipo?: Maybe<Scalars['String']>,
  entidadesIn?: Maybe<Array<Maybe<Scalars['ID']>>>,
  origem_In?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  ddd?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  sexo_In?: Maybe<Scalars['String']>,
  nascimento_Lte?: Maybe<Scalars['String']>,
  nascimento_Gte?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  criadoEm_Lte?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['String']>,
};

export type FinalizarRascunhoEmprestimoMutation = {
   __typename?: 'FinalizarRascunhoEmprestimoMutation',
  emprestimo?: Maybe<EmprestimoNode>,
};

/** An enumeration. */
export enum FormaPagamentoContaFormaPagamento {
  /** Boleto */
  Boleto = 'BOLETO',
  /** Transferência */
  Transferencia = 'TRANSFERENCIA'
}

/** An enumeration. */
export enum FormaPagamentoContaTransferenciaTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

export type FormaPagamentoContaType = {
   __typename?: 'FormaPagamentoContaType',
  id: Scalars['ID'],
  conta: ContaNode,
  formaPagamento: FormaPagamentoContaFormaPagamento,
  titular: Scalars['String'],
  valor?: Maybe<Scalars['Float']>,
  boletoDataVencimento?: Maybe<Scalars['DateTime']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  transferenciaNome?: Maybe<Scalars['String']>,
  transferenciaBanco?: Maybe<Scalars['String']>,
  transferenciaOutroBanco?: Maybe<Scalars['String']>,
  transferenciaAgencia?: Maybe<Scalars['String']>,
  transferenciaTipoConta?: Maybe<FormaPagamentoContaTransferenciaTipoConta>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaTipoPix: Scalars['String'],
  transferenciaPix?: Maybe<Scalars['String']>,
  transferenciaVariacao?: Maybe<Scalars['String']>,
  transferenciaCpfCnpj?: Maybe<Scalars['String']>,
  comprovantePagamento?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

/** An enumeration. */
export enum FormaPagamentoEmprestimoContaTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

export type FormaPagamentoEmprestimoType = {
   __typename?: 'FormaPagamentoEmprestimoType',
  id: Scalars['ID'],
  emprestimo?: Maybe<EmprestimoRelatorioType>,
  valor: Scalars['Float'],
  anexo?: Maybe<Scalars['String']>,
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  boletoDataVencimento?: Maybe<Scalars['Date']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoAnexo?: Maybe<Scalars['String']>,
  contaNome?: Maybe<Scalars['String']>,
  contaBanco?: Maybe<Scalars['String']>,
  contaAgencia?: Maybe<Scalars['String']>,
  contaTipoConta?: Maybe<FormaPagamentoEmprestimoContaTipoConta>,
  contaConta?: Maybe<Scalars['String']>,
  contaVariacao?: Maybe<Scalars['String']>,
  contaCpfCnpj?: Maybe<Scalars['String']>,
  pk: Scalars['Int'],
};

/** An enumeration. */
export enum FormaPagamentoFornecedorContaTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

/** An enumeration. */
export enum FormaPagamentoFornecedorFormaPagamento {
  /** Boleto */
  Boleto = 'BOLETO',
  /** Transferência */
  Transferencia = 'TRANSFERENCIA'
}

export type FormaPagamentoFornecedorType = {
   __typename?: 'FormaPagamentoFornecedorType',
  id: Scalars['ID'],
  fornecedor?: Maybe<FornecedorViewNodeType>,
  formaPagamento: FormaPagamentoFornecedorFormaPagamento,
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  boletoDataVencimento?: Maybe<Scalars['Date']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoAnexo?: Maybe<Scalars['String']>,
  contaNome?: Maybe<Scalars['String']>,
  contaBanco?: Maybe<Scalars['String']>,
  contaAgencia?: Maybe<Scalars['String']>,
  contaTipoConta?: Maybe<FormaPagamentoFornecedorContaTipoConta>,
  contaConta?: Maybe<Scalars['String']>,
  contaVariacao?: Maybe<Scalars['String']>,
  contaCpfCnpj?: Maybe<Scalars['String']>,
  default: Scalars['Boolean'],
};

export type FornecedorNodeType = Node & {
   __typename?: 'FornecedorNodeType',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  cpfCnpj: Scalars['String'],
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  entidade: EntidadeTypeRelayConnection,
  categorias: CategoriaTypeRelayConnection,
  subcategorias: SubCategoriasTypeRelayConnection,
  telefones?: Maybe<Array<Maybe<TelefoneDeFornecedorType>>>,
  formasPagamento?: Maybe<Array<Maybe<FormaPagamentoFornecedorType>>>,
  contaSet: ContaNodeConnection,
  vinculado?: Maybe<Scalars['Boolean']>,
  totalContas?: Maybe<Scalars['Int']>,
  totalValorPago?: Maybe<Scalars['Decimal']>,
};


export type FornecedorNodeTypeEntidadeArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FornecedorNodeTypeCategoriasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Iexact?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  subcategorias_Nome_Iexact?: Maybe<Scalars['String']>,
  subcategorias_Nome_Icontains?: Maybe<Scalars['String']>,
  excluido?: Maybe<Scalars['Boolean']>
};


export type FornecedorNodeTypeSubcategoriasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['Float']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  excluido?: Maybe<Scalars['Boolean']>,
  categoria_Id_In?: Maybe<Scalars['String']>
};


export type FornecedorNodeTypeContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type FornecedorNodeTypeConnection = {
   __typename?: 'FornecedorNodeTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FornecedorNodeTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `FornecedorNodeType` and its cursor. */
export type FornecedorNodeTypeEdge = {
   __typename?: 'FornecedorNodeTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<FornecedorNodeType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type FornecedorViewNodeType = Node & {
   __typename?: 'FornecedorViewNodeType',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  cpfCnpj: Scalars['String'],
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  entidade: EntidadeTypeRelayConnection,
  categorias: CategoriaTypeRelayConnection,
  subcategorias: SubCategoriasTypeRelayConnection,
  telefones: Array<TelefoneDeFornecedorType>,
  formasPagamento: Array<FormaPagamentoFornecedorType>,
  contaSet: ContaNodeConnection,
};


export type FornecedorViewNodeTypeEntidadeArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FornecedorViewNodeTypeCategoriasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Iexact?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  subcategorias_Nome_Iexact?: Maybe<Scalars['String']>,
  subcategorias_Nome_Icontains?: Maybe<Scalars['String']>,
  excluido?: Maybe<Scalars['Boolean']>
};


export type FornecedorViewNodeTypeSubcategoriasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['Float']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  excluido?: Maybe<Scalars['Boolean']>,
  categoria_Id_In?: Maybe<Scalars['String']>
};


export type FornecedorViewNodeTypeContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type FornecedorViewNodeTypeConnection = {
   __typename?: 'FornecedorViewNodeTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FornecedorViewNodeTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `FornecedorViewNodeType` and its cursor. */
export type FornecedorViewNodeTypeEdge = {
   __typename?: 'FornecedorViewNodeTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<FornecedorViewNodeType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum FuncionarioCategoria {
  /** Panfletista */
  Panfletista = 'PANFLETISTA',
  /** Vendedor */
  Vendedor = 'VENDEDOR',
  /** Sócio da Unidade */
  Socio = 'SOCIO',
  /** Diretor Executivo */
  DiretorExecutivo = 'DIRETOR_EXECUTIVO',
  /** Gerente Regional */
  GerenteRegional = 'GERENTE_REGIONAL',
  /** Gerente Comercial */
  GerenteComercial = 'GERENTE_COMERCIAL',
  /** Supervisor de Loja */
  SupervisorLoja = 'SUPERVISOR_LOJA',
  /** Supervisor Comercial */
  SupervisorComercial = 'SUPERVISOR_COMERCIAL',
  /** Supervisor Regional */
  SupervisorRegional = 'SUPERVISOR_REGIONAL'
}

export type FuncionarioDeGrupoType = {
   __typename?: 'FuncionarioDeGrupoType',
  id: Scalars['ID'],
  grupo: GrupoDePermissoesTypeRelay,
  funcionario: FuncionarioTypeRelay,
  tabelaParceiro?: Maybe<TabelasNodeTypeRelay>,
};

export type FuncionariosRepasseNodeTypeRelay = Node & {
   __typename?: 'FuncionariosRepasseNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  funcionario: FuncionarioTypeRelay,
  tabelaDeParceiro: TabelasNodeTypeRelay,
  tipoFuncionario: FuncionariosRepasseTipoFuncionario,
  funcionariosPermitidos: FuncionarioTypeRelayConnection,
  gruposPermitidos: GrupoDePermissoesTypeRelayConnection,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  entidadefuncionariorepasseSet: Array<EntidadeFuncionarioRepasseType>,
  grupoentidadefuncionariorepasseSet: Array<GrupoEntidadeFuncionarioRepasseType>,
};


export type FuncionariosRepasseNodeTypeRelayFuncionariosPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  categoria?: Maybe<Scalars['String']>,
  tipoFuncionario_Nome?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  user_IsActive?: Maybe<Scalars['Boolean']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionariosRepasseNodeTypeRelayGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};

export type FuncionariosRepasseNodeTypeRelayConnection = {
   __typename?: 'FuncionariosRepasseNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FuncionariosRepasseNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `FuncionariosRepasseNodeTypeRelay` and its cursor. */
export type FuncionariosRepasseNodeTypeRelayEdge = {
   __typename?: 'FuncionariosRepasseNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<FuncionariosRepasseNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum FuncionariosRepasseTipoFuncionario {
  /** Comissão individual */
  Individual = 'INDIVIDUAL',
  /** Comissão coletiva */
  Coletiva = 'COLETIVA'
}

export type FuncionariosRepasseType = {
   __typename?: 'FuncionariosRepasseType',
  id: Scalars['ID'],
  funcionario: FuncionarioTypeRelay,
  tabelaDeParceiro: TabelasNodeTypeRelay,
  tipoFuncionario: FuncionariosRepasseTipoFuncionario,
  funcionariosPermitidos: FuncionarioTypeRelayConnection,
  gruposPermitidos: GrupoDePermissoesTypeRelayConnection,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  entidadefuncionariorepasseSet: Array<EntidadeFuncionarioRepasseType>,
  grupoentidadefuncionariorepasseSet: Array<GrupoEntidadeFuncionarioRepasseType>,
};


export type FuncionariosRepasseTypeFuncionariosPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  categoria?: Maybe<Scalars['String']>,
  tipoFuncionario_Nome?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  user_IsActive?: Maybe<Scalars['Boolean']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionariosRepasseTypeGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};

/** An enumeration. */
export enum FuncionarioTipoContrato {
  /** CLT */
  Clt = 'CLT',
  /** Prestação de Serviço */
  Prestacao = 'PRESTACAO',
  /** Associado */
  Associado = 'ASSOCIADO',
  /** Sem Vínculo */
  SemVinculo = 'SEM_VINCULO'
}

export type FuncionarioType = {
   __typename?: 'FuncionarioType',
  id: Scalars['ID'],
  user: UserType,
  nome: Scalars['String'],
  cpf: Scalars['String'],
  rg: Scalars['String'],
  email: Scalars['String'],
  categoria?: Maybe<FuncionarioCategoria>,
  tipoFuncionario?: Maybe<TipoFuncionarioTypeRelay>,
  tipoContrato: FuncionarioTipoContrato,
  anexoContrato?: Maybe<Scalars['String']>,
  endereco: Scalars['String'],
  complemento: Scalars['String'],
  bairro: Scalars['String'],
  municipio: Scalars['String'],
  uf: Scalars['String'],
  cep: Scalars['String'],
  pais: Scalars['String'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  permissaoglobal?: Maybe<PermissaoGlobalType>,
  permissaoSet: Array<PermissaoType>,
  pedidodereembolsoSet: PedidoDeReembolsoNodeConnection,
  contaapagarSet: ContaAPagarNodeConnection,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  funcionariodegrupoSet: Array<FuncionarioDeGrupoType>,
  metaSet: MetaNodeTypeRelayConnection,
  excluidoPor: Array<CobrancaType>,
  criadoPor: Array<CobrancaType>,
  relatorios: Array<RelatorioNode>,
  ocorrenciacommaquinaSet: OcorrenciaComMaquinaNodeConnection,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  tarefasSet: TarefasNodeTypeRelayConnection,
  historicoSet: HistoricoNodeTypeRelayConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  parceirosRepassePermitidos: ParceirosNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  funcionariosrepasseSet: FuncionariosRepasseNodeTypeRelayConnection,
  funcionariosRepassePermitidos: FuncionariosRepasseNodeTypeRelayConnection,
  /** Esse parametro vai estar prenchido somento na COMISSAO_COLETIVA */
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
  contaSet: ContaNodeConnection,
  historicocontaSet: Array<HistoricoContaType>,
};


export type FuncionarioTypePedidodereembolsoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeContaapagarSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  destinatario?: Maybe<Scalars['String']>,
  destinatario_Icontains?: Maybe<Scalars['String']>,
  destinatario_Istartswith?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<Scalars['String']>,
  recorrencia_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gte?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lte?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gte?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lte?: Maybe<Scalars['DateTime']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaConta_Icontains?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type FuncionarioTypeMetaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  valorMeta?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  inicioMeta?: Maybe<Scalars['String']>,
  inicioMeta_Gte?: Maybe<Scalars['Date']>,
  inicioMeta_Gt?: Maybe<Scalars['Date']>,
  inicioMeta_Lt?: Maybe<Scalars['Date']>,
  inicioMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta?: Maybe<Scalars['String']>,
  fimMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta_Lt?: Maybe<Scalars['Date']>,
  fimMeta_Gte?: Maybe<Scalars['Date']>,
  fimMeta_Gt?: Maybe<Scalars['Date']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeOcorrenciacommaquinaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type FuncionarioTypeTarefasSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  assunto_Icontains?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lead_Nome?: Maybe<Scalars['String']>,
  lead_Nome_Icontains?: Maybe<Scalars['String']>,
  lead_Entidade_Id?: Maybe<Scalars['ID']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  dataHora?: Maybe<Scalars['DateTime']>,
  dataHora_Gte?: Maybe<Scalars['DateTime']>,
  dataHora_Gt?: Maybe<Scalars['DateTime']>,
  dataHora_Lt?: Maybe<Scalars['DateTime']>,
  dataHora_Lte?: Maybe<Scalars['DateTime']>,
  status?: Maybe<Scalars['String']>,
  leadId?: Maybe<Scalars['ID']>
};


export type FuncionarioTypeHistoricoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  leadId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type FuncionarioTypeParceirosRepassePermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type FuncionarioTypeFuncionariosrepasseSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type FuncionarioTypeFuncionariosRepassePermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type FuncionarioTypeComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type FuncionarioTypeRelay = Node & {
   __typename?: 'FuncionarioTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  user: UserType,
  nome: Scalars['String'],
  cpf: Scalars['String'],
  rg: Scalars['String'],
  email: Scalars['String'],
  categoria?: Maybe<FuncionarioCategoria>,
  tipoFuncionario?: Maybe<TipoFuncionarioTypeRelay>,
  tipoContrato: FuncionarioTipoContrato,
  anexoContrato?: Maybe<Scalars['String']>,
  endereco: Scalars['String'],
  complemento: Scalars['String'],
  bairro: Scalars['String'],
  municipio: Scalars['String'],
  uf: Scalars['String'],
  cep: Scalars['String'],
  pais: Scalars['String'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  permissaoglobal?: Maybe<PermissaoGlobalType>,
  permissaoSet: Array<PermissaoType>,
  pedidodereembolsoSet: PedidoDeReembolsoNodeConnection,
  contaapagarSet: ContaAPagarNodeConnection,
  emprestimoSet: EmprestimoRelatorioTypeConnection,
  funcionariodegrupoSet: Array<FuncionarioDeGrupoType>,
  metaSet: MetaNodeTypeRelayConnection,
  excluidoPor: Array<CobrancaType>,
  criadoPor: Array<CobrancaType>,
  relatorios: Array<RelatorioNode>,
  ocorrenciacommaquinaSet: OcorrenciaComMaquinaNodeConnection,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  tarefasSet: TarefasNodeTypeRelayConnection,
  historicoSet: HistoricoNodeTypeRelayConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  parceirosRepassePermitidos: ParceirosNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  funcionariosrepasseSet: FuncionariosRepasseNodeTypeRelayConnection,
  funcionariosRepassePermitidos: FuncionariosRepasseNodeTypeRelayConnection,
  /** Esse parametro vai estar prenchido somento na COMISSAO_COLETIVA */
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
  contaSet: ContaNodeConnection,
  historicocontaSet: Array<HistoricoContaType>,
  pk: Scalars['Int'],
};


export type FuncionarioTypeRelayPedidodereembolsoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayContaapagarSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  destinatario?: Maybe<Scalars['String']>,
  destinatario_Icontains?: Maybe<Scalars['String']>,
  destinatario_Istartswith?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<Scalars['String']>,
  recorrencia_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gte?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lte?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gte?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lte?: Maybe<Scalars['DateTime']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaConta_Icontains?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayEmprestimoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type FuncionarioTypeRelayMetaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  valorMeta?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  inicioMeta?: Maybe<Scalars['String']>,
  inicioMeta_Gte?: Maybe<Scalars['Date']>,
  inicioMeta_Gt?: Maybe<Scalars['Date']>,
  inicioMeta_Lt?: Maybe<Scalars['Date']>,
  inicioMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta?: Maybe<Scalars['String']>,
  fimMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta_Lt?: Maybe<Scalars['Date']>,
  fimMeta_Gte?: Maybe<Scalars['Date']>,
  fimMeta_Gt?: Maybe<Scalars['Date']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayOcorrenciacommaquinaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayTarefasSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  assunto_Icontains?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lead_Nome?: Maybe<Scalars['String']>,
  lead_Nome_Icontains?: Maybe<Scalars['String']>,
  lead_Entidade_Id?: Maybe<Scalars['ID']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  dataHora?: Maybe<Scalars['DateTime']>,
  dataHora_Gte?: Maybe<Scalars['DateTime']>,
  dataHora_Gt?: Maybe<Scalars['DateTime']>,
  dataHora_Lt?: Maybe<Scalars['DateTime']>,
  dataHora_Lte?: Maybe<Scalars['DateTime']>,
  status?: Maybe<Scalars['String']>,
  leadId?: Maybe<Scalars['ID']>
};


export type FuncionarioTypeRelayHistoricoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  leadId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type FuncionarioTypeRelayParceirosRepassePermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type FuncionarioTypeRelayFuncionariosrepasseSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type FuncionarioTypeRelayFuncionariosRepassePermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type FuncionarioTypeRelayComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type FuncionarioTypeRelayContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type FuncionarioTypeRelayConnection = {
   __typename?: 'FuncionarioTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FuncionarioTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `FuncionarioTypeRelay` and its cursor. */
export type FuncionarioTypeRelayEdge = {
   __typename?: 'FuncionarioTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<FuncionarioTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type GenerateNewUserPasswordMobile = {
   __typename?: 'GenerateNewUserPasswordMobile',
  lead?: Maybe<LeadsType>,
  sendEmail?: Maybe<Scalars['Boolean']>,
};


export type GerarNovaSenhaClienteMobile = {
   __typename?: 'GerarNovaSenhaClienteMobile',
  cliente?: Maybe<ClienteType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type GerarNovaSenhaFuncionarioMutation = {
   __typename?: 'GerarNovaSenhaFuncionarioMutation',
  funcionario?: Maybe<FuncionarioType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export enum GrapheneListaPermissionsEnum {
  VerParceiro = 'VER_PARCEIRO',
  DashboardParceiro = 'DASHBOARD_PARCEIRO',
  VerTabelaParceiro = 'VER_TABELA_PARCEIRO',
  HistoricoResgateParceiro = 'HISTORICO_RESGATE_PARCEIRO'
}

export type GrupoDePermissoesType = {
   __typename?: 'GrupoDePermissoesType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tabelaParceiro?: Maybe<TabelasNodeTypeRelay>,
  permissoes: Array<PermissaoDeGrupoType>,
  entidades: Array<EntidadeDeGrupoType>,
  funcionarios: Array<FuncionarioDeGrupoType>,
  parceirosGruposPermitidos: ParceirosNodeTypeRelayConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  funcionariosRepasseGruposPermitidos: FuncionariosRepasseNodeTypeRelayConnection,
  grupofuncionariosrepasseSet: Array<GrupoFuncionariosRepasseType>,
  grupoentidadefuncionariorepasseSet: Array<GrupoEntidadeFuncionarioRepasseType>,
  referenciaSet: Array<ReferenciaContaType>,
  totalPermissoes?: Maybe<Scalars['Int']>,
  totalEntidades?: Maybe<Scalars['Int']>,
  totalFuncionarios?: Maybe<Scalars['Int']>,
};


export type GrupoDePermissoesTypeParceirosGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type GrupoDePermissoesTypeParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type GrupoDePermissoesTypeFuncionariosRepasseGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type GrupoDePermissoesTypeRelay = Node & {
   __typename?: 'GrupoDePermissoesTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tabelaParceiro?: Maybe<TabelasNodeTypeRelay>,
  permissoes: Array<PermissaoDeGrupoType>,
  entidades: Array<EntidadeDeGrupoType>,
  funcionarios: Array<FuncionarioDeGrupoType>,
  parceirosGruposPermitidos: ParceirosNodeTypeRelayConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  funcionariosRepasseGruposPermitidos: FuncionariosRepasseNodeTypeRelayConnection,
  grupofuncionariosrepasseSet: Array<GrupoFuncionariosRepasseType>,
  grupoentidadefuncionariorepasseSet: Array<GrupoEntidadeFuncionarioRepasseType>,
  referenciaSet: Array<ReferenciaContaType>,
  pk: Scalars['Int'],
  totalPermissoes?: Maybe<Scalars['Int']>,
  totalEntidades?: Maybe<Scalars['Int']>,
  totalFuncionarios?: Maybe<Scalars['Int']>,
};


export type GrupoDePermissoesTypeRelayParceirosGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type GrupoDePermissoesTypeRelayParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type GrupoDePermissoesTypeRelayFuncionariosRepasseGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type GrupoDePermissoesTypeRelayConnection = {
   __typename?: 'GrupoDePermissoesTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GrupoDePermissoesTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `GrupoDePermissoesTypeRelay` and its cursor. */
export type GrupoDePermissoesTypeRelayEdge = {
   __typename?: 'GrupoDePermissoesTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<GrupoDePermissoesTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type GrupoEntidadeFuncionarioRepasseType = {
   __typename?: 'GrupoEntidadeFuncionarioRepasseType',
  id: Scalars['ID'],
  funcionario: FuncionariosRepasseNodeTypeRelay,
  grupo: GrupoDePermissoesTypeRelay,
};

export type GrupoFuncionariosRepasseType = {
   __typename?: 'GrupoFuncionariosRepasseType',
  id: Scalars['ID'],
  grupo?: Maybe<GrupoDePermissoesTypeRelay>,
  tabelaDeParceiro: TabelasNodeTypeRelay,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type GrupoPorDescricoesType = {
   __typename?: 'GrupoPorDescricoesType',
  grupo?: Maybe<Scalars['Int']>,
  permssaoDescricao?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type HabilitarMaquinaMutation = {
   __typename?: 'HabilitarMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
};

export type HistoricoContaType = {
   __typename?: 'HistoricoContaType',
  id: Scalars['ID'],
  conta: ContaNode,
  funcionario: FuncionarioTypeRelay,
  descricao?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type HistoricoEmprestimoClienteInput = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Cpf?: Maybe<Scalars['String']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  nsu_NsuCv?: Maybe<Scalars['String']>,
  nsu_NsuCv_Icontains?: Maybe<Scalars['String']>,
  nsu_NsuCv_In?: Maybe<Scalars['String']>,
  valorTotal?: Maybe<Scalars['Float']>,
  valorTotal_In?: Maybe<Scalars['Float']>,
  valorTotal_Gte?: Maybe<Scalars['Float']>,
  valorTotal_Lte?: Maybe<Scalars['Float']>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorDesejado_In?: Maybe<Scalars['Float']>,
  valorDesejado_Gte?: Maybe<Scalars['Float']>,
  valorDesejado_Lte?: Maybe<Scalars['Float']>,
  nsu_TitularDivergente_Cpf?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_In?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  bandeiraCartao_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  parcelas_Lt?: Maybe<Scalars['String']>,
  parcelas_Gt?: Maybe<Scalars['String']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  entidade_Id?: Maybe<Scalars['ID']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  origem_In?: Maybe<Scalars['String']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
};

export type HistoricoEmprestimoClienteType = Node & {
   __typename?: 'HistoricoEmprestimoClienteType',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
  funcionario: FuncionarioTypeRelay,
  valorDesejado: Scalars['Float'],
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada: Scalars['Float'],
  parcelas: EmprestimoParcelas,
  tabelaTaxa?: Maybe<Scalars['String']>,
  delivery: Scalars['Boolean'],
  maquina?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoContrato?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  taxaMinima?: Maybe<Scalars['Float']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus,
  causaStatus?: Maybe<Scalars['String']>,
  clickSignChaveDocumento?: Maybe<Scalars['String']>,
  solicitadoVia: EmprestimoSolicitadoVia,
  vendaDigitada: Scalars['Boolean'],
  vendaResgatada: Scalars['Boolean'],
  leadId?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  resgate?: Maybe<HistoricoResgateNodeTypeRelay>,
  nsus: NsuRelatorioTypeConnection,
  formasPagamento: Array<FormaPagamentoEmprestimoType>,
  anexos: Array<AnexoDeEmprestimoType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
  lucroBruto?: Maybe<Scalars['Decimal']>,
};


export type HistoricoEmprestimoClienteTypeNsusArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type HistoricoEmprestimoClienteTypeComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type HistoricoEmprestimoClienteTypeComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type HistoricoEmprestimoClienteTypeConnection = {
   __typename?: 'HistoricoEmprestimoClienteTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HistoricoEmprestimoClienteTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `HistoricoEmprestimoClienteType` and its cursor. */
export type HistoricoEmprestimoClienteTypeEdge = {
   __typename?: 'HistoricoEmprestimoClienteTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<HistoricoEmprestimoClienteType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type HistoricoEmprestimoType = {
   __typename?: 'HistoricoEmprestimoType',
  nome?: Maybe<Scalars['String']>,
  model?: Maybe<Scalars['String']>,
  pk?: Maybe<Scalars['Int']>,
  fields?: Maybe<FieldsHistoricoEmprestimoType>,
};

export type HistoricoNodeTypeRelay = Node & {
   __typename?: 'HistoricoNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  acao: Scalars['String'],
  data: Scalars['DateTime'],
  funcionario?: Maybe<FuncionarioTypeRelay>,
  lead: LeadsNodeTypeRelayProp,
};

export type HistoricoNodeTypeRelayConnection = {
   __typename?: 'HistoricoNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HistoricoNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `HistoricoNodeTypeRelay` and its cursor. */
export type HistoricoNodeTypeRelayEdge = {
   __typename?: 'HistoricoNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<HistoricoNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type HistoricoResgateExcelType = {
   __typename?: 'HistoricoResgateExcelType',
  relatorio?: Maybe<Scalars['String']>,
  background?: Maybe<Scalars['Boolean']>,
};

export type HistoricoResgateFuncionarioNodeTypeRelay = Node & {
   __typename?: 'HistoricoResgateFuncionarioNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  inicioPeriodo: Scalars['Date'],
  fimPeriodo: Scalars['Date'],
  valorResgate: Scalars['Float'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet: ComissaoFuncionarioNodeTypeRelayConnection,
  nomesEntidades?: Maybe<Scalars['String']>,
  nomesFuncionarios?: Maybe<Scalars['String']>,
};


export type HistoricoResgateFuncionarioNodeTypeRelayComissaofuncionariosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type HistoricoResgateFuncionarioNodeTypeRelayConnection = {
   __typename?: 'HistoricoResgateFuncionarioNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HistoricoResgateFuncionarioNodeTypeRelayEdge>>,
  totalResgate?: Maybe<Scalars['Float']>,
  edgeCount?: Maybe<Scalars['Int']>,
  totalCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `HistoricoResgateFuncionarioNodeTypeRelay` and its cursor. */
export type HistoricoResgateFuncionarioNodeTypeRelayEdge = {
   __typename?: 'HistoricoResgateFuncionarioNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<HistoricoResgateFuncionarioNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type HistoricoResgateNodeTypeRelay = Node & {
   __typename?: 'HistoricoResgateNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  valorResgate: Scalars['Float'],
  valorPago?: Maybe<Scalars['Float']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  parceiro: ParceirosNodeTypeRelay,
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  banco: Scalars['String'],
  agencia: Scalars['String'],
  conta: Scalars['String'],
  emprestimoSet?: Maybe<Array<Maybe<EmprestimoType>>>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
};


export type HistoricoResgateNodeTypeRelayComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};

export type HistoricoResgateNodeTypeRelayConnection = {
   __typename?: 'HistoricoResgateNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HistoricoResgateNodeTypeRelayEdge>>,
  totalResgate?: Maybe<Scalars['Float']>,
  edgeCount?: Maybe<Scalars['Int']>,
  totalCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `HistoricoResgateNodeTypeRelay` and its cursor. */
export type HistoricoResgateNodeTypeRelayEdge = {
   __typename?: 'HistoricoResgateNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<HistoricoResgateNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type IncluirAnexoDeContaAPagarMutation = {
   __typename?: 'IncluirAnexoDeContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  anexo?: Maybe<AnexoDeContaAPagarType>,
};

export type IncluirAnexoDePedidoDeReembolsoMutation = {
   __typename?: 'IncluirAnexoDePedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  anexo?: Maybe<AnexoDePedidoDeReembolsoType>,
};

export type IncluirContaDeClienteMutation = {
   __typename?: 'IncluirContaDeClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  conta?: Maybe<ContaDeClienteType>,
};

export type IncluirTelefoneDeClienteMutation = {
   __typename?: 'IncluirTelefoneDeClienteMutation',
  ok?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<TelefoneDeClienteType>,
};

export type IncluirTelefoneDeEntidadeMutation = {
   __typename?: 'IncluirTelefoneDeEntidadeMutation',
  ok?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<TelefoneDeEntidadeType>,
};

export type InputContaReferenciaType = {
  id?: Maybe<Scalars['ID']>,
  idUnidadeCusto?: Maybe<Scalars['ID']>,
  idsEntidades: Array<Maybe<Scalars['ID']>>,
  recorrencia?: Maybe<ReferenciaRecorrencia>,
  dataFinalRecorrencia?: Maybe<Scalars['Date']>,
  entidadesValor: Array<Maybe<EntidadesValor>>,
};

export type InputContaType = {
  id?: Maybe<Scalars['ID']>,
  idFornecedor?: Maybe<Scalars['ID']>,
  idCategoria?: Maybe<Scalars['ID']>,
  idSubcategoria?: Maybe<Scalars['ID']>,
  titulo: Scalars['String'],
  valorPago?: Maybe<Scalars['Decimal']>,
  dataVencimento: Scalars['Date'],
  dataPagamento?: Maybe<Scalars['Date']>,
  parcelas?: Maybe<Scalars['Int']>,
  tipoDespesa: ContaTipoDespesa,
  status?: Maybe<ContaStatus>,
  observacao?: Maybe<Scalars['String']>,
  anexos?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type InputCriarCartaoDeCredito = {
  nomeNoCartao: Scalars['String'],
  validade: Scalars['String'],
  numeroCartao: Scalars['String'],
  cvc: Scalars['String'],
  cartaoFrente: Scalars['String'],
  cartaoVerso: Scalars['String'],
  cartaoSelf?: Maybe<Scalars['String']>,
};

export type InputCriarCartaoDeCreditoTerceiros = {
  rg: Scalars['String'],
  cpf: Scalars['String'],
  endereco: Scalars['String'],
  complemento?: Maybe<Scalars['String']>,
  bairro: Scalars['String'],
  municipio: Scalars['String'],
  uf: Scalars['String'],
  cep: Scalars['String'],
  pais: Scalars['String'],
  anexoRgFrente: Scalars['String'],
  anexoRgVerso: Scalars['String'],
  anexoCpfFrente: Scalars['String'],
  anexoCpfVerso: Scalars['String'],
  anexoEndereco?: Maybe<Scalars['String']>,
};

export type InputEditarFornecedorType = {
  id: Scalars['ID'],
  nome: Scalars['String'],
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  idsEntidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsCategorias?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsSubcategorias?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type InputEmprestimoMobile = {
  valorTotal: Scalars['Float'],
  valorDesejado: Scalars['Float'],
  taxaAplicada: Scalars['Int'],
  parcelas: EmprestimoParcelas,
  data: Scalars['Date'],
};

export type InputFormaPagamentoContaType = {
  id?: Maybe<Scalars['ID']>,
  titular: Scalars['String'],
  valor: Scalars['Decimal'],
  formaPagamento: FormaPagamentoContaFormaPagamento,
  boletoDataVencimento?: Maybe<Scalars['DateTime']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  transferenciaNome?: Maybe<Scalars['String']>,
  transferenciaBanco?: Maybe<Scalars['String']>,
  transferenciaOutroBanco?: Maybe<Scalars['String']>,
  transferenciaAgencia?: Maybe<Scalars['String']>,
  transferenciaTipoConta?: Maybe<FormaPagamentoContaTransferenciaTipoConta>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaTipoPix?: Maybe<Scalars['String']>,
  transferenciaPix?: Maybe<Scalars['String']>,
  transferenciaVariacao?: Maybe<Scalars['String']>,
  transferenciaCpfCnpj: Scalars['String'],
  comprovantePagamento?: Maybe<Scalars['String']>,
};

export type InputFormaPagamentoFornecedorType = {
  id?: Maybe<Scalars['ID']>,
  tipoPix?: Maybe<Scalars['String']>,
  pix?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<FormaPagamentoFornecedorFormaPagamento>,
  boletoDataVencimento?: Maybe<Scalars['Date']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoAnexo?: Maybe<Scalars['String']>,
  contaNome?: Maybe<Scalars['String']>,
  contaBanco?: Maybe<Scalars['String']>,
  contaAgencia?: Maybe<Scalars['String']>,
  contaTipoConta?: Maybe<Scalars['String']>,
  contaConta?: Maybe<Scalars['String']>,
  contaVariacao?: Maybe<Scalars['String']>,
  contaCpfCnpj?: Maybe<Scalars['String']>,
  default?: Maybe<Scalars['Boolean']>,
};

export type InputFornecedorType = {
  nome: Scalars['String'],
  cpfCnpj: Scalars['String'],
  endereco?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  idsEntidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsCategorias?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsSubcategorias?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type InputTarefa = {
  leadId: Scalars['ID'],
  assunto: Scalars['String'],
  tipo: Scalars['String'],
  funcionarioId: Scalars['ID'],
  data: Scalars['Date'],
  hora: Scalars['Time'],
};

export type InputTelefoneFornecedorType = {
  id?: Maybe<Scalars['ID']>,
  ddd?: Maybe<Scalars['String']>,
  numero?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
};

export type InputTiposEntidadesType = {
  tipoLoja: Scalars['String'],
  valorAcimaTipo?: Maybe<Scalars['Float']>,
  default?: Maybe<Scalars['Boolean']>,
};

export type InstanciaZApiInput = {
  id?: Maybe<Scalars['Int']>,
  entidadeId?: Maybe<Scalars['ID']>,
  nome?: Maybe<Scalars['String']>,
  isActived?: Maybe<Scalars['Boolean']>,
  zApiId?: Maybe<Scalars['String']>,
  zApiToken?: Maybe<Scalars['String']>,
};

export type InstanciaZApiNode = Node & {
   __typename?: 'InstanciaZApiNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade: EntidadeTypeRelay,
  nome: Scalars['String'],
  isActived: Scalars['Boolean'],
  isConnected: Scalars['Boolean'],
  zApiId: Scalars['String'],
  zApiToken: Scalars['String'],
  zekiCash: Scalars['Int'],
  dataCriacao?: Maybe<Scalars['DateTime']>,
  mensagemzapiSet: Array<MensagemZApiType>,
  pk: Scalars['Int'],
};

export type InstanciaZApiNodeConnection = {
   __typename?: 'InstanciaZApiNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstanciaZApiNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `InstanciaZApiNode` and its cursor. */
export type InstanciaZApiNodeEdge = {
   __typename?: 'InstanciaZApiNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<InstanciaZApiNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type InstanciaZApiType = {
   __typename?: 'InstanciaZApiType',
  id: Scalars['ID'],
  entidade: EntidadeTypeRelay,
  nome: Scalars['String'],
  isActived: Scalars['Boolean'],
  isConnected: Scalars['Boolean'],
  zApiId: Scalars['String'],
  zApiToken: Scalars['String'],
  zekiCash: Scalars['Int'],
  dataCriacao?: Maybe<Scalars['DateTime']>,
  mensagemzapiSet: Array<MensagemZApiType>,
  pk: Scalars['Int'],
};


export type LeadMarketingAgregations = {
   __typename?: 'LeadMarketingAgregations',
  somaValorPassado?: Maybe<Scalars['Decimal']>,
  somaValorRetirado?: Maybe<Scalars['Decimal']>,
  taxaMediaTotal?: Maybe<Scalars['Decimal']>,
  mediaParcelas?: Maybe<Scalars['Float']>,
  ticketMedio?: Maybe<Scalars['Decimal']>,
};

export type LeadRelatorioInput = {
  nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  tarefas_Funcionario_Id?: Maybe<Scalars['ID']>,
  pausado?: Maybe<Scalars['Boolean']>,
  email_Isnull?: Maybe<Scalars['Boolean']>,
  telefone_Isnull?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  qualificacao?: Maybe<Scalars['Int']>,
  clienteId?: Maybe<Scalars['ID']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  statusLead_Index?: Maybe<Scalars['Int']>,
  statusLead_Nome?: Maybe<Scalars['String']>,
  tarefas_Tipo_Icontains?: Maybe<Scalars['String']>,
  tarefas_Status?: Maybe<Scalars['String']>,
  tarefas_Hora?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gte?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lte?: Maybe<Scalars['Time']>,
  tarefas_Data?: Maybe<Scalars['Date']>,
  tarefas_Data_Gte?: Maybe<Scalars['Date']>,
  tarefas_Data_Gt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lte?: Maybe<Scalars['Date']>,
  tarefas_DataHora?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gte?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  produtoInteresse_Icontains?: Maybe<Scalars['String']>,
  app?: Maybe<Scalars['Boolean']>,
  statusLeadId?: Maybe<Scalars['ID']>,
  tarefas_Id?: Maybe<Scalars['ID']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
};

export type LeadsNodeTypeRelay = Node & {
   __typename?: 'LeadsNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  pausado: Scalars['Boolean'],
  vendaRealizada: Scalars['Boolean'],
  cliente?: Maybe<EmprestimoClienteNode>,
  entidade?: Maybe<EntidadeTypeRelay>,
  responsavel?: Maybe<FuncionarioTypeRelay>,
  statusAnterior?: Maybe<Scalars['Int']>,
  qualificacao?: Maybe<Scalars['Int']>,
  justificativa?: Maybe<Scalars['String']>,
  origemCrm?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origemUrl?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  tipoBeneficio?: Maybe<Scalars['String']>,
  contaMaisTempo?: Maybe<Scalars['Boolean']>,
  carteiraAssinada?: Maybe<Scalars['Boolean']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  position?: Maybe<Scalars['Float']>,
  statusLead?: Maybe<StatusLeadType>,
  produtoInteresse?: Maybe<Scalars['String']>,
  emprestimoContratado?: Maybe<Scalars['Int']>,
  gclidField?: Maybe<Scalars['String']>,
  vagas: Array<VagaType>,
  utmSource?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  app: Scalars['Boolean'],
  tarefasSet?: Maybe<Array<Maybe<TarefasType>>>,
  propostasSet: Array<PropostasType>,
  anotacaoSet?: Maybe<Array<Maybe<AnotacaoType>>>,
  historicoSet: HistoricoNodeTypeRelayConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
  quantidadeEmprestimo?: Maybe<Scalars['Int']>,
  totalValorDesejado?: Maybe<Scalars['Decimal']>,
  totalValorEmprestimo?: Maybe<Scalars['Decimal']>,
  taxaMedia?: Maybe<Scalars['Decimal']>,
  lucroBruto?: Maybe<Scalars['Float']>,
};


export type LeadsNodeTypeRelayHistoricoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  leadId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type LeadsNodeTypeRelayListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type LeadsNodeTypeRelayConnection = {
   __typename?: 'LeadsNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeadsNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `LeadsNodeTypeRelay` and its cursor. */
export type LeadsNodeTypeRelayEdge = {
   __typename?: 'LeadsNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<LeadsNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type LeadsNodeTypeRelayProp = Node & {
   __typename?: 'LeadsNodeTypeRelayProp',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  pausado: Scalars['Boolean'],
  vendaRealizada: Scalars['Boolean'],
  cliente?: Maybe<EmprestimoClienteNode>,
  entidade?: Maybe<EntidadeTypeRelay>,
  responsavel?: Maybe<FuncionarioTypeRelay>,
  statusAnterior?: Maybe<Scalars['Int']>,
  qualificacao?: Maybe<Scalars['Int']>,
  justificativa?: Maybe<Scalars['String']>,
  origemCrm?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origemUrl?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  tipoBeneficio?: Maybe<Scalars['String']>,
  contaMaisTempo?: Maybe<Scalars['Boolean']>,
  carteiraAssinada?: Maybe<Scalars['Boolean']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  position?: Maybe<Scalars['Float']>,
  statusLead?: Maybe<StatusLeadType>,
  produtoInteresse?: Maybe<Scalars['String']>,
  emprestimoContratado?: Maybe<Scalars['Int']>,
  gclidField?: Maybe<Scalars['String']>,
  vagas: Array<VagaType>,
  utmSource?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  app: Scalars['Boolean'],
  tarefasSet: TarefasNodeTypeRelayConnection,
  propostasSet: Array<PropostasType>,
  anotacaoSet: Array<AnotacaoType>,
  historicoSet: HistoricoNodeTypeRelayConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
};


export type LeadsNodeTypeRelayPropTarefasSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  assunto_Icontains?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lead_Nome?: Maybe<Scalars['String']>,
  lead_Nome_Icontains?: Maybe<Scalars['String']>,
  lead_Entidade_Id?: Maybe<Scalars['ID']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  dataHora?: Maybe<Scalars['DateTime']>,
  dataHora_Gte?: Maybe<Scalars['DateTime']>,
  dataHora_Gt?: Maybe<Scalars['DateTime']>,
  dataHora_Lt?: Maybe<Scalars['DateTime']>,
  dataHora_Lte?: Maybe<Scalars['DateTime']>,
  status?: Maybe<Scalars['String']>,
  leadId?: Maybe<Scalars['ID']>
};


export type LeadsNodeTypeRelayPropHistoricoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  leadId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type LeadsNodeTypeRelayPropListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type LeadsNodeTypeRelayPropConnection = {
   __typename?: 'LeadsNodeTypeRelayPropConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeadsNodeTypeRelayPropEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `LeadsNodeTypeRelayProp` and its cursor. */
export type LeadsNodeTypeRelayPropEdge = {
   __typename?: 'LeadsNodeTypeRelayPropEdge',
  /** The item at the end of the edge */
  node?: Maybe<LeadsNodeTypeRelayProp>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type LeadsType = {
   __typename?: 'LeadsType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  pausado: Scalars['Boolean'],
  vendaRealizada: Scalars['Boolean'],
  cliente?: Maybe<EmprestimoClienteNode>,
  entidade?: Maybe<EntidadeTypeRelay>,
  responsavel?: Maybe<FuncionarioTypeRelay>,
  statusAnterior?: Maybe<Scalars['Int']>,
  qualificacao?: Maybe<Scalars['Int']>,
  justificativa?: Maybe<Scalars['String']>,
  origemCrm?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origemUrl?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  tipoBeneficio?: Maybe<Scalars['String']>,
  contaMaisTempo?: Maybe<Scalars['Boolean']>,
  carteiraAssinada?: Maybe<Scalars['Boolean']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  position?: Maybe<Scalars['Float']>,
  statusLead?: Maybe<StatusLeadType>,
  produtoInteresse?: Maybe<Scalars['String']>,
  emprestimoContratado?: Maybe<Scalars['Int']>,
  gclidField?: Maybe<Scalars['String']>,
  vagas: Array<VagaType>,
  utmSource?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  app: Scalars['Boolean'],
  tarefasSet?: Maybe<Array<Maybe<TarefasType>>>,
  propostasSet: Array<PropostasType>,
  anotacaoSet: Array<AnotacaoType>,
  historicoSet: HistoricoNodeTypeRelayConnection,
  listasmsSet: ListaSmsTypeRelayConnection,
};


export type LeadsTypeHistoricoSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  leadId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type LeadsTypeListasmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type LerArquivoLead = {
   __typename?: 'LerArquivoLead',
  ok?: Maybe<Scalars['Boolean']>,
};

export enum ListaEntidadeFuncionarioPermissionsEnum {
  VerLeads = 'VER_LEADS',
  VerTabelaParceiro = 'VER_TABELA_PARCEIRO',
  VerParceiro = 'VER_PARCEIRO'
}

export type ListaSmsType = {
   __typename?: 'ListaSmsType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  entidades: EntidadeTypeRelayConnection,
  clientes: EmprestimoClienteNodeConnection,
  leads: LeadsNodeTypeRelayPropConnection,
  totalContatos?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  filtros?: Maybe<FiltrosListaObjectType>,
  enviosmsSet: EnvioSmsTypeRelayConnection,
  envioemailSet: EnvioEmailTypeRelayConnection,
};


export type ListaSmsTypeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ListaSmsTypeClientesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  cliente_Id?: Maybe<Scalars['ID']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_In?: Maybe<Scalars['String']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  cliente_Sexo_In?: Maybe<Scalars['String']>,
  cliente_Municipio?: Maybe<Scalars['String']>,
  cliente_Municipio_In?: Maybe<Scalars['String']>,
  cliente_Municipio_Icontains?: Maybe<Scalars['String']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['ID']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  quantidadeEmprestimo?: Maybe<Scalars['Float']>,
  taxaMedia?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ListaSmsTypeLeadsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type ListaSmsTypeEnviosmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  apiSmsFlag?: Maybe<Scalars['Boolean']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type ListaSmsTypeEnvioemailSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type ListaSmsTypeRelay = Node & {
   __typename?: 'ListaSmsTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  entidades?: Maybe<Array<Maybe<EntidadeType>>>,
  clientes?: Maybe<Array<Maybe<ClienteType>>>,
  leads?: Maybe<Array<Maybe<LeadsType>>>,
  totalContatos?: Maybe<Scalars['Int']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  filtros?: Maybe<Scalars['JSONString']>,
  enviosmsSet?: Maybe<Array<Maybe<EnvioSmsType>>>,
  envioemailSet?: Maybe<Array<Maybe<EnvioEmailType>>>,
  nomesEntidades?: Maybe<Scalars['String']>,
};

export type ListaSmsTypeRelayConnection = {
   __typename?: 'ListaSmsTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ListaSmsTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ListaSmsTypeRelay` and its cursor. */
export type ListaSmsTypeRelayEdge = {
   __typename?: 'ListaSmsTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<ListaSmsTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type LoginMobile = {
   __typename?: 'LoginMobile',
  token?: Maybe<Scalars['String']>,
  lead?: Maybe<LeadsType>,
  user?: Maybe<UserType>,
};

export type LojaParceiroType = {
   __typename?: 'LojaParceiroType',
  id: Scalars['ID'],
  loja?: Maybe<EntidadeTypeRelay>,
  parceiro: ParceirosNodeTypeRelay,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tabela?: Maybe<TabelasNodeTypeRelay>,
};

export type LojasParceirosInput = {
  parceiroId?: Maybe<Scalars['ID']>,
  lojasParceiroIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type LojasTabelasTypeInput = {
  idTabela?: Maybe<Scalars['ID']>,
  idsLoja?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type MaquinaCreateInput = {
  etiquetagem: Scalars['String'],
  serialNumber: Scalars['String'],
  status?: Maybe<MaquinaStatus>,
  numeroTerminal?: Maybe<Scalars['String']>,
  razaoSocial?: Maybe<Scalars['String']>,
  nomeFantasia?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  numeroEstabelecimento?: Maybe<Scalars['String']>,
  adquirente?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  taxasParcelas?: Maybe<Array<Maybe<TaxasParcelasMaquinaInput>>>,
  idEntidade?: Maybe<Scalars['ID']>,
};

export type MaquinaNode = Node & {
   __typename?: 'MaquinaNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  etiquetagem: Scalars['String'],
  serialNumber: Scalars['String'],
  status: MaquinaStatus,
  entidade?: Maybe<EntidadeTypeRelay>,
  numeroTerminal?: Maybe<Scalars['String']>,
  razaoSocial?: Maybe<Scalars['String']>,
  nomeFantasia?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  numeroEstabelecimento?: Maybe<Scalars['String']>,
  adquirente?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  nsus: Array<NsuType>,
  ocorrencias: OcorrenciaComMaquinaNodeConnection,
  taxasParcelas: TaxasParcelasMaquinaNodeConnection,
  quantidadeEmprestimos?: Maybe<Scalars['Int']>,
};


export type MaquinaNodeOcorrenciasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  detalhamento?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type MaquinaNodeTaxasParcelasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type MaquinaNodeConnection = {
   __typename?: 'MaquinaNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MaquinaNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `MaquinaNode` and its cursor. */
export type MaquinaNodeEdge = {
   __typename?: 'MaquinaNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<MaquinaNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum MaquinaStatus {
  /** Em Uso */
  EmUso = 'EM_USO',
  /** Parada */
  Parada = 'PARADA',
  /** Em Estoque */
  EmEstoque = 'EM_ESTOQUE',
  /** Em Estoque com Problema */
  EmEstoqueProblema = 'EM_ESTOQUE_PROBLEMA',
  /** Em Estoque Descartada */
  EmEstoqueDescartada = 'EM_ESTOQUE_DESCARTADA',
  /** Bloqueada */
  Bloqueada = 'BLOQUEADA',
  /** Antecipação Bloqueada */
  AntecipacaoBloqueada = 'ANTECIPACAO_BLOQUEADA'
}

export type MaquinaUpdateInput = {
  id: Scalars['ID'],
  numeroTerminal?: Maybe<Scalars['String']>,
  etiquetagem?: Maybe<Scalars['String']>,
  razaoSocial?: Maybe<Scalars['String']>,
  nomeFantasia?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  numeroEstabelecimento?: Maybe<Scalars['String']>,
  adquirente?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  status?: Maybe<MaquinaStatus>,
  taxasParcelas?: Maybe<Array<Maybe<TaxasParcelasMaquinaInput>>>,
};

export type MensagemSmsType = {
   __typename?: 'MensagemSmsType',
  id: Scalars['ID'],
  titulo: Scalars['String'],
  descricao: Scalars['String'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  enviosmsSet: EnvioSmsTypeRelayConnection,
};


export type MensagemSmsTypeEnviosmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  apiSmsFlag?: Maybe<Scalars['Boolean']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type MensagemSmsTypeRelay = Node & {
   __typename?: 'MensagemSmsTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  titulo: Scalars['String'],
  descricao: Scalars['String'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  enviosmsSet: EnvioSmsTypeRelayConnection,
};


export type MensagemSmsTypeRelayEnviosmsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  apiSmsFlag?: Maybe<Scalars['Boolean']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type MensagemSmsTypeRelayConnection = {
   __typename?: 'MensagemSmsTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MensagemSmsTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `MensagemSmsTypeRelay` and its cursor. */
export type MensagemSmsTypeRelayEdge = {
   __typename?: 'MensagemSmsTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<MensagemSmsTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type MensagemType = {
  titulo?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
};

export type MensagemZApiType = {
   __typename?: 'MensagemZApiType',
  id: Scalars['ID'],
  status: StatusLeadType,
  instancia: InstanciaZApiNode,
  texto: Scalars['String'],
  pk: Scalars['Int'],
};

export type MetaDiariaTypeRelay = Node & {
   __typename?: 'MetaDiariaTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  meta: MetaNodeTypeRelay,
  dataDia: Scalars['Date'],
  valorPassado?: Maybe<Scalars['Float']>,
  valorAcumulada?: Maybe<Scalars['Float']>,
  taxaPassado?: Maybe<Scalars['Float']>,
  taxaAcumulada?: Maybe<Scalars['Float']>,
};

export type MetaDiariaTypeRelayConnection = {
   __typename?: 'MetaDiariaTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MetaDiariaTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `MetaDiariaTypeRelay` and its cursor. */
export type MetaDiariaTypeRelayEdge = {
   __typename?: 'MetaDiariaTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<MetaDiariaTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type MetaInput = {
  entidadeId: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  funcionarioId: Scalars['ID'],
  inicioMeta: Scalars['Date'],
  fimMeta: Scalars['Date'],
  valorMeta: Scalars['Float'],
  taxaMeta: Scalars['Float'],
};

export type MetaInputList = {
  entidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  nome?: Maybe<Scalars['String']>,
  funcionarioId: Scalars['ID'],
  inicioMeta: Scalars['Date'],
  fimMeta: Scalars['Date'],
  valorMeta: Scalars['Float'],
  taxaMeta: Scalars['Float'],
};

export type MetaNodeTypeRelay = Node & {
   __typename?: 'MetaNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  entidade: EntidadeTypeRelay,
  inicioMeta: Scalars['Date'],
  fimMeta: Scalars['Date'],
  valorMeta: Scalars['Float'],
  valorAlcancado?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  taxaAlcancado?: Maybe<Scalars['Float']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  funcionario: FuncionarioTypeRelay,
  metasdiariasSet: MetaDiariaTypeRelayConnection,
  totalValorRetirado?: Maybe<Scalars['Decimal']>,
  totalValorPassado?: Maybe<Scalars['Decimal']>,
  valorBruto?: Maybe<Scalars['Decimal']>,
  taxaAlcancada?: Maybe<Scalars['Decimal']>,
};


export type MetaNodeTypeRelayMetasdiariasSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  dataDia?: Maybe<Scalars['Date']>,
  valorPassado?: Maybe<Scalars['Float']>,
  valorAcumulada?: Maybe<Scalars['Float']>,
  taxaPassado?: Maybe<Scalars['Float']>,
  taxaAcumulada?: Maybe<Scalars['Float']>
};

export type MetaNodeTypeRelayConnection = {
   __typename?: 'MetaNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MetaNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `MetaNodeTypeRelay` and its cursor. */
export type MetaNodeTypeRelayEdge = {
   __typename?: 'MetaNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<MetaNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type MetasDiariasType = {
   __typename?: 'MetasDiariasType',
  id: Scalars['ID'],
  meta: MetaNodeTypeRelay,
  dataDia: Scalars['Date'],
  valorPassado?: Maybe<Scalars['Float']>,
  valorAcumulada?: Maybe<Scalars['Float']>,
  taxaPassado?: Maybe<Scalars['Float']>,
  taxaAcumulada?: Maybe<Scalars['Float']>,
};

export type MetaType = {
   __typename?: 'MetaType',
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  entidade: EntidadeTypeRelay,
  inicioMeta: Scalars['Date'],
  fimMeta: Scalars['Date'],
  valorMeta: Scalars['Float'],
  valorAlcancado?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  taxaAlcancado?: Maybe<Scalars['Float']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  funcionario: FuncionarioTypeRelay,
  metasdiariasSet: MetaDiariaTypeRelayConnection,
  totalValorRetirado?: Maybe<Scalars['Decimal']>,
  totalValorPassado?: Maybe<Scalars['Decimal']>,
  valorBruto?: Maybe<Scalars['Decimal']>,
  taxaAlcancada?: Maybe<Scalars['Decimal']>,
};


export type MetaTypeMetasdiariasSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  dataDia?: Maybe<Scalars['Date']>,
  valorPassado?: Maybe<Scalars['Float']>,
  valorAcumulada?: Maybe<Scalars['Float']>,
  taxaPassado?: Maybe<Scalars['Float']>,
  taxaAcumulada?: Maybe<Scalars['Float']>
};

export type MoveLead = {
   __typename?: 'MoveLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type MudarStatusContaAPagarMutation = {
   __typename?: 'MudarStatusContaAPagarMutation',
  contaPagar?: Maybe<ContaAPagarNode>,
};

export type MudarStatusEmprestimoMutation = {
   __typename?: 'MudarStatusEmprestimoMutation',
  emprestimo?: Maybe<EmprestimoNode>,
};

export type MudarStatusPedidoDeReembolsoMutation = {
   __typename?: 'MudarStatusPedidoDeReembolsoMutation',
  pedidoReembolso?: Maybe<PedidoDeReembolsoNode>,
};

export type Mutation = {
   __typename?: 'Mutation',
  criarInstancia?: Maybe<CriarInstanciaZApi>,
  atualizarInstancia?: Maybe<AtualizarInstanciaZApi>,
  deletarInstancia?: Maybe<DeletarInstanciaZApi>,
  criarAutomacao?: Maybe<CriarAutomacaoZApi>,
  criarConta?: Maybe<CriarContaMutation>,
  editarConta?: Maybe<EditarContaMutation>,
  excluirConta?: Maybe<ExcluirContaMutation>,
  criarFornecedor?: Maybe<CriarFornecedor>,
  editarFornecedor?: Maybe<EditarFornecedor>,
  vincularFornecedor?: Maybe<VincularFornecedorEntidade>,
  removerRelatorio?: Maybe<RemoverRelatorio>,
  criarParceiros?: Maybe<CriarParceiros>,
  editarParceiros?: Maybe<EditarParceiros>,
  criarTabela?: Maybe<CriarTabelas>,
  editarTabela?: Maybe<EditarTabelas>,
  excluirColuna?: Maybe<ExcluirColunaTabelas>,
  resgatarSaldo?: Maybe<ResgatarSaldo>,
  criarFuncionarioRepasse?: Maybe<CriarFuncionarioRepasse>,
  editarFuncionarioRepasse?: Maybe<EditarFuncionarioRepasse>,
  criarMaquina?: Maybe<CriarMaquinaMutation>,
  atualizarMaquina?: Maybe<AtualizarMaquinaMutation>,
  bloquearMaquina?: Maybe<BloquearMaquinaMutation>,
  desbloquearMaquina?: Maybe<DesbloquearMaquinaMutation>,
  desabilitarMaquina?: Maybe<DesabilitarMaquinaMutation>,
  habilitarMaquina?: Maybe<HabilitarMaquinaMutation>,
  reportarProblemaMaquina?: Maybe<ReportarProblemaMaquinaMutation>,
  deletarTaxasMaquina?: Maybe<DeletarTaxasMaquina>,
  criarEmprestimo?: Maybe<CriarEmprestimoMutation>,
  vinculaLeadEmprestimo?: Maybe<VinculaLeadEmprestimo>,
  atualizarEmprestimo?: Maybe<AtualizarEmprestimoMutation>,
  pagarEmprestimo?: Maybe<PagarEmprestimoMutation>,
  atualizarStatusEmprestimo?: Maybe<MudarStatusEmprestimoMutation>,
  adicionarPagamentoEmprestimo?: Maybe<AdicionarPagamentoEmprestimo>,
  removerPagamentoEmprestimo?: Maybe<RemoverPagamentoEmprestimo>,
  adicionarAnexosTaxaAbaixo?: Maybe<AdicionarAnexosTaxaAbaixoDeEmprestimo>,
  removerAnexosTaxaAbaixo?: Maybe<RemoverAnexosTaxaAbaixoDeEmprestimo>,
  completarEmprestimoDelivery?: Maybe<CompletarEmprestimoDeliveryMutation>,
  finalizarRascunho?: Maybe<FinalizarRascunhoEmprestimoMutation>,
  removerRascunhos?: Maybe<RemoverRascunhos>,
  removerNsusEmprestimo?: Maybe<RemoverEmprestimoNsus>,
  atualizarNsuEmprestimo?: Maybe<AtualizarEmprestimoNsu>,
  atualizarTitularDivergenteEmprestimo?: Maybe<AtualizarTitularDivergenteNsu>,
  criarMensagem?: Maybe<CriarMensagens>,
  criarListaSms?: Maybe<CriarLista>,
  editarListaSms?: Maybe<EdiarLista>,
  excluirListaSms?: Maybe<ExcluirLista>,
  criarEnviosSms?: Maybe<CriarEnvio>,
  dashboardEnviosSms?: Maybe<Dashboard_Envios>,
  criarEntidade?: Maybe<CriarEntidadeMutation>,
  atualizarEntidade?: Maybe<AtualizarEntidadeMutation>,
  excluirEntidade?: Maybe<ExcluirEntidadeMutation>,
  definirTaxasParcelas?: Maybe<DefinirTaxasParcelas>,
  definirTaxasTabela?: Maybe<DefinirTaxasTabela>,
  definirTabelaTaxasParcelas?: Maybe<DefinirTablelaTaxasParcelas>,
  excluirTabelaTaxas?: Maybe<ExcluirTabelaTaxas>,
  editarTabelaTaxasParcelas?: Maybe<EditarTablelaTaxasParcelas>,
  excluirTodasTabelaTaxas?: Maybe<ExcluirTodasTabelaTaxas>,
  incluirTelefoneEntidade?: Maybe<IncluirTelefoneDeEntidadeMutation>,
  atualizarTelefoneEntidade?: Maybe<AtualizarTelefoneDeEntidadeMutation>,
  excluirTelefoneEntidade?: Maybe<ExcluirTelefoneDeEntidadeMutation>,
  excluirTaxasParcelas?: Maybe<ExcluirTaxasParcelas>,
  criarFuncionario?: Maybe<CriarFuncionarioMutation>,
  atualizarFuncionario?: Maybe<AtualizarFuncionarioMutation>,
  excluirFuncionario?: Maybe<ExcluirFuncionarioMutation>,
  alterarSenhaFuncionario?: Maybe<AlterarSenhaFuncionarioMutation>,
  gerarNovaSenhaFuncionario?: Maybe<GerarNovaSenhaFuncionarioMutation>,
  bloquearFuncionario?: Maybe<BloquearFuncionario>,
  desbloquearFuncionario?: Maybe<DesbloquearFuncionario>,
  alterarEmailFuncionario?: Maybe<AlterarEmailFuncionario>,
  criarTipoFuncionario?: Maybe<CriarTipoFuncionarioMutation>,
  editarTipoFuncionario?: Maybe<EditarTipoFuncionarioMutation>,
  excluirTipoFuncionario?: Maybe<ExcluirTipoFuncionarioMutation>,
  darVariasPermissoes?: Maybe<DarVariasPermissoesMutation>,
  excluirVariasPermissoes?: Maybe<ExcluirVariasPermissoesMutation>,
  criarGrupoPermissoes?: Maybe<CriarGrupoDePermissoesMutation>,
  alterarGrupoPermissoes?: Maybe<AlterarGrupoDePermissoesMutation>,
  excluirGrupoPermissoes?: Maybe<ExcluirGrupoDePermissoesMutation>,
  darPermissoesGrupo?: Maybe<DarPermissoesGrupoMutation>,
  retirarPermissoesGrupo?: Maybe<RetirarPermissoesGrupoMutation>,
  associarEntidadesGrupo?: Maybe<AssociarEntidadesGrupoMutation>,
  desassociarEntidadesGrupo?: Maybe<DesassociarEntidadesGrupoMutation>,
  associarFuncionariosGrupo?: Maybe<AssociarFuncionariosGrupoMutation>,
  desassociarFuncionariosGrupo?: Maybe<DesassociarFuncionariosGrupoMutation>,
  criarPedidoReembolso?: Maybe<CriarPedidoDeReembolsoMutation>,
  atualizarPedidoReembolso?: Maybe<AtualizarPedidoDeReembolsoMutation>,
  excluirPedidoReembolso?: Maybe<ExcluirPedidoDeReembolsoMutation>,
  atualizarStatusReembolso?: Maybe<MudarStatusPedidoDeReembolsoMutation>,
  pagarPedidoReembolso?: Maybe<PagarPedidoDeReembolsoMutation>,
  incluirAnexoPedidoReembolso?: Maybe<IncluirAnexoDePedidoDeReembolsoMutation>,
  atualizarAnexoPedidoReembolso?: Maybe<AtualizarAnexoDePedidoDeReembolsoMutation>,
  excluirAnexoPedidoReembolso?: Maybe<ExcluirAnexoDePedidoDeReembolsoMutation>,
  enviarDocumentoClickSign?: Maybe<EnviarDocumentosClickSignMutation>,
  /** 
 * Esse método cria um pagamento com pré captura na Pagcerto e envia
   * Gera o contrato e envia para o cliente
   * Notifica o cliente quando da alguma falha no pagamento ou caso tenha sucesso
 */
  enviarDocumentoClickSignMobile?: Maybe<EnviarDocumentosClickSignMutationMobile>,
  enviarDocumentoClickSignMobileDigitadaMobile?: Maybe<EnviarDocumentosClickSignDigitadoMobile>,
  vincularDocumentoClickSign?: Maybe<VincularClickSignMutation>,
  vincularTokenOnesignalMutation?: Maybe<VincularTokenOnesignalMutation>,
  disvinculaTokenOnesignalMutation?: Maybe<DisvinculaTokenOnesignalMutation>,
  criarCliente?: Maybe<CriarClienteMutation>,
  atualizarCliente?: Maybe<AtualizarClienteMutation>,
  associarClienteEntidade?: Maybe<AssociarClienteEntidadeMutation>,
  desassociarClienteEntidade?: Maybe<DesassociarClienteEntidadeMutation>,
  incluirTelefoneCliente?: Maybe<IncluirTelefoneDeClienteMutation>,
  atualizarTelefoneCliente?: Maybe<AtualizarTelefoneDeClienteMutation>,
  excluirTelefoneCliente?: Maybe<ExcluirTelefoneDeClienteMutation>,
  incluirContaCliente?: Maybe<IncluirContaDeClienteMutation>,
  atualizarContaCliente?: Maybe<AtualizarContaDeClienteMutation>,
  excluirContaCliente?: Maybe<ExcluirContaDeClienteMutation>,
  criarClientRapido?: Maybe<CriarClienteRapidoMutation>,
  vinculaClienteAsaasMutation?: Maybe<VinculaClienteAsaasMutation>,
  buscarECriarClienteAsaas?: Maybe<BuscarECriarClienteAsaasMutation>,
  atualizarClienteRapido?: Maybe<AtualizarClienteRapidoMutation>,
  criarContaPagar?: Maybe<CriarContaAPagarMutation>,
  atualizarContaPagar?: Maybe<AtualizarContaAPagarMutation>,
  excluirContaPagar?: Maybe<ExcluirContaAPagarMutation>,
  pagarContaPagar?: Maybe<PagarContaAPagarMutation>,
  atualizarStatusContaPagar?: Maybe<MudarStatusContaAPagarMutation>,
  incluirAnexoContaPagar?: Maybe<IncluirAnexoDeContaAPagarMutation>,
  atualizarAnexoContaPagar?: Maybe<AtualizarAnexoDeContaAPagarMutation>,
  excluirAnexoContaPagar?: Maybe<ExcluirAnexoDeContaAPagarMutation>,
  criarMetaMutation?: Maybe<CriarMetaMutation>,
  updateMetaMutation?: Maybe<UpdateMetaMutation>,
  updateMetaDiariaMutation?: Maybe<UpdateMetaDiariaMutation>,
  deleteMetaMutation?: Maybe<DeleteMetaMutation>,
  todasMetasDash?: Maybe<TodasMetasDash>,
  editarLeadProspeccao?: Maybe<EditarLeadProp>,
  createLeadProspeccao?: Maybe<CreateLeadProp>,
  createClienteFromLead?: Maybe<CreateClienteFromLead>,
  criarCategoria?: Maybe<CriarCategoriaMutation>,
  editarCategoria?: Maybe<EditarCategoriaMutation>,
  excluirCategoria?: Maybe<ExcluirCategoriaMutation>,
  criarSubcategoria?: Maybe<CriarSubCategoriaMutation>,
  editarSubcategoria?: Maybe<EditarSubCategoriaMutation>,
  excluirSubcategoria?: Maybe<ExcluirSubCategoriaMutation>,
  criarOrigem?: Maybe<CreateSourceMutation>,
  editarOrigem?: Maybe<EditSourceMutation>,
  deletarOrigem?: Maybe<DeleteSourceMutation>,
  criarRegramentoEntidade?: Maybe<CriarRegramentoEntidade>,
  criarRegramentoTipoEntidade?: Maybe<CriarRegramentoTipoEntidade>,
  editarRegramentoEntidade?: Maybe<EditarRegramentoEntidade>,
  editarRegramentoTipoEntidade?: Maybe<EditarRegramentoTipoEntidade>,
  excluirRegramentoEntidade?: Maybe<ExcluirRegramentoEntidade>,
  excluirRegramentoTipoEntidade?: Maybe<ExcluirRegramentoTipoEntidade>,
  criarTipoEntidade?: Maybe<CriarTipoEntidade>,
  editarTipoEntidade?: Maybe<EditarTipoEntidade>,
  excluirTipoEntidade?: Maybe<ExcluirTipoEntidade>,
  createTarefa?: Maybe<CreateTarefa>,
  editarTarefa?: Maybe<EditarTarefa>,
  excluirTarefa?: Maybe<ExcluirTarefa>,
  editarLead?: Maybe<EditarLead>,
  simulaEmprestimoLead?: Maybe<SimulaEmprestimoLead>,
  atualizaStatusNegociacao?: Maybe<PausarLead>,
  marcarPerdaLead?: Maybe<PerdaLead>,
  marcarVendaLead?: Maybe<VendaLead>,
  moveLead?: Maybe<MoveLead>,
  qualificaLead?: Maybe<QualificaLead>,
  retomarOportunidadeLead?: Maybe<RetomarOportunidadeLead>,
  createLeadCrm?: Maybe<CreateLeadCrm>,
  createAnotacao?: Maybe<CreateAnotacao>,
  editarAnotacao?: Maybe<EditarAnotacao>,
  excluirAnotacao?: Maybe<ExcluirAnotacao>,
  produtoInteresse?: Maybe<ProdutoDeInteresse>,
  responsavelLead?: Maybe<ResponsavelLead>,
  excluirTarefasMultiplas?: Maybe<ExcluirTarefasMult>,
  editarTarefasMultiplas?: Maybe<EditarTarefasMult>,
  lerArquivoLead?: Maybe<LerArquivoLead>,
  enviarSimulacaoEmail?: Maybe<EnviarSimulacaoEmail>,
  excluirCobranca?: Maybe<DeleteCobranca>,
  criarCobranca?: Maybe<CreateCobranca>,
  atualizarCobranca?: Maybe<UpdateCobranca>,
  checkCobrancaDuplicada?: Maybe<CheckDuplicateCobranca>,
  replicaCobrancaExistenteAsaas?: Maybe<ReplicaCobrancaExistenteAsaas>,
  loginMobile?: Maybe<LoginMobile>,
  createClienteMobile?: Maybe<CreateClienteMobile>,
  createCustomerMobileSimplify?: Maybe<CreateCustomerMobileSimplify>,
  createLoanSimulationMobile?: Maybe<CreateLoanSimulationMobile>,
  createPreClienteMobile?: Maybe<CreatePreClienteMobile>,
  createEmprestimoMobile?: Maybe<CreateEmprestimoMobile>,
  createCartaoCredito?: Maybe<CreateCartaoCreditoMobile>,
  createContaClienteMobile?: Maybe<CreateContaClienteMobilne>,
  createCartaoCreditoTerceiros?: Maybe<CreateCartaoCreditoTerceirosMobile>,
  updateClienteMobile?: Maybe<UpdateClienteMobile>,
  editUserProfileMobile?: Maybe<EditUserProfileMobile>,
  updateEnderecoClienteMobile?: Maybe<UpdateEnderecoClienteMobile>,
  updateContaClienteMobile?: Maybe<UpdateContaClienteMobile>,
  excluirClienteMobile?: Maybe<ExcluirClienteMobile>,
  excluirCartaoCredito?: Maybe<ExcluirCartaoCreditoMutation>,
  excluirContaClienteMobile?: Maybe<ExcluirContaClienteMobile>,
  enviarMemsagemSuporteMobile?: Maybe<EnviarEmailSuporteMobile>,
  gerarNovaSenhaClienteMobile?: Maybe<GerarNovaSenhaClienteMobile>,
  alterarSenhaClienteMobile?: Maybe<AlterarSenhaCLienteMutation>,
  cancelaEmprestimoMobile?: Maybe<CancelaEmprestimoMobile>,
  uploadFoto64?: Maybe<Upload_Foto_64>,
  generateNewUserPasswordMobile?: Maybe<GenerateNewUserPasswordMobile>,
  obterToken?: Maybe<CustomJsonWebToken>,
  verificarToken?: Maybe<Verify>,
  atualizarToken?: Maybe<Refresh>,
};


export type MutationCriarInstanciaArgs = {
  instanciaInput?: Maybe<InstanciaZApiInput>
};


export type MutationAtualizarInstanciaArgs = {
  instanciaInput?: Maybe<InstanciaZApiInput>
};


export type MutationDeletarInstanciaArgs = {
  instanciaId?: Maybe<Scalars['ID']>
};


export type MutationCriarAutomacaoArgs = {
  instanciaId?: Maybe<Scalars['ID']>,
  triggersInput?: Maybe<Array<Maybe<TriggerInputZApiInput>>>
};


export type MutationCriarContaArgs = {
  contaInput?: Maybe<InputContaType>,
  formaPagamentoInput?: Maybe<Array<Maybe<InputFormaPagamentoContaType>>>,
  referenciaInput?: Maybe<InputContaReferenciaType>
};


export type MutationEditarContaArgs = {
  contaInput?: Maybe<InputContaType>,
  editarFuturas?: Maybe<Scalars['Boolean']>,
  editarUnidadeCusto?: Maybe<Scalars['Boolean']>,
  formaPagamentoInput?: Maybe<Array<Maybe<InputFormaPagamentoContaType>>>,
  referenciaInput?: Maybe<InputContaReferenciaType>
};


export type MutationExcluirContaArgs = {
  excluirFuturas?: Maybe<Scalars['Boolean']>,
  excluirUnidadeCusto?: Maybe<Scalars['Boolean']>,
  idConta: Scalars['ID']
};


export type MutationCriarFornecedorArgs = {
  formasPagamento?: Maybe<Array<Maybe<InputFormaPagamentoFornecedorType>>>,
  input?: Maybe<InputFornecedorType>,
  telefones?: Maybe<Array<Maybe<InputTelefoneFornecedorType>>>
};


export type MutationEditarFornecedorArgs = {
  formasPagamento?: Maybe<Array<Maybe<InputFormaPagamentoFornecedorType>>>,
  input?: Maybe<InputEditarFornecedorType>,
  telefones?: Maybe<Array<Maybe<InputTelefoneFornecedorType>>>
};


export type MutationVincularFornecedorArgs = {
  idFornecedor: Scalars['ID'],
  idsEntidades?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationRemoverRelatorioArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationCriarParceirosArgs = {
  contaInput?: Maybe<Array<Maybe<ContaParceirosTypeInput>>>,
  gruposDePermissoesInput?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsFuncionariosPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsGruposPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsLojas?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasTabelasInput?: Maybe<Array<Maybe<LojasTabelasTypeInput>>>,
  parceirosInput?: Maybe<ParceirosTypeInput>,
  resgateAutomaticoInput?: Maybe<ResgateAutomaticoParceirosTypeInput>
};


export type MutationEditarParceirosArgs = {
  contaInput?: Maybe<Array<Maybe<ContaParceirosTypeInput>>>,
  gruposDePermissoesInput?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsFuncionariosPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsGruposPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsLojas?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasTabelasInput?: Maybe<Array<Maybe<LojasTabelasTypeInput>>>,
  parceirosInput?: Maybe<ParceirosTypeInput>,
  resgateAutomaticoInput?: Maybe<ResgateAutomaticoParceirosTypeInput>
};


export type MutationCriarTabelaArgs = {
  bandeiraTabelaIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  categoria?: Maybe<Scalars['String']>,
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsFuncionariosPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsGruposPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasParceiro?: Maybe<Array<Maybe<LojasParceirosInput>>>,
  nomeTabela?: Maybe<Scalars['String']>,
  tabelaInput?: Maybe<Array<Maybe<ColunaTabelaTypeInput>>>,
  tipoTabela?: Maybe<Scalars['String']>
};


export type MutationEditarTabelaArgs = {
  bandeiraTabelaIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  categoria?: Maybe<Scalars['String']>,
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsFuncionariosPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsGruposPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasParceiro?: Maybe<Array<Maybe<LojasParceirosInput>>>,
  nomeTabela?: Maybe<Scalars['String']>,
  tabelaId?: Maybe<Scalars['ID']>,
  tabelaInput?: Maybe<Array<Maybe<ColunaTabelaTypeInput>>>,
  tipoTabela?: Maybe<Scalars['String']>
};


export type MutationExcluirColunaArgs = {
  colunaId?: Maybe<Scalars['ID']>
};


export type MutationResgatarSaldoArgs = {
  contaId?: Maybe<Scalars['ID']>,
  dataFim?: Maybe<Scalars['Date']>,
  dataInicio?: Maybe<Scalars['Date']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  lojasId?: Maybe<Array<Maybe<Scalars['ID']>>>,
  parceiroId?: Maybe<Scalars['ID']>
};


export type MutationCriarFuncionarioRepasseArgs = {
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idFuncionario?: Maybe<Scalars['ID']>,
  idsFuncionariosPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsGruposPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  tabelaDeParceiro?: Maybe<Scalars['ID']>
};


export type MutationEditarFuncionarioRepasseArgs = {
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idFuncionario?: Maybe<Scalars['ID']>,
  idsFuncionariosPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idsGruposPermitidos?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  tabelaDeParceiro?: Maybe<Scalars['ID']>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationCriarMaquinaArgs = {
  input: MaquinaCreateInput
};


export type MutationAtualizarMaquinaArgs = {
  input: MaquinaUpdateInput
};


export type MutationBloquearMaquinaArgs = {
  detalhamento?: Maybe<Scalars['String']>,
  id: Scalars['ID']
};


export type MutationDesbloquearMaquinaArgs = {
  id: Scalars['ID']
};


export type MutationDesabilitarMaquinaArgs = {
  id: Scalars['ID']
};


export type MutationHabilitarMaquinaArgs = {
  id: Scalars['ID'],
  idEntidade: Scalars['ID']
};


export type MutationReportarProblemaMaquinaArgs = {
  detalhamento: Scalars['String'],
  id: Scalars['ID']
};


export type MutationDeletarTaxasMaquinaArgs = {
  maquinaId: Scalars['ID'],
  taxasIds: Array<Maybe<Scalars['ID']>>
};


export type MutationCriarEmprestimoArgs = {
  anexoContrato?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  anexosTaxaAbaixo?: Maybe<Array<Maybe<EmprestimoAnexoInput>>>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  data: Scalars['Date'],
  delivery?: Maybe<Scalars['Boolean']>,
  formasPagamento?: Maybe<Array<Maybe<EmprestimoFormaPagamentoInput>>>,
  idCliente: Scalars['ID'],
  idEntidade: Scalars['ID'],
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  nsus?: Maybe<Array<Maybe<NsuInput>>>,
  origem?: Maybe<Scalars['String']>,
  parcelas?: Maybe<EmprestimoParcelas>,
  tabelaTaxa?: Maybe<Scalars['String']>,
  taxaAplicada: Scalars['Float'],
  valorDesejado: Scalars['Float']
};


export type MutationVinculaLeadEmprestimoArgs = {
  idEmprestimo?: Maybe<Scalars['ID']>,
  idLead?: Maybe<Scalars['ID']>
};


export type MutationAtualizarEmprestimoArgs = {
  anexoContrato?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  cvvCartao?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  entidadeId?: Maybe<Scalars['ID']>,
  formasPagamento?: Maybe<Array<Maybe<EmprestimoFormaPagamentoEditInput>>>,
  id: Scalars['ID'],
  justificativaCancelamento?: Maybe<Scalars['String']>,
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  nomeCartao?: Maybe<Scalars['String']>,
  nsuCartao?: Maybe<Scalars['String']>,
  nsus?: Maybe<Array<Maybe<NsuInput>>>,
  numeroCartao?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  parcelas?: Maybe<EmprestimoParcelas>,
  tabelaTaxa?: Maybe<Scalars['String']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  validadeCartao?: Maybe<Scalars['String']>,
  valorDesejado?: Maybe<Scalars['Float']>
};


export type MutationPagarEmprestimoArgs = {
  anexosPagamento?: Maybe<Array<Maybe<EmprestimoFormaPagamentoPagarInput>>>,
  id: Scalars['ID']
};


export type MutationAtualizarStatusEmprestimoArgs = {
  idEmprestimo: Scalars['ID'],
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status: EmprestimoStatus
};


export type MutationAdicionarPagamentoEmprestimoArgs = {
  formasPagamento: Array<Maybe<EmprestimoFormaPagamentoInput>>,
  id: Scalars['ID']
};


export type MutationRemoverPagamentoEmprestimoArgs = {
  id?: Maybe<Scalars['ID']>,
  idsPagamento: Array<Maybe<Scalars['ID']>>
};


export type MutationAdicionarAnexosTaxaAbaixoArgs = {
  anexosTaxaAbaixo?: Maybe<Array<Maybe<EmprestimoAnexoInput>>>,
  idEmprestimo: Scalars['ID']
};


export type MutationRemoverAnexosTaxaAbaixoArgs = {
  idEmprestimo: Scalars['ID'],
  idsAnexosTaxaAbaixo?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationCompletarEmprestimoDeliveryArgs = {
  anexoContrato?: Maybe<Scalars['String']>,
  id: Scalars['ID']
};


export type MutationFinalizarRascunhoArgs = {
  anexoContrato?: Maybe<Scalars['String']>,
  anexosTaxaAbaixo?: Maybe<Array<Maybe<EmprestimoAnexoInput>>>,
  delivery?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  formasPagamento?: Maybe<Array<Maybe<EmprestimoFormaPagamentoInput>>>,
  id: Scalars['ID'],
  justificativaTaxaAbaixo?: Maybe<Scalars['String']>,
  nsus?: Maybe<Array<Maybe<NsuInput>>>,
  origem?: Maybe<Scalars['String']>,
  parcelas?: Maybe<EmprestimoParcelas>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  valorDesejado?: Maybe<Scalars['Float']>
};


export type MutationRemoverRascunhosArgs = {
  ids: Array<Maybe<Scalars['ID']>>
};


export type MutationRemoverNsusEmprestimoArgs = {
  idEmprestimo: Scalars['ID'],
  idsNsus?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationAtualizarNsuEmprestimoArgs = {
  idEmprestimo: Scalars['ID'],
  idNsu: Scalars['ID'],
  nsu: NsuInput
};


export type MutationAtualizarTitularDivergenteEmprestimoArgs = {
  idEmprestimo: Scalars['ID'],
  idTitular: Scalars['ID'],
  titularDivergente: TitularDivergenteInput
};


export type MutationCriarMensagemArgs = {
  descricao?: Maybe<Scalars['String']>,
  titulo?: Maybe<Scalars['String']>
};


export type MutationCriarListaSmsArgs = {
  filtros?: Maybe<FiltrosListaInput>,
  nome?: Maybe<Scalars['String']>
};


export type MutationEditarListaSmsArgs = {
  filtros?: Maybe<FiltrosListaInput>,
  id?: Maybe<Scalars['ID']>,
  nome?: Maybe<Scalars['String']>
};


export type MutationExcluirListaSmsArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationCriarEnviosSmsArgs = {
  data: Scalars['Date'],
  envioEmailInput?: Maybe<EnvioEmailInput>,
  hora: Scalars['Time'],
  listas?: Maybe<Array<Maybe<Scalars['ID']>>>,
  mensagem?: Maybe<MensagemType>,
  mensagemId?: Maybe<Scalars['ID']>,
  nome?: Maybe<Scalars['String']>,
  tipoEnvio?: Maybe<TiposEnvioOpcoes>
};


export type MutationDashboardEnviosSmsArgs = {
  dataFim?: Maybe<Scalars['Date']>,
  dataInicio?: Maybe<Scalars['Date']>
};


export type MutationCriarEntidadeArgs = {
  anexoCnpj?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  comprovanteEndereco?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['String']>,
  mensagemInvoice?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  nome: Scalars['String'],
  nomeIdentificacao: Scalars['String'],
  pais?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Array<Maybe<TaxasParcelasInput>>>,
  razao?: Maybe<Scalars['String']>,
  taxaLucro?: Maybe<Scalars['Float']>,
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
  tipoLoja?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>
};


export type MutationAtualizarEntidadeArgs = {
  anexoCnpj?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  comprovanteEndereco?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  inscricaoEstadual?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['String']>,
  mensagemInvoice?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  taxaLucro?: Maybe<Scalars['Float']>,
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
  tipoLoja?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>
};


export type MutationExcluirEntidadeArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationDefinirTaxasParcelasArgs = {
  entidadesIds: Array<Maybe<Scalars['ID']>>,
  parcelas?: Maybe<Array<Maybe<TaxasParcelasInput>>>
};


export type MutationDefinirTaxasTabelaArgs = {
  entidadesIds: Array<Maybe<Scalars['ID']>>,
  parcelasList?: Maybe<Array<Maybe<TaxasTabelasInput>>>
};


export type MutationDefinirTabelaTaxasParcelasArgs = {
  entidadesIds: Array<Maybe<Scalars['ID']>>,
  tabelas?: Maybe<Array<Maybe<TabelaTaxasInput>>>
};


export type MutationExcluirTabelaTaxasArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationEditarTabelaTaxasParcelasArgs = {
  entidadesIds: Array<Maybe<Scalars['ID']>>,
  tabelas?: Maybe<Array<Maybe<TabelaTaxasInput>>>
};


export type MutationExcluirTodasTabelaTaxasArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationIncluirTelefoneEntidadeArgs = {
  ddd?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  idEntidade?: Maybe<Scalars['ID']>,
  numero: Scalars['String']
};


export type MutationAtualizarTelefoneEntidadeArgs = {
  ddd?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  numero?: Maybe<Scalars['String']>
};


export type MutationExcluirTelefoneEntidadeArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationExcluirTaxasParcelasArgs = {
  idEntidade?: Maybe<Scalars['ID']>
};


export type MutationCriarFuncionarioArgs = {
  anexoContrato?: Maybe<Scalars['String']>,
  bairro: Scalars['String'],
  categoria?: Maybe<Scalars['String']>,
  cep: Scalars['String'],
  complemento: Scalars['String'],
  cpf: Scalars['String'],
  email: Scalars['String'],
  endereco: Scalars['String'],
  idEntidade: Scalars['ID'],
  municipio: Scalars['String'],
  nome: Scalars['String'],
  pais: Scalars['String'],
  rg: Scalars['String'],
  senha: Scalars['String'],
  tipoContrato: Scalars['String'],
  tipoFuncionario: Scalars['String'],
  uf: Scalars['String']
};


export type MutationAtualizarFuncionarioArgs = {
  anexoContrato?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  municipio?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  senha?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  tipoFuncionario: Scalars['String'],
  uf?: Maybe<Scalars['String']>
};


export type MutationExcluirFuncionarioArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationAlterarSenhaFuncionarioArgs = {
  idFuncionario?: Maybe<Scalars['ID']>,
  senhaAtual: Scalars['String'],
  senhaNova: Scalars['String']
};


export type MutationGerarNovaSenhaFuncionarioArgs = {
  emailFuncionario: Scalars['String']
};


export type MutationBloquearFuncionarioArgs = {
  idFuncionario: Scalars['ID']
};


export type MutationDesbloquearFuncionarioArgs = {
  idFuncionario: Scalars['ID']
};


export type MutationAlterarEmailFuncionarioArgs = {
  email: Scalars['String']
};


export type MutationCriarTipoFuncionarioArgs = {
  nome: Scalars['String'],
  tipoDefault?: Maybe<Scalars['Boolean']>
};


export type MutationEditarTipoFuncionarioArgs = {
  id: Scalars['ID'],
  nome: Scalars['String'],
  tipoDefault?: Maybe<Scalars['Boolean']>
};


export type MutationExcluirTipoFuncionarioArgs = {
  id: Scalars['ID']
};


export type MutationDarVariasPermissoesArgs = {
  atributosPermissoes?: Maybe<Array<Maybe<AtributosDePermissaoInput>>>,
  idFuncionario: Scalars['ID']
};


export type MutationExcluirVariasPermissoesArgs = {
  atributosPermissoes?: Maybe<Array<Maybe<AtributosDePermissaoInput>>>,
  idFuncionario: Scalars['ID']
};


export type MutationCriarGrupoPermissoesArgs = {
  descricao?: Maybe<Scalars['String']>,
  nome: Scalars['String']
};


export type MutationAlterarGrupoPermissoesArgs = {
  descricao?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>
};


export type MutationExcluirGrupoPermissoesArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationDarPermissoesGrupoArgs = {
  descricoes: Array<Maybe<Scalars['String']>>,
  idGrupo: Scalars['ID']
};


export type MutationRetirarPermissoesGrupoArgs = {
  descricoes: Array<Maybe<Scalars['String']>>,
  idGrupo: Scalars['ID']
};


export type MutationAssociarEntidadesGrupoArgs = {
  idGrupo: Scalars['ID'],
  idsEntidades: Array<Maybe<Scalars['Int']>>
};


export type MutationDesassociarEntidadesGrupoArgs = {
  idGrupo: Scalars['ID'],
  idsEntidades: Array<Maybe<Scalars['Int']>>
};


export type MutationAssociarFuncionariosGrupoArgs = {
  idGrupo: Scalars['ID'],
  idsFuncionarios: Array<Maybe<Scalars['Int']>>
};


export type MutationDesassociarFuncionariosGrupoArgs = {
  idGrupo: Scalars['ID'],
  idsFuncionarios: Array<Maybe<Scalars['Int']>>
};


export type MutationCriarPedidoReembolsoArgs = {
  agencia: Scalars['String'],
  anexoPagamento?: Maybe<Scalars['String']>,
  banco: Scalars['String'],
  categoria: Scalars['String'],
  conta: Scalars['String'],
  cpfCnpj: Scalars['String'],
  data: Scalars['DateTime'],
  dataPagamento?: Maybe<Scalars['DateTime']>,
  descricao: Scalars['String'],
  idEntidade: Scalars['ID'],
  nome: Scalars['String'],
  pix?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<PedidoDeReembolsoTipoConta>,
  tipoDespesa?: Maybe<PedidoDeReembolsoTipoDespesa>,
  tipoPix?: Maybe<Scalars['String']>,
  valor: Scalars['Float'],
  variacao?: Maybe<Scalars['String']>
};


export type MutationAtualizarPedidoReembolsoArgs = {
  agencia?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  banco?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  descricao?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  pix?: Maybe<Scalars['String']>,
  status?: Maybe<PedidoDeReembolsoStatus>,
  tipoConta?: Maybe<PedidoDeReembolsoTipoConta>,
  tipoDespesa?: Maybe<PedidoDeReembolsoTipoDespesa>,
  tipoPix?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  variacao?: Maybe<Scalars['String']>
};


export type MutationExcluirPedidoReembolsoArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationAtualizarStatusReembolsoArgs = {
  anexoPagamento?: Maybe<Scalars['String']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  idPedido: Scalars['ID'],
  justificativaCancelamento?: Maybe<Scalars['String']>,
  status?: Maybe<PedidoDeReembolsoStatus>
};


export type MutationPagarPedidoReembolsoArgs = {
  anexoPagamento?: Maybe<Scalars['String']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  id: Scalars['ID']
};


export type MutationIncluirAnexoPedidoReembolsoArgs = {
  descricao?: Maybe<Scalars['String']>,
  idPedido: Scalars['ID'],
  url: Scalars['String']
};


export type MutationAtualizarAnexoPedidoReembolsoArgs = {
  descricao?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  url?: Maybe<Scalars['String']>
};


export type MutationExcluirAnexoPedidoReembolsoArgs = {
  id: Scalars['ID']
};


export type MutationEnviarDocumentoClickSignArgs = {
  idEmprestimo: Scalars['ID']
};


export type MutationEnviarDocumentoClickSignMobileArgs = {
  idEmprestimo: Scalars['ID']
};


export type MutationEnviarDocumentoClickSignMobileDigitadaMobileArgs = {
  idEmprestimo: Scalars['ID']
};


export type MutationVincularDocumentoClickSignArgs = {
  chaveDocumento: Scalars['String'],
  idEmprestimo: Scalars['ID']
};


export type MutationVincularTokenOnesignalMutationArgs = {
  token: Scalars['String']
};


export type MutationDisvinculaTokenOnesignalMutationArgs = {
  token: Scalars['String']
};


export type MutationCriarClienteArgs = {
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  cadastroRapido?: Maybe<Scalars['Boolean']>,
  cep?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  estadoCivil?: Maybe<Scalars['String']>,
  idEntidade?: Maybe<Scalars['ID']>,
  municipio?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  nome: Scalars['String'],
  origem?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>
};


export type MutationAtualizarClienteArgs = {
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  estadoCivil?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  municipio?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  nome?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>
};


export type MutationAssociarClienteEntidadeArgs = {
  id: Scalars['ID'],
  idEntidade: Scalars['ID']
};


export type MutationDesassociarClienteEntidadeArgs = {
  id: Scalars['ID'],
  idEntidade: Scalars['ID']
};


export type MutationIncluirTelefoneClienteArgs = {
  ddd?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  idCliente?: Maybe<Scalars['ID']>,
  numero: Scalars['String']
};


export type MutationAtualizarTelefoneClienteArgs = {
  ddd?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  numero?: Maybe<Scalars['String']>
};


export type MutationExcluirTelefoneClienteArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationIncluirContaClienteArgs = {
  agencia: Scalars['String'],
  banco: Scalars['String'],
  conta: Scalars['String'],
  cpfCnpj: Scalars['String'],
  idCliente?: Maybe<Scalars['ID']>,
  nome: Scalars['String'],
  pix?: Maybe<Scalars['String']>,
  tipoConta: Scalars['String'],
  tipoPix?: Maybe<Scalars['String']>,
  variacao?: Maybe<Scalars['String']>
};


export type MutationAtualizarContaClienteArgs = {
  agencia?: Maybe<Scalars['String']>,
  banco?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  nome?: Maybe<Scalars['String']>,
  pix?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoPix?: Maybe<Scalars['String']>,
  variacao?: Maybe<Scalars['String']>
};


export type MutationExcluirContaClienteArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationCriarClientRapidoArgs = {
  input?: Maybe<CriarClienteRapidoInput>
};


export type MutationVinculaClienteAsaasMutationArgs = {
  clientInput?: Maybe<VinculaClienteAsaasMutationInput>
};


export type MutationBuscarECriarClienteAsaasArgs = {
  input?: Maybe<BuscarECriarClienteAsaasInput>
};


export type MutationAtualizarClienteRapidoArgs = {
  clientInput?: Maybe<AtualizarClienteRapidoInput>
};


export type MutationCriarContaPagarArgs = {
  anexoPagamento?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoDataVencimento?: Maybe<Scalars['DateTime']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  categoria: Scalars['String'],
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataVencimento: Scalars['DateTime'],
  destinatario: Scalars['String'],
  formaPagamento?: Maybe<ContaAPagarFormaPagamento>,
  idEntidade: Scalars['ID'],
  observacao?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<ContaAPagarRecorrencia>,
  tipoDespesa?: Maybe<ContaAPagarTipoDespesa>,
  titulo: Scalars['String'],
  transferenciaAgencia?: Maybe<Scalars['String']>,
  transferenciaBanco?: Maybe<Scalars['String']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaCpfCnpj?: Maybe<Scalars['String']>,
  transferenciaNome?: Maybe<Scalars['String']>,
  transferenciaPix?: Maybe<Scalars['String']>,
  transferenciaTipoConta?: Maybe<ContaAPagarTransferenciaTipoConta>,
  transferenciaTipoPix?: Maybe<Scalars['String']>,
  transferenciaVariacao?: Maybe<Scalars['String']>,
  valor: Scalars['Float'],
  valorPago?: Maybe<Scalars['Float']>
};


export type MutationAtualizarContaPagarArgs = {
  anexoPagamento?: Maybe<Scalars['String']>,
  boletoCodigoBarra?: Maybe<Scalars['String']>,
  boletoDataVencimento?: Maybe<Scalars['DateTime']>,
  boletoNumeroControle?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['DateTime']>,
  destinatario?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<ContaAPagarFormaPagamento>,
  id: Scalars['ID'],
  idEntidade?: Maybe<Scalars['ID']>,
  observacao?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<ContaAPagarRecorrencia>,
  tipoDespesa?: Maybe<ContaAPagarTipoDespesa>,
  titulo?: Maybe<Scalars['String']>,
  transferenciaAgencia?: Maybe<Scalars['String']>,
  transferenciaBanco?: Maybe<Scalars['String']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaCpfCnpj?: Maybe<Scalars['String']>,
  transferenciaNome?: Maybe<Scalars['String']>,
  transferenciaPix?: Maybe<Scalars['String']>,
  transferenciaTipoConta?: Maybe<ContaAPagarTransferenciaTipoConta>,
  transferenciaTipoPix?: Maybe<Scalars['String']>,
  transferenciaVariacao?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valorPago?: Maybe<Scalars['Float']>
};


export type MutationExcluirContaPagarArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationPagarContaPagarArgs = {
  anexoPagamento?: Maybe<Scalars['String']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  id: Scalars['ID'],
  justificativaValorPago?: Maybe<Scalars['String']>,
  valorPago?: Maybe<Scalars['Decimal']>
};


export type MutationAtualizarStatusContaPagarArgs = {
  anexoPagamento?: Maybe<Scalars['String']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  idConta: Scalars['ID'],
  justificativaCancelamento?: Maybe<Scalars['String']>,
  justificativaValorPago?: Maybe<Scalars['String']>,
  status?: Maybe<ContaAPagarStatus>,
  valorPago?: Maybe<Scalars['Decimal']>
};


export type MutationIncluirAnexoContaPagarArgs = {
  descricao?: Maybe<Scalars['String']>,
  idConta: Scalars['ID'],
  url: Scalars['String']
};


export type MutationAtualizarAnexoContaPagarArgs = {
  descricao?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  url?: Maybe<Scalars['String']>
};


export type MutationExcluirAnexoContaPagarArgs = {
  id: Scalars['ID']
};


export type MutationCriarMetaMutationArgs = {
  input?: Maybe<Array<Maybe<MetaInputList>>>
};


export type MutationUpdateMetaMutationArgs = {
  id: Scalars['ID'],
  input?: Maybe<Array<Maybe<MetaInput>>>
};


export type MutationUpdateMetaDiariaMutationArgs = {
  id: Scalars['ID']
};


export type MutationDeleteMetaMutationArgs = {
  id: Scalars['ID']
};


export type MutationTodasMetasDashArgs = {
  fimMeta?: Maybe<Scalars['Date']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idEntidade?: Maybe<Array<Maybe<Scalars['ID']>>>,
  idFuncionario?: Maybe<Array<Maybe<Scalars['ID']>>>,
  inicioMeta?: Maybe<Scalars['Date']>
};


export type MutationEditarLeadProspeccaoArgs = {
  bairro?: Maybe<Scalars['String']>,
  carteiraAssinada?: Maybe<Scalars['Boolean']>,
  cidade?: Maybe<Scalars['String']>,
  contaMaisTempo?: Maybe<Scalars['Boolean']>,
  email?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['Int']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  tipoBeneficio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  vagasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorTotal?: Maybe<Scalars['Float']>
};


export type MutationCreateLeadProspeccaoArgs = {
  bairro?: Maybe<Scalars['String']>,
  carteiraAssinada?: Maybe<Scalars['Boolean']>,
  cidade?: Maybe<Scalars['String']>,
  contaMaisTempo?: Maybe<Scalars['Boolean']>,
  email?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  nome: Scalars['String'],
  origem?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['Int']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  tipoBeneficio?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  vagasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorTotal?: Maybe<Scalars['Float']>
};


export type MutationCreateClienteFromLeadArgs = {
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRenda?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  bairro: Scalars['String'],
  cep: Scalars['String'],
  complemento: Scalars['String'],
  cpf: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  endereco: Scalars['String'],
  estadoCivil: Scalars['String'],
  idEntidade?: Maybe<Scalars['ID']>,
  idLead?: Maybe<Scalars['ID']>,
  municipio: Scalars['String'],
  nascimento: Scalars['String'],
  nome: Scalars['String'],
  profissao: Scalars['String'],
  rg: Scalars['String'],
  sexo: Scalars['String'],
  telefone?: Maybe<Scalars['String']>,
  uf: Scalars['String']
};


export type MutationCriarCategoriaArgs = {
  nome: Scalars['String']
};


export type MutationEditarCategoriaArgs = {
  id: Scalars['ID'],
  nome: Scalars['String']
};


export type MutationExcluirCategoriaArgs = {
  id: Scalars['ID']
};


export type MutationCriarSubcategoriaArgs = {
  categoria: Scalars['ID'],
  nome: Scalars['String']
};


export type MutationEditarSubcategoriaArgs = {
  categoria: Scalars['ID'],
  id: Scalars['ID'],
  nome: Scalars['String']
};


export type MutationExcluirSubcategoriaArgs = {
  id: Scalars['ID']
};


export type MutationCriarOrigemArgs = {
  origem?: Maybe<Scalars['String']>
};


export type MutationEditarOrigemArgs = {
  id: Scalars['ID'],
  origem?: Maybe<Scalars['String']>
};


export type MutationDeletarOrigemArgs = {
  id: Scalars['ID']
};


export type MutationCriarRegramentoEntidadeArgs = {
  idEntidade: Scalars['ID'],
  valorAcima: Scalars['Float']
};


export type MutationCriarRegramentoTipoEntidadeArgs = {
  idTipoEntidade: Scalars['ID'],
  valorAcima: Scalars['Float']
};


export type MutationEditarRegramentoEntidadeArgs = {
  idEntidade: Scalars['ID'],
  valorAcima: Scalars['Float']
};


export type MutationEditarRegramentoTipoEntidadeArgs = {
  idTipoEntidade: Scalars['ID'],
  valorAcima: Scalars['Float']
};


export type MutationExcluirRegramentoEntidadeArgs = {
  idEntidade: Scalars['ID']
};


export type MutationExcluirRegramentoTipoEntidadeArgs = {
  idTipoEntidade: Scalars['ID']
};


export type MutationCriarTipoEntidadeArgs = {
  input?: Maybe<InputTiposEntidadesType>
};


export type MutationEditarTipoEntidadeArgs = {
  idTipoEntidade: Scalars['ID'],
  input?: Maybe<InputTiposEntidadesType>
};


export type MutationExcluirTipoEntidadeArgs = {
  idTipoEntidade: Scalars['ID']
};


export type MutationCreateTarefaArgs = {
  tarefa?: Maybe<InputTarefa>
};


export type MutationEditarTarefaArgs = {
  assunto?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  hora?: Maybe<Scalars['Time']>,
  id: Scalars['ID'],
  leadId?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>
};


export type MutationExcluirTarefaArgs = {
  id: Scalars['ID']
};


export type MutationEditarLeadArgs = {
  bairro?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  telefone?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  vagasIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationSimulaEmprestimoLeadArgs = {
  propostaInput?: Maybe<PropostaTypeInput>
};


export type MutationAtualizaStatusNegociacaoArgs = {
  id: Scalars['ID'],
  justificativa?: Maybe<Scalars['String']>,
  pausado: Scalars['Boolean']
};


export type MutationMarcarPerdaLeadArgs = {
  id: Scalars['ID'],
  justificativa: Scalars['String']
};


export type MutationMarcarVendaLeadArgs = {
  id: Scalars['ID'],
  idCliente?: Maybe<Scalars['ID']>,
  idEmprestimo?: Maybe<Scalars['ID']>
};


export type MutationMoveLeadArgs = {
  id: Scalars['ID'],
  index: Scalars['Int'],
  position: Scalars['Float']
};


export type MutationQualificaLeadArgs = {
  id: Scalars['ID'],
  qualificacao: Scalars['Int']
};


export type MutationRetomarOportunidadeLeadArgs = {
  id: Scalars['ID']
};


export type MutationCreateLeadCrmArgs = {
  bairro?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  nome: Scalars['String'],
  origem?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  vagasIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationCreateAnotacaoArgs = {
  leadId: Scalars['ID'],
  nota: Scalars['String']
};


export type MutationEditarAnotacaoArgs = {
  id: Scalars['ID'],
  nota: Scalars['String']
};


export type MutationExcluirAnotacaoArgs = {
  id: Scalars['ID']
};


export type MutationProdutoInteresseArgs = {
  idLead: Scalars['ID'],
  produto: Scalars['String']
};


export type MutationResponsavelLeadArgs = {
  idLead: Scalars['ID'],
  idResponsavel: Scalars['ID']
};


export type MutationExcluirTarefasMultiplasArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type MutationEditarTarefasMultiplasArgs = {
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  status?: Maybe<Scalars['String']>
};


export type MutationLerArquivoLeadArgs = {
  arquivo?: Maybe<Scalars['String']>
};


export type MutationEnviarSimulacaoEmailArgs = {
  leadId?: Maybe<Scalars['ID']>,
  propostaId?: Maybe<Scalars['ID']>
};


export type MutationExcluirCobrancaArgs = {
  id: Scalars['ID']
};


export type MutationCriarCobrancaArgs = {
  cobrancaInput: CobrancaInput
};


export type MutationAtualizarCobrancaArgs = {
  cobrancaUpdateInput?: Maybe<CobrancaUpdateInput>
};


export type MutationCheckCobrancaDuplicadaArgs = {
  checkCobrancaDuplicadaInput: CheckDuplicateCobrancaInput
};


export type MutationReplicaCobrancaExistenteAsaasArgs = {
  asaasCustomerId: Scalars['String'],
  clienteId: Scalars['Int'],
  cobrancaId?: Maybe<Scalars['Int']>,
  lojaId: Scalars['Int'],
  value: Scalars['Decimal'],
  vencimento: Scalars['String'],
  vigenciaFim?: Maybe<Scalars['String']>,
  vigenciaInicio?: Maybe<Scalars['String']>
};


export type MutationLoginMobileArgs = {
  password: Scalars['String'],
  username: Scalars['String']
};


export type MutationCreateClienteMobileArgs = {
  celular?: Maybe<Scalars['String']>,
  contas?: Maybe<Array<Maybe<ContaClienteInputMobile>>>,
  input?: Maybe<ClienteInputMobile>,
  password?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>
};


export type MutationCreateCustomerMobileSimplifyArgs = {
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  source?: Maybe<Scalars['String']>
};


export type MutationCreateLoanSimulationMobileArgs = {
  date: Scalars['Date'],
  desiredValue: Scalars['Float'],
  installments: EmprestimoParcelas
};


export type MutationCreatePreClienteMobileArgs = {
  celular?: Maybe<Scalars['String']>,
  contas?: Maybe<Array<Maybe<ContaClienteInputMobile>>>,
  idCliente: Scalars['ID'],
  input: ClienteInputMobile,
  password?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>
};


export type MutationCreateEmprestimoMobileArgs = {
  boletos?: Maybe<Array<Maybe<BoletoClienteMobile>>>,
  cartaoId: Scalars['Int'],
  contas?: Maybe<Array<Maybe<ContaClienteMobileType2>>>,
  emprestimoInput?: Maybe<InputEmprestimoMobile>
};


export type MutationCreateCartaoCreditoArgs = {
  dadosCartao?: Maybe<InputCriarCartaoDeCredito>
};


export type MutationCreateContaClienteMobileArgs = {
  agencia?: Maybe<Scalars['String']>,
  banco?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
  idCliente: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  variacao?: Maybe<Scalars['String']>
};


export type MutationCreateCartaoCreditoTerceirosArgs = {
  dadosCartao?: Maybe<InputCriarCartaoDeCredito>,
  dadosTerceiro?: Maybe<InputCriarCartaoDeCreditoTerceiros>
};


export type MutationUpdateClienteMobileArgs = {
  anexoCpfFrente?: Maybe<Scalars['String']>,
  anexoCpfVerso?: Maybe<Scalars['String']>,
  anexoEndereco?: Maybe<Scalars['String']>,
  anexoRgFrente?: Maybe<Scalars['String']>,
  anexoRgVerso?: Maybe<Scalars['String']>,
  celular?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  estadoCivil?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  nome?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>
};


export type MutationEditUserProfileMobileArgs = {
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  source?: Maybe<Scalars['String']>
};


export type MutationUpdateEnderecoClienteMobileArgs = {
  anexoEndereco?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  cep?: Maybe<Scalars['String']>,
  complemento?: Maybe<Scalars['String']>,
  endereco?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  municipio?: Maybe<Scalars['String']>,
  pais?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>
};


export type MutationUpdateContaClienteMobileArgs = {
  agencia?: Maybe<Scalars['String']>,
  banco?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
  idCliente: Scalars['ID'],
  idConta: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  variacao?: Maybe<Scalars['String']>
};


export type MutationExcluirClienteMobileArgs = {
  idCliente: Scalars['ID'],
  password: Scalars['String']
};


export type MutationExcluirCartaoCreditoArgs = {
  id: Scalars['ID']
};


export type MutationExcluirContaClienteMobileArgs = {
  id: Scalars['ID']
};


export type MutationEnviarMemsagemSuporteMobileArgs = {
  assunto: Scalars['String'],
  idCliente: Scalars['ID'],
  memsagem: Scalars['String']
};


export type MutationGerarNovaSenhaClienteMobileArgs = {
  emailCliente: Scalars['String']
};


export type MutationAlterarSenhaClienteMobileArgs = {
  idCliente?: Maybe<Scalars['ID']>,
  senhaAtual: Scalars['String'],
  senhaNova: Scalars['String']
};


export type MutationCancelaEmprestimoMobileArgs = {
  id: Scalars['ID'],
  justificativa: Scalars['String']
};


export type MutationUploadFoto64Args = {
  file: Scalars['String']
};


export type MutationGenerateNewUserPasswordMobileArgs = {
  userEmail: Scalars['String']
};


export type MutationObterTokenArgs = {
  username: Scalars['String'],
  password: Scalars['String']
};


export type MutationVerificarTokenArgs = {
  token?: Maybe<Scalars['String']>
};


export type MutationAtualizarTokenArgs = {
  token?: Maybe<Scalars['String']>
};

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'],
};

export type NsuInput = {
  id?: Maybe<Scalars['ID']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  valorNsuCv: Scalars['Float'],
  maquinaId?: Maybe<Scalars['ID']>,
  maquinaOld?: Maybe<Scalars['String']>,
  parcelas?: Maybe<EmprestimoParcelas>,
  nsuCv?: Maybe<Scalars['String']>,
  titularDivergente?: Maybe<TitularDivergenteInput>,
};

export type NsuNode = Node & {
   __typename?: 'NsuNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  emprestimo: EmprestimoRelatorioType,
  bandeiraCartao?: Maybe<Scalars['String']>,
  nsuCv?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  idPagamento?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  nomeNoCartao?: Maybe<Scalars['String']>,
  validade?: Maybe<Scalars['DateTime']>,
  numeroCartao?: Maybe<Scalars['String']>,
  idPagcetoCartao?: Maybe<Scalars['String']>,
  pagcertoPagamentoPayload?: Maybe<Scalars['JSONString']>,
  maquinaOld?: Maybe<Scalars['String']>,
  maquina?: Maybe<MaquinaNode>,
  limiteMaquinaAlterado: Scalars['Boolean'],
  parcelas?: Maybe<NsuParcelas>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  titularDivergente?: Maybe<NsuTitularDivergenteType>,
  pk: Scalars['Int'],
};

export type NsuNodeConnection = {
   __typename?: 'NsuNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NsuNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `NsuNode` and its cursor. */
export type NsuNodeEdge = {
   __typename?: 'NsuNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<NsuNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum NsuParcelas {
  /** 1x */
  A_1 = 'A_1',
  /** 2x */
  A_2 = 'A_2',
  /** 3x */
  A_3 = 'A_3',
  /** 4x */
  A_4 = 'A_4',
  /** 5x */
  A_5 = 'A_5',
  /** 6x */
  A_6 = 'A_6',
  /** 7x */
  A_7 = 'A_7',
  /** 8x */
  A_8 = 'A_8',
  /** 9x */
  A_9 = 'A_9',
  /** 10x */
  A_10 = 'A_10',
  /** 11x */
  A_11 = 'A_11',
  /** 12x */
  A_12 = 'A_12',
  /** 13x */
  A_13 = 'A_13',
  /** 14x */
  A_14 = 'A_14',
  /** 15x */
  A_15 = 'A_15',
  /** 16x */
  A_16 = 'A_16',
  /** 17x */
  A_17 = 'A_17',
  /** 18x */
  A_18 = 'A_18',
  /** 19x */
  A_19 = 'A_19',
  /** 20x */
  A_20 = 'A_20',
  /** 21x */
  A_21 = 'A_21',
  /** 22x */
  A_22 = 'A_22',
  /** 23x */
  A_23 = 'A_23',
  /** 24x */
  A_24 = 'A_24'
}

export type NsuRelatorioInput = {
  id?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId_In?: Maybe<Scalars['ID']>,
  emprestimo_Id?: Maybe<Scalars['Float']>,
  nsuCv?: Maybe<Scalars['String']>,
  nsuCv_In?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  valorNsuCv_In?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado_In?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada_In?: Maybe<Scalars['Float']>,
  emprestimo_Status?: Maybe<Scalars['String']>,
  emprestimo_Status_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type NsuRelatorioType = Node & {
   __typename?: 'NsuRelatorioType',
  /** The ID of the object. */
  id: Scalars['ID'],
  emprestimo: EmprestimoRelatorioType,
  bandeiraCartao?: Maybe<Scalars['String']>,
  nsuCv?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  idPagamento?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  nomeNoCartao?: Maybe<Scalars['String']>,
  validade?: Maybe<Scalars['DateTime']>,
  numeroCartao?: Maybe<Scalars['String']>,
  idPagcetoCartao?: Maybe<Scalars['String']>,
  pagcertoPagamentoPayload?: Maybe<Scalars['JSONString']>,
  maquinaOld?: Maybe<Scalars['String']>,
  maquina?: Maybe<MaquinaNode>,
  limiteMaquinaAlterado: Scalars['Boolean'],
  parcelas?: Maybe<NsuParcelas>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  titularDivergente?: Maybe<NsuTitularDivergenteType>,
  valorRetirado?: Maybe<Scalars['Decimal']>,
  valorPassado?: Maybe<Scalars['Decimal']>,
};

export type NsuRelatorioTypeConnection = {
   __typename?: 'NsuRelatorioTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NsuRelatorioTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `NsuRelatorioType` and its cursor. */
export type NsuRelatorioTypeEdge = {
   __typename?: 'NsuRelatorioTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<NsuRelatorioType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type NsusAgregations = {
   __typename?: 'NsusAgregations',
  somaValoresPassados?: Maybe<Scalars['Decimal']>,
  somaValorRetirado?: Maybe<Scalars['Decimal']>,
  taxaMediaTotal?: Maybe<Scalars['Decimal']>,
};

export type NsuTitularDivergenteType = {
   __typename?: 'NsuTitularDivergenteType',
  id: Scalars['ID'],
  nsu: NsuRelatorioType,
  nome: Scalars['String'],
  anexoCpf: Scalars['String'],
  anexoRg: Scalars['String'],
  cpf: Scalars['String'],
  rg?: Maybe<Scalars['String']>,
  cep: Scalars['String'],
  endereco: Scalars['String'],
  complemento: Scalars['String'],
  bairro: Scalars['String'],
  municipio: Scalars['String'],
  uf: Scalars['String'],
  pais: Scalars['String'],
  comprovanteEndereco?: Maybe<Scalars['String']>,
};

export type NsuType = {
   __typename?: 'NsuType',
  id: Scalars['ID'],
  emprestimo: EmprestimoRelatorioType,
  bandeiraCartao?: Maybe<Scalars['String']>,
  nsuCv?: Maybe<Scalars['String']>,
  anexoNsuCartao?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  idPagamento?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  nomeNoCartao?: Maybe<Scalars['String']>,
  validade?: Maybe<Scalars['DateTime']>,
  numeroCartao?: Maybe<Scalars['String']>,
  idPagcetoCartao?: Maybe<Scalars['String']>,
  pagcertoPagamentoPayload?: Maybe<Scalars['JSONString']>,
  maquinaOld?: Maybe<Scalars['String']>,
  maquina?: Maybe<MaquinaNode>,
  limiteMaquinaAlterado: Scalars['Boolean'],
  parcelas?: Maybe<NsuParcelas>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  titularDivergente?: Maybe<NsuTitularDivergenteType>,
  numeroMascara?: Maybe<Scalars['String']>,
};

export type OcorrenciaComMaquinaNode = Node & {
   __typename?: 'OcorrenciaComMaquinaNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  maquina: MaquinaNode,
  funcionario: FuncionarioTypeRelay,
  dataHora: Scalars['DateTime'],
  tipo: OcorrenciaComMaquinaTipo,
  entidade?: Maybe<EntidadeTypeRelay>,
  entidadeAnterior?: Maybe<EntidadeTypeRelay>,
  detalhamento?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type OcorrenciaComMaquinaNodeConnection = {
   __typename?: 'OcorrenciaComMaquinaNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OcorrenciaComMaquinaNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `OcorrenciaComMaquinaNode` and its cursor. */
export type OcorrenciaComMaquinaNodeEdge = {
   __typename?: 'OcorrenciaComMaquinaNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<OcorrenciaComMaquinaNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum OcorrenciaComMaquinaTipo {
  /** Habilitar */
  Habilitar = 'HABILITAR',
  /** Desabilitar */
  Desabilitar = 'DESABILITAR',
  /** Bloquear */
  Bloquear = 'BLOQUEAR',
  /** Desbloquear */
  Desbloquear = 'DESBLOQUEAR',
  /** Reportar Problema */
  ReportarProblema = 'REPORTAR_PROBLEMA'
}

export type OrigensMetasDash = {
   __typename?: 'OrigensMetasDash',
  id?: Maybe<Scalars['String']>,
  label?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['Decimal']>,
};

export type OrigensType = {
   __typename?: 'OrigensType',
  id: Scalars['ID'],
  origem: Scalars['String'],
  excluido: Scalars['Boolean'],
};

export type PagarContaAPagarMutation = {
   __typename?: 'PagarContaAPagarMutation',
  ok?: Maybe<Scalars['Boolean']>,
  recorrenciaAtualizada?: Maybe<ContaAPagarNode>,
  contaPagar?: Maybe<ContaAPagarNode>,
};

export type PagarEmprestimoMutation = {
   __typename?: 'PagarEmprestimoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  emprestimo?: Maybe<EmprestimoNode>,
};

export type PagarPedidoDeReembolsoMutation = {
   __typename?: 'PagarPedidoDeReembolsoMutation',
  ok?: Maybe<Scalars['Boolean']>,
  pedidoReembolso?: Maybe<PedidoDeReembolsoNode>,
};

export type PagcertoCreditCardPayloadType = {
   __typename?: 'PagcertoCreditCardPayloadType',
  id?: Maybe<Scalars['String']>,
  holderName?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  expirationMonth?: Maybe<Scalars['Int']>,
  expirationYear?: Maybe<Scalars['Int']>,
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
   __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'],
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'],
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
};

/** An enumeration. */
export enum ParceirosDiasRepasse {
  /** 0 */
  D_0 = 'D_0',
  /** 1 */
  D_1 = 'D_1',
  /** 2 */
  D_2 = 'D_2',
  /** 14 */
  D_14 = 'D_14',
  /** 20 */
  D_20 = 'D_20',
  /** 30 */
  D_30 = 'D_30'
}

export type ParceirosNodeTypeRelay = Node & {
   __typename?: 'ParceirosNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  lojaRepasse?: Maybe<EntidadeTypeRelay>,
  diasRepasse: ParceirosDiasRepasse,
  funcionario: FuncionarioTypeRelay,
  funcionariosPermitidos: FuncionarioTypeRelayConnection,
  gruposPermitidos: GrupoDePermissoesTypeRelayConnection,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tabela?: Maybe<TabelasNodeTypeRelay>,
  idsLojas?: Maybe<Array<Scalars['Int']>>,
  gruposDePermissoes: GrupoDePermissoesTypeRelayConnection,
  lojaparceiroSet: Array<LojaParceiroType>,
  contaparceirosSet: Array<ContaParceirosType>,
  resgateparceiroSet: HistoricoResgateNodeTypeRelayConnection,
  resgateautomaticoparceiros?: Maybe<ResgateAutomaticoParceirosType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
};


export type ParceirosNodeTypeRelayFuncionariosPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  categoria?: Maybe<Scalars['String']>,
  tipoFuncionario_Nome?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  user_IsActive?: Maybe<Scalars['Boolean']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ParceirosNodeTypeRelayGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ParceirosNodeTypeRelayGruposDePermissoesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ParceirosNodeTypeRelayResgateparceiroSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  valorResgate?: Maybe<Scalars['Float']>,
  valorResgate_Icontains?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  parceiroId_In?: Maybe<Scalars['String']>,
  conta_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type ParceirosNodeTypeRelayComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};

export type ParceirosNodeTypeRelayConnection = {
   __typename?: 'ParceirosNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ParceirosNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `ParceirosNodeTypeRelay` and its cursor. */
export type ParceirosNodeTypeRelayEdge = {
   __typename?: 'ParceirosNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<ParceirosNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type ParceirosType = {
   __typename?: 'ParceirosType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  lojaRepasse?: Maybe<EntidadeTypeRelay>,
  diasRepasse: ParceirosDiasRepasse,
  funcionario: FuncionarioTypeRelay,
  funcionariosPermitidos?: Maybe<Array<Maybe<FuncionarioType>>>,
  gruposPermitidos?: Maybe<Array<Maybe<GrupoDePermissoesType>>>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tabela?: Maybe<TabelasNodeTypeRelay>,
  idsLojas?: Maybe<Array<Scalars['Int']>>,
  gruposDePermissoes?: Maybe<Array<Maybe<GrupoDePermissoesType>>>,
  lojaparceiroSet: Array<LojaParceiroType>,
  contaparceirosSet: Array<ContaParceirosType>,
  resgateparceiroSet: HistoricoResgateNodeTypeRelayConnection,
  resgateautomaticoparceiros?: Maybe<ResgateAutomaticoParceirosType>,
  comissaoparceirosSet: ComissaoNodeTypeRelayConnection,
};


export type ParceirosTypeResgateparceiroSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  valorResgate?: Maybe<Scalars['Float']>,
  valorResgate_Icontains?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  parceiroId_In?: Maybe<Scalars['String']>,
  conta_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type ParceirosTypeComissaoparceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};

export type ParceirosTypeInput = {
  nome?: Maybe<Scalars['String']>,
  idParceiro?: Maybe<Scalars['ID']>,
  idFuncionario: Scalars['ID'],
  idLojaRepasse: Scalars['ID'],
  diasRepasse?: Maybe<Scalars['String']>,
};

export type ParcelasMetasDash = {
   __typename?: 'ParcelasMetasDash',
  id?: Maybe<Scalars['String']>,
  label?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['Decimal']>,
};

export type PausarLead = {
   __typename?: 'PausarLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type PedidoDeReembolsoNode = Node & {
   __typename?: 'PedidoDeReembolsoNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  entidade?: Maybe<EntidadeTypeRelay>,
  funcionario: FuncionarioTypeRelay,
  descricao: Scalars['String'],
  data: Scalars['DateTime'],
  valor: Scalars['Float'],
  categoria: Scalars['String'],
  status: PedidoDeReembolsoStatus,
  tipoDespesa?: Maybe<PedidoDeReembolsoTipoDespesa>,
  nome: Scalars['String'],
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  banco: Scalars['String'],
  agencia: Scalars['String'],
  tipoConta: PedidoDeReembolsoTipoConta,
  conta: Scalars['String'],
  variacao?: Maybe<Scalars['String']>,
  cpfCnpj: Scalars['String'],
  justificativaCancelamento?: Maybe<Scalars['String']>,
  anexoPagamento?: Maybe<Scalars['String']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  anexos: Array<AnexoDePedidoDeReembolsoType>,
};

export type PedidoDeReembolsoNodeConnection = {
   __typename?: 'PedidoDeReembolsoNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PedidoDeReembolsoNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `PedidoDeReembolsoNode` and its cursor. */
export type PedidoDeReembolsoNodeEdge = {
   __typename?: 'PedidoDeReembolsoNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<PedidoDeReembolsoNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum PedidoDeReembolsoStatus {
  /** Pendente */
  Pendente = 'PENDENTE',
  /** Aprovado */
  Aprovado = 'APROVADO',
  /** Reprovado */
  Reprovado = 'REPROVADO',
  /** Pago */
  Pago = 'PAGO',
  /** Vencido */
  Vencido = 'VENCIDO',
  /** Cancelado */
  Cancelado = 'CANCELADO',
  /** Informações Incompletas */
  InformacoesIncompletas = 'INFORMACOES_INCOMPLETAS'
}

/** An enumeration. */
export enum PedidoDeReembolsoTipoConta {
  /** Corrente */
  Corrente = 'CORRENTE',
  /** Poupança */
  Poupanca = 'POUPANCA',
  /** Fácil */
  Facil = 'FACIL'
}

/** An enumeration. */
export enum PedidoDeReembolsoTipoDespesa {
  /** Fixa */
  Fixa = 'FIXA',
  /** Variável */
  Variavel = 'VARIAVEL'
}

export type PerdaLead = {
   __typename?: 'PerdaLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

/** An enumeration. */
export enum PermissaoDeGrupoDescricao {
  /** VER BOT ZEKI */
  VerBotZeki = 'VER_BOT_ZEKI',
  /** Ver somatório de cobranças */
  VerSomatorioCobranca = 'VER_SOMATORIO_COBRANCA',
  /** Ver Cobrança */
  VerCobranca = 'VER_COBRANCA',
  /** Excluir Cobrança */
  ExcluirCobranca = 'EXCLUIR_COBRANCA',
  /** Criar Cobrança */
  CriarCobranca = 'CRIAR_COBRANCA',
  /** Editar Cobrança */
  EditarCobranca = 'EDITAR_COBRANCA',
  /** EDITAR BOT ZEKI */
  EditarBotZeki = 'EDITAR_BOT_ZEKI',
  /** Ver Entidade */
  VerEntidade = 'VER_ENTIDADE',
  /** Criar Entidade */
  CriarEntidade = 'CRIAR_ENTIDADE',
  /** Editar Entidade */
  EditarEntidade = 'EDITAR_ENTIDADE',
  /** Excluir Entidade */
  ExcluirEntidade = 'EXCLUIR_ENTIDADE',
  /** Definir Taxa de segurança da Entidade */
  DefinirTaxaSegurancaEntidade = 'DEFINIR_TAXA_SEGURANCA_ENTIDADE',
  /** Definir Taxa mínima da Entidade */
  DefinirTaxaMinimaEntidade = 'DEFINIR_TAXA_MINIMA_ENTIDADE',
  /** Definir Taxa de lucratividade da Entidade */
  DefinirTaxaLucroEntidade = 'DEFINIR_TAXA_LUCRO_ENTIDADE',
  /** Ver Funcionário */
  VerFuncionario = 'VER_FUNCIONARIO',
  /** Criar Funcionário */
  CriarFuncionario = 'CRIAR_FUNCIONARIO',
  /** Editar Funcionário */
  EditarFuncionario = 'EDITAR_FUNCIONARIO',
  /** Excluir Funcionário */
  ExcluirFuncionario = 'EXCLUIR_FUNCIONARIO',
  /** Bloquear Funcionário */
  BloquearFuncionario = 'BLOQUEAR_FUNCIONARIO',
  /** Desbloquear Funcionário */
  DesbloquearFuncionario = 'DESBLOQUEAR_FUNCIONARIO',
  /** Ver Cliente */
  VerCliente = 'VER_CLIENTE',
  /** Criar Cliente */
  CriarCliente = 'CRIAR_CLIENTE',
  /** Editar Cliente */
  EditarCliente = 'EDITAR_CLIENTE',
  /** Ver Somatório de Empréstimos cliente */
  VerSomatorioEmprestimoCliente = 'VER_SOMATORIO_EMPRESTIMO_CLIENTE',
  /** Ver Pedido de Reembolso */
  VerPedidoReembolso = 'VER_PEDIDO_REEMBOLSO',
  /** Criar Pedido de Reembolso */
  CriarPedidoReembolso = 'CRIAR_PEDIDO_REEMBOLSO',
  /** Editar Pedido de Reembolso */
  EditarPedidoReembolso = 'EDITAR_PEDIDO_REEMBOLSO',
  /** Excluir Pedido de Reembolso */
  ExcluirPedidoReembolso = 'EXCLUIR_PEDIDO_REEMBOLSO',
  /** Aprovar Pedido de Reembolso */
  AprovarPedidoReembolso = 'APROVAR_PEDIDO_REEMBOLSO',
  /** Pagar Pedido de Reembolso */
  PagarPedidoReembolso = 'PAGAR_PEDIDO_REEMBOLSO',
  /** Ver Conta a Pagar */
  VerContaPagar = 'VER_CONTA_PAGAR',
  /** Criar Conta a Pagar */
  CriarContaPagar = 'CRIAR_CONTA_PAGAR',
  /** Editar Conta a Pagar */
  EditarContaPagar = 'EDITAR_CONTA_PAGAR',
  /** Editar a Loja da Conta a Pagar */
  EditarLojaContaPagar = 'EDITAR_LOJA_CONTA_PAGAR',
  /** Excluir Conta a Pagar */
  ExcluirContaPagar = 'EXCLUIR_CONTA_PAGAR',
  /** Aprovar Conta a Pagar */
  AprovarContaPagar = 'APROVAR_CONTA_PAGAR',
  /** Pagar Conta a Pagar */
  PagarContaPagar = 'PAGAR_CONTA_PAGAR',
  /** Ver Empréstimo */
  VerEmprestimo = 'VER_EMPRESTIMO',
  /** Criar Empréstimo */
  CriarEmprestimo = 'CRIAR_EMPRESTIMO',
  /** Editar Empréstimo */
  EditarEmprestimo = 'EDITAR_EMPRESTIMO',
  /** Simular Empréstimo */
  SimularEmprestimo = 'SIMULAR_EMPRESTIMO',
  /** Aprovar Empréstimo */
  AprovarEmprestimo = 'APROVAR_EMPRESTIMO',
  /** Pagar Empréstimo */
  PagarEmprestimo = 'PAGAR_EMPRESTIMO',
  /** Editar Empréstimo pago */
  EditarStatuspagoEmprestimo = 'EDITAR_STATUSPAGO_EMPRESTIMO',
  /** Ver Grupo de Permissões */
  VerGrupoPermissoes = 'VER_GRUPO_PERMISSOES',
  /** Criar Grupo de Permissões */
  CriarGrupoPermissoes = 'CRIAR_GRUPO_PERMISSOES',
  /** Editar Grupo de Permissões */
  EditarGrupoPermissoes = 'EDITAR_GRUPO_PERMISSOES',
  /** Excluir Grupo de Permissões */
  ExcluirGrupoPermissoes = 'EXCLUIR_GRUPO_PERMISSOES',
  /** Ver Máquina */
  VerMaquina = 'VER_MAQUINA',
  /** Criar Máquina */
  CriarMaquina = 'CRIAR_MAQUINA',
  /** Editar Máquina */
  EditarMaquina = 'EDITAR_MAQUINA',
  /** Habilitar Máquina */
  HabilitarMaquina = 'HABILITAR_MAQUINA',
  /** Bloquear Máquina */
  BloquearMaquina = 'BLOQUEAR_MAQUINA',
  /** Fechamento de Caixa Relatório */
  FechamentoCaixaRelatorio = 'FECHAMENTO_CAIXA_RELATORIO',
  /** Exibir somatório repasse parceiro */
  SomatorioRepasseEmprestimoRelatorio = 'SOMATORIO_REPASSE_EMPRESTIMO_RELATORIO',
  /** Pedido de Reembolso Relatório */
  PedidoReembolsoRelatorio = 'PEDIDO_REEMBOLSO_RELATORIO',
  /** Conta a Pagar Relatório */
  ContaPagarRelatorio = 'CONTA_PAGAR_RELATORIO',
  /** Lead Relatório */
  LeadRelatorio = 'LEAD_RELATORIO',
  /** Lead Relatório Marketing */
  LeadMarketingRelatorio = 'LEAD_MARKETING_RELATORIO',
  /** Cards Lead Relatório Marketing */
  CardsLeadMarketingRelatorio = 'CARDS_LEAD_MARKETING_RELATORIO',
  /** NSU Relatório */
  NsuRelatorio = 'NSU_RELATORIO',
  /** Cliente Relatório */
  ClienteRelatorio = 'CLIENTE_RELATORIO',
  /** Empréstimo Cliente Relatório */
  EmprestimoClienteRelatorio = 'EMPRESTIMO_CLIENTE_RELATORIO',
  /** Ver Meta */
  VerMeta = 'VER_META',
  /** Criar Meta */
  CriarMeta = 'CRIAR_META',
  /** Editar Meta */
  EditarMeta = 'EDITAR_META',
  /** Excluir Meta */
  ExcluirMeta = 'EXCLUIR_META',
  /** Criar Leads */
  CriarLeads = 'CRIAR_LEADS',
  /** Ver Leads */
  VerLeads = 'VER_LEADS',
  /** Editar Leads */
  EditarLeads = 'EDITAR_LEADS',
  /** Gerenciar Leads */
  GerenciarLeads = 'GERENCIAR_LEADS',
  /** Ver Tarefas */
  VerTarefas = 'VER_TAREFAS',
  /** Criar Tarefas */
  CriarTarefas = 'CRIAR_TAREFAS',
  /** Editar Tarefas */
  EditarTarefas = 'EDITAR_TAREFAS',
  /** Excluir Tarefas */
  ExcluirTarefas = 'EXCLUIR_TAREFAS',
  /** Ver Histórico e Anotações */
  VerAnotacoesHistorico = 'VER_ANOTACOES_HISTORICO',
  /** Criar Anotações */
  CriarAnotacoesHistorico = 'CRIAR_ANOTACOES_HISTORICO',
  /** Editar Anotações */
  EditarAnotacoesHistorico = 'EDITAR_ANOTACOES_HISTORICO',
  /** Excluir Anotações */
  ExcluirAnotacoesHistorico = 'EXCLUIR_ANOTACOES_HISTORICO',
  /** Ver parceiro */
  VerParceiro = 'VER_PARCEIRO',
  /** Criar parceiro */
  CriarParceiro = 'CRIAR_PARCEIRO',
  /** Editar parceiro */
  EditarParceiro = 'EDITAR_PARCEIRO',
  /** Ver tabela */
  VerTabelaParceiro = 'VER_TABELA_PARCEIRO',
  /** Criar tabela */
  CriarTabelaParceiro = 'CRIAR_TABELA_PARCEIRO',
  /** Editar tabela */
  EditarTabelaParceiro = 'EDITAR_TABELA_PARCEIRO',
  /** Dashboard parceiro */
  DashboardParceiro = 'DASHBOARD_PARCEIRO',
  /** Dashboard funcionario */
  DashboardFuncionarioParceiro = 'DASHBOARD_FUNCIONARIO_PARCEIRO',
  /** Solicitar Resgate */
  SolicitarResgateParceiro = 'SOLICITAR_RESGATE_PARCEIRO',
  /** Histórico de Resgate */
  HistoricoResgateParceiro = 'HISTORICO_RESGATE_PARCEIRO',
  /** Ver funcionários repasse */
  VerFuncionariosRepasseParceiro = 'VER_FUNCIONARIOS_REPASSE_PARCEIRO',
  /** Criar funcionários repasse */
  CriarFuncionariosRepasseParceiro = 'CRIAR_FUNCIONARIOS_REPASSE_PARCEIRO',
  /** Editar funcionários repasse */
  EditarFuncionariosRepasseParceiro = 'EDITAR_FUNCIONARIOS_REPASSE_PARCEIRO',
  /** Histórico de resgate de Funcionário */
  HistoricoResgateFuncionarioParceiro = 'HISTORICO_RESGATE_FUNCIONARIO_PARCEIRO',
  /** Ver mensagem */
  VerMensagemSms = 'VER_MENSAGEM_SMS',
  /** Criar mensagem */
  CriarMensagemSms = 'CRIAR_MENSAGEM_SMS',
  /** Ver lista sms */
  VerListaSms = 'VER_LISTA_SMS',
  /** Criar lista */
  CriarListaSms = 'CRIAR_LISTA_SMS',
  /** Editar lista */
  EditarListaSms = 'EDITAR_LISTA_SMS',
  /** Excluir lista */
  ExcluirListaSms = 'EXCLUIR_LISTA_SMS',
  /** Ver envios */
  VerEnviosSms = 'VER_ENVIOS_SMS',
  /** Criar envios */
  CriarEnviosSms = 'CRIAR_ENVIOS_SMS',
  /** Criar envios email */
  CriarEnviosEmail = 'CRIAR_ENVIOS_EMAIL',
  /** Dashboard envios */
  DashboardEnviosSms = 'DASHBOARD_ENVIOS_SMS',
  /** SMS Relatório */
  SmsRelatorio = 'SMS_RELATORIO',
  /** Comissão Funcionário Relatório */
  ComissaoFuncionarioRelatorio = 'COMISSAO_FUNCIONARIO_RELATORIO',
  /** Regramento entidade */
  RegramentoEntidadeConfiguracoes = 'REGRAMENTO_ENTIDADE_CONFIGURACOES',
  /** Criar Fornecedor */
  CriarFornecedor = 'CRIAR_FORNECEDOR',
  /** Ver Fornecedor */
  VerFornecedor = 'VER_FORNECEDOR',
  /** Editar Fornecedor */
  EditarFornecedor = 'EDITAR_FORNECEDOR'
}

export type PermissaoDeGrupoType = {
   __typename?: 'PermissaoDeGrupoType',
  id: Scalars['ID'],
  grupo: GrupoDePermissoesTypeRelay,
  descricao: PermissaoDeGrupoDescricao,
};

/** An enumeration. */
export enum PermissaoDescricao {
  /** VER BOT ZEKI */
  VerBotZeki = 'VER_BOT_ZEKI',
  /** Ver somatório de cobranças */
  VerSomatorioCobranca = 'VER_SOMATORIO_COBRANCA',
  /** Ver Cobrança */
  VerCobranca = 'VER_COBRANCA',
  /** Excluir Cobrança */
  ExcluirCobranca = 'EXCLUIR_COBRANCA',
  /** Criar Cobrança */
  CriarCobranca = 'CRIAR_COBRANCA',
  /** Editar Cobrança */
  EditarCobranca = 'EDITAR_COBRANCA',
  /** EDITAR BOT ZEKI */
  EditarBotZeki = 'EDITAR_BOT_ZEKI',
  /** Ver Entidade */
  VerEntidade = 'VER_ENTIDADE',
  /** Criar Entidade */
  CriarEntidade = 'CRIAR_ENTIDADE',
  /** Editar Entidade */
  EditarEntidade = 'EDITAR_ENTIDADE',
  /** Excluir Entidade */
  ExcluirEntidade = 'EXCLUIR_ENTIDADE',
  /** Definir Taxa de segurança da Entidade */
  DefinirTaxaSegurancaEntidade = 'DEFINIR_TAXA_SEGURANCA_ENTIDADE',
  /** Definir Taxa mínima da Entidade */
  DefinirTaxaMinimaEntidade = 'DEFINIR_TAXA_MINIMA_ENTIDADE',
  /** Definir Taxa de lucratividade da Entidade */
  DefinirTaxaLucroEntidade = 'DEFINIR_TAXA_LUCRO_ENTIDADE',
  /** Ver Funcionário */
  VerFuncionario = 'VER_FUNCIONARIO',
  /** Criar Funcionário */
  CriarFuncionario = 'CRIAR_FUNCIONARIO',
  /** Editar Funcionário */
  EditarFuncionario = 'EDITAR_FUNCIONARIO',
  /** Excluir Funcionário */
  ExcluirFuncionario = 'EXCLUIR_FUNCIONARIO',
  /** Bloquear Funcionário */
  BloquearFuncionario = 'BLOQUEAR_FUNCIONARIO',
  /** Desbloquear Funcionário */
  DesbloquearFuncionario = 'DESBLOQUEAR_FUNCIONARIO',
  /** Ver Cliente */
  VerCliente = 'VER_CLIENTE',
  /** Criar Cliente */
  CriarCliente = 'CRIAR_CLIENTE',
  /** Editar Cliente */
  EditarCliente = 'EDITAR_CLIENTE',
  /** Ver Somatório de Empréstimos cliente */
  VerSomatorioEmprestimoCliente = 'VER_SOMATORIO_EMPRESTIMO_CLIENTE',
  /** Ver Pedido de Reembolso */
  VerPedidoReembolso = 'VER_PEDIDO_REEMBOLSO',
  /** Criar Pedido de Reembolso */
  CriarPedidoReembolso = 'CRIAR_PEDIDO_REEMBOLSO',
  /** Editar Pedido de Reembolso */
  EditarPedidoReembolso = 'EDITAR_PEDIDO_REEMBOLSO',
  /** Excluir Pedido de Reembolso */
  ExcluirPedidoReembolso = 'EXCLUIR_PEDIDO_REEMBOLSO',
  /** Aprovar Pedido de Reembolso */
  AprovarPedidoReembolso = 'APROVAR_PEDIDO_REEMBOLSO',
  /** Pagar Pedido de Reembolso */
  PagarPedidoReembolso = 'PAGAR_PEDIDO_REEMBOLSO',
  /** Ver Conta a Pagar */
  VerContaPagar = 'VER_CONTA_PAGAR',
  /** Criar Conta a Pagar */
  CriarContaPagar = 'CRIAR_CONTA_PAGAR',
  /** Editar Conta a Pagar */
  EditarContaPagar = 'EDITAR_CONTA_PAGAR',
  /** Editar a Loja da Conta a Pagar */
  EditarLojaContaPagar = 'EDITAR_LOJA_CONTA_PAGAR',
  /** Excluir Conta a Pagar */
  ExcluirContaPagar = 'EXCLUIR_CONTA_PAGAR',
  /** Aprovar Conta a Pagar */
  AprovarContaPagar = 'APROVAR_CONTA_PAGAR',
  /** Pagar Conta a Pagar */
  PagarContaPagar = 'PAGAR_CONTA_PAGAR',
  /** Ver Empréstimo */
  VerEmprestimo = 'VER_EMPRESTIMO',
  /** Criar Empréstimo */
  CriarEmprestimo = 'CRIAR_EMPRESTIMO',
  /** Editar Empréstimo */
  EditarEmprestimo = 'EDITAR_EMPRESTIMO',
  /** Simular Empréstimo */
  SimularEmprestimo = 'SIMULAR_EMPRESTIMO',
  /** Aprovar Empréstimo */
  AprovarEmprestimo = 'APROVAR_EMPRESTIMO',
  /** Pagar Empréstimo */
  PagarEmprestimo = 'PAGAR_EMPRESTIMO',
  /** Editar Empréstimo pago */
  EditarStatuspagoEmprestimo = 'EDITAR_STATUSPAGO_EMPRESTIMO',
  /** Ver Grupo de Permissões */
  VerGrupoPermissoes = 'VER_GRUPO_PERMISSOES',
  /** Criar Grupo de Permissões */
  CriarGrupoPermissoes = 'CRIAR_GRUPO_PERMISSOES',
  /** Editar Grupo de Permissões */
  EditarGrupoPermissoes = 'EDITAR_GRUPO_PERMISSOES',
  /** Excluir Grupo de Permissões */
  ExcluirGrupoPermissoes = 'EXCLUIR_GRUPO_PERMISSOES',
  /** Ver Máquina */
  VerMaquina = 'VER_MAQUINA',
  /** Criar Máquina */
  CriarMaquina = 'CRIAR_MAQUINA',
  /** Editar Máquina */
  EditarMaquina = 'EDITAR_MAQUINA',
  /** Habilitar Máquina */
  HabilitarMaquina = 'HABILITAR_MAQUINA',
  /** Bloquear Máquina */
  BloquearMaquina = 'BLOQUEAR_MAQUINA',
  /** Fechamento de Caixa Relatório */
  FechamentoCaixaRelatorio = 'FECHAMENTO_CAIXA_RELATORIO',
  /** Exibir somatório repasse parceiro */
  SomatorioRepasseEmprestimoRelatorio = 'SOMATORIO_REPASSE_EMPRESTIMO_RELATORIO',
  /** Pedido de Reembolso Relatório */
  PedidoReembolsoRelatorio = 'PEDIDO_REEMBOLSO_RELATORIO',
  /** Conta a Pagar Relatório */
  ContaPagarRelatorio = 'CONTA_PAGAR_RELATORIO',
  /** Lead Relatório */
  LeadRelatorio = 'LEAD_RELATORIO',
  /** Lead Relatório Marketing */
  LeadMarketingRelatorio = 'LEAD_MARKETING_RELATORIO',
  /** Cards Lead Relatório Marketing */
  CardsLeadMarketingRelatorio = 'CARDS_LEAD_MARKETING_RELATORIO',
  /** NSU Relatório */
  NsuRelatorio = 'NSU_RELATORIO',
  /** Cliente Relatório */
  ClienteRelatorio = 'CLIENTE_RELATORIO',
  /** Empréstimo Cliente Relatório */
  EmprestimoClienteRelatorio = 'EMPRESTIMO_CLIENTE_RELATORIO',
  /** Ver Meta */
  VerMeta = 'VER_META',
  /** Criar Meta */
  CriarMeta = 'CRIAR_META',
  /** Editar Meta */
  EditarMeta = 'EDITAR_META',
  /** Excluir Meta */
  ExcluirMeta = 'EXCLUIR_META',
  /** Criar Leads */
  CriarLeads = 'CRIAR_LEADS',
  /** Ver Leads */
  VerLeads = 'VER_LEADS',
  /** Editar Leads */
  EditarLeads = 'EDITAR_LEADS',
  /** Gerenciar Leads */
  GerenciarLeads = 'GERENCIAR_LEADS',
  /** Ver Tarefas */
  VerTarefas = 'VER_TAREFAS',
  /** Criar Tarefas */
  CriarTarefas = 'CRIAR_TAREFAS',
  /** Editar Tarefas */
  EditarTarefas = 'EDITAR_TAREFAS',
  /** Excluir Tarefas */
  ExcluirTarefas = 'EXCLUIR_TAREFAS',
  /** Ver Histórico e Anotações */
  VerAnotacoesHistorico = 'VER_ANOTACOES_HISTORICO',
  /** Criar Anotações */
  CriarAnotacoesHistorico = 'CRIAR_ANOTACOES_HISTORICO',
  /** Editar Anotações */
  EditarAnotacoesHistorico = 'EDITAR_ANOTACOES_HISTORICO',
  /** Excluir Anotações */
  ExcluirAnotacoesHistorico = 'EXCLUIR_ANOTACOES_HISTORICO',
  /** Ver parceiro */
  VerParceiro = 'VER_PARCEIRO',
  /** Criar parceiro */
  CriarParceiro = 'CRIAR_PARCEIRO',
  /** Editar parceiro */
  EditarParceiro = 'EDITAR_PARCEIRO',
  /** Ver tabela */
  VerTabelaParceiro = 'VER_TABELA_PARCEIRO',
  /** Criar tabela */
  CriarTabelaParceiro = 'CRIAR_TABELA_PARCEIRO',
  /** Editar tabela */
  EditarTabelaParceiro = 'EDITAR_TABELA_PARCEIRO',
  /** Dashboard parceiro */
  DashboardParceiro = 'DASHBOARD_PARCEIRO',
  /** Dashboard funcionario */
  DashboardFuncionarioParceiro = 'DASHBOARD_FUNCIONARIO_PARCEIRO',
  /** Solicitar Resgate */
  SolicitarResgateParceiro = 'SOLICITAR_RESGATE_PARCEIRO',
  /** Histórico de Resgate */
  HistoricoResgateParceiro = 'HISTORICO_RESGATE_PARCEIRO',
  /** Ver funcionários repasse */
  VerFuncionariosRepasseParceiro = 'VER_FUNCIONARIOS_REPASSE_PARCEIRO',
  /** Criar funcionários repasse */
  CriarFuncionariosRepasseParceiro = 'CRIAR_FUNCIONARIOS_REPASSE_PARCEIRO',
  /** Editar funcionários repasse */
  EditarFuncionariosRepasseParceiro = 'EDITAR_FUNCIONARIOS_REPASSE_PARCEIRO',
  /** Histórico de resgate de Funcionário */
  HistoricoResgateFuncionarioParceiro = 'HISTORICO_RESGATE_FUNCIONARIO_PARCEIRO',
  /** Ver mensagem */
  VerMensagemSms = 'VER_MENSAGEM_SMS',
  /** Criar mensagem */
  CriarMensagemSms = 'CRIAR_MENSAGEM_SMS',
  /** Ver lista sms */
  VerListaSms = 'VER_LISTA_SMS',
  /** Criar lista */
  CriarListaSms = 'CRIAR_LISTA_SMS',
  /** Editar lista */
  EditarListaSms = 'EDITAR_LISTA_SMS',
  /** Excluir lista */
  ExcluirListaSms = 'EXCLUIR_LISTA_SMS',
  /** Ver envios */
  VerEnviosSms = 'VER_ENVIOS_SMS',
  /** Criar envios */
  CriarEnviosSms = 'CRIAR_ENVIOS_SMS',
  /** Criar envios email */
  CriarEnviosEmail = 'CRIAR_ENVIOS_EMAIL',
  /** Dashboard envios */
  DashboardEnviosSms = 'DASHBOARD_ENVIOS_SMS',
  /** SMS Relatório */
  SmsRelatorio = 'SMS_RELATORIO',
  /** Comissão Funcionário Relatório */
  ComissaoFuncionarioRelatorio = 'COMISSAO_FUNCIONARIO_RELATORIO',
  /** Regramento entidade */
  RegramentoEntidadeConfiguracoes = 'REGRAMENTO_ENTIDADE_CONFIGURACOES',
  /** Criar Fornecedor */
  CriarFornecedor = 'CRIAR_FORNECEDOR',
  /** Ver Fornecedor */
  VerFornecedor = 'VER_FORNECEDOR',
  /** Editar Fornecedor */
  EditarFornecedor = 'EDITAR_FORNECEDOR'
}

export type PermissaoGlobalType = {
   __typename?: 'PermissaoGlobalType',
  id: Scalars['ID'],
  funcionario: FuncionarioTypeRelay,
  origens: Scalars['Boolean'],
  contasAPagar: Scalars['Boolean'],
  funcionarios: Scalars['Boolean'],
  lojas: Scalars['Boolean'],
  emprestimos: Scalars['Boolean'],
};

export type PermissaoSimplificadaType = {
   __typename?: 'PermissaoSimplificadaType',
  descricaoPorGrupos?: Maybe<Array<Maybe<DescricaoPorGruposType>>>,
  grupoPorDescricoes?: Maybe<Array<Maybe<GrupoPorDescricoesType>>>,
  agrupadoPorLoja?: Maybe<Array<Maybe<AgrupadoPorLojaType>>>,
  agrupadoPorDescricao?: Maybe<Array<Maybe<AgrupadoPorDescricaoType>>>,
};

export type PermissaoType = {
   __typename?: 'PermissaoType',
  id: Scalars['ID'],
  funcionario: FuncionarioTypeRelay,
  entidade?: Maybe<EntidadeTypeRelay>,
  descricao: PermissaoDescricao,
};

export type PermissaoTypeMeta = {
   __typename?: 'PermissaoTypeMeta',
  id: Scalars['ID'],
  funcionario: FuncionarioTypeRelay,
  entidade?: Maybe<EntidadeTypeRelay>,
  descricao: PermissaoDescricao,
};

export type ProdutoDeInteresse = {
   __typename?: 'ProdutoDeInteresse',
  ok?: Maybe<Scalars['Boolean']>,
  lead?: Maybe<LeadsType>,
};

export type PropostasType = {
   __typename?: 'PropostasType',
  id: Scalars['ID'],
  lead: LeadsNodeTypeRelayProp,
  valorDesejado?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['Date']>,
  parcelas?: Maybe<Scalars['Int']>,
  valorParcela?: Maybe<Scalars['Float']>,
  valorTotal?: Maybe<Scalars['Float']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  comentario?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type PropostaTypeInput = {
  leadId: Scalars['ID'],
  valorDesejado?: Maybe<Scalars['Float']>,
  parcelas?: Maybe<Scalars['Int']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  valorParcela?: Maybe<Scalars['Float']>,
  valorTotal?: Maybe<Scalars['Float']>,
};

export type QualificaLead = {
   __typename?: 'QualificaLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type Query = {
   __typename?: 'Query',
  dashboardTipoFuncionariosRelay?: Maybe<TipoFuncionarioTypeRelayConnection>,
  dashboardCategoriasRelay?: Maybe<CategoriaTypeRelayConnection>,
  listaSubcategorias?: Maybe<SubCategoriasTypeRelayConnection>,
  listaTodasLeadsRelay?: Maybe<LeadsNodeTypeRelayPropConnection>,
  listaTarefasEntidades?: Maybe<TarefasNodeTypeRelayConnection>,
  todasMetasRelay?: Maybe<MetaNodeTypeRelayConnection>,
  buscaMeta?: Maybe<MetaType>,
  listaFuncinariosMetax?: Maybe<Array<Maybe<PermissaoTypeMeta>>>,
  metaDiaria?: Maybe<MetaDiariaTypeRelayConnection>,
  instanciasFilter?: Maybe<InstanciaZApiNodeConnection>,
  detalheInstancia?: Maybe<InstanciaZApiType>,
  cobrancaList?: Maybe<CobrancaNodeConnection>,
  cobrancaListAgregacoes?: Maybe<CobrancaAgregacoes>,
  listaUnidadesCusto?: Maybe<Array<Maybe<UnidadeCustoType>>>,
  listaContas?: Maybe<ContaNodeConnection>,
  historicoConta?: Maybe<Array<Maybe<HistoricoContaType>>>,
  listaContasAgregacoes?: Maybe<ContaAgregacao>,
  listaFornecedores?: Maybe<FornecedorNodeTypeConnection>,
  visualizarFornecedor?: Maybe<FornecedorViewNodeTypeConnection>,
  relatorioComissaoFuncionarios?: Maybe<ComissaoFuncionariosTypeRelayConnection>,
  somatorioComissaoFuncionariosAgregacoes?: Maybe<ComissaoFuncionariosAgregations>,
  relatorioComissaoFuncionariosAgregacoes?: Maybe<Array<Maybe<RelatorioComissaoFuncionariosNode>>>,
  relatorioComissaoFuncionariosExcel?: Maybe<RelatorioExcelType>,
  relatorioTelefonesSms?: Maybe<RelatorioTelefonesSmsType>,
  relatorioTelefonesSmsTxt?: Maybe<RelatorioTxtType>,
  relatorioEmprestimoCliente?: Maybe<EmprestimoClienteRelatorioTypeConnection>,
  emprestimoClienteAgregacoes?: Maybe<EmprestimoClienteAgregations>,
  relatorioEmprestimoClienteAgregacoes?: Maybe<Array<Maybe<RelatorioEmprestimoClienteNode>>>,
  relatorioEmprestimoClienteExcel?: Maybe<RelatorioExcelType>,
  relatorioEmprestimoClienteTxt?: Maybe<Scalars['String']>,
  relatorioLeadsMarketing?: Maybe<LeadsNodeTypeRelayConnection>,
  leadsMarketingAgregacoes?: Maybe<LeadMarketingAgregations>,
  relatorioLeadsMarketingExcel?: Maybe<RelatorioExcelType>,
  relatorioNsu?: Maybe<NsuRelatorioTypeConnection>,
  relatorioNsuExcel?: Maybe<RelatorioExcelType>,
  relatorioNsuAgregacoes?: Maybe<Array<Maybe<RelatorioNsuAgregacaoNode>>>,
  relatorioClientes?: Maybe<ClienteTypeRelayConnection>,
  relatorioClientesExcel?: Maybe<RelatorioExcelType>,
  relatorioClienteEmprestimos?: Maybe<RelatorioExcelType>,
  relatorioLeads?: Maybe<LeadsNodeTypeRelayConnection>,
  relatorioLeadsExcel?: Maybe<RelatorioExcelType>,
  relatorioReembolso?: Maybe<PedidoDeReembolsoNodeConnection>,
  relatorioReembolsoExcel?: Maybe<RelatorioExcelType>,
  relatorioReembolsoAgregacoes?: Maybe<Array<Maybe<RelatorioReembolsoAgregacaoNode>>>,
  relatorioContaPagar?: Maybe<ContaNodeConnection>,
  relatorioContaPagarExcel?: Maybe<RelatorioExcelType>,
  relatorioContaPagarAgregacoes?: Maybe<Array<Maybe<RelatorioContaAgregacaoNode>>>,
  relatorioEmprestimo?: Maybe<EmprestimoRelatorioTypeConnection>,
  emprestimosAgregacoes?: Maybe<EmprestimoAgregations>,
  relatorioEmprestimoAgregacoes?: Maybe<Array<Maybe<RelatorioEmprestimoAgregacaoNode>>>,
  relatorioEmprestimoExcel?: Maybe<RelatorioExcelType>,
  listaParceirosRelay?: Maybe<ParceirosNodeTypeRelayConnection>,
  pegaParceiros?: Maybe<ParceirosType>,
  pegaTabela?: Maybe<TabelaParceirosType>,
  listaTabelasParceirosRelay?: Maybe<TabelasNodeTypeRelayConnection>,
  listaTabelasParceiros?: Maybe<Array<Maybe<TabelaParceirosType>>>,
  listaLojasParceiros?: Maybe<Array<Maybe<LojaParceiroType>>>,
  listaLojasComissaoFuncionarios?: Maybe<Array<Maybe<EntidadeType>>>,
  dashParceiros?: Maybe<ComissaoNodeTypeRelayConnection>,
  dashboardComissoesFuncionarios?: Maybe<ComissaoFuncionarioNodeTypeRelayConnection>,
  historicoResgate?: Maybe<HistoricoResgateNodeTypeRelayConnection>,
  detalhesResgate?: Maybe<Array<Maybe<ResgateParceiroType>>>,
  detalhesResgateFuncionario?: Maybe<Array<Maybe<ResgateFuncionarioType>>>,
  listaBandeirasTabelas?: Maybe<Array<Maybe<BandeiraTabelaParceirosType>>>,
  listaFuncionariosRepasseRelay?: Maybe<FuncionariosRepasseNodeTypeRelayConnection>,
  listaFuncionariosComPermissaoEmRepasse?: Maybe<Array<Maybe<FuncionarioType>>>,
  listaFuncionarioRepasseDashboardFuncionarioParceiro?: Maybe<Array<Maybe<FuncionariosRepasseType>>>,
  dashFuncionarioGeral?: Maybe<EmprestimoFuncionarioNodeTypeRalayConnection>,
  calculaComissaoRepasse?: Maybe<Scalars['Decimal']>,
  detalheComissaoParceiro?: Maybe<ComissaoParceiroType>,
  detalheComissaoFuncionario?: Maybe<ComissaoFuncionariosType>,
  historicoResgateFuncionario?: Maybe<HistoricoResgateFuncionarioNodeTypeRelayConnection>,
  historicoResgateFuncionarioExcel?: Maybe<HistoricoResgateExcelType>,
  todasSolicitacoesEmprestimo?: Maybe<EmprestimoNodeConnection>,
  todasSolicitacoesNsus?: Maybe<NsuNodeConnection>,
  solicitacaoEmprestimo?: Maybe<EmprestimoNode>,
  historicoEmprestimo?: Maybe<Array<Maybe<Array<Maybe<HistoricoEmprestimoType>>>>>,
  nsusAgregacoes?: Maybe<NsusAgregations>,
  emprestimosRascunho?: Maybe<EmprestimoNodeConnection>,
  pegaTaxasEmprestimo?: Maybe<Array<Maybe<ColunaTaxasParcelasType>>>,
  historicoClienteEmprestimo?: Maybe<HistoricoEmprestimoClienteTypeConnection>,
  historicoClienteEmprestimoAgregacoes?: Maybe<EmprestimoClienteAgregations>,
  todasMaquinas?: Maybe<MaquinaNodeConnection>,
  todasMaquinasSistema?: Maybe<MaquinaNodeConnection>,
  maquina?: Maybe<MaquinaNode>,
  maquinaSistema?: Maybe<MaquinaNode>,
  listaMensagensRelay?: Maybe<MensagemSmsTypeRelayConnection>,
  listaSmsRelay?: Maybe<ListaSmsTypeRelayConnection>,
  detalhesListaSms?: Maybe<ListaSmsType>,
  listaTemplatesEmail?: Maybe<Array<Maybe<TemplatesEmailType>>>,
  listaEmailsRemetente?: Maybe<Array<Maybe<EmailRemetenteType>>>,
  listaEnviosRelay?: Maybe<EnvioSmsTypeRelayConnection>,
  listaEnviosEmailRelay?: Maybe<EnvioEmailTypeRelayConnection>,
  dashboardSms?: Maybe<Array<Maybe<DashboardSms>>>,
  dashboardEmails?: Maybe<Array<Maybe<DashboardEmail>>>,
  gruposPermissoes?: Maybe<Array<Maybe<GrupoDePermissoesType>>>,
  gruposPermissoesRelay?: Maybe<GrupoDePermissoesTypeRelayConnection>,
  gruposPermissoesUserLogado?: Maybe<GrupoDePermissoesTypeRelayConnection>,
  listaGruposFuncionarios?: Maybe<Array<Maybe<GrupoDePermissoesType>>>,
  gruposLojasPermissoesUserLogado?: Maybe<GrupoDePermissoesTypeRelayConnection>,
  grupoLojas?: Maybe<Array<Maybe<EntidadeDeGrupoType>>>,
  contaPagar?: Maybe<ContaAPagarNode>,
  contaPagarRelay?: Maybe<ContaAPagarNode>,
  todasContasPagarRelay?: Maybe<ContaAPagarNodeConnection>,
  todosClientes?: Maybe<Array<Maybe<ClienteType>>>,
  todosClientesRelay?: Maybe<ClienteTypeRelayConnection>,
  todosLeads?: Maybe<ClienteTypeRelayConnection>,
  cliente?: Maybe<Array<Maybe<ClienteType>>>,
  buscarClienteOutrasEntidades?: Maybe<ClienteType>,
  todosPedidosReembolso?: Maybe<PedidoDeReembolsoNodeConnection>,
  pedidoReembolso?: Maybe<PedidoDeReembolsoNode>,
  todosFuncionarios?: Maybe<Array<Maybe<FuncionarioType>>>,
  todosFuncionariosSistema?: Maybe<Array<Maybe<FuncionarioType>>>,
  todosFuncionariosDasLojasComPermissoes?: Maybe<Array<Maybe<FuncionarioType>>>,
  todosFuncionariosRelay?: Maybe<FuncionarioTypeRelayConnection>,
  todosFuncionariosSistemaRelay?: Maybe<FuncionarioTypeRelayConnection>,
  funcionario?: Maybe<FuncionarioType>,
  todasEntidades?: Maybe<Array<Maybe<EntidadeType>>>,
  todasEntidadesSistema?: Maybe<Array<Maybe<EntidadeType>>>,
  todasEntidadesRelay?: Maybe<EntidadeTypeRelayConnection>,
  filtroEntidadesPermissao?: Maybe<EntidadeTypeRelayConnection>,
  todasEntidadesSistemaRelay?: Maybe<EntidadeTypeRelayConnection>,
  todasEntidadesPermissao?: Maybe<EntidadeTypeRelayConnection>,
  listaTabelataxaEntidade?: Maybe<Array<Maybe<TabelaTaxaParcelasType>>>,
  entidade?: Maybe<EntidadeType>,
  contarEntidadesPorCategoria?: Maybe<ContagemDeCategoriaType>,
  listaColunas?: Maybe<Array<Maybe<ColunaTaxasParcelasType>>>,
  permissoes?: Maybe<Array<Maybe<PermissaoType>>>,
  permissoesSimplificadas?: Maybe<PermissaoSimplificadaType>,
  node?: Maybe<Node>,
  regramentoTipoLoja?: Maybe<RegramentoTiposEntidadesNodeTypeConnection>,
  regramentoLoja?: Maybe<EntidadeTypeRelayConnection>,
  criarRegramentoLoja?: Maybe<EntidadeTypeRelayConnection>,
  tiposLojas?: Maybe<TiposEntidadesNodeTypeConnection>,
  listaHistoricoRelay?: Maybe<HistoricoNodeTypeRelayConnection>,
  listaLeadsRelay?: Maybe<LeadsNodeTypeRelayConnection>,
  listarTarefasLead?: Maybe<Array<Maybe<TarefasType>>>,
  pegaLead?: Maybe<LeadsType>,
  listaFuncionarioPermissao?: Maybe<Array<Maybe<FuncionarioType>>>,
  listaFuncionarioEntidade?: Maybe<Array<Maybe<FuncionarioType>>>,
  listaEmprestimosClientes?: Maybe<Array<Maybe<EmprestimoType>>>,
  listaEntidadeFuncionario?: Maybe<Array<Maybe<EntidadeType>>>,
  listarAnotacoesLead?: Maybe<Array<Maybe<AnotacaoType>>>,
  listaOrigens?: Maybe<Array<Maybe<OrigensType>>>,
  listaVagas?: Maybe<Array<Maybe<VagaType>>>,
  listaStatusCrm?: Maybe<Array<Maybe<StatusLeadType>>>,
  listaEmprestimoClientesMobileRelay?: Maybe<EmprestimoNodeTypeRelayConnection>,
  listaCartoesClientesMobileRelay?: Maybe<CartaoDeCreditoNodeTypeRelayConnection>,
  sourceList?: Maybe<Array<Maybe<OrigensType>>>,
  listaTaxasEmprestimoMobile?: Maybe<Array<Maybe<TabelaTaxaParcelasType>>>,
  listaClientes?: Maybe<Array<Maybe<ClienteType>>>,
  listaEntidades?: Maybe<Array<Maybe<EntidadeType>>>,
  perfilClienteMobile?: Maybe<ClienteType>,
  profileUserMobile?: Maybe<LeadsType>,
  simulaEmprestimo?: Maybe<EntidadeType>,
  dadosClientes?: Maybe<ClienteType>,
  listaEmprestimoClientesMobile?: Maybe<Array<Maybe<EmprestimoType>>>,
  listaEmprestimoCartoesMobile?: Maybe<Array<Maybe<NsuType>>>,
  detalhesEmprestimoClientesMobile?: Maybe<EmprestimoType>,
  detalhesContaClientesMobile?: Maybe<ContaDeClienteType>,
  ultimoEmprestimoClientesMobile?: Maybe<EmprestimoType>,
  listaCartoesClientesMobile?: Maybe<Array<Maybe<CartaoDeCreditoType>>>,
  listaContasClientesMobile?: Maybe<Array<Maybe<ContaDeClienteType>>>,
  listaCartoesEmprestimoMobile?: Maybe<Array<Maybe<CartaoDeCreditoType>>>,
  faleConosco?: Maybe<AppConfigType>,
  slideInicial?: Maybe<Array<Maybe<AppSlideType>>>,
  politicaPrivacidade?: Maybe<Array<Maybe<AppPoliticaPrivacideType>>>,
  faqApp?: Maybe<Array<Maybe<AppFaqType>>>,
  termosUso?: Maybe<Array<Maybe<AppTermosUsoType>>>,
  aloMundo?: Maybe<Scalars['String']>,
  me?: Maybe<FuncionarioType>,
};


export type QueryDashboardTipoFuncionariosRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  nome_Iexact?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  excluido?: Maybe<Scalars['Boolean']>
};


export type QueryDashboardCategoriasRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Iexact?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  subcategorias_Nome_Iexact?: Maybe<Scalars['String']>,
  subcategorias_Nome_Icontains?: Maybe<Scalars['String']>,
  excluido?: Maybe<Scalars['Boolean']>
};


export type QueryListaSubcategoriasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['Float']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  excluido?: Maybe<Scalars['Boolean']>,
  categoria_Id_In?: Maybe<Scalars['String']>
};


export type QueryListaTodasLeadsRelayArgs = {
  entidadesIn?: Maybe<Array<Maybe<Scalars['ID']>>>,
  ddd?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type QueryListaTarefasEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  assunto_Icontains?: Maybe<Scalars['String']>,
  tipo?: Maybe<Scalars['String']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lead_Nome?: Maybe<Scalars['String']>,
  lead_Nome_Icontains?: Maybe<Scalars['String']>,
  lead_Entidade_Id?: Maybe<Scalars['ID']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  dataHora?: Maybe<Scalars['DateTime']>,
  dataHora_Gte?: Maybe<Scalars['DateTime']>,
  dataHora_Gt?: Maybe<Scalars['DateTime']>,
  dataHora_Lt?: Maybe<Scalars['DateTime']>,
  dataHora_Lte?: Maybe<Scalars['DateTime']>,
  status?: Maybe<Scalars['String']>,
  leadId?: Maybe<Scalars['ID']>
};


export type QueryTodasMetasRelayArgs = {
  lojaId?: Maybe<Scalars['ID']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  valorMeta?: Maybe<Scalars['Float']>,
  taxaMeta?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  inicioMeta?: Maybe<Scalars['String']>,
  inicioMeta_Gte?: Maybe<Scalars['Date']>,
  inicioMeta_Gt?: Maybe<Scalars['Date']>,
  inicioMeta_Lt?: Maybe<Scalars['Date']>,
  inicioMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta?: Maybe<Scalars['String']>,
  fimMeta_Lte?: Maybe<Scalars['Date']>,
  fimMeta_Lt?: Maybe<Scalars['Date']>,
  fimMeta_Gte?: Maybe<Scalars['Date']>,
  fimMeta_Gt?: Maybe<Scalars['Date']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryBuscaMetaArgs = {
  id: Scalars['ID']
};


export type QueryListaFuncinariosMetaxArgs = {
  idEntidade?: Maybe<Scalars['Int']>
};


export type QueryMetaDiariaArgs = {
  id: Scalars['ID'],
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  dataDia?: Maybe<Scalars['Date']>,
  valorPassado?: Maybe<Scalars['Float']>,
  valorAcumulada?: Maybe<Scalars['Float']>,
  taxaPassado?: Maybe<Scalars['Float']>,
  taxaAcumulada?: Maybe<Scalars['Float']>
};


export type QueryInstanciasFilterArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  entidade?: Maybe<Scalars['ID']>,
  entidade_In?: Maybe<Scalars['String']>,
  isActived?: Maybe<Scalars['Boolean']>,
  isConnected?: Maybe<Scalars['Boolean']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Float']>
};


export type QueryDetalheInstanciaArgs = {
  id: Scalars['ID']
};


export type QueryCobrancaListArgs = {
  input?: Maybe<CobrancaListInput>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryCobrancaListAgregacoesArgs = {
  input?: Maybe<CobrancaListInput>
};


export type QueryListaContasArgs = {
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryHistoricoContaArgs = {
  idConta: Scalars['ID']
};


export type QueryListaContasAgregacoesArgs = {
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryListaFornecedoresArgs = {
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_In?: Maybe<Scalars['String']>,
  nome_Istartswith?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  cpfCnpj?: Maybe<Scalars['String']>,
  cpfCnpj_Icontains?: Maybe<Scalars['String']>,
  categorias_Nome?: Maybe<Scalars['String']>,
  categorias_Nome_In?: Maybe<Scalars['String']>,
  subcategorias_Nome?: Maybe<Scalars['String']>,
  subcategorias_Nome_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  todosFornecedores?: Maybe<Scalars['Boolean']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryVisualizarFornecedorArgs = {
  id?: Maybe<Scalars['ID']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryRelatorioComissaoFuncionariosArgs = {
  tipoComissao?: Maybe<Scalars['String']>,
  funcionario?: Maybe<Scalars['ID']>,
  funcionario_In?: Maybe<Scalars['ID']>,
  valorComissao?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgate_InicioPeriodo?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Gte?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Gt?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Lt?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Lte?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Gte?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Gt?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Lt?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Lte?: Maybe<Scalars['Date']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QuerySomatorioComissaoFuncionariosAgregacoesArgs = {
  tipoComissao?: Maybe<Scalars['String']>,
  funcionario?: Maybe<Scalars['ID']>,
  funcionario_In?: Maybe<Scalars['ID']>,
  valorComissao?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgate_InicioPeriodo?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Gte?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Gt?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Lt?: Maybe<Scalars['Date']>,
  resgate_InicioPeriodo_Lte?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Gte?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Gt?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Lt?: Maybe<Scalars['Date']>,
  resgate_FimPeriodo_Lte?: Maybe<Scalars['Date']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryRelatorioComissaoFuncionariosAgregacoesArgs = {
  input?: Maybe<ComissaoFuncionariosRelatorioInput>
};


export type QueryRelatorioComissaoFuncionariosExcelArgs = {
  input?: Maybe<ComissaoFuncionariosRelatorioInput>
};


export type QueryRelatorioTelefonesSmsArgs = {
  clienteFiltro?: Maybe<ClienteRelatorioInput>,
  leadsFiltro?: Maybe<LeadRelatorioInput>,
  clienteOuLeadsFiltro?: Maybe<Scalars['String']>
};


export type QueryRelatorioTelefonesSmsTxtArgs = {
  clienteFiltro?: Maybe<ClienteRelatorioInput>,
  leadsFiltro?: Maybe<LeadRelatorioInput>,
  clienteOuLeadsFiltro?: Maybe<Scalars['String']>
};


export type QueryRelatorioEmprestimoClienteArgs = {
  input?: Maybe<EmprestimoClienteRelatorioInput>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEmprestimoClienteAgregacoesArgs = {
  input?: Maybe<EmprestimoClienteRelatorioInput>
};


export type QueryRelatorioEmprestimoClienteAgregacoesArgs = {
  input?: Maybe<EmprestimoClienteRelatorioInput>
};


export type QueryRelatorioEmprestimoClienteExcelArgs = {
  input?: Maybe<EmprestimoClienteRelatorioInput>
};


export type QueryRelatorioEmprestimoClienteTxtArgs = {
  input?: Maybe<EmprestimoClienteRelatorioInput>
};


export type QueryRelatorioLeadsMarketingArgs = {
  nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  tarefas_Funcionario_Id?: Maybe<Scalars['ID']>,
  pausado?: Maybe<Scalars['Boolean']>,
  email_Isnull?: Maybe<Scalars['Boolean']>,
  telefone_Isnull?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  qualificacao?: Maybe<Scalars['Int']>,
  clienteId?: Maybe<Scalars['ID']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  statusLead_Index?: Maybe<Scalars['Int']>,
  statusLead_Nome?: Maybe<Scalars['String']>,
  tarefas_Tipo_Icontains?: Maybe<Scalars['String']>,
  tarefas_Status?: Maybe<Scalars['String']>,
  tarefas_Hora?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gte?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lte?: Maybe<Scalars['Time']>,
  tarefas_Data?: Maybe<Scalars['Date']>,
  tarefas_Data_Gte?: Maybe<Scalars['Date']>,
  tarefas_Data_Gt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lte?: Maybe<Scalars['Date']>,
  tarefas_DataHora?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gte?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  produtoInteresse_Icontains?: Maybe<Scalars['String']>,
  app?: Maybe<Scalars['Boolean']>,
  statusLeadId?: Maybe<Scalars['ID']>,
  tarefas_Id?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryLeadsMarketingAgregacoesArgs = {
  nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  tarefas_Funcionario_Id?: Maybe<Scalars['ID']>,
  pausado?: Maybe<Scalars['Boolean']>,
  email_Isnull?: Maybe<Scalars['Boolean']>,
  telefone_Isnull?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  qualificacao?: Maybe<Scalars['Int']>,
  clienteId?: Maybe<Scalars['ID']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  statusLead_Index?: Maybe<Scalars['Int']>,
  statusLead_Nome?: Maybe<Scalars['String']>,
  tarefas_Tipo_Icontains?: Maybe<Scalars['String']>,
  tarefas_Status?: Maybe<Scalars['String']>,
  tarefas_Hora?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gte?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lte?: Maybe<Scalars['Time']>,
  tarefas_Data?: Maybe<Scalars['Date']>,
  tarefas_Data_Gte?: Maybe<Scalars['Date']>,
  tarefas_Data_Gt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lte?: Maybe<Scalars['Date']>,
  tarefas_DataHora?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gte?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  produtoInteresse_Icontains?: Maybe<Scalars['String']>,
  app?: Maybe<Scalars['Boolean']>,
  statusLeadId?: Maybe<Scalars['ID']>,
  tarefas_Id?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type QueryRelatorioLeadsMarketingExcelArgs = {
  input?: Maybe<LeadRelatorioInput>
};


export type QueryRelatorioNsuArgs = {
  id?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId_In?: Maybe<Scalars['ID']>,
  emprestimo_Id?: Maybe<Scalars['Float']>,
  nsuCv?: Maybe<Scalars['String']>,
  nsuCv_In?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  valorNsuCv_In?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado_In?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada_In?: Maybe<Scalars['Float']>,
  emprestimo_Status?: Maybe<Scalars['String']>,
  emprestimo_Status_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryRelatorioNsuExcelArgs = {
  input?: Maybe<NsuRelatorioInput>
};


export type QueryRelatorioNsuAgregacoesArgs = {
  input?: Maybe<NsuRelatorioInput>
};


export type QueryRelatorioClientesArgs = {
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  entidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  lead?: Maybe<Scalars['Boolean']>,
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  nascimento_Lte?: Maybe<Scalars['Date']>,
  nascimento_Gte?: Maybe<Scalars['Date']>,
  nascimento_Month_In?: Maybe<Scalars['String']>,
  nascimento_Day_In?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  sexo_In?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  telefone_Numero?: Maybe<Scalars['String']>,
  telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  telefone_Ddd?: Maybe<Scalars['String']>,
  telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  uf_Icontains?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  bairro_Icontains?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  profissao_Icontains?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryRelatorioClientesExcelArgs = {
  input?: Maybe<ClienteRelatorioInput>
};


export type QueryRelatorioClienteEmprestimosArgs = {
  input?: Maybe<ClienteRelatorioInput>
};


export type QueryRelatorioLeadsArgs = {
  nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  tarefas_Funcionario_Id?: Maybe<Scalars['ID']>,
  pausado?: Maybe<Scalars['Boolean']>,
  email_Isnull?: Maybe<Scalars['Boolean']>,
  telefone_Isnull?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  qualificacao?: Maybe<Scalars['Int']>,
  clienteId?: Maybe<Scalars['ID']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  statusLead_Index?: Maybe<Scalars['Int']>,
  statusLead_Nome?: Maybe<Scalars['String']>,
  tarefas_Tipo_Icontains?: Maybe<Scalars['String']>,
  tarefas_Status?: Maybe<Scalars['String']>,
  tarefas_Hora?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gte?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lte?: Maybe<Scalars['Time']>,
  tarefas_Data?: Maybe<Scalars['Date']>,
  tarefas_Data_Gte?: Maybe<Scalars['Date']>,
  tarefas_Data_Gt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lte?: Maybe<Scalars['Date']>,
  tarefas_DataHora?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gte?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  produtoInteresse_Icontains?: Maybe<Scalars['String']>,
  app?: Maybe<Scalars['Boolean']>,
  statusLeadId?: Maybe<Scalars['ID']>,
  tarefas_Id?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryRelatorioLeadsExcelArgs = {
  input?: Maybe<LeadRelatorioInput>
};


export type QueryRelatorioReembolsoArgs = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryRelatorioReembolsoExcelArgs = {
  input?: Maybe<ReembolsoRelatorioInput>
};


export type QueryRelatorioReembolsoAgregacoesArgs = {
  input?: Maybe<ReembolsoRelatorioInput>
};


export type QueryRelatorioContaPagarArgs = {
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryRelatorioContaPagarExcelArgs = {
  input?: Maybe<ContaRelatorioInput>
};


export type QueryRelatorioContaPagarAgregacoesArgs = {
  input?: Maybe<ContaRelatorioInput>
};


export type QueryRelatorioEmprestimoArgs = {
  input?: Maybe<EmprestimoRelatorioInput>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEmprestimosAgregacoesArgs = {
  input?: Maybe<EmprestimoRelatorioInput>
};


export type QueryRelatorioEmprestimoAgregacoesArgs = {
  input?: Maybe<EmprestimoRelatorioInput>
};


export type QueryRelatorioEmprestimoExcelArgs = {
  input?: Maybe<EmprestimoRelatorioInput>
};


export type QueryListaParceirosRelayArgs = {
  lojasRepasseIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  tabelasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  permissoesRequeridas?: Maybe<Array<Maybe<GrapheneListaPermissionsEnum>>>,
  filtrarLojasComParceirosEmComun?: Maybe<Scalars['Boolean']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type QueryPegaParceirosArgs = {
  id: Scalars['ID']
};


export type QueryPegaTabelaArgs = {
  id: Scalars['ID']
};


export type QueryListaTabelasParceirosRelayArgs = {
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  gruposIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  parceiroIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  categoriaTabela?: Maybe<Scalars['String']>,
  categoriaTabela_Icontains?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  parceiros_Nome_Icontains?: Maybe<Scalars['String']>,
  parceiros_Id?: Maybe<Scalars['ID']>,
  parceiros_Id_In?: Maybe<Scalars['ID']>,
  parceiros_Lojaparceiro_Loja_Id?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryListaTabelasParceirosArgs = {
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  gruposIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  parceiroIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>
};


export type QueryListaLojasParceirosArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryListaLojasComissaoFuncionariosArgs = {
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryDashParceirosArgs = {
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  parceiroIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Nsu_Parcelas?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_In?: Maybe<Scalars['String']>,
  emprestimo_Nsu_Parcelas_Iexact?: Maybe<Scalars['String']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  dataRepasse?: Maybe<Scalars['Date']>,
  dataRepasse_Gte?: Maybe<Scalars['Date']>,
  dataRepasse_Gt?: Maybe<Scalars['Date']>,
  dataRepasse_Lt?: Maybe<Scalars['Date']>,
  dataRepasse_Lte?: Maybe<Scalars['Date']>,
  emprestimo_CriadoEm?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gte?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Gt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lt?: Maybe<Scalars['DateTime']>,
  emprestimo_CriadoEm_Lte?: Maybe<Scalars['DateTime']>,
  emprestimo_Data?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gte?: Maybe<Scalars['Date']>,
  emprestimo_Data_Gt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lt?: Maybe<Scalars['Date']>,
  emprestimo_Data_Lte?: Maybe<Scalars['Date']>,
  valorComissao?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryDashboardComissoesFuncionariosArgs = {
  funcionariosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  emprestimo_Id?: Maybe<Scalars['ID']>,
  emprestimo_Id_In?: Maybe<Scalars['String']>,
  emprestimo_Funcionario_Id?: Maybe<Scalars['ID']>,
  emprestimo_Parcelas?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  tipoComissao?: Maybe<Scalars['String']>,
  valorComissao?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryHistoricoResgateArgs = {
  emprestimosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  valorResgate?: Maybe<Scalars['Float']>,
  valorResgate_Icontains?: Maybe<Scalars['Float']>,
  parceiroId?: Maybe<Scalars['ID']>,
  parceiroId_In?: Maybe<Scalars['String']>,
  conta_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryDetalhesResgateArgs = {
  id: Scalars['ID']
};


export type QueryDetalhesResgateFuncionarioArgs = {
  idResgate: Scalars['ID']
};


export type QueryListaFuncionariosRepasseRelayArgs = {
  funcionariosRepasse?: Maybe<Array<Maybe<Scalars['ID']>>>,
  lojasIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryDashFuncionarioGeralArgs = {
  tipo?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  parcelas?: Maybe<Scalars['String']>,
  comissaofuncionarios_ValorComissao?: Maybe<Scalars['Float']>,
  comissaofuncionarios_TipoComissao?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  comissaofuncionarios_Id?: Maybe<Scalars['ID']>
};


export type QueryCalculaComissaoRepasseArgs = {
  entidadeId: Scalars['ID'],
  valorDesejado: Scalars['Decimal'],
  parcelas: Scalars['String'],
  taxaAplicada: Scalars['Decimal']
};


export type QueryDetalheComissaoParceiroArgs = {
  id: Scalars['ID']
};


export type QueryDetalheComissaoFuncionarioArgs = {
  id: Scalars['ID']
};


export type QueryHistoricoResgateFuncionarioArgs = {
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  valorResgate?: Maybe<Scalars['Float']>,
  valorResgate_Icontains?: Maybe<Scalars['Float']>,
  comissaofuncionarios_TipoComissao?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  respassefuncionario_FuncionarioId?: Maybe<Scalars['ID']>,
  respassefuncionario_FuncionarioId_In?: Maybe<Scalars['String']>,
  respassefuncionario_LojaId?: Maybe<Scalars['ID']>,
  respassefuncionario_LojaId_In?: Maybe<Scalars['String']>
};


export type QueryHistoricoResgateFuncionarioExcelArgs = {
  input?: Maybe<ResgateFuncionarioRelatorioInput>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryTodasSolicitacoesEmprestimoArgs = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Cpf?: Maybe<Scalars['String']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  nsu_NsuCv?: Maybe<Scalars['String']>,
  nsu_NsuCv_Icontains?: Maybe<Scalars['String']>,
  nsu_NsuCv_In?: Maybe<Scalars['String']>,
  valorTotal?: Maybe<Scalars['Float']>,
  valorTotal_In?: Maybe<Scalars['Float']>,
  valorTotal_Gte?: Maybe<Scalars['Float']>,
  valorTotal_Lte?: Maybe<Scalars['Float']>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorDesejado_In?: Maybe<Scalars['Float']>,
  valorDesejado_Gte?: Maybe<Scalars['Float']>,
  valorDesejado_Lte?: Maybe<Scalars['Float']>,
  nsu_TitularDivergente_Cpf?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_In?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  bandeiraCartao_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  parcelas_Lt?: Maybe<Scalars['String']>,
  parcelas_Gt?: Maybe<Scalars['String']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  entidade_Id?: Maybe<Scalars['ID']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  origem_In?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryTodasSolicitacoesNsusArgs = {
  id?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId_In?: Maybe<Scalars['ID']>,
  emprestimo_Id?: Maybe<Scalars['Float']>,
  nsuCv?: Maybe<Scalars['String']>,
  nsuCv_In?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  valorNsuCv_In?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado_In?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada_In?: Maybe<Scalars['Float']>,
  emprestimo_Status?: Maybe<Scalars['String']>,
  emprestimo_Status_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QuerySolicitacaoEmprestimoArgs = {
  id: Scalars['ID']
};


export type QueryHistoricoEmprestimoArgs = {
  id?: Maybe<Scalars['Int']>
};


export type QueryNsusAgregacoesArgs = {
  id?: Maybe<Scalars['Float']>,
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_Entidade_In?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeId_In?: Maybe<Scalars['ID']>,
  emprestimo_Id?: Maybe<Scalars['Float']>,
  nsuCv?: Maybe<Scalars['String']>,
  nsuCv_In?: Maybe<Scalars['String']>,
  valorNsuCv?: Maybe<Scalars['Float']>,
  valorNsuCv_In?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado?: Maybe<Scalars['Float']>,
  emprestimo_ValorDesejado_In?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada?: Maybe<Scalars['Float']>,
  emprestimo_TaxaAplicada_In?: Maybe<Scalars['Float']>,
  emprestimo_Status?: Maybe<Scalars['String']>,
  emprestimo_Status_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryEmprestimosRascunhoArgs = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Cpf?: Maybe<Scalars['String']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  nsu_NsuCv?: Maybe<Scalars['String']>,
  nsu_NsuCv_Icontains?: Maybe<Scalars['String']>,
  nsu_NsuCv_In?: Maybe<Scalars['String']>,
  valorTotal?: Maybe<Scalars['Float']>,
  valorTotal_In?: Maybe<Scalars['Float']>,
  valorTotal_Gte?: Maybe<Scalars['Float']>,
  valorTotal_Lte?: Maybe<Scalars['Float']>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorDesejado_In?: Maybe<Scalars['Float']>,
  valorDesejado_Gte?: Maybe<Scalars['Float']>,
  valorDesejado_Lte?: Maybe<Scalars['Float']>,
  nsu_TitularDivergente_Cpf?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_In?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  bandeiraCartao_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  parcelas_Lt?: Maybe<Scalars['String']>,
  parcelas_Gt?: Maybe<Scalars['String']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  entidade_Id?: Maybe<Scalars['ID']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  origem_In?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryPegaTaxasEmprestimoArgs = {
  idLoja: Scalars['ID'],
  valorDesejado: Scalars['Float'],
  parcela: Scalars['String']
};


export type QueryHistoricoClienteEmprestimoArgs = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  entidade_EntidadeGrupo_Grupo_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionarioId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Cpf?: Maybe<Scalars['String']>,
  cliente_Nome?: Maybe<Scalars['String']>,
  cliente_Nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero?: Maybe<Scalars['String']>,
  cliente_Telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd?: Maybe<Scalars['String']>,
  cliente_Telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem?: Maybe<Scalars['String']>,
  cliente_Origem_Icontains?: Maybe<Scalars['String']>,
  cliente_Origem_In?: Maybe<Scalars['String']>,
  nsu_NsuCv?: Maybe<Scalars['String']>,
  nsu_NsuCv_Icontains?: Maybe<Scalars['String']>,
  nsu_NsuCv_In?: Maybe<Scalars['String']>,
  valorTotal?: Maybe<Scalars['Float']>,
  valorTotal_In?: Maybe<Scalars['Float']>,
  valorTotal_Gte?: Maybe<Scalars['Float']>,
  valorTotal_Lte?: Maybe<Scalars['Float']>,
  valorDesejado?: Maybe<Scalars['Float']>,
  valorDesejado_In?: Maybe<Scalars['Float']>,
  valorDesejado_Gte?: Maybe<Scalars['Float']>,
  valorDesejado_Lte?: Maybe<Scalars['Float']>,
  nsu_TitularDivergente_Cpf?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Cpf_In?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_Icontains?: Maybe<Scalars['String']>,
  nsu_TitularDivergente_Nome_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  bandeiraCartao?: Maybe<Scalars['String']>,
  bandeiraCartao_In?: Maybe<Scalars['String']>,
  parcelas?: Maybe<Scalars['String']>,
  parcelas_In?: Maybe<Scalars['String']>,
  parcelas_Lt?: Maybe<Scalars['String']>,
  parcelas_Gt?: Maybe<Scalars['String']>,
  taxaAplicada?: Maybe<Scalars['Float']>,
  entidade_Id?: Maybe<Scalars['ID']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  lucroBruto?: Maybe<Scalars['Float']>,
  origem_In?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryHistoricoClienteEmprestimoAgregacoesArgs = {
  input?: Maybe<HistoricoEmprestimoClienteInput>
};


export type QueryTodasMaquinasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  cnpj?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  etiquetagem?: Maybe<Scalars['String']>,
  etiquetagem_Icontains?: Maybe<Scalars['String']>,
  numeroTerminal?: Maybe<Scalars['String']>,
  numeroTerminal_Icontains?: Maybe<Scalars['String']>,
  serialNumber?: Maybe<Scalars['String']>,
  serialNumber_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTodasMaquinasSistemaArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  cnpj?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  etiquetagem?: Maybe<Scalars['String']>,
  etiquetagem_Icontains?: Maybe<Scalars['String']>,
  numeroTerminal?: Maybe<Scalars['String']>,
  numeroTerminal_Icontains?: Maybe<Scalars['String']>,
  serialNumber?: Maybe<Scalars['String']>,
  serialNumber_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryMaquinaArgs = {
  id: Scalars['ID']
};


export type QueryMaquinaSistemaArgs = {
  id: Scalars['ID']
};


export type QueryListaMensagensRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryListaSmsRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  entidades_Id?: Maybe<Scalars['ID']>,
  entidades_Id_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryDetalhesListaSmsArgs = {
  id: Scalars['ID']
};


export type QueryListaEnviosRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  apiSmsFlag?: Maybe<Scalars['Boolean']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryListaEnviosEmailRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  listas_Id?: Maybe<Scalars['ID']>,
  listas_Id_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  hora?: Maybe<Scalars['Time']>,
  hora_Gte?: Maybe<Scalars['Time']>,
  hora_Gt?: Maybe<Scalars['Time']>,
  hora_Lt?: Maybe<Scalars['Time']>,
  hora_Lte?: Maybe<Scalars['Time']>,
  data?: Maybe<Scalars['Date']>,
  data_Gte?: Maybe<Scalars['Date']>,
  data_Gt?: Maybe<Scalars['Date']>,
  data_Lt?: Maybe<Scalars['Date']>,
  data_Lte?: Maybe<Scalars['Date']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>
};


export type QueryDashboardSmsArgs = {
  dataIni?: Maybe<Scalars['Date']>,
  dataFim?: Maybe<Scalars['Date']>
};


export type QueryDashboardEmailsArgs = {
  dataIni?: Maybe<Scalars['Date']>,
  dataFim?: Maybe<Scalars['Date']>
};


export type QueryGruposPermissoesArgs = {
  id?: Maybe<Scalars['ID']>,
  nome?: Maybe<Scalars['String']>
};


export type QueryGruposPermissoesRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryGruposPermissoesUserLogadoArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryListaGruposFuncionariosArgs = {
  funcionarioIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryGruposLojasPermissoesUserLogadoArgs = {
  permissoesFuncionario?: Maybe<Array<Maybe<Scalars['String']>>>,
  permissoesGrupo?: Maybe<Array<Maybe<Scalars['String']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryContaPagarArgs = {
  pk: Scalars['ID']
};


export type QueryContaPagarRelayArgs = {
  id: Scalars['ID']
};


export type QueryTodasContasPagarRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  destinatario?: Maybe<Scalars['String']>,
  destinatario_Icontains?: Maybe<Scalars['String']>,
  destinatario_Istartswith?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  recorrencia?: Maybe<Scalars['String']>,
  recorrencia_In?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gt?: Maybe<Scalars['DateTime']>,
  dataVencimento_Gte?: Maybe<Scalars['DateTime']>,
  dataVencimento_Lte?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gte?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lte?: Maybe<Scalars['DateTime']>,
  transferenciaConta?: Maybe<Scalars['String']>,
  transferenciaConta_Icontains?: Maybe<Scalars['String']>,
  formaPagamento?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTodosClientesArgs = {
  idEntidade?: Maybe<Scalars['ID']>
};


export type QueryTodosClientesRelayArgs = {
  entidadesIn?: Maybe<Array<Maybe<Scalars['ID']>>>,
  semPermissoes?: Maybe<Scalars['Boolean']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  entidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  lead?: Maybe<Scalars['Boolean']>,
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  nascimento_Lte?: Maybe<Scalars['Date']>,
  nascimento_Gte?: Maybe<Scalars['Date']>,
  nascimento_Month_In?: Maybe<Scalars['String']>,
  nascimento_Day_In?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  sexo_In?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  telefone_Numero?: Maybe<Scalars['String']>,
  telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  telefone_Ddd?: Maybe<Scalars['String']>,
  telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  uf_Icontains?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  bairro_Icontains?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  profissao_Icontains?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTodosLeadsArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  entidades?: Maybe<Array<Maybe<Scalars['ID']>>>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  lead?: Maybe<Scalars['Boolean']>,
  cpf?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  nascimento?: Maybe<Scalars['Date']>,
  nascimento_Lte?: Maybe<Scalars['Date']>,
  nascimento_Gte?: Maybe<Scalars['Date']>,
  nascimento_Month_In?: Maybe<Scalars['String']>,
  nascimento_Day_In?: Maybe<Scalars['String']>,
  sexo?: Maybe<Scalars['String']>,
  sexo_In?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  telefone_Numero?: Maybe<Scalars['String']>,
  telefone_Numero_Icontains?: Maybe<Scalars['String']>,
  telefone_Ddd?: Maybe<Scalars['String']>,
  telefone_Ddd_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  uf_Icontains?: Maybe<Scalars['String']>,
  bairro?: Maybe<Scalars['String']>,
  bairro_Icontains?: Maybe<Scalars['String']>,
  profissao?: Maybe<Scalars['String']>,
  profissao_Icontains?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryClienteArgs = {
  id?: Maybe<Scalars['Int']>,
  cpf?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>
};


export type QueryBuscarClienteOutrasEntidadesArgs = {
  id?: Maybe<Scalars['Int']>,
  cpf?: Maybe<Scalars['String']>
};


export type QueryTodosPedidosReembolsoArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryPedidoReembolsoArgs = {
  id: Scalars['ID']
};


export type QueryTodosFuncionariosArgs = {
  idEntidade?: Maybe<Scalars['Int']>
};


export type QueryTodosFuncionariosSistemaArgs = {
  idEntidade?: Maybe<Scalars['Int']>
};


export type QueryTodosFuncionariosDasLojasComPermissoesArgs = {
  entidadesIds?: Maybe<Array<Maybe<Scalars['Int']>>>,
  permissoes?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type QueryTodosFuncionariosRelayArgs = {
  entidadeId?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Array<Maybe<Scalars['String']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  categoria?: Maybe<Scalars['String']>,
  tipoFuncionario_Nome?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  user_IsActive?: Maybe<Scalars['Boolean']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTodosFuncionariosSistemaRelayArgs = {
  entidadeId?: Maybe<Scalars['ID']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  categoria?: Maybe<Scalars['String']>,
  tipoFuncionario_Nome?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  user_IsActive?: Maybe<Scalars['Boolean']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryFuncionarioArgs = {
  id?: Maybe<Scalars['Int']>,
  cpf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>
};


export type QueryTodasEntidadesRelayArgs = {
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryFiltroEntidadesPermissaoArgs = {
  permissoes?: Maybe<Array<Maybe<Scalars['String']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTodasEntidadesSistemaRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTodasEntidadesPermissaoArgs = {
  permissoes: Array<Maybe<Scalars['String']>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryListaTabelataxaEntidadeArgs = {
  idEntidade?: Maybe<Scalars['ID']>
};


export type QueryEntidadeArgs = {
  id?: Maybe<Scalars['Int']>,
  nome?: Maybe<Scalars['String']>
};


export type QueryContarEntidadesPorCategoriaArgs = {
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryListaColunasArgs = {
  idEntidade?: Maybe<Scalars['ID']>
};


export type QueryPermissoesArgs = {
  idEntidade?: Maybe<Scalars['Int']>,
  idFuncionario?: Maybe<Scalars['Int']>
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};


export type QueryRegramentoTipoLojaArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  tipoLoja?: Maybe<Scalars['String']>,
  tipoLoja_Iexact?: Maybe<Scalars['String']>,
  tipoLoja_In?: Maybe<Scalars['String']>,
  tipoLoja_Icontains?: Maybe<Scalars['String']>,
  tipoLoja_Istartswith?: Maybe<Scalars['String']>,
  valorAcimaTipo?: Maybe<Scalars['Float']>,
  valorAcimaTipo_In?: Maybe<Scalars['Float']>,
  valorAcimaTipo_Gt?: Maybe<Scalars['Float']>,
  default?: Maybe<Scalars['Boolean']>,
  excluido?: Maybe<Scalars['Boolean']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryRegramentoLojaArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryCriarRegramentoLojaArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryTiposLojasArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  tipoLoja?: Maybe<Scalars['String']>,
  tipoLoja_Iexact?: Maybe<Scalars['String']>,
  tipoLoja_In?: Maybe<Scalars['String']>,
  tipoLoja_Icontains?: Maybe<Scalars['String']>,
  tipoLoja_Istartswith?: Maybe<Scalars['String']>,
  valorAcimaTipo?: Maybe<Scalars['Float']>,
  valorAcimaTipo_In?: Maybe<Scalars['Float']>,
  valorAcimaTipo_Gt?: Maybe<Scalars['Float']>,
  default?: Maybe<Scalars['Boolean']>,
  excluido?: Maybe<Scalars['Boolean']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryListaHistoricoRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  leadId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>
};


export type QueryListaLeadsRelayArgs = {
  lojas?: Maybe<Array<Maybe<Scalars['ID']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  tarefas_Funcionario_Id?: Maybe<Scalars['ID']>,
  pausado?: Maybe<Scalars['Boolean']>,
  email_Isnull?: Maybe<Scalars['Boolean']>,
  telefone_Isnull?: Maybe<Scalars['Boolean']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  qualificacao?: Maybe<Scalars['Int']>,
  clienteId?: Maybe<Scalars['ID']>,
  cliente_Sexo?: Maybe<Scalars['String']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  possuiCartao?: Maybe<Scalars['Boolean']>,
  statusLead_Index?: Maybe<Scalars['Int']>,
  statusLead_Nome?: Maybe<Scalars['String']>,
  tarefas_Tipo_Icontains?: Maybe<Scalars['String']>,
  tarefas_Status?: Maybe<Scalars['String']>,
  tarefas_Hora?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gte?: Maybe<Scalars['Time']>,
  tarefas_Hora_Gt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lt?: Maybe<Scalars['Time']>,
  tarefas_Hora_Lte?: Maybe<Scalars['Time']>,
  tarefas_Data?: Maybe<Scalars['Date']>,
  tarefas_Data_Gte?: Maybe<Scalars['Date']>,
  tarefas_Data_Gt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lt?: Maybe<Scalars['Date']>,
  tarefas_Data_Lte?: Maybe<Scalars['Date']>,
  tarefas_DataHora?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gte?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Gt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lt?: Maybe<Scalars['DateTime']>,
  tarefas_DataHora_Lte?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  produtoInteresse_Icontains?: Maybe<Scalars['String']>,
  app?: Maybe<Scalars['Boolean']>,
  statusLeadId?: Maybe<Scalars['ID']>,
  tarefas_Id?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};


export type QueryListarTarefasLeadArgs = {
  idLead: Scalars['ID']
};


export type QueryPegaLeadArgs = {
  id: Scalars['ID']
};


export type QueryListaFuncionarioPermissaoArgs = {
  permissao?: Maybe<Scalars['String']>
};


export type QueryListaEmprestimosClientesArgs = {
  idCliente: Scalars['ID']
};


export type QueryListaEntidadeFuncionarioArgs = {
  permissoesRequeridas?: Maybe<Array<Maybe<ListaEntidadeFuncionarioPermissionsEnum>>>
};


export type QueryListarAnotacoesLeadArgs = {
  idLead: Scalars['ID']
};


export type QueryListaOrigensArgs = {
  id?: Maybe<Scalars['Float']>,
  origem?: Maybe<Scalars['String']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  excluido?: Maybe<Scalars['Boolean']>
};


export type QueryListaEmprestimoClientesMobileRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  clienteId?: Maybe<Scalars['ID']>
};


export type QueryListaCartoesClientesMobileRelayArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['ID']>
};


export type QueryListaEntidadesArgs = {
  latitude: Scalars['Float'],
  longitude: Scalars['Float']
};


export type QueryPerfilClienteMobileArgs = {
  id: Scalars['Int']
};


export type QuerySimulaEmprestimoArgs = {
  idCliente: Scalars['ID']
};


export type QueryDadosClientesArgs = {
  cpf: Scalars['String'],
  nascimento: Scalars['Date']
};


export type QueryListaEmprestimoClientesMobileArgs = {
  id: Scalars['ID']
};


export type QueryListaEmprestimoCartoesMobileArgs = {
  id: Scalars['ID']
};


export type QueryDetalhesEmprestimoClientesMobileArgs = {
  idEmprestimo: Scalars['ID']
};


export type QueryDetalhesContaClientesMobileArgs = {
  idConta: Scalars['ID']
};


export type QueryUltimoEmprestimoClientesMobileArgs = {
  idCliente: Scalars['ID']
};


export type QueryListaCartoesClientesMobileArgs = {
  idCliente: Scalars['ID']
};


export type QueryListaContasClientesMobileArgs = {
  idCliente: Scalars['ID']
};


export type QueryListaCartoesEmprestimoMobileArgs = {
  emprestimoId: Scalars['ID']
};


export type QueryAloMundoArgs = {
  nome?: Maybe<Scalars['String']>
};

export type ReembolsoRelatorioInput = {
  id?: Maybe<Scalars['Float']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  tipoConta?: Maybe<Scalars['String']>,
  tipoConta_In?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  categoria_Icontains?: Maybe<Scalars['String']>,
  categoria_In?: Maybe<Scalars['String']>,
  conta?: Maybe<Scalars['String']>,
  conta_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Icontains?: Maybe<Scalars['Float']>,
  data?: Maybe<Scalars['DateTime']>,
  data_Lt?: Maybe<Scalars['DateTime']>,
  data_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  dataPagamento?: Maybe<Scalars['DateTime']>,
  dataPagamento_Lt?: Maybe<Scalars['DateTime']>,
  dataPagamento_Gt?: Maybe<Scalars['DateTime']>,
  /** Ordenando */
  orderBy?: Maybe<Scalars['String']>,
};

export type ReferenciaContaType = {
   __typename?: 'ReferenciaContaType',
  id: Scalars['ID'],
  entidades: EntidadeTypeRelayConnection,
  unidadeCusto?: Maybe<GrupoDePermissoesTypeRelay>,
  recorrencia: ReferenciaRecorrencia,
  dataFinalRecorrenciaTime?: Maybe<Scalars['DateTime']>,
  dataFinalRecorrencia?: Maybe<Scalars['Date']>,
  contaSet: ContaNodeConnection,
};


export type ReferenciaContaTypeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};


export type ReferenciaContaTypeContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

/** An enumeration. */
export enum ReferenciaRecorrencia {
  /** Unica */
  Unica = 'UNICA',
  /** Diária */
  Diaria = 'DIARIA',
  /** Semanal */
  Semanal = 'SEMANAL',
  /** Quinzenal */
  Quinzenal = 'QUINZENAL',
  /** Mensal */
  Mensal = 'MENSAL',
  /** Bimestral */
  Bimestral = 'BIMESTRAL',
  /** Trimestral */
  Trimestral = 'TRIMESTRAL',
  /** Semestral */
  Semestral = 'SEMESTRAL',
  /** Anual */
  Anual = 'ANUAL'
}

export type Refresh = {
   __typename?: 'Refresh',
  payload: Scalars['GenericScalar'],
  refreshExpiresIn: Scalars['Int'],
  token: Scalars['String'],
};

export type RegramentoTiposEntidadesNodeType = Node & {
   __typename?: 'RegramentoTiposEntidadesNodeType',
  /** The ID of the object. */
  id: Scalars['ID'],
  tipoLoja: Scalars['String'],
  valorAcimaTipo: Scalars['Float'],
  default: Scalars['Boolean'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  excluido: Scalars['Boolean'],
  entidades: EntidadeTypeRelayConnection,
};


export type RegramentoTiposEntidadesNodeTypeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type RegramentoTiposEntidadesNodeTypeConnection = {
   __typename?: 'RegramentoTiposEntidadesNodeTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RegramentoTiposEntidadesNodeTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `RegramentoTiposEntidadesNodeType` and its cursor. */
export type RegramentoTiposEntidadesNodeTypeEdge = {
   __typename?: 'RegramentoTiposEntidadesNodeTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<RegramentoTiposEntidadesNodeType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type RelatorioComissaoFuncionariosNode = {
   __typename?: 'RelatorioComissaoFuncionariosNode',
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeNome?: Maybe<Scalars['String']>,
  somaRepasseFuncionario?: Maybe<Scalars['Decimal']>,
};

export type RelatorioContaAgregacaoNode = {
   __typename?: 'RelatorioContaAgregacaoNode',
  entidade?: Maybe<Scalars['ID']>,
  totalValor?: Maybe<Scalars['Decimal']>,
  entidadeNome?: Maybe<Scalars['String']>,
};

export type RelatorioEmprestimoAgregacaoNode = {
   __typename?: 'RelatorioEmprestimoAgregacaoNode',
  entidade?: Maybe<Scalars['ID']>,
  entidadeNome?: Maybe<Scalars['String']>,
  totalValorPassado?: Maybe<Scalars['Decimal']>,
  totalValorRetirado?: Maybe<Scalars['Decimal']>,
  mediaTaxa?: Maybe<Scalars['Decimal']>,
  somaRepasseParceiro?: Maybe<Scalars['String']>,
  lucroBruto?: Maybe<Scalars['Decimal']>,
};

export type RelatorioEmprestimoClienteNode = {
   __typename?: 'RelatorioEmprestimoClienteNode',
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeNome?: Maybe<Scalars['String']>,
  totalValorPassado?: Maybe<Scalars['Decimal']>,
  totalValorRetirado?: Maybe<Scalars['Decimal']>,
  mediaTaxa?: Maybe<Scalars['Decimal']>,
};

export type RelatorioExcelType = {
   __typename?: 'RelatorioExcelType',
  relatorio?: Maybe<Scalars['String']>,
  background?: Maybe<Scalars['Boolean']>,
};

export type RelatorioNode = {
   __typename?: 'RelatorioNode',
  id: Scalars['ID'],
  funcionario: FuncionarioTypeRelay,
  nome: Scalars['String'],
  status: RelatorioStatus,
  url?: Maybe<Scalars['String']>,
  filters?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type RelatorioNsuAgregacaoNode = {
   __typename?: 'RelatorioNsuAgregacaoNode',
  emprestimo_Entidade?: Maybe<Scalars['ID']>,
  emprestimo_EntidadeNome?: Maybe<Scalars['String']>,
  totalValorPassado?: Maybe<Scalars['Decimal']>,
  totalValorRetirado?: Maybe<Scalars['Decimal']>,
  mediaTaxa?: Maybe<Scalars['Decimal']>,
};

export type RelatorioReembolsoAgregacaoNode = {
   __typename?: 'RelatorioReembolsoAgregacaoNode',
  entidade?: Maybe<Scalars['ID']>,
  totalValor?: Maybe<Scalars['Decimal']>,
  entidadeNome?: Maybe<Scalars['String']>,
};

/** An enumeration. */
export enum RelatorioStatus {
  /** iniciado */
  Iniciado = 'INICIADO',
  /** completo */
  Completo = 'COMPLETO',
  /** erro */
  Erro = 'ERRO'
}

export type RelatorioTelefonesSmsType = {
   __typename?: 'RelatorioTelefonesSmsType',
  totalTelefones?: Maybe<Scalars['Int']>,
  telefones?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type RelatorioTxtType = {
   __typename?: 'RelatorioTxtType',
  relatorio?: Maybe<Scalars['String']>,
  background?: Maybe<Scalars['Boolean']>,
};

export type RemoverAnexosTaxaAbaixoDeEmprestimo = {
   __typename?: 'RemoverAnexosTaxaAbaixoDeEmprestimo',
  emprestimo?: Maybe<EmprestimoNode>,
};

export type RemoverEmprestimoNsus = {
   __typename?: 'RemoverEmprestimoNsus',
  emprestimo?: Maybe<EmprestimoNode>,
};

export type RemoverPagamentoEmprestimo = {
   __typename?: 'RemoverPagamentoEmprestimo',
  ok?: Maybe<Scalars['Boolean']>,
  emprestimo?: Maybe<EmprestimoNode>,
};

export type RemoverRascunhos = {
   __typename?: 'RemoverRascunhos',
  ok?: Maybe<Scalars['Boolean']>,
};

export type RemoverRelatorio = {
   __typename?: 'RemoverRelatorio',
  relatorios?: Maybe<Array<Maybe<RelatorioNode>>>,
};

export type ReplicaCobrancaExistenteAsaas = {
   __typename?: 'ReplicaCobrancaExistenteAsaas',
  ok?: Maybe<Scalars['Boolean']>,
  isAtSgi?: Maybe<Scalars['Boolean']>,
  isAtAsaas?: Maybe<Scalars['Boolean']>,
  cobranca?: Maybe<CobrancaType>,
};

export type ReportarProblemaMaquinaMutation = {
   __typename?: 'ReportarProblemaMaquinaMutation',
  maquina?: Maybe<MaquinaNode>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type ResgatarSaldo = {
   __typename?: 'ResgatarSaldo',
  ok?: Maybe<Scalars['Boolean']>,
};

export type ResgateAutomaticoParceirosType = {
   __typename?: 'ResgateAutomaticoParceirosType',
  id: Scalars['ID'],
  ativo: Scalars['Boolean'],
  parceiro: ParceirosNodeTypeRelay,
  contaBancaria: ContaParceirosType,
  segunda: Scalars['Boolean'],
  terca: Scalars['Boolean'],
  quarta: Scalars['Boolean'],
  quinta: Scalars['Boolean'],
  sexta: Scalars['Boolean'],
  sabado: Scalars['Boolean'],
};

export type ResgateAutomaticoParceirosTypeInput = {
  contaBancariaIdx?: Maybe<Scalars['Int']>,
  ativo?: Maybe<Scalars['Boolean']>,
  segunda?: Maybe<Scalars['Boolean']>,
  terca?: Maybe<Scalars['Boolean']>,
  quarta?: Maybe<Scalars['Boolean']>,
  quinta?: Maybe<Scalars['Boolean']>,
  sexta?: Maybe<Scalars['Boolean']>,
  sabado?: Maybe<Scalars['Boolean']>,
};

export type ResgateFuncionarioRelatorioInput = {
  valorResgate?: Maybe<Scalars['Float']>,
  valorResgate_Icontains?: Maybe<Scalars['Float']>,
  comissaofuncionarios_TipoComissao?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  respassefuncionario_FuncionarioId?: Maybe<Scalars['ID']>,
  respassefuncionario_FuncionarioId_In?: Maybe<Scalars['String']>,
  respassefuncionario_LojaId?: Maybe<Scalars['ID']>,
  respassefuncionario_LojaId_In?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  grupoId?: Maybe<Scalars['ID']>,
};

export type ResgateFuncionarioType = {
   __typename?: 'ResgateFuncionarioType',
  id: Scalars['ID'],
  inicioPeriodo: Scalars['Date'],
  fimPeriodo: Scalars['Date'],
  valorResgate: Scalars['Float'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  respassefuncionarioSet: Array<RespasseFuncionarioType>,
  comissaofuncionariosSet?: Maybe<Array<Maybe<ComissaoFuncionariosType>>>,
};

export type ResgateParceiroType = {
   __typename?: 'ResgateParceiroType',
  id: Scalars['ID'],
  valorResgate: Scalars['Float'],
  valorPago?: Maybe<Scalars['Float']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  parceiro: ParceirosNodeTypeRelay,
  tipoPix: Scalars['String'],
  pix: Scalars['String'],
  banco: Scalars['String'],
  agencia: Scalars['String'],
  conta: Scalars['String'],
  emprestimoSet?: Maybe<Array<Maybe<EmprestimoType>>>,
  comissaoparceirosSet?: Maybe<Array<Maybe<ComissaoParceirosType>>>,
};

export type RespasseFuncionarioType = {
   __typename?: 'RespasseFuncionarioType',
  id: Scalars['ID'],
  valorTotal: Scalars['Float'],
  funcionario: FuncionarioTypeRelay,
  emprestimo?: Maybe<EmprestimoRelatorioType>,
  loja: EntidadeTypeRelay,
  criadoEm: Scalars['DateTime'],
  resgate: HistoricoResgateFuncionarioNodeTypeRelay,
};

export type ResponsavelLead = {
   __typename?: 'ResponsavelLead',
  ok?: Maybe<Scalars['Boolean']>,
  lead?: Maybe<LeadsType>,
};

export type RespostaSmsType = {
   __typename?: 'RespostaSMSType',
  id: Scalars['ID'],
  response?: Maybe<Scalars['JSONString']>,
  envios: EnvioSmsTypeRelay,
};

export type RetirarPermissoesGrupoMutation = {
   __typename?: 'RetirarPermissoesGrupoMutation',
  ok?: Maybe<Scalars['Boolean']>,
};

export type RetomarOportunidadeLead = {
   __typename?: 'RetomarOportunidadeLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type SimulaEmprestimoLead = {
   __typename?: 'simulaEmprestimoLead',
  proposta?: Maybe<PropostasType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type StatusLeadType = {
   __typename?: 'StatusLeadType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  index?: Maybe<Scalars['Int']>,
  leadsSet: LeadsNodeTypeRelayPropConnection,
  mensagemzapiSet: Array<MensagemZApiType>,
};


export type StatusLeadTypeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};

export type SubCategoriasTypeRelay = Node & {
   __typename?: 'SubCategoriasTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome?: Maybe<Scalars['String']>,
  categoria: CategoriaTypeRelay,
  excluido: Scalars['Boolean'],
  fornecedorSet: FornecedorViewNodeTypeConnection,
  contaSet: ContaNodeConnection,
};


export type SubCategoriasTypeRelayFornecedorSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type SubCategoriasTypeRelayContaSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  titulo?: Maybe<Scalars['String']>,
  titulo_Icontains?: Maybe<Scalars['String']>,
  titulo_Istartswith?: Maybe<Scalars['String']>,
  fornecedorId?: Maybe<Scalars['ID']>,
  fornecedorId_In?: Maybe<Scalars['ID']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Nome_Istartswith?: Maybe<Scalars['String']>,
  categoriaId?: Maybe<Scalars['ID']>,
  categoriaId_In?: Maybe<Scalars['ID']>,
  subcategoriaId?: Maybe<Scalars['ID']>,
  subcategoriaId_In?: Maybe<Scalars['ID']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  status?: Maybe<Scalars['String']>,
  status_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>,
  dataVencimento?: Maybe<Scalars['Date']>,
  dataVencimento_Lt?: Maybe<Scalars['Date']>,
  dataVencimento_Gt?: Maybe<Scalars['Date']>,
  dataVencimento_Gte?: Maybe<Scalars['Date']>,
  dataVencimento_Lte?: Maybe<Scalars['Date']>,
  dataPagamento?: Maybe<Scalars['Date']>,
  dataPagamento_Lt?: Maybe<Scalars['Date']>,
  dataPagamento_Gt?: Maybe<Scalars['Date']>,
  dataPagamento_Gte?: Maybe<Scalars['Date']>,
  dataPagamento_Lte?: Maybe<Scalars['Date']>,
  referencia_UnidadeCusto?: Maybe<Scalars['ID']>,
  referencia_Recorrencia?: Maybe<Scalars['String']>,
  referencia_Recorrencia_In?: Maybe<Scalars['String']>,
  valor?: Maybe<Scalars['Float']>,
  valor_Lt?: Maybe<Scalars['Float']>,
  valor_Gt?: Maybe<Scalars['Float']>,
  fornecedor_Id?: Maybe<Scalars['Float']>,
  categoria_Id_In?: Maybe<Scalars['String']>,
  subcategoria_Id_In?: Maybe<Scalars['String']>,
  entidade_Id_In?: Maybe<Scalars['String']>,
  pesquisaFornecedor?: Maybe<Scalars['String']>,
  pesquisaDestinatario?: Maybe<Scalars['String']>,
  pesquisaTabela?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>,
  orderBy?: Maybe<Scalars['String']>
};

export type SubCategoriasTypeRelayConnection = {
   __typename?: 'SubCategoriasTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SubCategoriasTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `SubCategoriasTypeRelay` and its cursor. */
export type SubCategoriasTypeRelayEdge = {
   __typename?: 'SubCategoriasTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<SubCategoriasTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum TabelaParceirosBandeiraTabela {
  /** Visa */
  Visa = 'VISA',
  /** Mastercard */
  Mastercard = 'MASTERCARD'
}

/** An enumeration. */
export enum TabelaParceirosCategoriaTabela {
  /** Funcionario */
  Funcionario = 'FUNCIONARIO',
  /** Parceiro */
  Parceiro = 'PARCEIRO'
}

/** An enumeration. */
export enum TabelaParceirosTipoTabela {
  /** Tabela comissão */
  TabelaComissao = 'TABELA_COMISSAO',
  /** Tabela subadquirente */
  TabelaSubadquirente = 'TABELA_SUBADQUIRENTE',
  /** Tabela subadquirente + financeiro */
  TabelaSubadquirenteFinanceiro = 'TABELA_SUBADQUIRENTE_FINANCEIRO'
}

export type TabelaParceirosType = {
   __typename?: 'TabelaParceirosType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  categoriaTabela?: Maybe<TabelaParceirosCategoriaTabela>,
  tipoTabela?: Maybe<TabelaParceirosTipoTabela>,
  bandeiraTabela?: Maybe<TabelaParceirosBandeiraTabela>,
  grupodepermissoesSet?: Maybe<Array<Maybe<GrupoDePermissoesType>>>,
  funcionariodegrupoSet: Array<FuncionarioDeGrupoType>,
  colunatabelaparceirosSet?: Maybe<Array<Maybe<ColunaTabelaType>>>,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  lojaparceiroSet: Array<Maybe<LojaParceiroType>>,
  funcionariosrepasseSet?: Maybe<Array<Maybe<FuncionariosRepasseType>>>,
  grupofuncionariosrepasseSet: Array<GrupoFuncionariosRepasseType>,
};


export type TabelaParceirosTypeColunatabelaparceirosSetArgs = {
  orderBy?: Maybe<Scalars['String']>
};


export type TabelaParceirosTypeParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type TabelaParceirosTypeLojaparceiroSetArgs = {
  parceirosIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  comDistinct?: Maybe<Scalars['Boolean']>
};

export type TabelasNodeTypeRelay = Node & {
   __typename?: 'TabelasNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  categoriaTabela?: Maybe<TabelaParceirosCategoriaTabela>,
  tipoTabela?: Maybe<TabelaParceirosTipoTabela>,
  bandeiraTabela?: Maybe<TabelaParceirosBandeiraTabela>,
  grupodepermissoesSet: GrupoDePermissoesTypeRelayConnection,
  funcionariodegrupoSet: Array<FuncionarioDeGrupoType>,
  colunatabelaparceirosSet: Array<ColunaTabelaType>,
  parceirosSet?: Maybe<Array<Maybe<ParceirosType>>>,
  lojaparceiroSet: Array<LojaParceiroType>,
  funcionariosrepasseSet?: Maybe<Array<Maybe<FuncionariosRepasseType>>>,
  grupofuncionariosrepasseSet: Array<GrupoFuncionariosRepasseType>,
};


export type TabelasNodeTypeRelayGrupodepermissoesSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  descricao?: Maybe<Scalars['String']>,
  descricao_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  funcionario_Funcionario_Id?: Maybe<Scalars['ID']>,
  entidade_Entidade_Nome?: Maybe<Scalars['String']>,
  entidade_Entidade_Nome_Icontains?: Maybe<Scalars['String']>,
  entidade_Entidade_Id?: Maybe<Scalars['ID']>,
  permissao_Descricao?: Maybe<Scalars['String']>,
  permissao_Descricao_Icontains?: Maybe<Scalars['String']>,
  permissao_Descricao_In?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>
};

export type TabelasNodeTypeRelayConnection = {
   __typename?: 'TabelasNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TabelasNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `TabelasNodeTypeRelay` and its cursor. */
export type TabelasNodeTypeRelayEdge = {
   __typename?: 'TabelasNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<TabelasNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type TabelaTaxaParcelasType = {
   __typename?: 'TabelaTaxaParcelasType',
  id: Scalars['ID'],
  valorTabela?: Maybe<Scalars['Float']>,
  entidade: EntidadeTypeRelay,
  tabelaParcelas: Array<ColunaTaxasParcelasType>,
  parcelas?: Maybe<Scalars['String']>,
  taxaMinima?: Maybe<Scalars['Float']>,
};

export type TabelaTaxasInput = {
  valorTabela: Scalars['Float'],
  parcelas: Array<Maybe<TaxasParcelasInput>>,
};

export type TarefasNodeTypeRelay = Node & {
   __typename?: 'TarefasNodeTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  lead: LeadsNodeTypeRelayProp,
  assunto: Scalars['String'],
  tipo: TarefasTipo,
  funcionario?: Maybe<FuncionarioTypeRelay>,
  comentario?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  dataHora?: Maybe<Scalars['DateTime']>,
  status?: Maybe<TarefasStatus>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type TarefasNodeTypeRelayConnection = {
   __typename?: 'TarefasNodeTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TarefasNodeTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `TarefasNodeTypeRelay` and its cursor. */
export type TarefasNodeTypeRelayEdge = {
   __typename?: 'TarefasNodeTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<TarefasNodeTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum TarefasStatus {
  /** Pendente */
  Pendente = 'PENDENTE',
  /** Concluída */
  Concluida = 'CONCLUIDA'
}

/** An enumeration. */
export enum TarefasTipo {
  /** Visita à loja */
  Loja = 'LOJA',
  /** Ligação */
  Ligacao = 'LIGACAO',
  /** Whatsapp */
  Whatsapp = 'WHATSAPP',
  /** Email */
  Email = 'EMAIL',
  /** SMS */
  Sms = 'SMS',
  /** Redes Sociais */
  SocialNetwork = 'SOCIAL_NETWORK'
}

export type TarefasType = {
   __typename?: 'TarefasType',
  id: Scalars['ID'],
  lead: LeadsNodeTypeRelayProp,
  assunto: Scalars['String'],
  tipo: TarefasTipo,
  funcionario?: Maybe<FuncionarioTypeRelay>,
  comentario?: Maybe<Scalars['String']>,
  data?: Maybe<Scalars['Date']>,
  hora?: Maybe<Scalars['Time']>,
  dataHora?: Maybe<Scalars['DateTime']>,
  status?: Maybe<TarefasStatus>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
};

export type TaxaColunaType = {
   __typename?: 'TaxaColunaType',
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
};

export type TaxasParcelasInput = {
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
  parcelas: Scalars['String'],
};

export type TaxasParcelasMaquinaInput = {
  parcelas: TaxasParcelasMaquinaParcelas,
  bandeiras: Scalars['String'],
  taxaAdministrativa?: Maybe<Scalars['Float']>,
  taxaAntecipacao?: Maybe<Scalars['Float']>,
  limite?: Maybe<Scalars['Float']>,
  meta?: Maybe<Scalars['Float']>,
};

export type TaxasParcelasMaquinaNode = Node & {
   __typename?: 'TaxasParcelasMaquinaNode',
  /** The ID of the object. */
  id: Scalars['ID'],
  maquina: MaquinaNode,
  parcelas: TaxasParcelasMaquinaParcelas,
  bandeiras: Scalars['String'],
  taxaAdministrativa?: Maybe<Scalars['Float']>,
  taxaAntecipacao?: Maybe<Scalars['Float']>,
  limite: Scalars['Float'],
  meta: Scalars['Float'],
};

export type TaxasParcelasMaquinaNodeConnection = {
   __typename?: 'TaxasParcelasMaquinaNodeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaxasParcelasMaquinaNodeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `TaxasParcelasMaquinaNode` and its cursor. */
export type TaxasParcelasMaquinaNodeEdge = {
   __typename?: 'TaxasParcelasMaquinaNodeEdge',
  /** The item at the end of the edge */
  node?: Maybe<TaxasParcelasMaquinaNode>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

/** An enumeration. */
export enum TaxasParcelasMaquinaParcelas {
  /** 1x */
  A_1 = 'A_1',
  /** 2x */
  A_2 = 'A_2',
  /** 3x */
  A_3 = 'A_3',
  /** 4x */
  A_4 = 'A_4',
  /** 5x */
  A_5 = 'A_5',
  /** 6x */
  A_6 = 'A_6',
  /** 7x */
  A_7 = 'A_7',
  /** 8x */
  A_8 = 'A_8',
  /** 9x */
  A_9 = 'A_9',
  /** 10x */
  A_10 = 'A_10',
  /** 11x */
  A_11 = 'A_11',
  /** 12x */
  A_12 = 'A_12',
  /** 13x */
  A_13 = 'A_13',
  /** 14x */
  A_14 = 'A_14',
  /** 15x */
  A_15 = 'A_15',
  /** 16x */
  A_16 = 'A_16',
  /** 17x */
  A_17 = 'A_17',
  /** 18x */
  A_18 = 'A_18',
  /** 19x */
  A_19 = 'A_19',
  /** 20x */
  A_20 = 'A_20',
  /** 21x */
  A_21 = 'A_21',
  /** 22x */
  A_22 = 'A_22',
  /** 23x */
  A_23 = 'A_23',
  /** 24x */
  A_24 = 'A_24'
}

/** An enumeration. */
export enum TaxasParcelasParcelas {
  /** 1x */
  A_1 = 'A_1',
  /** 2x */
  A_2 = 'A_2',
  /** 3x */
  A_3 = 'A_3',
  /** 4x */
  A_4 = 'A_4',
  /** 5x */
  A_5 = 'A_5',
  /** 6x */
  A_6 = 'A_6',
  /** 7x */
  A_7 = 'A_7',
  /** 8x */
  A_8 = 'A_8',
  /** 9x */
  A_9 = 'A_9',
  /** 10x */
  A_10 = 'A_10',
  /** 11x */
  A_11 = 'A_11',
  /** 12x */
  A_12 = 'A_12',
  /** 13x */
  A_13 = 'A_13',
  /** 14x */
  A_14 = 'A_14',
  /** 15x */
  A_15 = 'A_15',
  /** 16x */
  A_16 = 'A_16',
  /** 17x */
  A_17 = 'A_17',
  /** 18x */
  A_18 = 'A_18',
  /** 19x */
  A_19 = 'A_19',
  /** 20x */
  A_20 = 'A_20',
  /** 21x */
  A_21 = 'A_21',
  /** 22x */
  A_22 = 'A_22',
  /** 23x */
  A_23 = 'A_23',
  /** 24x */
  A_24 = 'A_24'
}

export type TaxasParcelasType = {
   __typename?: 'TaxasParcelasType',
  id: Scalars['ID'],
  entidade: EntidadeTypeRelay,
  parcelas: TaxasParcelasParcelas,
  taxaLucro?: Maybe<Scalars['Float']>,
  taxaMinima?: Maybe<Scalars['Float']>,
  taxaSeguranca?: Maybe<Scalars['Float']>,
};

export type TaxasTabelasInput = {
  valor: Scalars['Float'],
  parcelas?: Maybe<Array<Maybe<TaxasParcelasInput>>>,
};

export type TelefoneDeClienteType = {
   __typename?: 'TelefoneDeClienteType',
  id: Scalars['ID'],
  cliente: EmprestimoClienteNode,
  ddd?: Maybe<Scalars['String']>,
  numero: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
};

export type TelefoneDeEntidadeType = {
   __typename?: 'TelefoneDeEntidadeType',
  id: Scalars['ID'],
  entidade: EntidadeTypeRelay,
  ddd?: Maybe<Scalars['String']>,
  numero: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
};

export type TelefoneDeFornecedorType = {
   __typename?: 'TelefoneDeFornecedorType',
  id: Scalars['ID'],
  fornecedor: FornecedorViewNodeType,
  ddd?: Maybe<Scalars['String']>,
  numero: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
};

export type TemplatesEmailType = {
   __typename?: 'TemplatesEmailType',
  id: Scalars['ID'],
  /** Print do template de email */
  imagem: Scalars['String'],
  /** Ex: sgisistemas.com.br */
  dominio: Scalars['String'],
  /** Nome do Template no Mailgun */
  nomeTemplate: Scalars['String'],
};


export type TipoFuncionarioTypeRelay = Node & {
   __typename?: 'TipoFuncionarioTypeRelay',
  /** The ID of the object. */
  id: Scalars['ID'],
  nome: Scalars['String'],
  tipoDefault: Scalars['Boolean'],
  excluido: Scalars['Boolean'],
  funcionarioSet: FuncionarioTypeRelayConnection,
  totalFuncionarioPorTipo?: Maybe<Scalars['Int']>,
};


export type TipoFuncionarioTypeRelayFuncionarioSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  categoria?: Maybe<Scalars['String']>,
  tipoFuncionario_Nome?: Maybe<Scalars['String']>,
  tipoContrato?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  email_Icontains?: Maybe<Scalars['String']>,
  cpf?: Maybe<Scalars['String']>,
  rg?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  user_IsActive?: Maybe<Scalars['Boolean']>,
  orderBy?: Maybe<Scalars['String']>
};

export type TipoFuncionarioTypeRelayConnection = {
   __typename?: 'TipoFuncionarioTypeRelayConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TipoFuncionarioTypeRelayEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `TipoFuncionarioTypeRelay` and its cursor. */
export type TipoFuncionarioTypeRelayEdge = {
   __typename?: 'TipoFuncionarioTypeRelayEdge',
  /** The item at the end of the edge */
  node?: Maybe<TipoFuncionarioTypeRelay>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export type TiposEntidadesNodeType = Node & {
   __typename?: 'TiposEntidadesNodeType',
  /** The ID of the object. */
  id: Scalars['ID'],
  tipoLoja: Scalars['String'],
  valorAcimaTipo: Scalars['Float'],
  default: Scalars['Boolean'],
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  excluido: Scalars['Boolean'],
  entidades: EntidadeTypeRelayConnection,
  totalEntidadesTipoLoja?: Maybe<Scalars['Int']>,
};


export type TiposEntidadesNodeTypeEntidadesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['Float']>,
  id_In?: Maybe<Scalars['String']>,
  nomeIdentificacao?: Maybe<Scalars['String']>,
  nomeIdentificacao_Icontains?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  inscricaoEstadual?: Maybe<Scalars['String']>,
  inscricaoEstadual_Icontains?: Maybe<Scalars['String']>,
  municipio?: Maybe<Scalars['String']>,
  municipio_Icontains?: Maybe<Scalars['String']>,
  razao?: Maybe<Scalars['String']>,
  razao_Icontains?: Maybe<Scalars['String']>,
  cnpj?: Maybe<Scalars['String']>,
  categoria?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  tipoLoja_TipoLoja?: Maybe<Scalars['String']>,
  valorAcimaEntidade?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_In?: Maybe<Scalars['Float']>,
  valorAcimaEntidade_Gt?: Maybe<Scalars['Float']>,
  orderBy?: Maybe<Scalars['String']>
};

export type TiposEntidadesNodeTypeConnection = {
   __typename?: 'TiposEntidadesNodeTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TiposEntidadesNodeTypeEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
  edgeCount?: Maybe<Scalars['Int']>,
};

/** A Relay edge containing a `TiposEntidadesNodeType` and its cursor. */
export type TiposEntidadesNodeTypeEdge = {
   __typename?: 'TiposEntidadesNodeTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<TiposEntidadesNodeType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
};

export enum TiposEnvioOpcoes {
  Email = 'EMAIL',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

export type TitularDivergenteInput = {
  nome: Scalars['String'],
  cpf: Scalars['String'],
  anexoCpf: Scalars['String'],
  anexoRg: Scalars['String'],
  rg: Scalars['String'],
  cep: Scalars['String'],
  endereco: Scalars['String'],
  complemento: Scalars['String'],
  bairro: Scalars['String'],
  municipio: Scalars['String'],
  uf: Scalars['String'],
  pais: Scalars['String'],
  comprovanteEndereco?: Maybe<Scalars['String']>,
};

export type TodasMetasDash = {
   __typename?: 'TodasMetasDash',
  ok?: Maybe<Scalars['Boolean']>,
  valor?: Maybe<Scalars['Float']>,
  valorAlcancado?: Maybe<Scalars['Float']>,
  taxaGeral?: Maybe<Scalars['Float']>,
  taxaAlcancado?: Maybe<Scalars['Float']>,
  parcelas?: Maybe<Array<Maybe<ParcelasMetasDash>>>,
  metas?: Maybe<Array<Maybe<MetaType>>>,
  origens?: Maybe<Array<Maybe<OrigensMetasDash>>>,
  bandeiras?: Maybe<Array<Maybe<BandeirasDash>>>,
};

export type TokenSmsType = {
   __typename?: 'TokenSmsType',
  id: Scalars['ID'],
  tokenSms?: Maybe<Scalars['String']>,
  envios: EnvioSmsTypeRelay,
};

export type TriggerInputZApiInput = {
  statusId?: Maybe<Scalars['ID']>,
  texto?: Maybe<Scalars['String']>,
};

export type UnidadeCustoType = {
   __typename?: 'UnidadeCustoType',
  id: Scalars['ID'],
  nome: Scalars['String'],
  descricao?: Maybe<Scalars['String']>,
  criadoEm: Scalars['DateTime'],
  atualizadoEm: Scalars['DateTime'],
  tabelaParceiro?: Maybe<TabelasNodeTypeRelay>,
  permissoes: Array<PermissaoDeGrupoType>,
  entidades: Array<EntidadeDeGrupoType>,
  funcionarios: Array<FuncionarioDeGrupoType>,
  parceirosGruposPermitidos: ParceirosNodeTypeRelayConnection,
  parceirosSet: ParceirosNodeTypeRelayConnection,
  funcionariosRepasseGruposPermitidos: FuncionariosRepasseNodeTypeRelayConnection,
  grupofuncionariosrepasseSet: Array<GrupoFuncionariosRepasseType>,
  grupoentidadefuncionariorepasseSet: Array<GrupoEntidadeFuncionarioRepasseType>,
  referenciaSet: Array<ReferenciaContaType>,
};


export type UnidadeCustoTypeParceirosGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type UnidadeCustoTypeParceirosSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Id?: Maybe<Scalars['ID']>,
  funcionarioId?: Maybe<Scalars['ID']>,
  lojaparceiro_Loja_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela?: Maybe<Scalars['String']>,
  lojaparceiro_Tabela_TipoTabela_Icontains?: Maybe<Scalars['String']>,
  tabela_Nome_Icontains?: Maybe<Scalars['String']>,
  lojaRepasseId?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  resgateautomaticoparceiros_Segunda?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Terca?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quarta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Quinta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sexta?: Maybe<Scalars['Boolean']>,
  resgateautomaticoparceiros_Sabado?: Maybe<Scalars['Boolean']>,
  tabelaId?: Maybe<Scalars['ID']>
};


export type UnidadeCustoTypeFuncionariosRepasseGruposPermitidosArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  funcionario_Id?: Maybe<Scalars['ID']>,
  funcionario_Id_In?: Maybe<Scalars['ID']>,
  tipoFuncionario?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Id?: Maybe<Scalars['ID']>,
  tabelaDeParceiro_Id_In?: Maybe<Scalars['String']>,
  funcionario_Nome?: Maybe<Scalars['String']>,
  funcionario_Nome_Icontains?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome?: Maybe<Scalars['String']>,
  tabelaDeParceiro_Nome_Icontains?: Maybe<Scalars['String']>,
  criadoEm?: Maybe<Scalars['DateTime']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  atualizadoEm?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gte?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Gt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lt?: Maybe<Scalars['DateTime']>,
  atualizadoEm_Lte?: Maybe<Scalars['DateTime']>
};

export type UpdateClienteMobile = {
   __typename?: 'UpdateClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
  token?: Maybe<Scalars['String']>,
};

export type UpdateCobranca = {
   __typename?: 'UpdateCobranca',
  ok?: Maybe<Scalars['Boolean']>,
  cobranca?: Maybe<CobrancaType>,
};

export type UpdateContaClienteMobile = {
   __typename?: 'UpdateContaClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
  conta?: Maybe<ContaDeClienteType>,
};

export type UpdateEnderecoClienteMobile = {
   __typename?: 'UpdateEnderecoClienteMobile',
  ok?: Maybe<Scalars['Boolean']>,
  cliente?: Maybe<ClienteType>,
};

export type UpdateMetaDiariaMutation = {
   __typename?: 'UpdateMetaDiariaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  metaDiaria?: Maybe<Array<Maybe<MetasDiariasType>>>,
};

export type UpdateMetaMutation = {
   __typename?: 'UpdateMetaMutation',
  ok?: Maybe<Scalars['Boolean']>,
  metasComErroOutput?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type Upload_Foto_64 = {
   __typename?: 'upload_foto_64',
  ok?: Maybe<Scalars['Boolean']>,
  url?: Maybe<Scalars['String']>,
};

export type UserType = {
   __typename?: 'UserType',
  id: Scalars['ID'],
  password: Scalars['String'],
  lastLogin?: Maybe<Scalars['DateTime']>,
  /** Indica que este usuário tem todas as permissões sem atribuí-las explicitamente. */
  isSuperuser: Scalars['Boolean'],
  /** Obrigatório. 150 caracteres ou menos. Letras, números e @/./+/-/_ apenas. */
  username: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  /** Indica que usuário consegue acessar este site de administração. */
  isStaff: Scalars['Boolean'],
  /** Indica que o usuário será tratado como ativo. Ao invés de excluir contas de usuário, desmarque isso. */
  isActive: Scalars['Boolean'],
  dateJoined: Scalars['DateTime'],
  funcionario?: Maybe<FuncionarioTypeRelay>,
  cliente?: Maybe<EmprestimoClienteNode>,
};

export type VagaType = {
   __typename?: 'VagaType',
  id: Scalars['ID'],
  descricao: Scalars['String'],
  leadsSet: LeadsNodeTypeRelayPropConnection,
};


export type VagaTypeLeadsSetArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  propostas_ValorDesejado?: Maybe<Scalars['Float']>,
  origem_Icontains?: Maybe<Scalars['String']>,
  origem?: Maybe<Scalars['String']>,
  origem_In?: Maybe<Scalars['String']>,
  telefone?: Maybe<Scalars['String']>,
  telefone_Icontains?: Maybe<Scalars['String']>,
  telefone_Startswith?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  cidade?: Maybe<Scalars['String']>,
  cidade_Icontains?: Maybe<Scalars['String']>,
  uf?: Maybe<Scalars['String']>,
  nome?: Maybe<Scalars['String']>,
  nome_Icontains?: Maybe<Scalars['String']>,
  clienteId?: Maybe<Scalars['ID']>,
  clienteId_In?: Maybe<Scalars['ID']>,
  cliente_Isnull?: Maybe<Scalars['Boolean']>,
  entidadeId?: Maybe<Scalars['ID']>,
  entidadeId_In?: Maybe<Scalars['ID']>,
  utmSource?: Maybe<Scalars['String']>,
  utmSource_In?: Maybe<Scalars['String']>,
  utmSource_Icontains?: Maybe<Scalars['String']>,
  utmCampaign?: Maybe<Scalars['String']>,
  utmCampaign_In?: Maybe<Scalars['String']>,
  utmCampaign_Icontains?: Maybe<Scalars['String']>,
  utmMedium?: Maybe<Scalars['String']>,
  utmMedium_In?: Maybe<Scalars['String']>,
  utmMedium_Icontains?: Maybe<Scalars['String']>,
  utmTerm?: Maybe<Scalars['String']>,
  utmTerm_In?: Maybe<Scalars['String']>,
  utmTerm_Icontains?: Maybe<Scalars['String']>,
  utmContent?: Maybe<Scalars['String']>,
  utmContent_In?: Maybe<Scalars['String']>,
  utmContent_Icontains?: Maybe<Scalars['String']>,
  responsavelId?: Maybe<Scalars['ID']>,
  responsavelId_In?: Maybe<Scalars['ID']>,
  criadoEm?: Maybe<Scalars['String']>,
  criadoEm_Gte?: Maybe<Scalars['DateTime']>,
  criadoEm_Gt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lt?: Maybe<Scalars['DateTime']>,
  criadoEm_Lte?: Maybe<Scalars['DateTime']>,
  orderBy?: Maybe<Scalars['String']>,
  grupoIds?: Maybe<Scalars['String']>
};

export type VendaLead = {
   __typename?: 'VendaLead',
  lead?: Maybe<LeadsType>,
  ok?: Maybe<Scalars['Boolean']>,
};

export type Verify = {
   __typename?: 'Verify',
  payload: Scalars['GenericScalar'],
};

export type VinculaClienteAsaasMutation = {
   __typename?: 'VinculaClienteAsaasMutation',
  cliente?: Maybe<ClienteType>,
};

export type VinculaClienteAsaasMutationInput = {
  id: Scalars['ID'],
  idEntidade: Scalars['ID'],
  asaasCustomerId?: Maybe<Scalars['String']>,
};

export type VinculaLeadEmprestimo = {
   __typename?: 'VinculaLeadEmprestimo',
  ok?: Maybe<Scalars['Boolean']>,
};

export type VincularClickSignMutation = {
   __typename?: 'VincularClickSignMutation',
  ok?: Maybe<Scalars['Boolean']>,
  emprestimo?: Maybe<EmprestimoType>,
};

export type VincularFornecedorEntidade = {
   __typename?: 'VincularFornecedorEntidade',
  fornecedor?: Maybe<FornecedorNodeType>,
};

export type VincularTokenOnesignalMutation = {
   __typename?: 'VincularTokenOnesignalMutation',
  ok?: Maybe<Scalars['Boolean']>,
  newOnesignalId?: Maybe<Scalars['String']>,
};
