import React from 'react';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Controller } from 'react-hook-form';

type CheckInputProps = Omit<React.ComponentProps<typeof Controller>, 'as'> &
  Omit<React.ComponentProps<typeof FormControlLabel>, 'name' | 'control'>;

export function CheckboxCTL({ control, name, ...rest }: CheckInputProps) {
  return (
    <FormControlLabel
      control={
        <Controller
          as={({ checked = false, value, ...rest }: React.ComponentProps<typeof Checkbox>) => (
            <Checkbox {...rest} value={checked} checked={checked} />
          )}
          name={name}
          onChange={args => args[1]}
          control={control}
        />
      }
      {...rest}
    />
  );
}
