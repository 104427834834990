/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import { Grid } from '@material-ui/core';
import { Box, FormControl } from '@material-ui/core';
import { FormControlTypeMap } from '@material-ui/core/FormControl';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { ErrorMessage, FieldErrors } from 'react-hook-form';

import { StyledFilledInput } from './styles';

import { TypographyCN } from 'components/TypographyCN';
import { isEmpty } from 'utils/empty';
import { useMobile } from 'utils/useMobile';

type InnerProps = {
  formControlProps?: OverridableComponent<FormControlTypeMap<{}, 'div'>>;
  fullWidth?: boolean;
  multiline?: boolean;
  errorMessage?: string | FieldErrors<any>;
  countableInput?: boolean;
} & React.ComponentProps<typeof StyledFilledInput>;

type InputCNProps = InnerProps & {
  label?: string;
  labelProps?: React.ComponentProps<typeof TypographyCN>;
  boxHeight?: string;
  fixedHeight?: string;
  fontSize?: string;
};

const InnerInput = forwardRef<HTMLDivElement, InnerProps>(
  (
    { formControlProps, multiline, fullWidth, errorMessage = '', disabled, value = '', countableInput, ...other },
    ref,
  ) => (
    <>
      <FormControl variant="filled" {...{ fullWidth, ...formControlProps }}>
        <StyledFilledInput
          disableUnderline={Boolean(isEmpty(errorMessage))}
          error={Boolean(!isEmpty(errorMessage))}
          readOnly={disabled}
          multiline={multiline}
          className={disabled ? 'Mui-disabled ' + other.className : other.className}
          {...{ fullWidth, value, ...other, ref }}
        />
      </FormControl>
      {errorMessage && (
        <TypographyCN component="span" variant="body2" align="center" weight="lighter" setColor="red">
          {typeof errorMessage === 'string' ? (
            errorMessage
          ) : (
            <ErrorMessage errors={errorMessage} name={other.name}>
              {({ message }) => message}
            </ErrorMessage>
          )}
        </TypographyCN>
      )}
      {countableInput && !disabled && (
        <Grid item xs={6} direction="row" alignItems="flex-start" justify="flex-end" style={{ alignSelf: 'flex-end' }}>
          <TypographyCN
            component="h4"
            variant="caption"
            fontSize="16px"
            noWrap
            align="right"
            style={{ marginTop: 10, color: '#24c361', marginLeft: 10 }}
          >
            {value?.length}
          </TypographyCN>
        </Grid>
      )}
    </>
  ),
);

export const InputCN = forwardRef<HTMLDivElement, InputCNProps>(
  ({ label, labelProps, boxHeight, fixedHeight, fontSize, width, ...rest }, ref) => {
    const isMobile = useMobile();
    return label ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        minHeight={boxHeight || '90px'}
        height={fixedHeight}
        width={width}
      >
        <TypographyCN
          component="label"
          variant={isMobile ? 'body1' : 'h6'}
          align="center"
          weight="bold"
          fontSize={fontSize}
          {...labelProps}
        >
          {label}
        </TypographyCN>
        <InnerInput {...rest} ref={ref} />
      </Box>
    ) : (
      <InnerInput {...rest} ref={ref} />
    );
  },
);
