import React from 'react';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { isSameDay } from 'date-fns';

import { DefinedRange, DateRangeType } from '../types';

type DefinedRangesProps = {
  setRange: (range: DateRangeType) => void;
  selectedRange: DateRangeType;
  ranges: DefinedRange[];
};

const isSameRange = (first: DateRangeType, second: DateRangeType) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

const DefinedRanges = (props: DefinedRangesProps) => {
  return (
    <List>
      {props.ranges.map((range, idx) => (
        <ListItem button key={idx} onClick={() => props.setRange(range)}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              style: {
                fontWeight: isSameRange(range, props.selectedRange) ? 'bold' : 'normal',
              },
            }}
          >
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DefinedRanges;
