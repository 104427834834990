/* eslint-disable no-unused-expressions */
import { useMutation } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn } from 'apollo-boost';

import { AllEntityRelay, getAllEntityRelay } from './index';

import { CriarEntidadeMutation, EntidadeType, MutationCriarEntidadeArgs, PermissaoType } from 'types/graphql';

export type EntityCreateResult = {
  criarEntidade: CriarEntidadeMutation;
};

export const entityCreateMutation = gql`
  mutation EntityCreateMutation(
    $anexoCnpj: String
    $bairro: String
    $tipoLoja: String
    $cep: String
    $cnpj: String
    $complemento: String
    $comprovanteEndereco: String
    $endereco: String
    $inscricaoEstadual: String
    $mensagemInvoice: String
    $municipio: String
    $nome: String!
    $nomeIdentificacao: String!
    $pais: String
    $razao: String
    $uf: String
    $taxaMinima: Float
    $taxaSeguranca: Float
    $taxaLucro: Float
    $latitude: String
    $longitude: String
    $parcelas: [TaxasParcelasInput]
  ) {
    criarEntidade(
      anexoCnpj: $anexoCnpj
      bairro: $bairro
      tipoLoja: $tipoLoja
      cep: $cep
      cnpj: $cnpj
      complemento: $complemento
      comprovanteEndereco: $comprovanteEndereco
      endereco: $endereco
      inscricaoEstadual: $inscricaoEstadual
      mensagemInvoice: $mensagemInvoice
      municipio: $municipio
      nome: $nome
      nomeIdentificacao: $nomeIdentificacao
      pais: $pais
      razao: $razao
      uf: $uf
      taxaMinima: $taxaMinima
      taxaSeguranca: $taxaSeguranca
      taxaLucro: $taxaLucro
      latitude: $latitude
      longitude: $longitude
      parcelas: $parcelas
    ) {
      permissao {
        id
        descricao
      }
      entidade {
        id
        nome
        razao
        nomeIdentificacao
        cnpj
        anexoCnpj
        inscricaoEstadual
        endereco
        complemento
        bairro
        municipio
        uf
        cep
        pais
        comprovanteEndereco
        categoria
        latitude
        longitude
        telefones {
          id
          ddd
          numero
          descricao
        }
      }
    }
  }
`;

export type EntityCreateMutation = {
  criarEntidade: {
    __typename: 'CriarEntidadeMutation';
    permissao?: PermissaoType;
    entidade: EntidadeType;
  };
};

export function updateCacheEntityAdd(): MutationUpdaterFn<EntityCreateResult | undefined> {
  return (cache, mutationResult) => {
    const data = cache.readQuery<AllEntityRelay>({ query: getAllEntityRelay });
    data?.todasEntidadesRelay.edges.unshift({
      node: {
        ...mutationResult.data?.criarEntidade.entidade!,
        pk: Number(mutationResult.data?.criarEntidade.entidade?.id),
        __typename: 'EntidadeTypeRelay',
      },
      cursor: '',
      __typename: 'EntidadeTypeRelayEdge',
    });

    if (data?.todasEntidadesRelay.totalCount) data.todasEntidadesRelay.totalCount++;

    cache.writeQuery({
      query: getAllEntityRelay,
      data,
    });
  };
}

export function useEntityCreateMutation() {
  return useMutation<EntityCreateMutation, MutationCriarEntidadeArgs>(entityCreateMutation, {
    update: updateCacheEntityAdd(),
  });
}
