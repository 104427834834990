import React from 'react';

import { ProvidersIcon } from 'icons/pendingActions';
import PointOfSaleIcon from 'icons/PointOfSaleIcon';
import {
  FaTachometerAlt,
  FaStoreAlt,
  FaIdBadge,
  FaUsers,
  FaFileInvoiceDollar,
  FaSitemap,
  FaRegFileExcel,
  FaChartPie,
  FaCalculator,
  FaFilter,
  FaHandshake,
  FaTelegramPlane,
  FaCog,
  FaWhatsapp,
} from 'react-icons/fa';
import { ImTarget } from 'react-icons/im';
import { MdReplay, MdPerson } from 'react-icons/md';
import { LinkProps } from 'react-router-dom';

import { IconContext } from 'components/IconContext';
import { PermissaoDescricao } from 'types/graphql';
import microFrontUrlWithToken from 'utils/redirectToMicroFront';

interface MenuItem extends LinkProps {
  to: string;
  primary: string;
  exact?: boolean;
  icon: React.ReactElement;
  permission?: PermissaoDescricao[];
  isNew: boolean;
  isExternal?: boolean;
}

const items: MenuItem[] = [
  {
    to: '/dashboard',
    primary: 'Visão Geral',
    isNew: false,
    isExternal: false,
    exact: true,
    icon: <IconContext icon={<FaTachometerAlt />} className="icon" />,
  },
  {
    to: '/dashboard/lojas',
    primary: 'Lojas',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaStoreAlt />} className="icon" />,
    permission: [PermissaoDescricao.VerEntidade],
  },
  {
    to: '/dashboard/grupos',
    primary: 'Grupos',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaSitemap />} className="icon" />,
    permission: [PermissaoDescricao.VerGrupoPermissoes],
  },
  {
    to: '/dashboard/funcionarios',
    primary: 'Funcionários',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaIdBadge />} className="icon" />,
    permission: [PermissaoDescricao.VerFuncionario],
  },
  {
    to: '/dashboard/clientes',
    primary: 'Clientes',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaUsers />} className="icon" />,
    permission: [PermissaoDescricao.VerCliente],
  },
  {
    to: `${microFrontUrlWithToken('contas')}`,
    primary: 'Contas',
    isNew: false,
    isExternal: true,
    icon: (
      <IconContext
        icon={
          <svg width="22.4px" height="22.4px" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5516 0.351562H3.2C2.54375 0.351562 1.99688 0.588542 1.55938 1.0625C1.12188 1.5 0.903126 2.02865 0.903126 2.64844L0.848438 21.3516C0.848438 21.9714 1.06719 22.5182 1.50469 22.9922C1.97865 23.4297 2.54375 23.6484 3.2 23.6484H17.2C17.8563 23.6484 18.4031 23.4297 18.8406 22.9922C19.3146 22.5182 19.5516 21.9714 19.5516 21.3516V7.35156L12.5516 0.351562ZM8.94219 19L4.84063 14.8438L6.48125 13.2031L8.94219 15.7188L13.9188 10.7422L15.5594 12.3828L8.94219 19ZM11.3484 8.5V2.10156L17.8016 8.5H11.3484Z"
              fill={window.location.href === `${microFrontUrlWithToken('contas')}` ? '#EF6915' : 'rgb(69, 69, 69)'}
            />
          </svg>
        }
        className="icon"
      />
    ),
    permission: [PermissaoDescricao.VerContaPagar],
  },
  {
    to: `${microFrontUrlWithToken('cobrancas')}`,
    primary: 'Cobranças',
    isNew: true,
    isExternal: true,
    icon: (
      <PointOfSaleIcon
        color={window.location.href === `${microFrontUrlWithToken('cobrancas')}` ? '#EF6915' : 'rgb(69, 69, 69)'}
        className="icon"
      />
    ),
    permission: [PermissaoDescricao.VerCobranca],
  },
  // {
  //   to: '/dashboard/reembolsos',
  //   primary: 'Reembolsos',
  //   isNew: false,
  //   isExternal: false,
  //   icon: <IconContext icon={<FaHandHoldingUsd />} className="icon" />,
  //   permission: [PermissaoDescricao.VerPedidoReembolso],
  // },
  {
    to: '/dashboard/emprestimos',
    primary: 'Empréstimos',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaFileInvoiceDollar />} className="icon" />,
    permission: [PermissaoDescricao.VerEmprestimo],
  },
  {
    to: '/dashboard/maquinas',
    primary: 'Máquinas',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaCalculator />} className="icon" />,
    permission: [PermissaoDescricao.VerMaquina],
  },
  {
    to: '/dashboard/relatorios',
    primary: 'Relatórios',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaRegFileExcel />} className="icon" />,
    permission: [
      PermissaoDescricao.FechamentoCaixaRelatorio,
      PermissaoDescricao.ContaPagarRelatorio,
      PermissaoDescricao.PedidoReembolsoRelatorio,
      PermissaoDescricao.ClienteRelatorio,
      PermissaoDescricao.LeadRelatorio,
      PermissaoDescricao.NsuRelatorio,
      PermissaoDescricao.EmprestimoClienteRelatorio,
      PermissaoDescricao.LeadMarketingRelatorio,
    ],
  },
  {
    to: '/dashboard/taxas',
    primary: 'Taxas Administrativas',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaChartPie />} className="icon" />,
    permission: [
      PermissaoDescricao.DefinirTaxaLucroEntidade,
      PermissaoDescricao.DefinirTaxaMinimaEntidade,
      PermissaoDescricao.DefinirTaxaSegurancaEntidade,
    ],
  },
  {
    to: '/dashboard/metas',
    primary: 'Metas',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<ImTarget />} className="icon" />,
    permission: [PermissaoDescricao.VerMeta],
  },
  {
    to: '/dashboard/crm',
    primary: 'CRM',
    isNew: false,
    isExternal: false,
    icon: (
      <div style={{ marginLeft: -2 }}>
        <IconContext
          icon={<MdPerson size={10} style={{ top: 19, left: 23, position: 'absolute' }} />}
          className="icon"
        />
        <IconContext icon={<MdReplay size={28} />} className="icon" />
      </div>
    ),
    permission: [PermissaoDescricao.VerLeads],
  },
  {
    to: '/dashboard/prospeccao',
    primary: 'Prospecção',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaFilter />} className="icon" />,
    permission: [PermissaoDescricao.VerLeads],
  },
  {
    to: '/dashboard/repasses',
    primary: 'Repasses',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaHandshake />} className="icon" />,
    permission: [
      PermissaoDescricao.DashboardParceiro,
      PermissaoDescricao.DashboardFuncionarioParceiro,
      PermissaoDescricao.VerTabelaParceiro,
      PermissaoDescricao.VerParceiro,
    ],
  },
  {
    to: '/dashboard/envios',
    primary: 'Envios',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaTelegramPlane />} className="icon" style={{ left: 15 }} />,
    permission: [
      PermissaoDescricao.DashboardEnviosSms,
      PermissaoDescricao.VerEnviosSms,
      PermissaoDescricao.VerListaSms,
      PermissaoDescricao.VerMensagemSms,
    ],
  },
  {
    to: '/dashboard/configuracao',
    primary: 'Configuração',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaCog />} className="icon" />,
    permission: [PermissaoDescricao.VerCliente],
  },
  {
    to: '/dashboard/zeki-bot',
    primary: 'Zeki Bot',
    isNew: false,
    isExternal: false,
    icon: <IconContext icon={<FaWhatsapp />} className="icon" style={{ left: 15 }} />,
    permission: [PermissaoDescricao.VerBotZeki],
  },

  {
    to: `${microFrontUrlWithToken('fornecedores')}`,
    isExternal: true,
    primary: 'Fornecedores',
    isNew: false,
    icon: <IconContext icon={<ProvidersIcon color="rgb(69, 69, 69)" />} className="icon" style={{ left: 15 }} />,
    permission: [PermissaoDescricao.VerFornecedor],
  },
];

export default items;
