import React, { ReactNode } from 'react';

import { IconContext as RAIconContext } from 'react-icons';

import { rem2px } from 'utils/remToPx';

interface IconContextProp {
  icon: ReactNode;
  color?: string;
  size?: string;
  style?: React.CSSProperties;
  className?: string;
}

export function IconContext({ icon, color = '#454545', size = '1.4rem', style, className }: IconContextProp) {
  const convertedSize = rem2px(size);
  return (
    <RAIconContext.Provider value={{ color, size: convertedSize, style, className }}>{icon}</RAIconContext.Provider>
  );
}
