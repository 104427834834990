import React from 'react';

import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  DialogTitleProps,
  Typography,
  TypographyProps,
  DialogContentProps,
  DialogActionsProps,
} from '@material-ui/core';

export type Props = {
  mainTitle?: string;
  mainTitleProps?: TypographyProps;
  title?: string;
  content?: string | React.ReactNode;
  open?: boolean;
  loading?: boolean;
  onClose?: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
  confirmLabel?: string;
  typography?: boolean;
  typographyProps?: TypographyProps;
  typographyCont?: string;
  DialogContentProps?: DialogContentProps;
  DialogActionsProps?: DialogActionsProps;
  Titleprops?: DialogTitleProps;
  onConfirm?: () => void;
  cancelButtonProps?: React.ComponentProps<typeof Button>;
  confirmButtonProps?: React.ComponentProps<typeof Button>;
} & React.ComponentPropsWithoutRef<typeof MuiDialog>;

export function Dialog({
  mainTitle,
  mainTitleProps,
  title,
  content,
  open,
  loading,
  onClose,
  cancelLabel,
  confirmLabel,
  Titleprops,
  onCancel,
  typography,
  DialogActionsProps,
  children,
  typographyCont,
  typographyProps,
  DialogContentProps,
  onConfirm,
  cancelButtonProps,
  confirmButtonProps,
  ...rest
}: Props) {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
      style={{ width: '100%', height: '100%' }}
    >
      {mainTitle && (
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Typography {...mainTitleProps}>{mainTitle}</Typography>
        </DialogTitle>
      )}
      <DialogTitle {...Titleprops} disableTypography={typography ? typography : false} id="alert-dialog-title">
        {typography ? (
          <Typography {...typographyProps}>{typographyCont}</Typography>
        ) : (
          title || 'Você tem certeza que deseja excluir?'
        )}
      </DialogTitle>
      {!children && (
        <DialogContent {...DialogContentProps}>
          {content || (
            <DialogContentText id="alert-dialog-description">Está ação não poderá ser desfeita.</DialogContentText>
          )}
        </DialogContent>
      )}
      {children && <DialogContent {...DialogContentProps}>{children}</DialogContent>}
      <DialogActions {...DialogActionsProps}>
        {!loading && (
          <>
            <Button onClick={onCancel} color="primary" autoFocus {...cancelButtonProps}>
              {cancelLabel || 'Cancelar'}
            </Button>
            <Button onClick={onConfirm} color="primary" {...confirmButtonProps}>
              {confirmLabel || 'Excluir'}
            </Button>
          </>
        )}
        {loading && (
          <CircularProgress
            size={24}
            css={`
              position: 'absolute';
              top: '50%';
              left: '50%';
              margintop: -12;
              marginleft: -12;
            `}
          />
        )}
      </DialogActions>
    </MuiDialog>
  );
}
