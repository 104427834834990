/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

import { Typography } from '@material-ui/core';
import {
  typography,
  TypographyProps,
  spacing,
  SpacingProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
} from '@material-ui/system';

import filterProps, { StyledFilter } from 'utils/filterProps';

import styled from 'styled-components';

type StyledTextProps = {
  setColor?: string;
  opacity?: string;
  weight?: string;
  fontSize?: number | string;
  style?: React.CSSProperties;
  [key: string]: any;
} & TypographyProps &
  SpacingProps &
  DisplayProps &
  FlexboxProps;

export const TypographyCN = styled(
  filterProps<StyledTextProps>(Typography, ['setColor', 'opacity', 'weight']),
)<StyledTextProps>`
  font-weight: ${p => p.weight || 'bold'};
  color: ${p => p.setColor || p.theme.palette.typographyColor};
  opacity: ${p => p.opacity || '1'};
  ${typography}
  ${p => (isNumber(p?.fontSize) ? `font-size: ${p.fontSize}rem;` : '')}
  ${spacing}
  ${display}
  ${flexbox}
` as StyledFilter<typeof Typography, StyledTextProps>;

function isNumber(likeNumber: unknown): likeNumber is number {
  return typeof Number(likeNumber) === 'number';
}
