import React from 'react';

import { Controller } from 'react-hook-form';

import { AdornmentAttachment } from './AdornmentAttachment';

type ControlledProps = Omit<React.ComponentProps<typeof AdornmentAttachment>, 'onChange'> &
  Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function AdornmentAttachmentCTL({ control, name, ...rest }: ControlledProps) {
  return (
    <Controller
      as={AdornmentAttachment}
      name={name ?? ''}
      control={control}
      onChange={([value]) => value}
      // defaultValue={undefined}
      {...rest}
    />
  );
}
