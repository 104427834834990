import React from 'react';

import { Link } from 'react-router-dom';

import { HeaderCard, HeaderCardConfig } from './HeaderCard';
import { HeaderCardConfigMobile, HeaderCardMobile } from './HeaderCardMobile';

import { useMobile } from 'utils/useMobile';

export type HeaderCardProps = {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  registerUrl?: React.ComponentProps<typeof Link>['to'];
  href?: string;
  value?: number;
  disablePlus?: boolean;
  color: string;
  onClick?: any;
  isExternal?: boolean;
};

export function HeaderCardResponsive(props: HeaderCardProps) {
  return useMobile() ? <HeaderCardMobile {...props} /> : <HeaderCard {...props} />;
}

export function HeaderCardConfigResponsive(props: HeaderCardProps) {
  return useMobile() ? <HeaderCardConfigMobile {...props} /> : <HeaderCardConfig {...props} />;
}
