import { gql } from 'apollo-boost';

import { GerarNovaSenhaFuncionarioMutation } from '../../types/graphql';

export const gerarNovaSenhaMutation = gql`
  mutation gerarNovaSenhaFuncionario($emailFuncionario: String!) {
    gerarNovaSenhaFuncionario(emailFuncionario: $emailFuncionario) {
      ok
      funcionario {
        id
        nome
      }
    }
  }
`;

export interface NewPassType {
  gerarNovaSenhaFuncionario: GerarNovaSenhaFuncionarioMutation;
}
