// @ts-nocheck
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useTable, Column, useBlockLayout } from 'react-table';

import Container, { OverflowContainer, RelativeContainer, TableCell, TableBody, Table } from './styles';
import 'styled-components/macro';

interface Actions {
  [key: string]: (args: any) => void;
}

interface TableInterface<T extends object> {
  columns: Column<T>[];
  data: T[];
  actions?: Actions;
  externalRoute?: boolean;
  redirectTo?: string;
  renderTableCell?: (cell: any) => React.ReactElement;
  selectedRow?: number;
  onCellClick?: () => void;
}

export function TableCN<T extends object>({
  columns,
  data,
  actions,
  redirectTo,
  renderTableCell,
  selectedRow,
  externalRoute,
}: TableInterface<T>) {
  const defaultColumn = { minWidth: 150 };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      ...actions,
    },
    useBlockLayout,
  );

  const [absoluteHeight, setAbsoluteHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setAbsoluteHeight(ref.current.clientHeight);
  }, []);

  return (
    <RelativeContainer minHeight={absoluteHeight}>
      <Container ref={ref}>
        <OverflowContainer>
          <Table {...getTableProps()}>
            <TableBody
              {...getTableBodyProps()}
              columnsCount={headerGroups[0].headers.length}
              cellCount={(rows.length + 1) * headerGroups[0].headers.length}
            >
              {headerGroups.map(headerGroup =>
                headerGroup.headers.map(column => (
                  <TableCell className="header-cell" {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableCell>
                )),
              )}
              {rows.map((row, idx) => {
                prepareRow(row);
                return row.cells.map(cell => {
                  const { bold, a } = cell.column as any;
                  let urlToRedirect = `${redirectTo || 'visualizar'}/${row.original.id}`;
                  if (redirectTo?.includes('?')) {
                    const [url, params] = redirectTo.split('?');
                    urlToRedirect = `${url || 'visualizar'}/${row.original.id}?${params}`;
                  }
                  return (
                    renderTableCell?.(cell) ?? (
                      <TableCell
                        {...cell.getCellProps({
                          bold: bold ? 1 : 0,
                          a: a ? 1 : 0,
                        })}
                        style={idx % 2 === 0 ? { backgroundColor: '#fff' } : undefined}
                      >
                        <Link
                          to={externalRoute ? { pathname: urlToRedirect } : urlToRedirect}
                          onClick={() => {
                            if (externalRoute) window.location.href = urlToRedirect;
                          }}
                          css="text-decoration: none;"
                        >
                          {cell.render('Cell')}
                        </Link>
                      </TableCell>
                    )
                  );
                });
              })}
            </TableBody>
          </Table>
        </OverflowContainer>
      </Container>
    </RelativeContainer>
  );
}
