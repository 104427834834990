import { FilledInput } from '@material-ui/core';

import styled, { css } from 'styled-components';

export const StyledFilledInput = styled(FilledInput)<{
  height?: string;
  pad?: string;
  readOnly?: boolean;
}>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #00000005;

  height: ${props => props.height};

  .MuiFilledInput-input {
    padding: ${props => (props.multiline ? '0 12px 16px' : '21px 12px 16px')};
  }

  ${props =>
    props.readOnly &&
    css`
      &:hover,
      &:focus-within {
        background-color: #fff;
        cursor: pointer;
      }
    `}
`;
