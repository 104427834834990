import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { StyledButton } from './styles';
import 'styled-components/macro';

type Props = React.ComponentProps<typeof StyledButton> & { loading?: boolean };

export function ButtonCN({ children, loading = false, disabled, ...rest }: Props) {
  return (
    <StyledButton variant="contained" color="primary" disabled={loading || disabled} {...rest}>
      {children}
      {loading && (
        <CircularProgress
          size={32}
          css={`
            color: ${green[500]};
            position: absolute;
          `}
        />
      )}
    </StyledButton>
  );
}
