import React from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { ClickAwayListener } from '@material-ui/core';
import { FaSignOutAlt } from 'react-icons/fa';
import { FcMenu } from 'react-icons/fc';
import { IoIosArrowBack } from 'react-icons/io';
import { matchPath, useLocation } from 'react-router-dom';

import 'styled-components/macro';
import { GridItemClick, GridItemExternalLink, GridItemLink } from '../GridItemLink';
import items from './routes';
import { BarMenu, BorderMenu, ButtonMenu, CircleIcon, Drawer, GridMenu, PageName, GoBackButton } from './styles';

import { IconContext } from 'components';
import { recreateUrlWithToken } from 'utils/redirectToMicroFront';
import { useRouter } from 'utils/useRouter';

type Props = {
  open?: boolean;
  onClick?: () => void;
  onClose: () => void;
};

export function Menu({ open = false, onClick, onClose }: Props) {
  const location = useLocation();
  const { go } = useRouter();
  const itemsWithToken = items.map(item => {
    const externalUrlWithToken = recreateUrlWithToken(item.to);
    return { ...item, to: externalUrlWithToken };
  });
  const actualLink = itemsWithToken.find(({ to, exact }) => matchPath(location.pathname, { path: to, exact }));

  const client = useApolloClient();
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Drawer
        variant="permanent"
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          square: false,
          onClick() {
            open && onClose && onClose();
          },
        }}
      >
        {open ? (
          <>
            <div
              style={{
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 0',
                background: 'white',
              }}
            >
              <BorderMenu />
            </div>
            <GridMenu>
              {itemsWithToken.map(item => (
                <>
                  {item?.to?.includes('http') ? (
                    <GridItemExternalLink key={`${item.primary}-${item.to}`} {...item} />
                  ) : (
                    <GridItemLink key={`${item.primary}-${item.to}`} {...item} />
                  )}
                </>
              ))}
              <GridItemClick
                primary="Sair"
                icon={<IconContext icon={<FaSignOutAlt />} color="#EF6915" />}
                onClick={async () => {
                  await client.clearStore();
                  client.writeData({ data: { isLoggedIn: false } });
                  localStorage.clear();
                }}
              />
            </GridMenu>
          </>
        ) : (
          <>
            <BarMenu>
              {actualLink?.to !== location.pathname && (
                <GoBackButton onClick={() => go(-1)}>
                  <IoIosArrowBack size={32} color="#898989" />
                </GoBackButton>
              )}
              <CircleIcon elevation={2}>{actualLink?.icon ?? ''}</CircleIcon>
              <PageName>{actualLink?.primary ?? ''}</PageName>
              <ButtonMenu onClick={() => onClick && onClick()}>
                <FcMenu size={32} />
              </ButtonMenu>
            </BarMenu>
          </>
        )}
      </Drawer>
    </ClickAwayListener>
  );
}
