import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { ContagemDeCategoriaType, EntidadeType } from 'types/graphql';

export * from './addEntityQuery';
export * from './getAllEntity';
export * from './entityPhoneQueries';
export * from './deleteEntityQuery';

export const EntityDataFragment = gql`
  fragment entityFragmentOnStore on EntidadeType {
    id
    nome
    razao
    nomeIdentificacao
    cnpj
    anexoCnpj
    inscricaoEstadual
    endereco
    complemento
    bairro
    municipio
    uf
    cep
    pais
    comprovanteEndereco
    categoria
    taxaMinima
    taxaSeguranca
    latitude
    longitude
    mensagemInvoice
    telefones {
      id
      ddd
      numero
      descricao
    }
    tipoLoja {
      id
      tipoLoja
    }
  }
`;

export const getAllEntity = gql`
  query fdafsfds {
    todasEntidades {
      ...entityFragmentOnStore
    }
  }
  ${EntityDataFragment}
`;

export interface TodasEntidadesData {
  todasEntidades: EntidadeType[];
}

export const getEntity = gql`
  query getEntityValueOnStorePage($id: Int, $nome: String) {
    entidade(id: $id, nome: $nome) {
      ...entityFragmentOnStore
    }
  }
  ${EntityDataFragment}
`;

export const getEntityTableTax = gql`
  query getEntityTableTax($id: Int, $nome: String) {
    entidade(id: $id, nome: $nome) {
      ...entityFragmentOnStore
      tabelataxaparcelasSet {
        id
        valorTabela
        tabelaParcelas {
          parcelas
          taxaMinima
          taxaSeguranca
        }
      }
    }
  }
  ${EntityDataFragment}
`;

export interface EntidadeData {
  entidade: EntidadeType;
}

export const UpdateEntityMutation = gql`
  mutation updateEntityMutationOnStore(
    $anexoCnpj: String
    $bairro: String
    $tipoLoja: String
    $cep: String
    $cnpj: String
    $complemento: String
    $comprovanteEndereco: String
    $endereco: String
    $id: ID!
    $inscricaoEstadual: String
    $mensagemInvoice: String
    $municipio: String
    $nome: String
    $nomeIdentificacao: String
    $pais: String
    $razao: String
    $uf: String
    $taxaMinima: Float
    $taxaSeguranca: Float
    $latitude: String
    $longitude: String
  ) {
    atualizarEntidade(
      anexoCnpj: $anexoCnpj
      bairro: $bairro
      tipoLoja: $tipoLoja
      cep: $cep
      cnpj: $cnpj
      complemento: $complemento
      comprovanteEndereco: $comprovanteEndereco
      endereco: $endereco
      id: $id
      inscricaoEstadual: $inscricaoEstadual
      mensagemInvoice: $mensagemInvoice
      municipio: $municipio
      nome: $nome
      nomeIdentificacao: $nomeIdentificacao
      pais: $pais
      razao: $razao
      uf: $uf
      taxaMinima: $taxaMinima
      taxaSeguranca: $taxaSeguranca
      latitude: $latitude
      longitude: $longitude
    ) {
      ok
      entidade {
        ...entityFragmentOnStore
      }
    }
  }
  ${EntityDataFragment}
`;

export const UpdateEntityPhone = gql`
  mutation UpdateEntityPhone($id: ID!, $ddd: String, $descricao: String, $numero: String!) {
    atualizarTelefoneEntidade(ddd: $ddd, descricao: $descricao, id: $id, numero: $numero) {
      ok
      telefone {
        id
        entidade {
          nome
          razao
          nomeIdentificacao
        }
        ddd
        numero
        descricao
      }
    }
  }
`;

export const storesCountQuery = gql`
  query StoresCountQuery(
    $id: Float
    $grupoIds: [ID]
    $nomeIdentificacao: String
    $nomeIdentificacao_Icontains: String
    $razao: String
    $razaoSocial: String
    $cnpj: String
    $categoria: String
    $inscricaoEstadual: String
    $municipio: String
  ) {
    contarEntidadesPorCategoria(
      id: $id
      grupoIds: $grupoIds
      nomeIdentificacao: $nomeIdentificacao
      nomeIdentificacao_Icontains: $nomeIdentificacao_Icontains
      razao: $razao
      municipio_Icontains: $municipio
      razao_Icontains: $razaoSocial
      cnpj: $cnpj
      categoria: $categoria
      inscricaoEstadual_Icontains: $inscricaoEstadual
    ) {
      totalTipoLojaLojas
      totalTipoLojaParceiros
      totalTipoLojaAssociados
      totalTipoLojaOutros
    }
  }
`;
export interface StoresCountDataType {
  contarEntidadesPorCategoria: ContagemDeCategoriaType;
}

export const taxList = gql`
  query taxList($idEntidade: ID) {
    listaColunas(idEntidade: $idEntidade) {
      parcelas
      tabelataxaparcelas {
        id
        valorTabela
      }
      taxas {
        taxaMinima
        taxaSeguranca
      }
    }
  }
`;

export function useGetTaxesList(id: string | number) {
  return useQuery(taxList, { variables: { idEntidade: id } });
}
