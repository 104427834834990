import React from 'react';

import { Box } from '@material-ui/core';
import { ErrorMessage, FieldErrors } from 'react-hook-form';

import { StyledKeyboardDatePicker } from './styles';

import { TypographyCN } from 'components/TypographyCN';
import { useMobile } from 'utils/useMobile';

type DatePickerProps = React.ComponentProps<typeof StyledKeyboardDatePicker>;
type DateInputCNProps = Omit<DatePickerProps, 'value'> & {
  label?: string;
  sublabel?: string;
  labelProps?: React.ComponentProps<typeof TypographyCN>;
  name?: string;
  errorMessage?: string | FieldErrors<any>;
  boxHeight?: string;
  fontSize?: string;
  value?: DatePickerProps['value'];
};

export function DateInputCN({
  label,
  sublabel,
  labelProps,
  onChange,
  value = null,
  errorMessage,
  boxHeight,
  fontSize,
  ...rest
}: DateInputCNProps) {
  const downBreakPoint = useMobile();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
      height={boxHeight ?? '90px'}
    >
      {label && (
        <TypographyCN
          component="label"
          variant={downBreakPoint ? 'body1' : 'h6'}
          align="center"
          fontSize={fontSize}
          {...labelProps}
        >
          {label}
        </TypographyCN>
      )}
      <StyledKeyboardDatePicker
        // disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        label={sublabel}
        margin="none"
        inputVariant="filled"
        InputProps={{ disableUnderline: !Boolean(errorMessage), error: false }}
        fullWidth
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        invalidLabel="Desconhecido"
        invalidDateMessage="Formato de data inválido"
        maxDateMessage="Data não deve ser maior que 01-01-2100"
        minDateMessage="Data não deve ser menor que 01-01-1900"
        onChange={onChange}
        value={value}
        {...rest}
      />
      {errorMessage && (
        <TypographyCN component="span" variant="body2" align="center" weight="lighter" setColor="red">
          {typeof errorMessage === 'string' ? (
            errorMessage
          ) : (
            <ErrorMessage errors={errorMessage} name={rest.name as string}>
              {({ message }) => message}
            </ErrorMessage>
          )}
        </TypographyCN>
      )}
    </Box>
  );
}
