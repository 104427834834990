import React, { forwardRef } from 'react';

import { ListItem, ListItemIcon, ListItemText, Zoom, Tooltip } from '@material-ui/core';
import { FaExclamationCircle } from 'react-icons/fa';
import { LinkProps, LinkProps as RouterLinkProps, NavLink } from 'react-router-dom';

import { recreateUrlWithToken } from 'utils/redirectToMicroFront';

interface ListItemLinkProps extends LinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  style?: React.CSSProperties;
  className?: string;
  exact?: boolean;
  disableTooltip?: boolean;
  newModel?: boolean;
  disabled?: boolean;
  isExternal?: boolean;
}

export function ListItemLink({
  icon,
  primary,
  exact,
  to,
  style,
  className,
  disableTooltip,
  newModel,
  disabled,
  isExternal,
}: ListItemLinkProps) {
  const externalUrlWithToken = recreateUrlWithToken(to);
  const renderLink = React.useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <NavLink to={to} {...itemProps} innerRef={ref} activeClassName="active" exact={exact} />
      )),
    [isExternal, to, exact],
  );

  return (
    <Tooltip TransitionComponent={Zoom} title={disableTooltip ? '' : primary} placement="right">
      <li style={style} className={className}>
        {isExternal ? (
          <ListItem button component="a" href={externalUrlWithToken} disabled={disabled}>
            {icon &&
              (newModel === true ? (
                <ListItemIcon>
                  <div style={{ display: 'grid' }}>
                    <FaExclamationCircle color="#24c361" style={{ transform: 'translateX(-20%)' }} />
                    {icon}
                  </div>
                </ListItemIcon>
              ) : (
                <ListItemIcon>{icon}</ListItemIcon>
              ))}
            <ListItemText
              style={{
                marginTop: 'auto',
                marginBottom: 0,
              }}
              primary={primary}
            />
          </ListItem>
        ) : (
          <ListItem button component={renderLink} disabled={disabled}>
            {icon &&
              (newModel === true ? (
                <ListItemIcon>
                  <div style={{ display: 'grid' }}>
                    <FaExclamationCircle color="#24c361" style={{ transform: 'translateX(-20%)' }} />
                    {icon}
                  </div>
                </ListItemIcon>
              ) : (
                <ListItemIcon>{icon}</ListItemIcon>
              ))}
            <ListItemText primary={primary} />
          </ListItem>
        )}
      </li>
    </Tooltip>
  );
}

type ListItemExitProps = {
  icon?: React.ReactElement;
  primary: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disableTooltip?: boolean;
};

export const ListItemClick = ({ style, className, icon, primary, onClick, disableTooltip }: ListItemExitProps) => (
  <Tooltip TransitionComponent={Zoom} title={disableTooltip ? '' : primary} placement="right">
    <li {...{ style, className }}>
      <ListItem button onClick={onClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  </Tooltip>
);
