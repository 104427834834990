import { formatISO } from 'date-fns';
import _ from 'lodash';
import { UploadResponseReturn } from 'react-aws-s3';

import { Steps } from '../Create/loanState';
import { LoanSecondStepFormType } from '../formType';

import { S3Client } from 'api';
import { uploadedAttachments } from 'components/AttachmentInput/utils';
import {
  EmprestimoStatus,
  EntidadeTypeRelay,
  EntidadeType,
  MutationCriarEmprestimoArgs,
  EmprestimoFormaPagamentoInput,
  ClienteType,
  NsuInput,
} from 'types/graphql';

export const statusObj = {
  [EmprestimoStatus.Aprovada]: { color: '#45A9FF', name: 'Aprovado' },
  [EmprestimoStatus.EmAnalise]: { color: '#EF6915', name: 'Em Análise' },
  [EmprestimoStatus.Paga]: { color: '#24C361', name: 'Pago' },
  [EmprestimoStatus.AguardandoAssinatura]: { color: '#EF6915', name: 'Aguardando Assinatura' },
  [EmprestimoStatus.Reprovada]: { color: '#EF1D15', name: 'Reprovado' },
  [EmprestimoStatus.Cancelada]: { color: '#898989', name: 'Cancelado' },
  [EmprestimoStatus.Delivery]: { color: '#FFCB00', name: 'Delivery' },
  [EmprestimoStatus.Rascunho]: { color: '#b3b6b7', name: 'Rascunho' },
} as Record<EmprestimoStatus, { color: string; name: string }>;

export const StatusOptions = Object.values(EmprestimoStatus).map((value, idx) => ({
  label: statusObj[value].name,
  value,
  id: idx,
}));

export const getInstallments = (installment: string) => Number(installment.split('_')[1]);

export const calculateInstallmentValue = (value: number, installments: number) => value / installments;

// First: get the number with 3 decimal places only and put the comma 2 decimal places foward
// Second: round the value and back the comma 2 decimal places
// Third: return the value converting to number
function round(value: number, digits: number) {
  return Number(Math.round(Number(value.toFixed(digits + 1) + 'e' + digits)) + 'e-' + digits);
}

export const calculateTotalDue = (wantedValue: number, tax: number) => round(wantedValue * (1 + tax / 100), 2);

export const calculateLimitAvailable = (limitValue: number, tax: number) => round(limitValue / (1 + tax / 100), 2);

export function isInDeliveryState({
  anexoContrato,
  nsus,
}: Partial<{
  anexoContrato: unknown;
  nsus: Array<Partial<Record<'anexoNsuCartao' | 'nsuCv' | 'maquinaOld', unknown>>>;
}>) {
  if (!nsus || nsus?.length === 0) return true;
  const [firstNsu] = nsus;
  return ![firstNsu?.anexoNsuCartao, firstNsu?.nsuCv, firstNsu?.maquinaOld, anexoContrato].every(Boolean);
}

type CalcMinTaxParams = {
  entidade?: EntidadeTypeRelay | EntidadeType;
  parcelas?: number;
  valorDesejado?: number;
  defaultTaxes?: {
    taxaMinima: number;
    taxaSeguranca: number;
  };
  taxKey?: 'taxaMinima' | 'taxaSeguranca';
};

export function calcMinTax({
  entidade,
  parcelas,
  valorDesejado,
  defaultTaxes,
  taxKey = 'taxaMinima',
}: CalcMinTaxParams) {
  if (!entidade) return 0;
  if (!parcelas) return entidade[taxKey] ?? 0;
  const tableFiltered = entidade?.tabelataxaparcelasSet.filter(e => Number(e.valorTabela) <= Number(valorDesejado));
  const orderedTable = _.orderBy(tableFiltered, ['valorTabela'], ['desc']).map(values => values);
  const ascValues = orderedTable.filter(f => f.tabelaParcelas.find(t => getInstallments(t.parcelas) === parcelas));

  const foundInstalment = ascValues?.[0]?.tabelaParcelas.find(table => getInstallments(table.parcelas) === parcelas);
  if (!foundInstalment) {
    return (defaultTaxes?.[taxKey] as number) ?? 0;
  }
  return foundInstalment?.[taxKey] as number;
}

export async function transformLoanFormToInput(
  { mainForm, modalMinTax, firstStep }: { mainForm: LoanSecondStepFormType } & Steps,
  client: ClienteType,
) {
  const { anexoNsuCartao, contas, boletos, tipoAssinatura, nsus = [], ...values } = mainForm;

  const attachments = await uploadedAttachments({ anexoNsuCartao });

  const { anexosTaxaAbaixo, justificativaTaxaAbaixo } = modalMinTax ?? {};
  const taxAttachments = anexosTaxaAbaixo
    ? await Promise.all(
        anexosTaxaAbaixo.map(async anexo => {
          const attachmentIUploaded = (await S3Client.uploadFile(anexo)) as UploadResponseReturn;
          attachmentIUploaded.description = anexo.name;
          return attachmentIUploaded;
        }),
      )
    : undefined;

  const nsusList = await Promise.all(
    nsus.map(async ({ bandeiraCartao, parcelas, anexoNsuCartao, titularDivergente, ...rest }) => {
      const { location } = await S3Client.uploadFile(anexoNsuCartao);
      const uploadedFiles = await uploadedAttachments({
        anexoRg: titularDivergente?.anexoRg,
        anexoCpf: titularDivergente?.anexoCpf,
        comprovanteEndereco: titularDivergente?.comprovanteEndereco ?? undefined,
      });

      return {
        nsuCv: rest.nsuCv,
        valorNsuCv: rest.valorNsuCv,
        maquinaOld: rest.maquinaOld,
        maquinaId: rest.maquina?.id,
        bandeiraCartao: String(bandeiraCartao?.id),
        anexoNsuCartao: location,
        parcelas: parcelas?.id,
        titularDivergente: titularDivergente?.nome
          ? {
              ...uploadedFiles,
              bairro: titularDivergente.bairro,
              cep: titularDivergente.cep,
              complemento: titularDivergente.complemento,
              rg: titularDivergente.rg,
              cpf: titularDivergente.cpf,
              endereco: titularDivergente.endereco,
              municipio: titularDivergente.municipio,
              nome: titularDivergente.nome,
              pais: titularDivergente.pais,
              uf: titularDivergente.uf,
            }
          : null,
      } as NsuInput;
    }),
  );

  const formasPagamento = [
    (contas ?? []).map(({ value, conta }) => ({
      valor: value,
      contaNome: conta.nome,
      contaBanco: conta.banco,
      contaAgencia: conta.agencia,
      contaTipoConta: conta.tipoConta,
      contaConta: conta.conta,
      contaVariacao: conta.variacao,
      contaCpfCnpj: conta.cpfCnpj,
      tipoPix: conta.tipoPix,
      pix: conta.pix,
    })),
    await Promise.all(
      (boletos ?? []).map(async boleto => {
        const { location } = boleto.boletoAnexo ? await S3Client.uploadFile(boleto.boletoAnexo) : { location: '' };
        return {
          valor: Number(boleto.value),
          boletoDataVencimento: formatISO(boleto.date, { representation: 'date' }),
          boletoNumeroControle: boleto.controlNumber,
          boletoCodigoBarra: boleto.barCode,
          boletoAnexo: location,
        };
      }),
    ),
  ].flat() as EmprestimoFormaPagamentoInput[];

  // @ts-ignore
  return {
    ...values,
    ...attachments,
    anexosTaxaAbaixo: taxAttachments
      ? taxAttachments.map(anexo => ({ descricao: anexo.description, url: anexo.location }))
      : null,
    origem: values.origem.origem ?? '',
    idCliente: client?.id,
    idEntidade: String(values.entidade.pk),
    parcelas: String(values.parcelas),
    data: formatISO(new Date(), { representation: 'date' }),
    valorDesejado: Number(values.valorDesejado),
    taxaAplicada: Number(values.taxaAplicada),
    formasPagamento,
    delivery: values.isDelivery,
    justificativaTaxaAbaixo,
    nsus: nsusList,
  } as MutationCriarEmprestimoArgs;
}
