import React, { useState } from 'react';

import { HeaderShipContainer } from './styles';

import { SectionShipPill, TypographyCN } from 'components';

export type SendingType = 'sms' | 'email' | 'whatsapp' | 'push';

interface HeaderShipProps {
  title: string;
  marginTop?: boolean;
  shipPills: boolean;
  onChange?: (value: SendingType) => any;
  disabledShips?: SendingType[];
  selected?: SendingType;
}

export function HeaderShip({ title, marginTop, shipPills, onChange, disabledShips, selected }: HeaderShipProps) {
  return (
    <HeaderShipContainer marginTop={marginTop ? true : false}>
      <TypographyCN component="h3" variant="h5">
        {title}
      </TypographyCN>
      <div />
      {shipPills === true ? (
        <SectionShipPill flexDisplay={false} onChange={onChange} disabled={disabledShips} selected={selected} />
      ) : (
        ' '
      )}
    </HeaderShipContainer>
  );
}
