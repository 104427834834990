import 'styled-components/macro';

import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller, FormContextValues } from 'react-hook-form';

import { GroupFormType } from '../formType';

import { TypographyCN, CheckboxCTL } from 'components';
import { EntidadeType } from 'types/graphql';
import { useGroupedPermissions } from 'utils/useGetPermissionFields';

type Props = {
  formHandlers: FormContextValues<GroupFormType>;
  name: string;
  onSelectEntity?: (entity: EntidadeType) => void;
  disableInputs?: boolean;
};

export function PermissionForm({ formHandlers, name, disableInputs: disabled }: Props) {
  const { control, setValue } = formHandlers;
  const formControlLabelName = name;

  const groupedPermissions = useGroupedPermissions();
  const allCheckName = `${formControlLabelName}.all_`;
  const [selectBool, setSelectBool] = useState(false);

  return (
    <Grid item container css="margin: 4px 0" xs={12} spacing={6}>
      <TypographyCN css="margin: 12px 0" variant="h6">
        PERMISSÕES
      </TypographyCN>
      <Grid container component={'div'} spacing={1} css="padding: 0px; margin-top: 14px; background: transparent">
        {!disabled && (
          <>
            <Grid container item xs={12} justify="space-between">
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={event => event.stopPropagation()}
                onFocus={event => event.stopPropagation()}
                disabled={disabled}
                control={
                  <Controller
                    as={(props: React.ComponentProps<typeof Checkbox>) => {
                      const value = props.checked;
                      return <Checkbox {...{ ...props, value }} />;
                    }}
                    name={allCheckName}
                    onChange={([, value]) => {
                      groupedPermissions.forEach(({ group, permissions }) =>
                        permissions.forEach(({ action }) =>
                          setValue(`${formControlLabelName}.${action}_${group}`, value),
                        ),
                      );
                      setSelectBool(value);
                      return value;
                    }}
                    defaultValue={selectBool}
                    control={control}
                  />
                }
                label="TODAS"
              />
            </Grid>
          </>
        )}
        <Grid container item xs={12}>
          {groupedPermissions.map(grouped => (
            <Grid md={3} item css="flex-grow: 1" key={grouped.group + grouped.displayName}>
              <PermissionPanel
                grouped={grouped}
                disabled={disabled}
                formHandlers={formHandlers}
                formControlLabelName={formControlLabelName}
              />
            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="space-between" mt={2} width={1} style={{ gridColumn: '1/6' }}>
          <TypographyCN variant="caption" opacity="0.5" weight="regular">
            Escolha quantas desejar
          </TypographyCN>
          <TypographyCN variant="caption" opacity="0.5" weight="regular">
            Revise as Definições
          </TypographyCN>
        </Box>
      </Grid>
    </Grid>
  );
}

type PanelProp = {
  grouped: ReturnType<typeof useGroupedPermissions>[0];
  disabled?: boolean;
  formHandlers: FormContextValues<GroupFormType>;
  formControlLabelName: string;
};

export function PermissionPanel({ grouped, disabled, formControlLabelName, formHandlers }: PanelProp) {
  const { group, permissions, displayName: groupName } = grouped;
  const { setValue, control, watch } = formHandlers;

  const allCheckName = `${formControlLabelName}.all_${group}`;

  const checks = watch(permissions.map(({ action }) => `${formControlLabelName}.${action}_${group}`)) as Record<
    string,
    boolean
  >;

  const selecteds = Object.values(checks ?? {}).filter(Boolean);
  const selectBool = permissions.length === selecteds.length && selecteds.length !== 0;

  setValue(allCheckName, Boolean(selectBool));

  return (
    <ExpansionPanel css="margin: 2px 4px !important;" defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={groupName}
        aria-controls="additional-actions1-content"
      >
        <FormControlLabel
          aria-label="Acknowledge"
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
          disabled={disabled}
          control={
            <Controller
              as={(props: React.ComponentProps<typeof Checkbox>) => {
                const value = props.checked;
                return <Checkbox {...{ ...props, value }} />;
              }}
              name={allCheckName}
              onChange={([, value]) => {
                setValue(
                  permissions.map(({ action }) => ({
                    [`${formControlLabelName}.${action}_${group}`]: value,
                  })),
                );
                return value;
              }}
              defaultValue={selectBool}
              control={control}
            />
          }
          label={groupName}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        css={`
          display: flex;
          flex-direction: column;
        `}
      >
        {permissions.map(({ action, displayName: actionName }) => (
          <CheckboxCTL
            key={formControlLabelName + actionName}
            disabled={disabled}
            label={actionName}
            control={control}
            name={`${formControlLabelName}.${action}_${group}`}
          />
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
