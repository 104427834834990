import { Paper } from '@material-ui/core';

import { TypographyCN } from 'components/TypographyCN';
import { ITheme } from 'styles';

import styled from 'styled-components/macro';

export const PaperContainer = styled(Paper)`
  width: 320px;
  height: 225px;
  background: #fff;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 1230px) {
    width: 240px;
  }

  @media screen and (max-width: 960px) {
    width: 320px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    font-size: 1rem;
  }
`;

export const PaperContainerMobile = styled(Paper)`
  display: flex;
  flex-direction: row;
  /* min-width: 362px;
  max-width: 362px; */
  height: 54px;
  margin-bottom: 6px;
  /* @media screen and (max-width: 372px) {
    min-width: 348px;
  }
  @media screen and (max-width: 321px) {
    min-width: 306px;
  } */
  width: 100%;
`;

export const HeaderTitle = styled(TypographyCN)`
  font-size: 1rem;
  color: #f59019;
  margin-left: 8px;
`;

export const HeaderValue = styled(TypographyCN)`
  font-size: 1.2rem;
  color: #454545;
  margin-right: 0px;
`;

export const HeaderValueBalance = styled(TypographyCN)`
  font-size: 1.2rem;
  color: #454545;

  @media screen and (max-width: 367px) {
    margin-right: 6px;
  }
`;

export const PlusIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-right: 5px;
`;
