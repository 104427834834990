import React from 'react';

import { Box } from '@material-ui/core';
import { FaPlusCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { HeaderCardProps } from '.';
import { HeaderTitle, HeaderValue, PaperContainerMobile, PlusIconContainer } from './styles';

import { IconContext } from 'components';

export function HeaderCardMobile({
  icon,
  title,
  value,
  disablePlus,
  registerUrl,
  color,
  isExternal = false,
  href,
}: HeaderCardProps) {
  return (
    <PaperContainerMobile>
      <Box display="flex">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="0px"
          bgcolor="#D9D9D980"
          height="54px"
          width="60px"
        >
          {<IconContext size="2.25rem" icon={icon} color={color} />}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="17rem" paddingRight="10px">
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderValue>{value}</HeaderValue>
      </Box>
      <PlusIconContainer>
        {!isExternal ? (
          <>
            {disablePlus || (
              <Link to={registerUrl || ''} css="height: 50%;">
                <IconContext size="2rem" icon={<FaPlusCircle />} color="#24C361" />
              </Link>
            )}
          </>
        ) : (
          <>
            {disablePlus || (
              <a href={href || ''} css="height: 50%;">
                <IconContext size="2rem" icon={<FaPlusCircle />} color="#24C361" />
              </a>
            )}
          </>
        )}
      </PlusIconContainer>
    </PaperContainerMobile>
  );
}

export function HeaderCardConfigMobile({ icon, title, value, disablePlus, onClick, color }: HeaderCardProps) {
  return (
    <PaperContainerMobile>
      <Box display="flex">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="0px"
          bgcolor="#D9D9D980"
          height="54px"
          width="60px"
        >
          {<IconContext size="2.25rem" icon={icon} color={color} />}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="17rem" paddingRight="10px">
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderValue>{value}</HeaderValue>
      </Box>
      <PlusIconContainer>
        {disablePlus || (
          <button
            onClick={onClick}
            type="button"
            css="cursor: pointer; height: 50%;"
            style={{
              background: '#FFFFFF',
              border: 0,
            }}
          >
            <IconContext size="2rem" icon={<FaPlusCircle />} color="#24C361" />
          </button>
        )}
      </PlusIconContainer>
    </PaperContainerMobile>
  );
}
