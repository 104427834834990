import { DialogTitle } from '@material-ui/core';

import { ButtonCN } from 'components/ButtonCN';
import { theme } from 'styles';

import styled from 'styled-components/macro';

export const ForgotPasswordTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: bold;
  color: #454545;
  font-size: 35px;
  ${theme.breakpoints.down(470)} {
    font-size: 20px;
  }
` as typeof DialogTitle;

export const ForgotPasswordButton = styled(ButtonCN)`
  background-color: #24c361;
  margin-bottom: 40;
  margin-top: 10;
  width: 291px;
  height: 65px;
  ${theme.breakpoints.down(470)} {
    width: 161px;
    height: 55px;
  }
  .MuiButton-label {
    font-size: 1.2rem;
  }
` as typeof ButtonCN;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.breakpoints.up(470)} {
    margin: 40px;
    margin-bottom: 18px;
  }
`;
