import React, { useEffect, useState } from 'react';

import { Box, Checkbox, CircularProgress } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { ErrorMessage, FieldErrors } from 'react-hook-form';
import { FaRegCaretSquareDown } from 'react-icons/fa';
import 'styled-components/macro';
import { XOR } from 'ts-essentials';

import { makeRenderTags, StyledTextInput } from './styles';

import { TypographyCN } from 'components/TypographyCN';
import { useMobile } from 'utils/useMobile';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type MultipleOption = {
  id: string;
  [key: string]: any;
};

type CommonProps = {
  label?: string;
  labelProps?: React.ComponentProps<typeof TypographyCN>;
  boxHeight?: string;
  loading?: boolean;
  maxWidth?: string;
  className?: string;
  style?: React.CSSProperties;
  name?: string;
  errorMessage?: string | FieldErrors<any>;
  fontSize?: string;
  justifyCenter?: boolean;
  autoSelectOne?: boolean;
  detailClient?: boolean;
  height?: string;
  canWrite?: boolean;
};

type Props = XOR<CommonProps, CommonProps & { multiple: true; selectAll?: boolean; selectAllOptions: MultipleOption }>;

type AutocompleteCNProps = Props & Omit<React.ComponentProps<typeof Autocomplete>, 'renderInput'>;

export function AutoCompleteCN({
  label,
  labelProps,
  loading,
  multiple,
  boxHeight,
  errorMessage,
  className,
  style,
  value = multiple ? [] : '',
  onChange,
  options = [],
  selectAll,
  selectAllOptions = { id: 'idSelectAll', label: 'SELECIONAR TODOS' },
  getOptionLabel = (value: any) => value?.label || value || '',
  fontSize,
  autoSelectOne,
  maxWidth,
  justifyCenter,
  placeholder = '',
  detailClient,
  height,
  canWrite,
  ...other
}: AutocompleteCNProps) {
  const [checkedAll, setCheckedAll] = useState<{ event?: React.ChangeEvent<{}>; value: boolean }>({
    value: false,
  });

  function onLiClick(event: React.ChangeEvent<HTMLLIElement>) {
    if (event.target.innerText === getOptionLabel(selectAllOptions) && checkedAll.value === false) {
      setCheckedAll({ event, value: true });
      onChange && onChange(event, options);
    }
    if (event.target.innerText === getOptionLabel(selectAllOptions) && checkedAll.value === true) {
      setCheckedAll({ event, value: false });
      onChange && onChange(event, []);
    }
  }

  function onCheckBoxClick(event: React.ChangeEvent<HTMLInputElement>, option: MultipleOption) {
    if (option.id === selectAllOptions.id && checkedAll.value === false) {
      setCheckedAll({ event, value: true });
      onChange && onChange(event, options);
    }
    if (option.id === selectAllOptions.id && checkedAll.value === true) {
      setCheckedAll({ event, value: false });
      onChange && onChange(event, []);
    }
  }

  useEffect(() => {
    if (
      Array.isArray(value) &&
      Array.isArray(options) &&
      value.length > 0 &&
      value[0].id !== selectAllOptions.id &&
      value.length < options.length &&
      checkedAll.value
    ) {
      setCheckedAll(oldValue => ({ ...oldValue, value: false }));
    }
    if (Array.isArray(value) && Array.isArray(options) && value.length === options.length && options.length > 0) {
      setCheckedAll(oldValue => ({ ...oldValue, value: true }));
    }
  }, [value, options, selectAllOptions.id, checkedAll.value]);

  function optionsSelectAll() {
    if (selectAll) {
      return [selectAllOptions, ...options];
    }
    return options;
  }

  function getChecked(selected: boolean, isCheckedField: boolean) {
    if (isCheckedField) {
      return checkedAll.value;
    }
    return selected;
  }

  useEffect(() => {
    if (autoSelectOne && (options?.length ?? 0) === 1) {
      if (!multiple) {
        onChange && onChange(checkedAll.event!, options[0]);
      }
      if (multiple) {
        onChange && onChange(checkedAll.event!, options);
      }
    }
  }, [options.length, autoSelectOne, multiple]);

  const downBreakPoint = useMobile();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent={justifyCenter ? 'center' : 'space-between'}
      minHeight={boxHeight || '90px'}
      width="100%"
    >
      {label && (
        <TypographyCN
          component="label"
          variant={downBreakPoint ? 'body1' : 'h6'}
          align="center"
          fontSize={fontSize}
          {...labelProps}
        >
          {label}
        </TypographyCN>
      )}
      <Autocomplete
        loading={loading}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        ListboxProps={{
          onClick: onLiClick,
        }}
        style={{ width: '100%', height: height }}
        popupIcon={loading ? <CircularProgress color="inherit" size={20} /> : <FaRegCaretSquareDown />}
        renderOption={
          multiple
            ? (option: MultipleOption, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={getChecked(selected, option.id === selectAllOptions.id)}
                    onChange={e => onCheckBoxClick(e, option)}
                  />
                  {getOptionLabel(option)}
                </>
              )
            : undefined
        }
        renderTags={makeRenderTags<MultipleOption>(getOptionLabel)}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <StyledTextInput
            {...params}
            onChange={event => {
              canWrite && onChange && onChange(event, { id: '', label: event.target.value });
            }}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disabled: true,
              disableUnderline: !Boolean(errorMessage),
              fullWidth: true,
              placeholder: placeholder,
              readOnly: detailClient ?? false,
            }}
            className={className}
            style={style}
            fullWidth
            // @ts-expect-error
            maxWidth={maxWidth}
            css={`
              .MuiFilledInput-input {
                ${detailClient ? 'background-color: #00000001 !important' : undefined}
              }
              .MuiFilledInput-root {
                ${detailClient ? 'background-color: #0000001B !important' : undefined}
              }
            `}
          />
        )}
        {...other}
        value={value}
        onChange={(event, value) => {
          onChange && onChange(event, value);
          if (value.length === 0) setCheckedAll({ event, value: false });
        }}
        getOptionLabel={getOptionLabel}
        options={optionsSelectAll()}
      />
      {errorMessage && (
        <TypographyCN component="span" variant="body2" align="center" weight="lighter" setColor="red">
          {typeof errorMessage === 'string' ? (
            errorMessage
          ) : (
            <ErrorMessage errors={errorMessage} name={other.name as string}>
              {({ message }) => message}
            </ErrorMessage>
          )}
        </TypographyCN>
      )}
    </Box>
  );
}
