import React from 'react';

import { Box } from '@material-ui/core';

export const Loading = () => (
  <Box display="flex" height="100%" justifyContent="center" alignItems="center">
    Carregando...
  </Box>
);

export * from './fullscreenLoading';
