import S3 from 'react-aws-s3';

const ID = 'AKIAR3EYJOXMNYND664Y';
const SECRET = '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';
const BUCKET_NAME = 'casa-nova-staging';

export const S3Client = new S3({
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET ?? BUCKET_NAME,
  region: 'sa-east-1',
  accessKeyId: process.env.REACT_APP_AWS_S3_ID ?? ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET ?? SECRET,
});
