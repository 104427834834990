import React from 'react';

import { Box } from '@material-ui/core';
import { FaPlusCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { HeaderCardProps } from '.';
import { PaperContainer } from './styles';

import { IconContext } from 'components/IconContext';
import { TypographyCN } from 'components/TypographyCN';
import { useStyledTheme } from 'styles';
import 'styled-components/macro';

export function HeaderCard({
  icon,
  subtitle,
  title,
  registerUrl,
  disablePlus,
  value = 0,
  color,
  isExternal = false,
  href,
}: HeaderCardProps) {
  const theme = useStyledTheme();

  return (
    <PaperContainer>
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F4F4F4"
          height="90px"
          width="90px"
          borderRadius="50%"
        >
          {<IconContext size="3.5rem" icon={icon} color={color} />}
        </Box>
        {!isExternal ? (
          <>
            {disablePlus || (
              <Link to={registerUrl || ''} css="height: 50%;">
                <IconContext size="2rem" icon={<FaPlusCircle />} color="#24C361" />
              </Link>
            )}
          </>
        ) : (
          <>
            {disablePlus || (
              <a href={href || ''} css="height: 50%;">
                <IconContext size="2rem" icon={<FaPlusCircle />} color="#24C361" />
              </a>
            )}
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end" alignSelf="flex-end">
        <TypographyCN variant="h3">{value}</TypographyCN>
        <TypographyCN setColor={theme.palette.primary.main}>{title}</TypographyCN>
        <TypographyCN setColor={theme.palette.primary.main}>{subtitle}</TypographyCN>
      </Box>
    </PaperContainer>
  );
}

export function HeaderCardConfig({ icon, subtitle, title, onClick, disablePlus, value = 0, color }: HeaderCardProps) {
  const theme = useStyledTheme();

  return (
    <PaperContainer>
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F4F4F4"
          height="90px"
          width="90px"
          borderRadius="50%"
        >
          {<IconContext size="3.5rem" icon={icon} color={color} />}
        </Box>
        {disablePlus || (
          <button onClick={onClick} type="button" css="height: 50%; background: none; border: none; cursor: pointer;">
            <IconContext size="2rem" icon={<FaPlusCircle />} color="#24C361" />
          </button>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end" alignSelf="flex-end">
        <TypographyCN variant="h3">{value}</TypographyCN>

        <TypographyCN setColor={theme.palette.primary.main}>{title}</TypographyCN>
        <TypographyCN setColor={theme.palette.primary.main}>{subtitle}</TypographyCN>
      </Box>
    </PaperContainer>
  );
}
