import React, { useEffect, useState } from 'react';

import { IconButton, Link } from '@material-ui/core';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FaRegFileAlt, FaRegTrashAlt } from 'react-icons/fa';

import 'styled-components/macro';
import { ArgumentTypes, FileWithPreview } from './utils';

import { IconContext, InputAdornmentIcon, TypographyCN } from 'components';

type Props = {
  onDrop?: DropzoneOptions['onDrop'];
  onChange?: (file: FileWithPreview | null) => void;
  value?: FileWithPreview;
  disabled?: boolean;
};

export function AdornmentAttachment({ onDrop, onChange, value, disabled }: Props) {
  const [file, setFile] = useState<FileWithPreview | null>(null);

  async function droping(...args: ArgumentTypes<DropzoneOptions['onDrop']>) {
    const accepteds = args[0];
    const accepted = accepteds[0] as FileWithPreview;
    accepted.preview = URL.createObjectURL(accepted);
    setFile(accepted);

    onDrop && onDrop(...args);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: droping,
    multiple: false,
    disabled,
  });

  function removeFile() {
    setFile(null);
  }

  useEffect(() => {
    onChange && onChange(file);
  }, [file]);

  useEffect(() => {
    if (value instanceof File) setFile(value);
  }, [value]);

  if (file)
    return (
      <>
        <TypographyCN
          as={Link}
          css={`
            margin-left: 10px;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-decoration: underline;
          `}
          href={file.preview}
          target="_blank"
          rel="noopener"
        >
          {file.name}
        </TypographyCN>
        <IconButton aria-label="delete" disabled={disabled} onClick={removeFile}>
          <IconContext icon={<FaRegTrashAlt />} color="#EF6D1C" />
        </IconButton>
      </>
    );
  else
    return (
      <>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <InputAdornmentIcon
            disabled={disabled}
            width={'135px'}
            label="Anexar Arquivo"
            icon={<IconContext icon={<FaRegFileAlt />} color="#898989" size="1.8rem" />}
          />
        </div>
      </>
    );
}
