import { parseToNumber } from 'brazilian-values';
import { format, parseISO } from 'date-fns';

export const Masks = {
  CPF: '###.###.###-##',
  CNPJ: '##.###.###/####-##',
  CEP: '#####-###',
  RG: '##.###.###-#',
  CPF_CNPJ(valueToFormat: string) {
    if (valueToFormat.length < 3) return valueToFormat;
    if (valueToFormat.length < 6) return `${valueToFormat.slice(0, 3)}.${valueToFormat.slice(3)}`;
    if (valueToFormat.length < 9)
      return `${valueToFormat.slice(0, 3)}.${valueToFormat.slice(3, 6)}.${valueToFormat.slice(6)}`;
    if (valueToFormat.length < 12)
      return `${valueToFormat.slice(0, 3)}.${valueToFormat.slice(3, 6)}.${valueToFormat.slice(
        6,
        9,
      )}-${valueToFormat.slice(9)}`;

    return `${valueToFormat.slice(0, 2)}.${valueToFormat.slice(2, 5)}.${valueToFormat.slice(
      5,
      8,
    )}/${valueToFormat.slice(8, 12)}-${valueToFormat.slice(12, 14)}`;
  },
  MAX_WITHOUT_POUNTS: (max: number) => (value: string) => value.slice(0, max),
  TELEFONE: '(##) #####-####',
  SMARTPHONE: '+## (##) 9####-####',
  DDD: '##',
};

export function formatedToNumber(formatedValue: string) {
  const numberValue = formatedValue.replace(/\D/g, '');
  return Number(`${numberValue.slice(0, numberValue.length - 2)}.${numberValue.slice(-2)}`);
}

export function parseBrlToNumber(brlValue: string) {
  return parseToNumber(brlValue.replace(/[^0-9\,]/g, ''));
}

export function formatNumber(numberValue: number, prefix = 'R$ ', sufix = '') {
  return `${prefix}${numberValue.toFixed(2).replace('.', ',')}${sufix}`;
}

export function formatDate(isoDate: string) {
  return format(parseISO(isoDate), 'dd/MM/yyyy');
}

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};
