import React from 'react';

import { theme } from 'styles';

import styled from 'styled-components/macro';

export const Table = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 470px) {
    .MuiToolbar-gutters {
      padding-left: 6px;
    }

    .MuiTablePagination-selectRoot {
      margin-right: 10px;
    }

    .MuiTablePagination-actions {
      margin-left: 0px;
    }

    .MuiIconButton-root {
      padding: 4px;
    }
  }
`;

export const TableBody = styled.div<{ columnsCount: number; cellCount: number; topComponent: boolean }>`
  display: grid !important;
  align-items: center;
  grid-template-columns: repeat(${p => p.columnsCount}, 1fr);
  align-items: stretch;

  & > :nth-child(${p => p.cellCount - p.columnsCount + 1}) {
    border-bottom-left-radius: 8px;
  }

  & > :nth-child(${p => p.cellCount}) {
    border-bottom-right-radius: 8px;
  }

  border-radius: 8px;

  .header-cell {
    min-height: 58px;
    min-width: 181px;
    color: ${theme.palette.primary.main};
    overflow-wrap: normal;

    &:first-child {
      background: #fff;
      border-top-left-radius: ${props => (props.topComponent ? '0px' : '8px')};
    }

    &:last-of-type {
      border-top-right-radius: ${props => (props.topComponent ? '0px' : '8px')};
    }
  }
`;

type CellProps = {
  bold?: boolean;
  underline?: boolean;
  topComponent?: boolean;
  width?: number;
} & React.ComponentProps<'div'>;

function handleWidth(props: CellProps) {
  let defaultWidth = '100% !important';

  if (props?.style?.width) {
    defaultWidth = `${props?.style?.width}px !important`;
  }
  if (props?.width) {
    defaultWidth = `${props?.width}px !important`;
  }

  return defaultWidth;
}

export const TableCell = styled.div<CellProps>`
  background: #f7f8f9 0% 0% no-repeat padding-box;
  display: flex !important;
  width: ${handleWidth};
  min-width: 100% !important;
  align-items: center;
  min-height: 100%;
  padding: 8px 16px;
  border: ${props => (props.topComponent ? '1px solid lightgray' : '1px solid #30303030')};

  text-decoration: ${props => props.underline && 'underline'};
  font-weight: ${props => props.bold && 'bold'};
  text-decoration: none;
  color: ${theme.palette.text.primary};
  min-height: 0;

  & a:link,
  & a:visited,
  & a:hover,
  & a:active {
    color: rgba(0, 0, 0, 0.87);
    overflow: 'hidden';
    text-overflow: 'ellipsis';
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px;
`;

export const OverflowContainer = styled.div`
  flex: 1;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000000f;
`;

export const RelativeContainer = styled.div<{ minHeight?: number }>`
  position: relative;
  width: 100%;
  min-height: ${p => p.minHeight}px;
  padding-bottom: 24px;
`;

export default Container;
