import 'styled-components/macro';
import React, { useState } from 'react';

import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { BsPeopleCircle } from 'react-icons/bs';
import { FaCog, FaPlusSquare, FaBell } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { config } from '../../../package.json';
import { Menu } from '../Menu';
import { ActionsModal } from './ActionsModal';
import { LogoutButton } from './logoutButton';
import items from './routes';
import {
  AppBarSpacer,
  DrawerItemsContainer,
  StyledAppBarTitle,
  StyledDrawer,
  StyledDrawerAppBar,
  StyledMainContent,
} from './styles';

import { ChangePassword } from 'components/ChangePassword';
import { IconContext } from 'components/IconContext';
import { ListItemClick, ListItemLink } from 'components/ListItemLink';
import { TypographyCN } from 'components/TypographyCN';
import { MenuItemReport } from 'pages/Report/Components/MenuItem';
import { useStyledTheme } from 'styles';
import { useGetGlobalPermission } from 'utils/getEmployeeGlobalPermissions';
import { recreateUrlWithToken } from 'utils/redirectToMicroFront';
import { useGetLoggedEmployeeNoCache } from 'utils/useGetEmployee';
import { useGetPermissions } from 'utils/useHasPermission';
import { useIsLoggedIn } from 'utils/useInLoggedIn';
import { useMobile } from 'utils/useMobile';

interface Props {
  children: React.ReactNode;
  title: string;
}

export function Drawer({ children, title }: Props) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawer = () => {
    setOpenDrawer(prevOpen => !prevOpen);
  };
  const handleClickClose = () => {
    setOpenDrawer(false);
  };

  const [permissions, { loading }] = useGetPermissions();

  const { push } = useHistory();
  const isLogged = useIsLoggedIn();
  if (isLogged === false) push('/');

  const [openActionModal, setOpenActionModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  const [open, setOpen] = useState(false);

  const theme = useStyledTheme();
  const downBreakPoint = useMediaQuery(theme.breakpoints.down(470));

  const { data } = useGetLoggedEmployeeNoCache(true);

  const [success, setSuccess] = useState(false);

  const itemsWithValidToken = items.map(item => {
    const externalUrlWithToken = recreateUrlWithToken(item?.to);
    return { ...item, to: externalUrlWithToken };
  });
  const itemsNotConfig = itemsWithValidToken.filter(e => e.to !== '/dashboard/configuracao');
  const itemsConfig = itemsWithValidToken.filter(e => e.to === '/dashboard/configuracao');

  const isMobile = useMobile();

  const hasGlobalPermission =
    data?.permissaoglobal?.origens ||
    data?.permissaoglobal?.lojas ||
    data?.permissaoglobal?.emprestimos ||
    data?.permissaoglobal?.funcionarios ||
    data?.permissaoglobal?.contasAPagar;

  return (
    <Box display="flex">
      {!downBreakPoint && (
        <StyledDrawerAppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              css="margin-right: 36px"
            >
              <MenuIcon />
            </IconButton>
            <Box width={'100%'}>
              <StyledAppBarTitle variant="h6" noWrap>
                {title}
              </StyledAppBarTitle>
              <TypographyCN setColor="white" fontSize={0.75}>
                Versão: {config?.appVersion}
              </TypographyCN>
            </Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {data?.relatorios && data.relatorios.length > 0 && (
                <>
                  <ButtonBase onClick={handleMenu} style={{ marginRight: 15 }}>
                    <FaBell size={34} />
                  </ButtonBase>
                  <MenuItemReport reports={data.relatorios} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} />
                </>
              )}
              <TypographyCN setColor="white" mr={2}>
                {data?.nome ?? ''}
              </TypographyCN>
              <ButtonBase onClick={() => setOpen(true)}>
                <BsPeopleCircle size={34} />
              </ButtonBase>
              <ChangePassword
                open={open}
                success={success}
                onClose={() => {
                  setOpen(false);
                  setSuccess(false);
                }}
                setSuccess={setSuccess}
              />
            </div>
          </Toolbar>
        </StyledDrawerAppBar>
      )}
      {downBreakPoint ? (
        <Menu open={openDrawer} onClick={handleDrawer} onClose={handleClickClose} />
      ) : (
        <StyledDrawer
          variant="permanent"
          open={openDrawer}
          PaperProps={{
            onClick() {
              openDrawer && setOpenDrawer(!openDrawer);
            },
          }}
        >
          <DrawerItemsContainer openDrawer={openDrawer}>
            <Box>
              <AppBarSpacer />
              <Divider />
              <List>
                {loading && (
                  <ListItemLink
                    key={'loading-key'}
                    disableTooltip={openDrawer}
                    to="/dashboard"
                    primary="Carregando"
                    exact
                    icon={<IconContext icon={<CircularProgress size={32} />} className="icon" />}
                  />
                )}
                {itemsNotConfig.map(
                  item =>
                    (item.permission === undefined || item.permission.some(perm => permissions.includes(perm))) && (
                      <ListItemLink
                        key={`${item.primary}-${item.to}`}
                        disableTooltip={openDrawer}
                        {...item}
                        newModel={item.isNew}
                        isExternal={item.isExternal}
                      />
                    ),
                )}
              </List>
              <List>
                <ListItemClick
                  onClick={() => setOpenActionModal(true)}
                  icon={<IconContext icon={<FaPlusSquare />} color="#24C361" />}
                  primary="Adicionar"
                  disableTooltip={openDrawer}
                />
              </List>
            </Box>
            <Box>
              <Divider />
              <List>
                {itemsConfig.map(
                  item =>
                    (item.permission === undefined || item.permission.some(perm => permissions.includes(perm))) && (
                      <ListItemLink
                        key={`${item.primary}-${item.to}`}
                        disableTooltip={openDrawer}
                        disabled={!hasGlobalPermission && !isMobile}
                        {...item}
                      />
                    ),
                )}
                <LogoutButton disableTooltip={openDrawer} />
              </List>
            </Box>
          </DrawerItemsContainer>
        </StyledDrawer>
      )}
      <StyledMainContent>
        {!downBreakPoint && <AppBarSpacer />}
        {children}
        {downBreakPoint && <AppBarSpacer />}
      </StyledMainContent>
      <ActionsModal open={openActionModal} onClose={() => setOpenActionModal(false)} />
    </Box>
  );
}
