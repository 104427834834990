import styled from 'styled-components';

interface ShipPillProps {
  isActive?: boolean;
}

export const PillContainer = styled.section<{ flexDisplay?: boolean }>`
  max-height: 38px;

  ${props =>
    props.flexDisplay &&
    `
    width: 100%;
    display: flex;
    align-items: center;
  `}
`;

export const ShipPill = styled.button<ShipPillProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  background-color: ${props => (props.isActive ? '#24C361' : props.disabled ? 'inherit' : '#d9d9d9')};
  color: ${props => (props.isActive ? 'white' : props.disabled ? '#d9d9d9' : '#000')};

  border: ${props => (props.disabled ? '1px solid #d9d9d9' : 'none')};
  border-radius: 1.4rem;

  padding: 0.6rem 0.8rem;
  margin: 0 0.4rem;

  @media (max-width: 560px) {
    padding: 0.4rem 0.8rem;
    margin: 0 0.35rem;
    font-size: 0.8rem;
  }
`;
