import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useIsLoggedIn } from 'utils/useInLoggedIn';

type Props = {
  component: React.ComponentType<any>;
  redirectTo?: string;
  path: string;
  [key: string]: any;
};

export function PrivateRoute({ component: Component, redirectTo = '/', path, ...rest }: Props) {
  const isLogged = useIsLoggedIn();
  return (
    <Route
      {...rest}
      render={routerProps => (isLogged ? <Component {...routerProps} /> : <Redirect to={redirectTo} from={path} />)}
    />
  );
}
