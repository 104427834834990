import React from 'react';

import { Box } from '@material-ui/core';

import Container from './styles';

import { HelpButton } from 'components';
import { TypographyCN } from 'components/TypographyCN';
import { useMobile } from 'utils/useMobile';

type Props = {
  title: string;
  subtitle: React.ReactNode;
  height?: string;
  titleProps?: React.ComponentProps<typeof TypographyCN>;
  subtitleProps?: React.ComponentProps<typeof TypographyCN>;
  style?: React.CSSProperties;
  className?: string;
  actionComponent?: React.ReactNode;
  disableHelpButton?: boolean;
  footerComponent?: React.ReactNode;
  justifyContent?: string;
  boxOneProps?: React.ComponentProps<typeof Box>;
  boxTwoProps?: React.ComponentProps<typeof Box>;
  isShipment?: boolean;
};

export function PageTitle({
  title,
  subtitle,
  titleProps,
  actionComponent,
  subtitleProps,
  height,
  style,
  className,
  disableHelpButton,
  footerComponent,
  justifyContent,
  boxOneProps,
  boxTwoProps,
  isShipment,
}: Props) {
  const isMobile = useMobile();
  const isWide = useMobile(1250);
  return (
    <Container height={height} {...{ style, className }}>
      <Box
        display="flex"
        flexDirection={'row'}
        alignItems={isWide ? 'flex-start' : 'center'}
        justifyContent="flex-start"
        width="100%"
        style={{ gap: '1rem' }}
        {...boxOneProps}
      >
        <TypographyCN component="h3" variant="h4" align={!isShipment ? 'left' : ''} {...titleProps}>
          {title}
        </TypographyCN>
        {!isMobile && !disableHelpButton && !isShipment && <HelpButton />}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        {...boxTwoProps}
      >
        <TypographyCN component="h2" variant="h5" align="left" {...subtitleProps}>
          {subtitle}
        </TypographyCN>
        {actionComponent}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={justifyContent || 'flex-start'}
        width="100%"
      >
        {footerComponent}
      </Box>
    </Container>
  );
}
