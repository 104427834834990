import { gql, MutationUpdaterFn } from 'apollo-boost';

import { AllEntityRelay, getAllEntityRelay } from './index';

import { ExcluirEntidadeMutation } from 'types/graphql';

export const deleteEntity = gql`
  mutation DeleteEntity($id: ID!) {
    excluirEntidade(id: $id) {
      ok
    }
  }
`;

export interface RemoveEntityQueryType {
  excluirEntidade: ExcluirEntidadeMutation;
}

export function updateCacheRemoveEntity(id: number | string): MutationUpdaterFn<RemoveEntityQueryType | undefined> {
  return cache => {
    const data = cache.readQuery<AllEntityRelay>({ query: getAllEntityRelay });

    data?.todasEntidadesRelay.edges.filter(edge => edge?.node?.pk !== id);

    cache.writeQuery({ query: getAllEntityRelay, data });
  };
}
