import { PermissaoDescricao } from '../types/graphql';

export function removeDuplicated<T>(arr: Array<T>) {
  return arr.filter((element, index) => arr.indexOf(element) === index);
}

function captalize(name: string) {
  return name[0].toUpperCase() + name.slice(1).toLowerCase();
}

export function snackCaseToCamelWithSpace(perm: string) {
  return perm
    .split('_')
    .map(captalize)
    .join(' ');
}

enum Groups {
  ENTIDADE = 'Entidade',
  FUNCIONARIO = 'Funcionário',
  CLIENTE = 'Cliente',
  // PEDIDO_REEMBOLSO = 'Pedido de Reembolso',
  CONTA_PAGAR = 'Contas',
  EMPRESTIMO = 'Empréstimo',
  GRUPO_PERMISSOES = 'Grupo de Permissões',
  MAQUINA = 'Máquina',
  RELATORIO = 'Relatório',
  META = 'Metas',
  LEADS = 'Leads',
  TAREFAS = 'Tarefas CRM',
  ANOTACOES_HISTORICO = 'Histórico CRM',
  PARCEIRO = 'Repasses',
  SMS = 'Envios de SMS',
  ZEKI = 'Zeki',
  CONFIGURACOES = 'Config urações',
  FORNECEDOR = 'Fornecedor',
  COBRANCA = 'Cobranças',
}

type GroupsKey = keyof typeof Groups;

type ActionDescType = { displayName: string; action: PermissionAction };
type GroupDescType = { displayName: string; group: GroupsKey; permissions: ActionDescType[] };

const PermissonDisplayNames = {
  FECHAMENTO_CAIXA_RELATORIO: 'Fechamento de Caixa',
  EDITAR_LOJA_CONTA_PAGAR: 'Editar Loja',
  // PEDIDO_REEMBOLSO_RELATORIO: 'Pedido de Reembolso',
  CONTA_PAGAR_RELATORIO: 'Conta',
  DEFINIR_TAXA_SEGURANCA_ENTIDADE: 'Definir Taxa de Segurança',
  DEFINIR_TAXA_MINIMA_ENTIDADE: 'Definir Taxa Mínima',
  DEFINIR_TAXA_LUCRO_ENTIDADE: 'Definir Taxa de Lucro',
  VER_ANOTACOES_HISTORICO: 'Ver Histórico e Anotações',
  CRIAR_ANOTACOES_HISTORICO: 'Criar Anotações',
  EDITAR_ANOTACOES_HISTORICO: 'Editar Anotações',
  EXCLUIR_ANOTACOES_HISTORICO: 'Excluir Anotações',
  VER_PARCEIRO: 'Ver Parceiros',
  CRIAR_PARCEIRO: 'Criar Parceiros',
  EDITAR_PARCEIRO: 'Editar Parceiros',
  VER_TABELA_PARCEIRO: 'Ver Tabelas',
  CRIAR_TABELA_PARCEIRO: 'Criar Tabelas',
  EDITAR_TABELA_PARCEIRO: 'Editar Tabelas',
  DASHBOARD_PARCEIRO: 'Dashboard Parceiro',
  SOLICITAR_RESGATE_PARCEIRO: 'Solicitar Resgate',
  HISTORICO_RESGATE_PARCEIRO: 'Histórico de Resgate',
  HISTORICO_RESGATE_FUNCIONARIO_PARCEIRO: 'Histórico de Resgate Funcionário',
  VER_FUNCIONARIOS_REPASSE_PARCEIRO: 'Ver Repasse de Funcionários',
  CRIAR_FUNCIONARIOS_REPASSE_PARCEIRO: 'Criar Repasse de Funcionários',
  EDITAR_FUNCIONARIOS_REPASSE_PARCEIRO: 'Editar Repasse de Funcionários',
  DASHBOARD_FUNCIONARIO_PARCEIRO: 'Dashboard Repasse Funcionários',
  EMPRESTIMO_CLIENTE_RELATORIO: 'Cliente x Empréstimo',
  LEAD_MARKETING_RELATORIO: 'Marketing',
  VER_MENSAGEM_SMS: 'Ver Mensagem SMS',
  CRIAR_MENSAGEM_SMS: 'Criar Mensagem SMS',
  VER_LISTA_SMS: 'Ver Lista SMS',
  CRIAR_LISTA_SMS: 'Criar Lista SMS',
  EDITAR_LISTA_SMS: 'Editar Lista SMS',
  EXCLUIR_LISTA_SMS: 'Excluir Lista SMS',
  VER_ENVIOS_SMS: 'Ver Envios SMS',
  CRIAR_ENVIOS_SMS: 'Criar Envios SMS',
  DASHBOARD_ENVIOS_SMS: 'Dashboard envios SMS',
  VER_SOMATORIO_EMPRESTIMO_CLIENTE: 'Ver Somatório de Empréstimo',
  VER_SOMATORIO_COBRANCA: 'Ver Somatório de Cobranças',
  EDITAR_STATUSPAGO_EMPRESTIMO: 'Editar com status pago',
  SOMATORIO_REPASSE_EMPRESTIMO_RELATORIO: 'Ver Somatório de Repasses em Empréstimos',
  CARDS_LEAD_MARKETING_RELATORIO: 'Card Relatório Marketing',
  COMISSAO_FUNCIONARIO_RELATORIO: 'Comissão de Funcionário Repasse',
  SMS_RELATORIO: 'SMS/TXT',
  VER_BOT_ZEKI: 'Ver Bot',
  EDITAR_BOT_ZEKI: 'Editar Bot',
  REGRAMENTO_ENTIDADE_CONFIGURACOES: 'Regramento por Loja',
  EXCLUIR_FUNCIONARIO: 'Excluir funcionario',
  EXCLUIR_GRUPO_PERMISSOES: 'Excluir Grupo de permissões',
};

const PermissionGroup: Partial<Record<PermissaoDescricao, keyof typeof Groups>> = {
  // PEDIDO_REEMBOLSO_RELATORIO: 'RELATORIO',
  CONTA_PAGAR_RELATORIO: 'RELATORIO',
  CLIENTE_RELATORIO: 'RELATORIO',
  EMPRESTIMO_CLIENTE_RELATORIO: 'RELATORIO',
  SOMATORIO_REPASSE_EMPRESTIMO_RELATORIO: 'RELATORIO',
  SMS_RELATORIO: 'RELATORIO',
  CARDS_LEAD_MARKETING_RELATORIO: 'RELATORIO',
  COMISSAO_FUNCIONARIO_RELATORIO: 'RELATORIO',
  VER_FUNCIONARIOS_REPASSE_PARCEIRO: 'PARCEIRO',
  CRIAR_FUNCIONARIOS_REPASSE_PARCEIRO: 'PARCEIRO',
  EDITAR_FUNCIONARIOS_REPASSE_PARCEIRO: 'PARCEIRO',
  DASHBOARD_FUNCIONARIO_PARCEIRO: 'PARCEIRO',
  HISTORICO_RESGATE_FUNCIONARIO_PARCEIRO: 'PARCEIRO',
  VER_SOMATORIO_EMPRESTIMO_CLIENTE: 'CLIENTE',
  REGRAMENTO_ENTIDADE_CONFIGURACOES: 'CONFIGURACOES',
};

const ActionDisplayNames = {
  FECHAMENTO_CAIXA_RELATORIO: 'FECHAMENTO_CAIXA',
  EDITAR_LOJA_CONTA_PAGAR: 'EDITAR_LOJA',
  // PEDIDO_REEMBOLSO_RELATORIO: 'PEDIDO_REEMBOLSO',
  CONTA_PAGAR_RELATORIO: 'CONTA_PAGAR',
  VER_MENSAGEM_SMS: 'VER_MENSAGEM',
  CRIAR_MENSAGEM_SMS: 'CRIAR_MENSAGEM',
  VER_LISTA_SMS: 'VER_LISTA',
  CRIAR_LISTA_SMS: 'CRIAR_LISTA',
  EDITAR_LISTA_SMS: 'EDITAR_LISTA',
  EXCLUIR_LISTA_SMS: 'EXCLUIR_LISTA',
  VER_ENVIOS_SMS: 'VER_ENVIOS',
  CRIAR_ENVIOS_SMS: 'CRIAR_ENVIOS',
  DASHBOARD_ENVIOS_SMS: 'DASHBOARD_ENVIOS',
  DEFINIR_TAXA_SEGURANCA_ENTIDADE: 'DEFINIR_TAXA_SEGURANCA',
  DEFINIR_TAXA_MINIMA_ENTIDADE: 'DEFINIR_TAXA_MINIMA',
  DEFINIR_TAXA_LUCRO_ENTIDADE: 'DEFINIR_TAXA_LUCRO',
  VER_TABELA_PARCEIRO: 'VER_TABELA',
  CRIAR_TABELA_PARCEIRO: 'CRIAR_TABELA',
  EDITAR_TABELA_PARCEIRO: 'EDITAR_TABELA',
  SOLICITAR_RESGATE_PARCEIRO: 'SOLICITAR_RESGATE',
  HISTORICO_RESGATE_PARCEIRO: 'HISTORICO_RESGATE',
  HISTORICO_RESGATE_FUNCIONARIO_PARCEIRO: 'HISTORICO_RESGATE_FUNCIONARIO',
  VER_FUNCIONARIOS_REPASSE_PARCEIRO: 'VER_FUNCIONARIOS_REPASSE',
  CRIAR_FUNCIONARIOS_REPASSE_PARCEIRO: 'CRIAR_FUNCIONARIOS_REPASSE',
  EDITAR_FUNCIONARIOS_REPASSE_PARCEIRO: 'EDITAR_FUNCIONARIOS_REPASSE',
  DASHBOARD_FUNCIONARIO_PARCEIRO: 'DASHBOARD_FUNCIONARIO',
  EMPRESTIMO_CLIENTE_RELATORIO: 'EMPRESTIMO_CLIENTE',
  LEAD_MARKETING_RELATORIO: 'LEAD_MARKETING',
  VER_SOMATORIO_EMPRESTIMO_CLIENTE: 'VER_SOMATORIO_EMPRESTIMO',
  VER_SOMATORIO_COBRANCA: 'VER_SOMATORIO',
  EDITAR_STATUSPAGO_EMPRESTIMO: 'EDITAR_STATUSPAGO',
  SOMATORIO_REPASSE_EMPRESTIMO_RELATORIO: 'SOMATORIO_REPASSE_EMPRESTIMO',
  CARDS_LEAD_MARKETING_RELATORIO: 'CARDS_LEAD_MARKETING',
  VER_BOT_ZEKI: 'VER_BOT',
  EDITAR_BOT_ZEKI: 'EDITAR_BOT',
  REGRAMENTO_ENTIDADE_CONFIGURACOES: 'REGRAMENTO_ENTIDADE',
  COMISSAO_FUNCIONARIO_RELATORIO: 'COMISSAO_FUNCIONARIO',
};

export enum PermissionAction {
  VER = 'VER',
  CRIAR = 'CRIAR',
  EDITAR = 'EDITAR',
  EXCLUIR = 'EXCLUIR',
  SIMULAR = 'SIMULAR',
  DEFINIR_TAXA = 'DEFINIR_TAXA',
  FECHAMENTO = 'FECHAMENTO_CAIXA',
}

function permissionDisplayName(description: string): string {
  return (
    PermissonDisplayNames[description as keyof typeof PermissonDisplayNames] ??
    snackCaseToCamelWithSpace(description.split('_')[0])
  );
}

function permissionActionName(description: string): string {
  return ActionDisplayNames[description as keyof typeof ActionDisplayNames] ?? description.split('_')[0];
}

const noShowPermissions = [
  'VER_PEDIDO_REEMBOLSO',
  'CRIAR_PEDIDO_REEMBOLSO',
  'EDITAR_PEDIDO_REEMBOLSO',
  'EXCLUIR_PEDIDO_REEMBOLSO',
  'APROVAR_PEDIDO_REEMBOLSO',
  'PAGAR_PEDIDO_REEMBOLSO',
  'PEDIDO_REEMBOLSO',
  'PEDIDO_REEMBOLSO_RELATORIO',
];

function groupPermissions(permissionsDescriptions: PermissaoDescricao[]): GroupDescType[] {
  const groups = Object.keys(Groups) as Array<keyof typeof Groups>;
  const x = groups.map(group => {
    const permissions = permissionsDescriptions
      .filter(description => {
        if (Object.keys(PermissionGroup).includes(description) && String(PermissionGroup[description]) !== group) {
          return false;
        }
        if (noShowPermissions.includes(description)) return false;
        return description.includes(group);
      })
      .map<ActionDescType>(description => ({
        action: (permissionActionName(description) as unknown) as PermissionAction,
        displayName: permissionDisplayName(description),
      }));
    return { group, displayName: Groups[group], permissions };
  });
  return x;
}

export function useGroupedPermissions() {
  return groupPermissions(Object.values(PermissaoDescricao)).map(e => ({
    group: e.group,
    displayName: e.displayName,
    permissions: e.permissions.filter(
      e =>
        e.displayName !== 'SMS/TXT' &&
        e.displayName !== 'Histórico de Resgate Funcionário' &&
        e.displayName !== 'Excluir funcionario' &&
        e.displayName !== 'Excluir Grupo de permissões' &&
        e.displayName !== 'Editar Loja',
    ),
  }));
}
