import React from 'react';

import { AutoCompleteCTL } from 'components';

export const MultiSelect = ({
  isDetail,
  control,
  options,
  detailOptions,
  loading,
  disableInputs,
  name,
  label,
  onChange,
  getOptionLabel,
  selectAllOptions,
}: any) => {
  return (
    <AutoCompleteCTL
      label={label === 'Parceiros' || label === 'Funcionários' ? undefined : label}
      options={isDetail ? detailOptions : options}
      loading={isDetail ? false : loading}
      disabled={isDetail ? false : disableInputs}
      control={control}
      name={name}
      disableClearable={isDetail}
      disabledItemsFocusable={isDetail}
      selectAll={!isDetail}
      getOptionDisabled={option => isDetail}
      detailClient={isDetail}
      multiple
      onChange={([_, value]: [any, any]) => {
        if (onChange) {
          onChange();
        }
        return value;
      }}
      maxWidth="100%"
      getOptionLabel={getOptionLabel}
      labelProps={{
        fontSize: '16px',
      }}
      placeholder={!Boolean(disableInputs || options) ? 'Selecionar' : ''}
      boxHeight="86px"
      selectAllOptions={selectAllOptions}
    />
  );
};
