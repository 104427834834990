import 'styled-components/macro';
import React from 'react';

import { Box, Modal, Paper } from '@material-ui/core';
import {
  FaStoreAlt,
  FaUsers,
  FaIdBadge,
  FaFileInvoiceDollar,
  FaHandHoldingUsd,
  FaFileInvoice,
  FaCalculator,
  FaFilter,
} from 'react-icons/fa';
import { ImTarget } from 'react-icons/im';
import { Link } from 'react-router-dom';

import Container, { ContentContainer } from './styles';

import { ButtonCN } from 'components/ButtonCN';
import { IconContext } from 'components/IconContext';
import { TypographyCN } from 'components/TypographyCN';
import { PermissaoDescricao } from 'types/graphql';
import microFrontUrlWithToken from 'utils/redirectToMicroFront';
import { useGetPermissions } from 'utils/useHasPermission';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ActionsInfo = [
  {
    id: 6,
    title: 'EMPRÉSTIMO',
    description: 'Crie solicitações de empréstimo para qualquer cliente cadastrado ou novo.',
    icon: <FaFileInvoiceDollar />,
    iconColor: '#F59019',
    to: [
      { label: 'cadastrar', path: '/dashboard/emprestimos/cadastro', permission: PermissaoDescricao.CriarEmprestimo },
      { label: 'simular', path: '/dashboard/emprestimos/simulacao', permission: PermissaoDescricao.CriarEmprestimo },
    ],
  },
  {
    id: 8,
    title: 'PROSPECÇÃO',
    description: 'Visualize todos os leads, cadastre novos ou torne em clientes!',
    icon: <FaFilter />,
    iconColor: '#45A9FF',
    to: [{ label: 'cadastrar', path: '/dashboard/prospeccao/cadastro', permission: PermissaoDescricao.CriarLeads }],
  },
  {
    id: 7,
    title: 'MÁQUINAS',
    description: 'Visualize as máquinas, cadastre novas e edite as permissões.',
    icon: <FaCalculator />,
    iconColor: '#EF6915',
    to: [{ label: 'cadastrar', path: '/dashboard/maquinas/cadastro', permission: PermissaoDescricao.CriarMaquina }],
  },
  {
    id: 3,
    title: 'CLIENTE',
    description: 'Cadastro de clientes para futuras solicitações de empréstimo.',
    icon: <FaUsers />,
    iconColor: '#45A9FF',
    to: [{ label: 'cadastrar', path: '/dashboard/clientes/cadastro', permission: PermissaoDescricao.CriarCliente }],
  },
  {
    id: 4,
    title: 'CONTA',
    description: 'Cadastre contas referentes a operação da loja e solicite o pagamento dessas.',
    icon: (
      <svg width="4rem" height="4rem" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5516 0.351562H3.2C2.54375 0.351562 1.99688 0.588542 1.55938 1.0625C1.12188 1.5 0.903126 2.02865 0.903126 2.64844L0.848438 21.3516C0.848438 21.9714 1.06719 22.5182 1.50469 22.9922C1.97865 23.4297 2.54375 23.6484 3.2 23.6484H17.2C17.8563 23.6484 18.4031 23.4297 18.8406 22.9922C19.3146 22.5182 19.5516 21.9714 19.5516 21.3516V7.35156L12.5516 0.351562ZM8.94219 19L4.84063 14.8438L6.48125 13.2031L8.94219 15.7188L13.9188 10.7422L15.5594 12.3828L8.94219 19ZM11.3484 8.5V2.10156L17.8016 8.5H11.3484Z"
          fill="#24C361"
        />
      </svg>
    ),
    iconColor: '#24C361',
    to: [
      {
        label: 'cadastrar',
        path: `${microFrontUrlWithToken('contas', '/criar')}`,
        permission: PermissaoDescricao.CriarContaPagar,
      },
    ],
    onClick: () => {
      window.location.href = `${microFrontUrlWithToken('contas')}`;
    },
  },
  // {
  //   id: 5,
  //   title: 'REEMBOLSO',
  //   description: 'Solicite o reembolso de valores gastos com despesas diversas pela loja.',
  //   icon: <FaHandHoldingUsd />,
  //   iconColor: '#24C361',
  //   to: [
  //     {
  //       label: 'cadastrar',
  //       path: '/dashboard/reembolsos/cadastro',
  //       permission: PermissaoDescricao.CriarPedidoReembolso,
  //     },
  //   ],
  // },

  {
    id: 2,
    title: 'FUNCIONÁRIO',
    description: 'Funções específicas e definição de permissões de uso do sistema.',
    icon: <FaIdBadge />,
    iconColor: '#898989',
    to: [
      { label: 'cadastrar', path: '/dashboard/funcionarios/cadastro', permission: PermissaoDescricao.CriarFuncionario },
    ],
  },
  {
    id: 1,
    title: 'LOJA',
    description: 'Cadastro de loja própria, de parceiros, associados e outros.',
    icon: <FaStoreAlt />,
    iconColor: '#F59019',
    to: [{ label: 'cadastrar', path: '/dashboard/lojas/cadastro', permission: PermissaoDescricao.CriarEntidade }],
  },
  {
    id: 9,
    title: 'METAS',
    description: 'Cadastre, visualize e edite as metas. Tenha acesso ao dashboard com o desempenho de cada loja.',
    icon: <ImTarget />,
    iconColor: '#45A9FF',
    to: [{ label: 'cadastrar', path: '/dashboard/metas/cadastro', permission: PermissaoDescricao.CriarMeta }],
  },
];

export function ActionsModal({ open, onClose }: Props) {
  const token = localStorage.getItem('token');
  const Actions = React.useMemo(() => {
    if (token) return ActionsInfo;
    return [];
  }, [token]);
  const [employeePermissions] = useGetPermissions();
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
    >
      <Container onClick={e => e.currentTarget === e.target && onClose()}>
        <ContentContainer>
          container aqui
          {[...Actions]
            ?.filter(({ to: routes }) =>
              routes.map(route => route.permission).some(perm => employeePermissions.includes(perm)),
            )
            ?.map(action => (
              <Paper
                key={action.id}
                css={`
                  min-height: 300px;
                  min-width: 210px;
                  max-width: 230px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: flex-start;
                `}
                elevation={2}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#F4F4F4"
                  height="100px"
                  marginTop="48px"
                  width="100px"
                  borderRadius="50%"
                >
                  <IconContext icon={action.icon} size="4rem" color={action.iconColor} />
                </Box>
                <Box
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="space-evenly"
                  // height="30%"
                  style={{ minHeight: 120 }}
                  mb={2}
                >
                  <TypographyCN weight="bold" variant="h4" fontSize={1.8}>
                    {action.title}
                  </TypographyCN>
                  <Box textAlign="center" fontWeight="fontWeightRegular" width="80%">
                    {action.description}
                  </Box>
                </Box>
                {action.to.map(({ label, path, permission }) =>
                  employeePermissions.includes(permission) ? (
                    <Box key={path} marginBottom="12px" width="100%" display="flex" justifyContent="center">
                      <ButtonCN
                        width="90%"
                        typography="h6"
                        component={Link}
                        to={path}
                        onClick={() => {
                          action?.onClick?.();
                          onClose();
                        }}
                      >
                        {label}
                      </ButtonCN>
                    </Box>
                  ) : (
                    undefined
                  ),
                )}
              </Paper>
            ))}
        </ContentContainer>
      </Container>
    </Modal>
  );
}
