import React from 'react';

import { Box } from '@material-ui/core';
import { FaPlusCircle } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

import { PaperContainer } from './styles';

import { ButtonCN } from 'components/ButtonCN';
import { IconContext } from 'components/IconContext';
import { TypographyCN } from 'components/TypographyCN';
import { useStyledTheme } from 'styles';
import { useMobile } from 'utils/useMobile';
import 'styled-components/macro';

type HeaderCardProps = {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  registerUrl?: React.ComponentProps<typeof Link>['to'];
  value?: number;
  disablePlus?: boolean;
  color: string;
  buttonTitle?: string;
};

export function HeaderCardShip({
  icon,
  subtitle,
  title,
  registerUrl,
  disablePlus,
  value = 0,
  color,
  buttonTitle = '',
}: HeaderCardProps) {
  const theme = useStyledTheme();

  const history = useHistory();

  const lessThanXL = useMobile(1230);
  const moreThanMD = useMobile(960);

  const navigateToUrl = () => {
    if (registerUrl) {
      history.push(registerUrl as string);
    }
  };

  return (
    <PaperContainer>
      <Box display="flex" justifyContent="space-between" margin={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F4F4F4"
          height="90px"
          width="90px"
          borderRadius="50%"
          marginRight="16px"
        >
          {<IconContext size="3.5rem" icon={icon} color={color} />}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end" alignSelf="flex-end">
          <TypographyCN variant="h3">{value}</TypographyCN>
          <TypographyCN
            setColor={theme.palette.primary.main}
            align="right"
            noWrap={lessThanXL && !moreThanMD ? false : true}
          >
            {`${title} ${subtitle}`}
          </TypographyCN>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="70px"
        style={{ backgroundColor: '#EAEAEA' }}
      >
        <ButtonCN height="38px" typography="caption" onClick={navigateToUrl}>
          {buttonTitle}
        </ButtonCN>
      </Box>
    </PaperContainer>
  );
}
