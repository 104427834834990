import React from 'react';

import { Box, MenuItem } from '@material-ui/core';
import { ErrorMessage, FieldErrors } from 'react-hook-form';
import { FaRegCaretSquareDown } from 'react-icons/fa';

import { StyledSelect } from './styles';

import { TypographyCN } from 'components/TypographyCN';
import { useMobile } from 'utils/useMobile';

interface Options<T extends { id?: any; [key: string]: any } = any> {
  value?: T | string | number | string[];
  label: string;
}

type Props = {
  label?: string;
  options?: Options[];
  fullWidth?: boolean;
  labelProps?: React.ComponentProps<typeof TypographyCN>;
  boxHeight?: string;
  dropToDown?: boolean;
  name?: string;
  errorMessage?: string | FieldErrors<any>;
};

type SelectCNProps = Props & React.ComponentProps<typeof StyledSelect>;

export function SelectCN({
  label,
  options,
  fullWidth,
  labelProps,
  boxHeight,
  errorMessage,
  dropToDown,
  value = '',
  fontSize,
  alignItems,
  ...other
}: SelectCNProps) {
  const downBreakPoint = useMobile();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
      minHeight={boxHeight || '90px'}
    >
      {!!label && (
        <TypographyCN
          component="label"
          fontSize={fontSize}
          variant={downBreakPoint ? 'body1' : 'h6'}
          align="center"
          {...labelProps}
        >
          {label}
        </TypographyCN>
      )}
      <StyledSelect
        IconComponent={FaRegCaretSquareDown}
        disableUnderline={!Boolean(errorMessage)}
        fullWidth={fullWidth}
        variant="filled"
        MenuProps={
          dropToDown
            ? {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }
            : undefined
        }
        value={value}
        {...other}
      >
        {options?.map(({ label, value }, index) => (
          <MenuItem key={value?.id || index} value={value || index}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
      {errorMessage && (
        <TypographyCN component="span" variant="body2" align="center" weight="lighter" setColor="red">
          {typeof errorMessage === 'string' ? (
            errorMessage
          ) : (
            <ErrorMessage errors={errorMessage} name={other.name as string}>
              {({ message }) => message}
            </ErrorMessage>
          )}
        </TypographyCN>
      )}
    </Box>
  );
}
