import React from 'react';

import { Controller } from 'react-hook-form';

import { SelectCN } from './selectCN';

type ControlledProps = React.ComponentProps<typeof SelectCN> & Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function SelectCTL({ control, name, ...rest }: ControlledProps) {
  return (
    <Controller
      // @ts-ignore
      as={SelectCN}
      name={name ?? ''}
      control={control}
      // onChange={([event]) => event.target.value}
      fullWidth
      {...rest}
    />
  );
}
