import React from 'react';

import { TextField, Theme, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TypographyCN } from 'components';
import { useStyledTheme } from 'styles';
import { useMobile } from 'utils/useMobile';

const useStyles = makeStyles((theme: Theme) => ({
  boxField: {
    paddingBottom: '12px',
  },
  textField: {
    width: '95%',
    backgroundColor: '#fff',
    paddingTop: '16px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    margin: 0,
    '&:hover': {
      cursor: 'text',
      backgroundColor: '#EAEAEA',
    },
    '& div': {
      '&::before': {
        border: 0,
        content: '',
        width: 0,
      },
      '&::after': {
        border: 0,
      },
      '& input[type="time"]': {
        fontSize: 16,
        '&:hover': {
          cursor: 'text',
          backgroundColor: '#EAEAEA',
        },
      },
    },
  },
  labelField: {
    shrink: true,
    fontSize: '16px',
  },
}));

interface TimePickerProps {
  label?: string;
  labelProps?: React.ComponentProps<typeof TypographyCN>;
  boxHeight?: string;
  fixedHeight?: string;
  fontSize?: string;
}

export function TimePicker({
  label,
  labelProps,
  boxHeight,
  fixedHeight,
  fontSize = '16px',
  ...props
}: TimePickerProps) {
  const theme = useStyledTheme();
  const classes = useStyles();
  const isMobile = useMobile();

  const date = new Date();

  const exactDate = `${date.getHours()}:${date.getMinutes()}`;

  console.log(`${date.getHours()}:${date.getMinutes()}`);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
      minHeight={boxHeight || '90px'}
      height={fixedHeight}
      className={classes.boxField}
    >
      <TypographyCN
        component="label"
        variant={isMobile ? 'body1' : 'h6'}
        align="center"
        weight="bold"
        fontSize={fontSize}
        setColor={theme.palette.primary.main}
        {...labelProps}
      >
        {label}
      </TypographyCN>
      <TextField
        id="time"
        type="time"
        placeholder="Horário"
        // format="HH:mm"
        // defaultValue={exactDate}
        className={classes.textField}
        InputLabelProps={classes.labelField}
        inputProps={{
          step: 60,
        }}
        variant="standard"
        {...props}
      />
    </Box>
  );
}
