import React from 'react';

import { DefaultRawDatum, CommonPieProps, ResponsivePie } from '@nivo/pie';
import { formatToBRL } from 'brazilian-values';

import { TypographyCN } from 'components';

interface Props {
  data: DefaultRawDatum[];
  config?: CommonPieProps<any>;
}

export const PieGraph = ({ data, config }: Props) => (
  <ResponsivePie
    data={data}
    fit={true}
    colors={d => d.data.color}
    sliceLabel={(d: any) => formatToBRL(d.value).slice(2)}
    tooltip={(d: any) => (
      <div style={{ backgroundColor: '#FFF', padding: '8px' }}>
        <TypographyCN>
          {d.datum.data.id}:{formatToBRL(d.datum.data.value).slice(2)}
        </TypographyCN>
      </div>
    )}
    margin={{ top: 60, right: 120, bottom: 60, left: 120 }}
    innerRadius={0.5}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    borderWidth={3}
    radialLabelsTextXOffset={6}
    radialLabelsLinkOffset={3}
    radialLabelsLinkDiagonalLength={19}
    radialLabelsLinkHorizontalLength={27}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    enableSliceLabels={true}
    sliceLabelsSkipAngle={30}
    padAngle={4}
    sortByValue={true}
    {...config}
  />
);
