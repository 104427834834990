import React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';

type Props = {
  loading: boolean;
};

export function FullScreenLoading({ loading }: Props) {
  return (
    <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
