import React from 'react';

import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { InputCN } from './inputCN';

import { MaskedInput } from 'components/MaskedInput';

type Props = React.ComponentPropsWithoutRef<typeof NumberFormat> & React.ComponentPropsWithoutRef<typeof InputCN>;

type ControlledProps = {
  formated?: boolean;
  defaultValues?: string;
} & React.ComponentProps<typeof MaskedInput> &
  Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function formatter(value: string, percent?: boolean, currency?: boolean, defaultValue?: string) {
  if (!Number(value)) {
    const decimal = '0.000';
    const percentage = '0.000%';
    const cash = '';
    if (defaultValue) {
      console.log(defaultValue);

      return defaultValue;
    }
    if (currency) {
      return cash;
    }
    const final = percent ? percentage : decimal;
    return final;
  }

  if (currency) {
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value) / 100);
    return `${amount}`;
  }

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 3,
  }).format(Number(value) < 1.0 ? Number(value) : Number(value) / 1000);

  if (percent) return `${amount + '%'}`;
  return `${amount}`;
}

function TaxMaskedInput({ value, ...rest }: Props) {
  return <NumberFormat customInput={InputCN} value={value ? Number(value) * 1000 : value} {...rest} />;
}

export function TaxInput({ control, name, defaultValues, placeholder, ...rest }: ControlledProps) {
  return (
    <Controller
      as={TaxMaskedInput}
      name={name ?? ''}
      control={control}
      onChange={([arg]: any) => arg.value / 1000}
      onChangeName="onValueChange"
      placeholder={placeholder}
      fullWidth
      format={(value: string) => formatter(value, true, false, defaultValues)}
      isNumericString
      {...rest}
    />
  );
}

function BrlMaskedInput({ value, ...rest }: Props) {
  return <NumberFormat customInput={InputCN} value={value ? Number(value) * 100 : value} {...rest} />;
}

export function BrlInput({ control, name, defaultValues, ...rest }: ControlledProps) {
  return (
    <Controller
      as={BrlMaskedInput}
      name={name ?? ''}
      control={control}
      onChange={([arg]: any) => arg.value / 100}
      onChangeName="onValueChange"
      fullWidth
      format={(value: string) => formatter(value, false, true)}
      isNumericString
      {...rest}
    />
  );
}
