import { MutationUpdaterFn, gql } from 'apollo-boost';

import { EntidadeData, getEntity } from './index';

import { IncluirTelefoneDeEntidadeMutation, ExcluirTelefoneDeEntidadeMutation } from 'types/graphql';

type AddEntityPhoneType = {
  incluirTelefoneEntidade: IncluirTelefoneDeEntidadeMutation;
};

export const createEntityPhone = gql`
  mutation CreateEntityPhone($ddd: String, $descricao: String, $idEntidade: ID, $numero: String!) {
    incluirTelefoneEntidade(ddd: $ddd, descricao: $descricao, idEntidade: $idEntidade, numero: $numero) {
      ok
      telefone {
        id
        entidade {
          id
          nome
          razao
          nomeIdentificacao
        }
        ddd
        numero
        descricao
      }
    }
  }
`;

export function updateCacheEntityPhoneAdd(): MutationUpdaterFn<AddEntityPhoneType | undefined> {
  return (cache, mutationResult) => {
    const { id } = mutationResult.data?.incluirTelefoneEntidade.telefone?.entidade ?? {};
    const data = cache.readQuery<EntidadeData>({ query: getEntity, variables: { id } });

    data?.entidade.telefones.push(mutationResult.data?.incluirTelefoneEntidade.telefone!);

    cache.writeQuery({ query: getEntity, variables: { id }, data });
  };
}

type RemoveEntityPhoneType = {
  excluirTelefoneEntidade: ExcluirTelefoneDeEntidadeMutation;
};

export const DeleteEntityPhone = gql`
  mutation DeleteEntityPhone($id: ID!) {
    excluirTelefoneEntidade(id: $id) {
      ok
      telefone {
        id
        entidade {
          nome
          razao
          nomeIdentificacao
        }
        ddd
        numero
        descricao
      }
    }
  }
`;

export function updateCacheEntityPhoneRemove(): MutationUpdaterFn<RemoveEntityPhoneType | undefined> {
  return (cache, mutationResult) => {
    const { id } = mutationResult.data?.excluirTelefoneEntidade.telefone?.entidade ?? {};
    const data = cache.readQuery<EntidadeData>({ query: getEntity, variables: { id } });

    data?.entidade.telefones.filter(phone => phone.id !== mutationResult.data?.excluirTelefoneEntidade.telefone?.id);

    cache.writeQuery({ query: getEntity, variables: { id }, data });
  };
}
