import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { FaWindowClose } from 'react-icons/fa';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { ForgotPasswordButton as Button, ForgotPasswordTitle as Title, InputContainer } from '../ForgotPassword/styles';
import { alterarSenhaMutation } from './query';

import { TypographyCN } from 'components/TypographyCN';
import { AlterarSenhaFuncionarioMutation, MutationAlterarSenhaFuncionarioArgs } from 'types/graphql';

type Props = {
  open: boolean;
  success: boolean;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
} & React.ComponentPropsWithoutRef<typeof MuiDialog>;

type ChangePasswordFormType = {
  senhaAtual: string;
  senhaNova: string;
};

export function ChangePassword({ open, onClose, success, setSuccess }: Props) {
  const { control, getValues } = useForm<ChangePasswordFormType>();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  function handleClickShowNewPassword() {
    setShowNewPassword(prevState => !prevState);
  }

  const [alterarSenha, { error }] = useMutation<
    { alterarSenhaFuncionario: AlterarSenhaFuncionarioMutation },
    MutationAlterarSenhaFuncionarioArgs
  >(alterarSenhaMutation);

  async function onSubmit() {
    const { data } = await alterarSenha({
      variables: { senhaAtual: getValues().senhaAtual, senhaNova: getValues().senhaNova },
    });

    window['localStorage'].setItem('token', data?.alterarSenhaFuncionario.token as string);

    if (!error) {
      setSuccess(true);
    }
  }

  return (
    <MuiDialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          padding: 0,
        }}
        disableTypography
      >
        <IconButton onClick={onClose}>
          <FaWindowClose style={{ color: '#FF0000', fontSize: 35 }} />
        </IconButton>
      </DialogTitle>
      <Title disableTypography>Alterar Senha</Title>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <InputContainer style={{ width: 450 }}>
          <Controller
            as={
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Senha Atual"
                error={!!error}
                helperText={!!error && 'Senha Incorreta'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={e => e.preventDefault()}
                      >
                        {showPassword ? <FiEye /> : <FiEyeOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            {...{ control, name: 'senhaAtual' }}
          />
          <Controller
            as={
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={showNewPassword ? 'text' : 'password'}
                label="Senha Nova"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={e => e.preventDefault()}
                      >
                        {showNewPassword ? <FiEye /> : <FiEyeOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            {...{ control, name: 'senhaNova' }}
          />
        </InputContainer>
        {success && (
          <div>
            <TypographyCN
              style={{
                color: '#24C361',
                fontSize: 22,
                fontWeight: 300,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Senha alterada com sucesso!
            </TypographyCN>
          </div>
        )}
      </DialogContent>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <DialogActions style={{ marginBottom: '20px' }}>
          <Button onClick={onSubmit} typography="caption">
            ALTERAR
          </Button>
        </DialogActions>
      </div>
      <div
        style={{
          height: 10,
          background: '#EF6915',
        }}
      />
    </MuiDialog>
  );
}
