import React from 'react';

const http = <T>(request: RequestInfo): Promise<T> => {
  return new Promise((resolve, reject) => {
    let resp: Response;
    fetch(request)
      .then(response => {
        resp = response;
        return response.json();
      })
      .then(body => {
        if (!resp.ok) reject(body);
        if (resp.status === 400) reject(body);
        resolve(body);
      })
      .catch(reject);
  });
};

export interface ViaCepReturn {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}

export interface ViaCepDivergentReturn {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
}

export const useViaCep = () => {
  const [response, setResponse] = React.useState<ViaCepReturn>(null!);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const sendRequest = React.useCallback(async (cep: string) => {
    setLoading(true);
    setError(false);
    try {
      const res = await http<ViaCepReturn>(`https://viacep.com.br/ws/${cep}/json/`);
      setResponse(res);
      setLoading(false);
      return res;
    } catch {
      setError(true);
      setLoading(false);
      return {} as ViaCepReturn;
    }
  }, []);
  return { fetch: sendRequest, response, loading, error };
};

export type GeocondigReturn =
  | {
      results: {
        geometry: {
          location: {
            lat: number;
            lng: number;
          };
        };
      }[];
      status: 'OK';
    }
  | {
      results: Array<never>;
      status: 'ZERO_RESULTS';
    };

export const useGeocoding = () => {
  const [response, setResponse] = React.useState<GeocondigReturn>(null!);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const sendRequest = React.useCallback(async (cep: string) => {
    setLoading(true);
    setError(false);
    try {
      const res = await http<GeocondigReturn>(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
      );
      setResponse(res);
      setLoading(false);

      if (res.status !== 'OK') setError(true);

      return res;
    } catch {
      setError(true);
      setLoading(false);
      return {} as GeocondigReturn;
    }
  }, []);
  return { fetch: sendRequest, response, loading, error };
};
