import './utils/yupTranslation';

import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { hot } from 'react-hot-loader/root';
import { ModalProvider } from 'react-modal-hook';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { client } from 'api/graphql';
import { Pages } from 'pages';
import { SelectedPartnersProvider } from 'pages/Partners/Tabs/Dashboard/Contexts/SelectedPartners';
import { theme } from 'styles';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import './styles/global.css';

function App() {
  return (
    <SelectedPartnersProvider>
      <CssBaseline />
      <StylesProvider injectFirst>
        <StyledThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Router>
              <ApolloProvider client={client}>
                <SnackbarProvider maxSnack={3}>
                  <RecoilRoot>
                    <ModalContainer />
                    <ModalProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Pages />
                      </MuiPickersUtilsProvider>
                    </ModalProvider>
                  </RecoilRoot>
                </SnackbarProvider>
              </ApolloProvider>
            </Router>
          </ThemeProvider>
        </StyledThemeProvider>
      </StylesProvider>
    </SelectedPartnersProvider>
  );
}

export default hot(App);
// export default App;
