import 'styled-components/macro';
import React from 'react';

import {
  Divider,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  GridSize,
  InputLabel,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FormContextValues } from 'react-hook-form';

import { PermissionPanel } from '../../pages/Groups/Actions/Form/permissionForm';
import { useGroupedPermissions } from '../../utils/useGetPermissionFields';

import { AutoCompleteCTL, InputCTL, TypographyCN, ButtonCN, BrlInput, MaskedInputCTL, TaxInput } from 'components';
import { theme } from 'styles';
import { Masks } from 'utils/inputMasks';

type Props = {
  Options: Option[];
  formHandlers: FormContextValues<any>;
  handleButtonState: (value: boolean) => void;
  buttonState: boolean;
  resetForm?: () => void;
  title?: string;
  buttonSize?: GridSize;
  filterOptions?: any;
};

export interface Option {
  title: string;
  tipo?: 'select' | 'permission' | 'currency' | 'cnpj' | 'telefone' | 'multiSelect' | 'porcentagem';
  size?: GridSize;
  name?: string;
  description?: string;
  Props: {};
  style?: React.CSSProperties;
}

export function MoreFilters({
  Options,
  formHandlers,
  handleButtonState,
  buttonState,
  resetForm,
  title,
  filterOptions,
}: Props) {
  const { control } = formHandlers;
  const groupedPermissions = useGroupedPermissions();

  return (
    <Grid item xs={12}>
      <ExpansionPanel
        css="width: 100%; background-color: #f7f8f9"
        onChange={(_, expanded) => handleButtonState(expanded)}
        elevation={3}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <TypographyCN>{title ? title : 'Mais Filtros'}</TypographyCN>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item container xs={12} alignItems="center">
            <Grid item container spacing={2} xs={12}>
              {Options &&
                Options.map((option: Option) => {
                  if (option.tipo === 'select') {
                    return (
                      <Grid item xs={option.size ?? 2} key={option.title} style={option.style}>
                        <AutoCompleteCTL
                          label={option.title}
                          labelProps={{
                            variant: 'body1',
                            setColor: theme.palette.primary.main,
                          }}
                          control={control}
                          name={option.name ?? option.title.trim().toLowerCase()}
                          maxWidth="100%"
                          style={{ marginBottom: 20, marginTop: '0.5rem' }}
                          filterOptions={filterOptions}
                          {...option.Props}
                        />
                      </Grid>
                    );
                  }
                  if (option.tipo === 'permission') {
                    return groupedPermissions.map(grouped => (
                      <Grid xs={option.size ?? 2} item css="flex-grow: 1" key={grouped.group + grouped.displayName}>
                        <PermissionPanel
                          formControlLabelName={'permissoes'}
                          grouped={grouped}
                          formHandlers={formHandlers}
                          disabled={false}
                        />
                      </Grid>
                    ));
                  }
                  if (option.tipo === 'cnpj') {
                    return (
                      <Grid xs={option.size ?? 12} md={4} key={option.title} style={{ padding: '8px' }}>
                        <MaskedInputCTL
                          label="Filtrar por CNPJ"
                          format={Masks.CNPJ}
                          mask="_"
                          fullWidth
                          labelProps={{
                            variant: 'body1',
                            setColor: theme.palette.primary.main,
                            fontSize: '20px',
                          }}
                          control={control}
                          name="cnpj"
                        />
                      </Grid>
                    );
                  }
                  if (option.tipo === 'currency') {
                    return (
                      <Grid xs={option.size ?? 3} key={option.title} item css="flex-grow: 1">
                        <InputLabel
                          style={{
                            fontWeight: 'bold',
                            color: '#EF6915',
                            opacity: 1,
                            fontSize: '1rem',
                            lineHeight: 1.5,
                          }}
                        >
                          Filtrar por {option.title}
                        </InputLabel>
                        <BrlInput
                          defaultValues={''}
                          control={control}
                          name={option.name ?? option.title.trim().toLowerCase()}
                        />
                      </Grid>
                    );
                  }
                  if (option.tipo === 'telefone') {
                    return (
                      <Grid xs={option.size ?? 12} key={option.title} item>
                        <MaskedInputCTL
                          label="Filtrar por Telefone"
                          format={Masks.TELEFONE}
                          mask="_"
                          labelProps={{
                            variant: 'body1',
                            setColor: theme.palette.primary.main,
                          }}
                          control={control}
                          name={option.name ? option.name : option.title.trim().toLowerCase()}
                          {...option.Props}
                        />
                      </Grid>
                    );
                  }
                  if (option.tipo === 'porcentagem') {
                    return (
                      <Grid xs={option.size ?? 12} key={option.title} item>
                        <TaxInput
                          label="Filtrar por Taxa"
                          labelProps={{
                            variant: 'body1',
                            setColor: theme.palette.primary.main,
                            noWrap: true,
                          }}
                          control={control}
                          name={option.name ? option.name : option.title.trim().toLowerCase()}
                        />
                      </Grid>
                    );
                  }

                  return (
                    <Grid item key={option.title} xs={option.size ? option.size : 2}>
                      <InputCTL
                        label={`Filtrar por ${option.title}`}
                        labelProps={{
                          variant: 'body1',
                          setColor: theme.palette.primary.main,
                        }}
                        control={control}
                        name={option.name ? option.name : option.title.trim().toLowerCase()}
                        {...option.Props}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          {buttonState && (
            <>
              <Grid item container xs={6} md={2} style={{ alignSelf: 'center' }}>
                <ButtonCN
                  color="default"
                  width="100%"
                  variant="outlined"
                  height="55px"
                  typography="caption"
                  type="reset"
                  style={{ backgroundColor: '#fafafa', color: '#707070' }}
                  onClick={resetForm}
                >
                  LIMPAR FILTROS
                </ButtonCN>
              </Grid>
              <Grid item container xs={6} md={2} style={{ alignSelf: 'center' }}>
                <ButtonCN width="100%" height="55px" typography="caption" type="submit">
                  APLICAR FILTROS
                </ButtonCN>
              </Grid>
            </>
          )}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Grid>
  );
}
