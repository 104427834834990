import React from 'react';

import { Controller } from 'react-hook-form';

import { AutoCompleteCN } from './autoCompleteCN';

type ControlledProps = {
  multiple?: boolean;
  onSelectCallback?(args: any): void;
} & Omit<React.ComponentProps<typeof AutoCompleteCN>, 'onChange' | 'onFocus' | 'onBlur'> &
  Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function AutoCompleteCTL({ control, name, onSelectCallback, ...rest }: ControlledProps) {
  const handleOnChange = (args: any[]) => {
    if (onSelectCallback) {
      onSelectCallback(args[1]);
    }
    return args[1];
  };

  return <Controller as={AutoCompleteCN} name={name ?? ''} control={control} onChange={handleOnChange} {...rest} />;
}
