import { useContext } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';

import { CSSProp, ThemeContext } from 'styled-components';

export const MuiTheme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#EF6915',
        light: '#F59019',
        dark: '#D65E13',
      },
      secondary: { main: '#24c361' },
    },
    typography: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
    },
  },
  ptBR,
);

export const theme = {
  ...MuiTheme,
  palette: {
    ...MuiTheme.palette,
    typographyColor: '#454545',
    red: '#ef1d15',
    blue: '#45a9ff',
    background: {
      ...MuiTheme.palette.background,
      contrast: '#F5F7FB',
    },
  },
  notBroken: null,
};

export function useStyledTheme() {
  return useContext(ThemeContext);
}

export type ITheme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ITheme {
    notBroken: unknown;
  }
}

declare module 'react' {
  interface Attributes {
    css?: CSSProp;
  }
}
