import React from 'react';

import { Typography } from '@material-ui/core';

import { ButtonInputAdorment } from './styles';

type AdornmentProps = {
  label: string;
  icon: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  width?: string;
} & React.ComponentPropsWithoutRef<typeof ButtonInputAdorment>;

export function InputAdornmentIcon({ label, icon, ...rest }: AdornmentProps) {
  return (
    <ButtonInputAdorment focusRipple {...rest}>
      {icon}
      <Typography component="span" variant="subtitle2" color="inherit" style={{ textDecoration: 'underline' }}>
        {label}
      </Typography>
    </ButtonInputAdorment>
  );
}
