import React, { useEffect, useState } from 'react';

import { PillContainer, ShipPill } from './styles';

import { SendingType } from 'components';

export interface SectionShipPillProps {
  onChange?: (value: SendingType) => any;
  disabled?: SendingType[];
  flexDisplay?: boolean;
  selected?: SendingType;
}

export function SectionShipPill({
  onChange = () => {},
  disabled,
  flexDisplay = true,
  selected = 'sms',
}: SectionShipPillProps) {
  const [isSelectedPill, setIsSelectedPill] = useState<SendingType>(selected);

  const isSms = isSelectedPill === 'sms';
  const isEmail = isSelectedPill === 'email';
  const isWhatsapp = isSelectedPill === 'whatsapp';
  const isPush = isSelectedPill === 'push';

  useEffect(() => setIsSelectedPill(selected), [selected]);

  return (
    <PillContainer flexDisplay={flexDisplay}>
      <ShipPill
        type="button"
        isActive={isSms}
        onClick={e => {
          !e.currentTarget.disabled &&
            (() => {
              onChange('sms');
              setIsSelectedPill('sms');
            })();
        }}
        name="sms"
        disabled={disabled?.includes('sms')}
      >
        SMS
      </ShipPill>
      <ShipPill
        type="button"
        isActive={isEmail}
        onClick={e => {
          !e.currentTarget.disabled &&
            (() => {
              onChange('email');
              setIsSelectedPill('email');
            })();
        }}
        name="email"
        disabled={disabled?.includes('email')}
      >
        E-mail
      </ShipPill>
      <ShipPill
        type="button"
        isActive={isWhatsapp}
        onClick={e => {
          !e.currentTarget.disabled &&
            (() => {
              onChange('whatsapp');
              setIsSelectedPill('whatsapp');
            })();
        }}
        name="whatsapp"
        disabled={disabled?.includes('whatsapp')}
      >
        WhatsApp
      </ShipPill>
      <ShipPill
        type="button"
        isActive={isPush}
        onClick={e => {
          !e.currentTarget.disabled &&
            (() => {
              onChange('push');
              setIsSelectedPill('push');
            })();
        }}
        name="push"
        disabled={disabled?.includes('push')}
      >
        Push
      </ShipPill>
    </PillContainer>
  );
}
