import React from 'react';

import { Box } from '@material-ui/core';
import { IconContext } from 'react-icons';
import { FaQuestionCircle } from 'react-icons/fa';

import { StyledText } from './styles';

export function HelpButton() {
  return (
    <IconContext.Provider value={{ color: '#898989', size: '1.2rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="60px">
        <StyledText component="span" variant="caption" align="center">
          AJUDA
        </StyledText>
        <FaQuestionCircle />
      </Box>
    </IconContext.Provider>
  );
}
