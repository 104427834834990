import { useState } from 'react';

import { parse, stringify } from 'flatted';
import { Dictionary } from 'ts-essentials';

type Storage = {
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
};

export default function useStorage<T>(storage: Storage, key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  function setValue(value: T) {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      storage.setItem(key, stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  }

  function clean() {
    storage.removeItem(key);
  }

  return [storedValue, setValue, clean] as const;
}

function createMemoryStorage() {
  const storage: Dictionary<any> = {};
  return {
    getItem(key: string) {
      return storage[key];
    },
    setItem<T = any>(key: string, value: T) {
      storage[key] = value;
    },
  };
}

function getStorage(name: keyof Window) {
  return typeof window === 'object' && window[name] ? window[name] : createMemoryStorage();
}

/**
 * A convenient wrapper for useStorage(window.localStorage, ...)
 */
export function useLocalStorage<T = any>(key: string, defaultValue?: T) {
  return useStorage(getStorage('localStorage'), key, defaultValue);
}

/**
 * A convenient wrapper for useStorage(window.sessionStorage, ...)
 */
export function useSessionStorage<T = any>(key: string, defaultValue?: T) {
  return useStorage(getStorage('sessionStorage'), key, defaultValue);
}
