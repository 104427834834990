import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { PartnerArray } from '../History';

export interface ISelectedPartnersContextData {
  partners?: PartnerArray;
  setPartners?: Dispatch<SetStateAction<PartnerArray>>;
}

export const SelectedPartnersContext = createContext<ISelectedPartnersContextData>({});

export interface ISelectedPartnersProviderProps {
  children: ReactNode;
}

export function SelectedPartnersProvider({ children }: ISelectedPartnersProviderProps) {
  const [partners, setPartners] = useState<PartnerArray>();

  return (
    <SelectedPartnersContext.Provider value={{ partners, setPartners }}>{children}</SelectedPartnersContext.Provider>
  );
}
