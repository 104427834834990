import { gql } from 'apollo-boost';

import { EntidadeTypeRelayConnection } from 'types/graphql';

export type AllEntityRelay = {
  todasEntidadesRelay: EntidadeTypeRelayConnection;
};

export const getAllEntityRelay = gql`
  query AllEntityRelay(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $grupoIds: [ID]
    $nomeIdentificacao: String
    $nomeIdentificacao_Icontains: String
    $razao: String
    $razaoSocial: String
    $cnpj: String
    $categoria: String
    $inscricaoEstadual: String
    $municipio: String
    $tipoLoja_TipoLoja: String
  ) {
    todasEntidadesRelay(
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      grupoIds: $grupoIds
      nomeIdentificacao: $nomeIdentificacao
      nomeIdentificacao_Icontains: $nomeIdentificacao_Icontains
      razao: $razao
      municipio_Icontains: $municipio
      razao_Icontains: $razaoSocial
      cnpj: $cnpj
      categoria: $categoria
      inscricaoEstadual_Icontains: $inscricaoEstadual
      tipoLoja_TipoLoja: $tipoLoja_TipoLoja
    )
      @connection(
        key: "todasEntidades"
        filter: [
          "id"
          "nomeIdentificacao"
          "nomeIdentificacao_Icontains"
          "razao"
          "razao_Icontains"
          "cnpj"
          "tipoLoja_TipoLoja"
        ]
      ) {
      edgeCount
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          pk
          id
          nome
          razao
          nomeIdentificacao
          cnpj
          anexoCnpj
          inscricaoEstadual
          endereco
          complemento
          bairro
          municipio
          uf
          cep
          pais
          comprovanteEndereco
          tipoLoja {
            tipoLoja
          }
          latitude
          longitude
          telefones {
            id
            ddd
            numero
            descricao
          }
        }
      }
    }
  }
`;
