import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface Props {
  children: string;
  paperProps?: {};
  typographyProps?: {};
}

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    margin: 16,
    padding: 16,
    background: '#24c361',
  },
  typography: { color: '#fff' },
}));

export function Bubble({ children, typographyProps = {}, paperProps = {} }: Props) {
  const classes = useStyles();
  return (
    <>
      <div {...paperProps} className={classes.paper}>
        <Typography className={classes.typography} {...typographyProps}>
          {children}
        </Typography>
      </div>
    </>
  );
}
