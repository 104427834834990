import { atom } from 'recoil';

import { FilterType } from '../Dashboard/types';

export const sharedFilters = atom<FilterType>({
  key: 'sharedFilters',
  default: {
    dateFilter: { startDate: null, endDate: null },
    pausado: null,
    lead: null,
    produtoInteresse: null,
    qualificacao: null,
    responsavel: null,
    entidade: null,
    orderBy: ['-criado_em', '-atualizado_em', '-atualizado_em', '-atualizado_em', '-criado_em'],
    columnFilters: null,
    sourceFilter: [],
    phoneFilter: '',
    groupsFilter: [],
  },
});
