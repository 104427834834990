import React from 'react';

import { MenuItem } from '@material-ui/core';

import { StyledLabelSelect } from './styles';

type Props = {
  options: string[];
  getValue: (value: any) => void;
  value: string | number;
  defaultValue?: number;
  color?: string | undefined;
  disabled?: boolean;
  text?: string;
  name?: string;
  control?: any;
};

export function LabelSelectCN({
  options,
  getValue,
  value,
  color = '#ef6915',
  defaultValue = 0,
  text,
  disabled = false,
  name,
  control,
}: Props) {
  return (
    <StyledLabelSelect
      value={value}
      color={color}
      fontSize="36px"
      defaultValue={defaultValue}
      onChange={getValue}
      text={text}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      disableUnderline
      disabled={disabled}
      name={name}
      control={control}
    >
      {options?.map((label, index) => (
        <MenuItem key={index} value={index} className="menuItem">
          {label}
        </MenuItem>
      ))}
    </StyledLabelSelect>
  );
}
