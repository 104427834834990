import styled, { css } from 'styled-components/macro';

const disabledStyle = css`
  background-color: #0000001f;
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
`;

export const DropRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  min-height: 56px;
  width: 100%;
`;

export const ContentContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  background: #fff;
  box-shadow: 0px 3px 2px #00000005;
  border-radius: 4px 4px 0px 0px;
  margin-top: 2px;
  ${p => p.disabled && disabledStyle};
`;

export const FileList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const FileListItem = styled.li<{ disabled?: boolean }>`
  padding: 10px 0;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  ${p => p.disabled && disabledStyle};
`;
