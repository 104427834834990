import React from 'react';

import { Controller } from 'react-hook-form';

import { DateInputCN } from './DateInputComponent';

type ControlledProps = Omit<React.ComponentProps<typeof DateInputCN>, 'onChange' | 'onFocus' | 'onBlur' | 'value'> &
  Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function DateInputCTL({ control, name, ...rest }: ControlledProps) {
  return (
    <Controller
      as={DateInputCN}
      name={name ?? ''}
      control={control}
      onChange={([value]) => value}
      fullWidth
      {...rest}
    />
  );
}
