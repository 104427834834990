import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles';

import styled, { css, DefaultTheme, CSSObject } from 'styled-components';

type StyleProps = {
  width?: string;
  height?: string;
  typography?: keyof DefaultTheme['typography'];
  secondaryColor?: boolean;
  tertiaryColor?: boolean;
  // https://github.com/mui-org/material-ui/issues/15695
  [index: string]: any;
};

const secondaryColor = css`
  background: #f44336;
  &:hover {
    background: #ba000d;
  }
`;

const tertiaryColor = css`
  background: transparent;
  border: 1px solid #707070;
  border-radius: 4px;
  color: #707070;
  &:hover {
    border: darken(#707070, 0.2);
    color: darken(#707070, 0.2);
    background: transparent;
  }
`;

export const StyledButton = styled(Button).withConfig({
  shouldForwardProp: props => !['secondaryColor'].includes(props),
})<StyleProps>`
  ${p => css(p.theme.typography[p.typography || 'h5'] as CSSObject)};
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  background: ${p => p.theme.palette.secondary.main};
  height: ${p => p.height || '55px'};
  min-width: ${p => p.width || '40%'};
  font-weight: bold;

  &:hover {
    background: ${p => !p.tertiaryColor && darken(p.theme.palette.secondary.main, 0.2)};
  }

  ${p => p.secondaryColor && !p.disabled && secondaryColor}
  ${p => p.tertiaryColor && !p.disabled && tertiaryColor}
` as React.ComponentType<StyleProps & ButtonProps>;
