import React, { useState, useEffect } from 'react';

import { Box, Grid, Paper, PaperProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BarProps } from '@nivo/bar';
import { CommonPieProps } from '@nivo/pie';
import { FormContextValues } from 'react-hook-form';
import { FaChartPie, FaThList } from 'react-icons/fa';

import { UpperCaseFirst } from '../../utils/capitalize';
import { BarGraph } from '../BarGraph';
import { LinearGraph } from '../LinearGraph';
import { PieGraph } from '../PieGraph';

import { formatter, TypographyCN } from 'components';
import { AutoCompleteCTL } from 'components/SelectCN';
import { makeRenderTags } from 'components/SelectCN/styles';
import { SelectForm } from 'pages/Goals/dashboard';
import { getAlmostRandomColor } from 'pages/Goals/utils';
import { useStyledTheme } from 'styles';
import { parseBrlToNumber } from 'utils/inputMasks';

interface Props {
  graphConfig: {
    graphType: 'pie' | 'bar';
    data: Data[];
    keys: Array<string>;
    index: string;
    config?: BarProps | CommonPieProps<any>;
    linear?: boolean;
    linearValue?: string;
    height: string;
    currency?: boolean;
  };
  paperProps?: PaperProps;
  formHandlers: FormContextValues<SelectForm>;
  name: string;
  showValue?: boolean;
}

export interface Data {
  id: string;
  label: string;
  value: number;
}

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    padding: 16,
  },
}));

function getTotal(data: Data[], currency?: boolean): string {
  let aux = 0;
  data.map(data => (aux += data.value * 100));
  if (currency) return formatter(aux.toString(), false, true);
  return aux.toFixed(0);
}

export function GraphCard({ paperProps, graphConfig, formHandlers, name, showValue }: Props) {
  const classes = useStyles();

  const theme = useStyledTheme();

  const [graphData, setGraphData] = useState([{}] as Data[]);

  const [linearData, setLinearData] = useState({} as Record<string, string | number | string>);

  const [graphState, setGraphState] = useState<'pie' | 'bar'>(graphConfig.graphType);

  const { control, watch } = formHandlers;

  const handleGraphState = () => setGraphState(graphState === 'pie' ? 'bar' : 'pie');

  const isLinear = graphState === 'bar' && graphConfig.linear;

  const colors = getAlmostRandomColor(graphData.length);

  function convertToLinear(datas: Data[], linearValue: string): Record<string, string | number | string> {
    const LinearObject: Record<string, string | number | string> = {};
    datas.map((data, idx) => {
      LinearObject[data.label] = data.value;
      LinearObject[`${data.label}Color`] = colors[idx];
    });
    LinearObject['value'] = linearValue;

    return LinearObject;
  }

  function otherField(data: Data[], value: number): Data[] {
    const otherObj = { id: 'Outros', label: 'Outros', value: 0 } as Data;
    const newData = data?.reduce((acc: Data[] = [], curr) => {
      if (Boolean(curr.value >= value)) {
        acc.push(curr);
      } else {
        otherObj.value = Number(otherObj.value) + Number(curr.value);
      }
      return acc;
    }, []);
    return otherObj.value === 0 ? newData : [...newData, otherObj];
  }

  useEffect(() => {
    if (Boolean(name === 'bandeiras' || name === 'origem')) {
      const valorTotal = getTotal(watch(name), graphConfig.currency);
      const otherValue = parseBrlToNumber(valorTotal) * 0.05;
      setGraphData((watch(name) as Data[]).length >= 8 ? otherField(watch(name), otherValue) : watch(name));
    } else {
      setGraphData(watch(name));
    }
    if (graphConfig.linear && graphConfig.linearValue) {
      setLinearData(convertToLinear(watch(name), graphConfig.linearValue));
    }
  }, [graphConfig.currency, graphConfig.linear, graphConfig.linearValue, name, watch]);

  return (
    <>
      <Paper className={classes.paper} {...paperProps}>
        <Grid container spacing={2}>
          <Grid item container xs={12} direction="row" justify="space-between">
            <Grid item xs={9}>
              <AutoCompleteCTL
                label={UpperCaseFirst(name)}
                getOptionLabel={(data: any) => data.label ?? ''}
                renderTags={makeRenderTags<any>(data => data.label)}
                multiple
                selectAll
                selectAllOptions={{ id: 'selectAllId', label: `Selecionar todas ${name}` }}
                labelProps={{
                  variant: 'body1',
                  setColor: theme.palette.primary.main,
                }}
                boxHeight="70px"
                defaultValue={() => graphConfig.data.map((d: any) => d)}
                options={graphConfig.data}
                height="40px"
                fontSize="1rem"
                control={control}
                name={name}
              />
            </Grid>
            <Grid item xs={3} container justify="flex-end" alignItems="flex-end">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="56px"
                height="56px"
                bgcolor="#fff"
                boxShadow="0px 0px 5px #00000026"
                borderRadius="8px"
                style={{ cursor: 'pointer' }}
                onClick={handleGraphState}
              >
                {graphState === 'bar' ? (
                  <FaChartPie color="#F26900" size={32} />
                ) : (
                  <FaThList color="#F26900" size={32} />
                )}
              </Box>
            </Grid>
            {isLinear && (
              <Grid item xs={12} style={{ height: graphConfig.height }}>
                <div style={{ height: '3vh' }}>
                  <LinearGraph
                    index={graphConfig.index}
                    config={graphConfig.config as BarProps}
                    data={linearData ?? {}}
                    keys={graphConfig.keys}
                  />
                </div>
                <Grid container spacing={2} style={{ padding: 16, height: '100%' }}>
                  <Box display="flex" flexDirection="column" width="100%">
                    {graphData &&
                      graphData.map((data, idx) => (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          key={data.id}
                        >
                          <Box width="11px" height="9px" borderRadius="12px" marginRight="8px" bgcolor={colors[idx]} />
                          <Box width="100%" justifyContent="flex-start">
                            <Typography style={{ fontWeight: 'bold' }} variant="body1">
                              {data.label}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body2">
                              {graphConfig.currency
                                ? formatter((data.value * 100).toString(), false, true)
                                : data.value.toFixed(0)}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            )}
            {graphState === 'bar' && !isLinear && (
              <Grid item xs={12} style={{ height: graphConfig.height }}>
                <>
                  <BarGraph
                    index={graphConfig.index}
                    config={graphConfig.config as BarProps}
                    data={graphData ? graphData.map((data, idx) => ({ ...data, color: colors[idx] })) : []}
                    keys={graphConfig.keys}
                  />
                </>
              </Grid>
            )}
            {graphState === 'pie' && (
              <div style={{ display: 'flex', height: graphConfig.height, width: '100%' }}>
                <PieGraph
                  data={graphData ? graphData.map((data, idx) => ({ ...data, color: colors[idx] })) : []}
                  config={graphConfig.config as CommonPieProps<any>}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} container justify="center">
            <TypographyCN setColor="#454545" fontSize="22px">
              {getTotal(graphData, graphConfig.currency)}
            </TypographyCN>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
