import React, { useEffect, useState } from 'react';

import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  Avatar,
  Box,
  ButtonBase,
  Checkbox,
  Hidden,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import 'styled-components/macro';

import { loginUserMutation, LoginUserMutationType } from './query';
import Container, {
  LoginBox,
  LoginCard,
  LoginCardContent,
  LoginCardHeader,
  LoginFooterContainer,
  StyledLoginCheckbox,
  StyledLoginForm,
  StyledLoginHeader,
  StyledLoginHeaderCardContainer,
  StyledLoginHeaderCardTitle,
} from './styles';

import loginImg from 'assets/casa_nova_login.svg';
import { ButtonCN } from 'components';
import { ForgotPassword } from 'components/ForgotPassword';
import { MutationObterTokenArgs } from 'types/graphql';
import { useIsLoggedIn } from 'utils/useInLoggedIn';

interface InterfaceFormValues {
  username: string;
  password: string;
}

export function Login() {
  return (
    <Container>
      <Header />
      <LoginForm />
      <LoginFooter />
    </Container>
  );
}

const defaultValues: Partial<InterfaceFormValues> =
  process.env.NODE_ENV !== 'production' ? { username: 'admin@admin.com', password: 'sgi54321!' } : {};

export function LoginForm() {
  const client = useApolloClient();

  const [shouldRemember, setShouldRemember] = useState(false);
  const [login, { loading, error }] = useMutation<LoginUserMutationType, MutationObterTokenArgs>(loginUserMutation, {
    onCompleted({ obterToken }) {
      const storage = 'localStorage';
      window[storage].setItem('token', obterToken?.token as string);
      window[storage].setItem('should_remember', String(shouldRemember));
      window[storage].setItem('logged_time', format(new Date(), 'MM/dd/yyyy'));
      window[storage].setItem('employee', JSON.stringify(obterToken?.funcionario));
      client.writeData({ data: { isLoggedIn: true, employee: obterToken?.funcionario } });
    },
  });

  const { control, handleSubmit } = useForm({ defaultValues });

  function loginSubmit({ username, password }: InterfaceFormValues) {
    login({
      variables: {
        username,
        password,
      },
    });
  }

  const history = useHistory();
  const isLogged = useIsLoggedIn();
  useEffect(() => {
    if (isLogged) {
      history.push('/dashboard');
    }
  }, [isLogged, history]);

  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LoginBox display="flex" flexDirection="column" alignItems="center">
      <LoginCard>
        <LoginCardHeader avatar={<Avatar src={loginImg}>CN</Avatar>} title="SISTEMA ADMIN" />
        <LoginCardContent>
          <StyledLoginForm noValidate onSubmit={handleSubmit(loginSubmit)}>
            <Controller
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  error={!!error}
                  fullWidth
                  id="email"
                  label="E-mail"
                  autoComplete="email"
                  autoFocus
                />
              }
              {...{ control, name: 'username' }}
            />
            <Controller
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  error={!!error}
                  helperText={!!error && 'Email ou Senha invalidos'}
                  fullWidth
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={e => e.preventDefault()}
                        >
                          {showPassword ? <FiEye /> : <FiEyeOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
              {...{ control, name: 'password' }}
            />
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
              <div>
                <Link
                  variant="body2"
                  css={`
                    margin-top: 3%;
                    color: #575757;
                    margin-left: 200px;
                    @media screen and (max-width: 477px) {
                      margin-left: 260px;
                    }
                    @media screen and (max-width: 396px) {
                      margin-left: 230px;
                    }
                    @media screen and (max-width: 360px) {
                      margin-left: 200px;
                    }
                    @media screen and (max-height: 568px) {
                      margin-left: 163px;
                    }
                  `}
                  component={ButtonBase}
                  onClick={handleClickOpen}
                >
                  Esqueci a senha
                </Link>
                <ForgotPassword onClose={handleClose} open={open} />
              </div>
            </Hidden>
            <StyledLoginCheckbox
              control={<Checkbox value="remember" color="primary" />}
              label="Lembrar de mim no próximo acesso"
              onChange={(e, value) => setShouldRemember(value)}
            />
            <ButtonCN loading={loading} type="submit" width="70%" typography="body1">
              Entrar
            </ButtonCN>
          </StyledLoginForm>
        </LoginCardContent>
      </LoginCard>
      <Hidden only={['xs']}>
        <div>
          <Link
            variant="body2"
            css={`
              margin-top: 3%;
              color: #575757;
            `}
            component={ButtonBase}
            onClick={handleClickOpen}
          >
            Esqueceu a senha?
          </Link>
          <ForgotPassword onClose={handleClose} open={open} />
        </div>
      </Hidden>
    </LoginBox>
  );
}

export function LoginFooter() {
  return (
    <LoginFooterContainer>
      {/* <Typography variant="body2"> Grupo Sgi Sistema Cred @ {getYear(new Date())}</Typography> */}
    </LoginFooterContainer>
  );
}

export function Header() {
  return (
    <StyledLoginHeader>
      <StyledLoginHeaderCardContainer>
        <StyledLoginHeaderCardTitle>
          <Typography variant="body1" component="h3">
            <Box fontWeight="bold">SISTEMA ADMIN</Box>
          </Typography>
        </StyledLoginHeaderCardTitle>
      </StyledLoginHeaderCardContainer>
    </StyledLoginHeader>
  );
}
