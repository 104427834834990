import React from 'react';

import { Controller } from 'react-hook-form';

import { InputCN } from './inputCN';

type ControlledProps = React.ComponentProps<typeof InputCN> & Omit<React.ComponentProps<typeof Controller>, 'as'>;

export const InputCTL = React.memo(({ control, name, ...rest }: ControlledProps) => (
  <Controller as={InputCN} name={name ?? ''} control={control} fullWidth {...rest} />
));
