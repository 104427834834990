import { useRef, useEffect } from 'react';

export function isEmpty<T>(object: T) {
  return Object.entries(object).length === 0;
}

export function isObjectEmpty<T extends object>(object: T) {
  return Object.entries(object).length === 0 && object.constructor === Object;
}

export function toEntriesObjects<T>(object: T) {
  return Object.entries(object).map(([key, value]) => ({ [key]: value })) as Array<{ [key in keyof T]: T[key] }>;
}

export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
