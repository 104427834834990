import { Typography } from '@material-ui/core';

import { ITheme } from 'styles';

import styled from 'styled-components';

export const StyledText = styled(Typography)`
  font-weight: bold;
  color: ${({ theme }) => (theme as ITheme).palette.typographyColor};
  opacity: 0.5;
`;
