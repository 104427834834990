import React from 'react';

import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { InputCN } from 'components/InputCN';

type Props = React.ComponentPropsWithoutRef<typeof NumberFormat> & React.ComponentPropsWithoutRef<typeof InputCN>;

export function MaskedInput(props: Props) {
  return <NumberFormat customInput={InputCN} {...props} />;
}

type ControlledProps = {
  formated?: boolean;
} & React.ComponentProps<typeof MaskedInput> &
  Omit<React.ComponentProps<typeof Controller>, 'as'>;

export function MaskedInputCTL({ control, name, formated, ...rest }: ControlledProps) {
  return (
    <Controller
      as={MaskedInput}
      name={name ?? ''}
      control={control}
      onChange={([arg]) => (formated ? arg.formattedValue : arg.value)}
      onChangeName="onValueChange"
      fullWidth
      {...rest}
    />
  );
}
