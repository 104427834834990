import React, { Fragment, lazy, Suspense, useCallback, useEffect } from 'react';

import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useUpdateCheck } from 'react-update-notification';
import { useSetRecoilState } from 'recoil';

import { sharedFilters } from './CRM/atoms/filters';
import { loanFilterState } from './Loan/Create/loanState';
import { Login } from './Login';

import { Drawer, Loading } from 'components';
// import { useVerifyToken } from 'utils/updateToken';
import { useRouter } from 'utils/useRouter';

const Application = lazy(() => import('./application'));

export function Pages() {
  const { enqueueSnackbar } = useSnackbar();
  const { status, reloadPage } = useUpdateCheck({
    type: 'mount',
  });

  const { pathname } = useRouter();

  const setCrmFilters = useSetRecoilState(sharedFilters);
  const setLoanFilters = useSetRecoilState(loanFilterState);

  const prevPath = localStorage.getItem('@casanova/prevPath');

  const match = useRouteMatch({
    path: String(prevPath),
  });

  // useVerifyToken();
  useEffect(() => {
    if (match === null) {
      localStorage.setItem('@casanova/tablePageSize', '10');
      localStorage.removeItem('@casanova/tablePageSize');
    }
  }, [match, prevPath]);

  const clearCacheAndReload = useCallback(async () => {
    if ('caches' in window) {
      const cacheKeys = await window.caches.keys();
      await Promise.all(cacheKeys.map(key => window.caches.delete(key)));
    }
    reloadPage();
  }, [reloadPage]);

  useEffect(() => {
    if (status === 'available') {
      enqueueSnackbar('Uma nova versão da aplicação está disponivel!', {
        variant: 'info',
        persist: true,
        preventDuplicate: true,
        action: () => (
          <Fragment>
            <Button style={{ color: 'white' }} onClick={clearCacheAndReload}>
              Atualizar
            </Button>
          </Fragment>
        ),
      });
    }
  }, [clearCacheAndReload, enqueueSnackbar, status]);

  useEffect(() => {
    if (!pathname.includes('crm')) {
      setCrmFilters({
        dateFilter: { startDate: null, endDate: null },
        pausado: null,
        lead: null,
        produtoInteresse: null,
        qualificacao: null,
        responsavel: null,
        entidade: null,
        orderBy: ['-criado_em', '-atualizado_em', '-atualizado_em', '-atualizado_em', '-criado_em'],
        columnFilters: null,
        sourceFilter: [],
        phoneFilter: '',
        groupsFilter: [],
      });
    }
    if (!pathname.includes('emprestimos')) {
      setLoanFilters({});
    }
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <Drawer title="Sistema Admin">
          <Loading />
        </Drawer>
      }
    >
      <Switch>
        <Route path="/dashboard">
          <Drawer title="Sistema Admin">
            <Application />
          </Drawer>
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Suspense>
  );
}
